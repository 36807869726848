<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock _404img">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Error</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap _404page">
              <h5 class="heading-3">
                Or Call Toll Free : 800 - RapidRT ( 727 - 4378 )
              </h5>
              <h1 class="heading-13">
                <strong class="bold-text-4">404</strong>
              </h1>
              <div class="text-block-25">
                The Link you clicked may
                <br />be broken or the page <br />may have been removed.
              </div>
              <div class="text-block-26">VISIT THE</div>
              <div class="homecontbtn w-clearfix">
                <router-link
                  to="/store"
                  class="button cc-contact-us mobhidde homebtn w-inline-block"
                >
                  <div>Homepage</div>
                </router-link>
                <router-link
                  to="/store/contact"
                  class="button cc-contact-us mobhidde contbtn w-inline-block"
                >
                  <div>Contact</div>
                </router-link>
              </div>
              <p class="paragraph-4">
                Need a quick quote, fill out the below form and one of our
                <br />experienced sales associated will contact you shortly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
export default {
  components: {
    CommonHead,
    CommonFoot,
  },
};
</script>
<style>
</style>
