<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock 123">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Set New Password</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <b-form
                class="commonform w-clearfix"
                @submit.stop.prevent="forgotPassword"
              >
                <div class="_14"></div>
                <div class="div-block-48">
                  <div
                    :class="
                      form.errors.get('password')
                        ? 'haserror div-block-49'
                        : 'div-block-49'
                    "
                    :state="!form.errors.has('password')"
                    :invalid-feedback="form.errors.get('password')"
                  >
                    <label for="name" class="field-label-25"
                      >New Password *</label
                    >
                    <input
                      type="password"
                      class="w-input"
                      autofocus="true"
                      maxlength="256"
                      data-name="Name 3"
                      id="password"
                      v-model="form.password"
                      :state="!form.errors.has('password')"
                      @input="form.errors.clear('password')"
                    />
                    <span
                      class="error-msg"
                      v-if="form.errors.get('password')"
                      >{{ form.errors.get("password") }}</span
                    >
                  </div>
                </div>
                <div class="_14"></div>
                <div class="div-block-48">
                  <div
                    :class="
                      form.errors.get('confirm_password')
                        ? 'haserror div-block-49'
                        : 'div-block-49'
                    "
                    :state="!form.errors.has('confirm_password')"
                    :invalid-feedback="form.errors.get('confirm_password')"
                  >
                    <label for="name" class="field-label-25"
                      >Confirm Password *</label
                    >
                    <input
                      type="password"
                      class="w-input"
                      autofocus="true"
                      maxlength="256"
                      data-name="Name 3"
                      id="confirm_password"
                      v-model="form.confirm_password"
                      :state="!form.errors.has('confirm_password')"
                      @input="form.errors.clear('confirm_password')"
                    />
                    <span
                      class="error-msg"
                      v-if="form.errors.get('confirm_password')"
                      >{{ form.errors.get("confirm_password") }}</span
                    >
                  </div>
                </div>
                <div class="_14"></div>
                <b-button
                  type="submit"
                  class="button w-button"
                  block
                  variant="dark"
                  >submit</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import AuthMixin from "../../mixins/auth";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import { mapGetters } from "vuex";
import ResourceMixin from "../../mixins/resources";

export default {
  components: {
    CommonHead,
    CommonFoot,
  },
  mixins: [ResourceMixin],
  data() {
    return {
      form: new Form({
        password: "",
        confirm_password: "",
        emailpass_option: "password",
      }),

      isLoading: false,
    };
  },
  methods: {
    forgotPassword() {
      this.isLoading = true;
      this.$store
        .dispatch("newPassword", {
          token: this.$route.params.token,
          form: this.form,
        })
        .then((response) => {
          if (response.data.success) {
            /*this.$notify({
                            text: response.data.message,
                            type: "success"
                        });*/
            this.$router.push("/store/login");
            this.form = new Form({
              password: null,
              confirm_password: null,
              emailpass_option: "password",
            });
          } else {
          }
        });
      this.isLoading = false;
    },
  },
};
</script>
<style>
.login {
  width: 100%;
  overflow: hidden;
}
.login .bg-white {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.login .img-fluid {
  height: 1045px;
  object-fit: cover;
  width: 100%;
}
.login .login-form {
  max-width: 420px;
  float: right;
  width: 100%;
  margin-right: 280px;
}
.login .login-form h1 {
  margin-top: 110px;
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  line-height: 32px;
}
.login .pr-0.col-5.pl-0 {
  max-width: 43.5%;
  flex: 0 0 43.5%;
}

.md-theme-default .login-form .sign_up .forget_pass {
  float: right;
  margin: 15px 0 25px 0;
  color: #303030;
  /* text-decoration: underline; */
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #303030;
  border-radius: 0;
  padding: 0;
}
.md-theme-default .login-form .sign_up .forget_pass:hover {
  text-decoration: none;
}
#app .sign_up .md-checkbox {
  margin: 15px 0 0 0;
}
.login .md-checkbox .md-checkbox-label {
  color: #303030;
  font-weight: 400;
  font-size: 14px;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.5);
}
.common_pop .modal-dialog.modal-md {
  max-width: 440px;
  margin-top: 27.5rem;
}
.common_pop .modal-header .close {
  font-size: 24px;
  padding: 0;
  margin: 0;
  font-weight: normal;
  color: #303030;
  opacity: 1;
  position: absolute;
  right: 25px;
  top: 20px;
}
.common_pop .modal-dialog.modal-md .modal-header {
  border-bottom: 0;
}
.common_pop .modal-dialog.modal-md .modal-header h3 {
  font-size: 16px;
  text-align: center;
  display: inline-block;
  width: 100%;
  font-weight: 500;
  color: #303030;
}
.common_pop .modal-dialog.modal-md .modal-content {
  padding: 20px 40px 40px 40px;
  box-sizing: border-box;
}
.common_pop .modal-dialog.modal-md .modal-footer {
  display: none;
}
@media only screen and (max-width: 1440px) and (min-width: 1280px) {
  .login .login-form {
    margin-right: 200px;
  }
}
</style>
