import Users from "./modules/users";
import Resources from "./modules/resources";
import Product from "./modules/product";
import Ticket from "./modules/ticket";

export default {
    Users,
    Resources,
    Product,
    Ticket
};
