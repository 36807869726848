import Store from '../store';

export default {
    /**
     * Returns true if the user is logged in.
     *
     * @return {boolean}
     */
    isLoggedIn() {
        return Store.getters.user !== null && Store.getters.user.id !== 0;
    },

    /**
     * Returns true if the user is a developer.
     *
     * @return {boolean}
     */
    isDeveloper() {
        return Store.getters.isDeveloper;
    }
};
