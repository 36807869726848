// import "babel-polyfill";
import "whatwg-fetch";
import Vue from "vue";
import Vuex from "vuex";
import store from "./store";
import App from "./views/AppWF";
import router from "./router/wfRoute";
import VueLodash from "vue-lodash";
import BootstrapVue from "bootstrap-vue";
import Notifications from "vue-notification";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faGem } from "@fortawesome/free-solid-svg-icons/faGem";
import { faSync } from "@fortawesome/free-solid-svg-icons/faSync";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { faGem as farGem } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import {
  MdCheckbox,
  MdRadio,
  MdTooltip,
} from "vue-material/dist/components";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VueMeta from "vue-meta";

export const initWF = () => {
  library.add(
    faSync,
    faPlus,
    faMinus,
    faEnvelope,
    faPencilAlt,
    faCaretDown,
    faCalendarAlt,
    faStar,
    faGem,
    farStar,
    farGem,
    faSearch,
    faSignOutAlt
  );

  Vue.use(VueLodash);
  Vue.use(BootstrapVue);
  Vue.use(Vuex);
  Vue.use(Notifications);
  Vue.use(MdCheckbox);
  Vue.use(MdRadio);
  Vue.use(MdTooltip);
  Vue.use(PerfectScrollbar);
  Vue.use(VueMeta);

  Vue.component("font-awesome-icon", FontAwesomeIcon);

  require("./bootstrap");

  const _header = document.querySelector("#header");

  _header.replaceChildren();

  const header = document.createElement("div");
  header.setAttribute("id", "app");
  //<app ref="app"></app>

  header.innerHTML = `<app ref="app"></app>`;

  _header.replaceWith(header);

  document.querySelector(".top-bar").remove();

  new Vue({
    router,
    store,
    components: {
      App,
    },
  }).$mount(header);
};
