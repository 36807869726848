<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container thankyoupage">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock thankyoublock">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10 thankyoucont">
            <div class="div-block-12">
              <h4 class="heading">Forgot Password</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image thankcut"
              />
            </div>
            <div class="home-section-wrap">
              <div class="text-block-27">
                We have sent a recovery email to {{ email }}. Check your inbox
                and follow the link to verify your address.
              </div>
              <br />
              <div class="thankyou_btns">
                <a
                  class="button cc-contact-us mobhidde w-inline-block"
                  href="/store/login"
                  >Go To Login</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import { mapGetters } from "vuex";
export default {
  components: {
    CommonHead,
    CommonFoot,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.email = atob(this.$route.query.token);
  },
  data() {
    return {
      email: "",
    };
  },
};
</script>
<style>
.page--quote-thankyou .thankyou_btns a {
  margin: 10px;
}
</style>
