<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="home-content-wrap-copy">
          <div class="div-block-30">
            <h3>Customer Info</h3>
            <div class="loginform w-form" v-if="this.miniCartList.length > 0">
              <b-form class="commonform w-clearfix" @submit="submitQuote">
                <h4 class="heading-4 yourinfo">
                  <strong>Please provide your contact information</strong>
                </h4>

                <div class="_14"></div>
                <div class="div-block-48">
                  <div
                    :class="
                      form.errors.get('firstname')
                        ? 'haserror div-block-49'
                        : 'div-block-49'
                    "
                    :state="!form.errors.has('firstname')"
                    :invalid-feedback="form.errors.get('firstname')"
                  >
                    <label for="name-4" class="field-label-33"
                      >First name*</label
                    >
                    <input
                      type="text"
                      class="w-input"
                      maxlength="256"
                      v-model="form.firstname"
                      :state="!form.errors.has('firstname')"
                      @input="form.errors.clear('firstname')"
                      data-name="firstname"
                      id="firstname"
                    />
                    <span class="error-msg" v-if="form.errors.get('firstname')"
                      >Required Field</span
                    >
                  </div>
                  <div class="_35"></div>
                  <div
                    :class="
                      form.errors.get('lastname')
                        ? 'haserror div-block-50 typein'
                        : 'div-block-50 typein'
                    "
                    :state="!form.errors.has('lastname')"
                    :invalid-feedback="form.errors.get('lastname')"
                  >
                    <label for="name-4" class="field-label-34"
                      >Last name*</label
                    >
                    <input
                      type="text"
                      class="w-input"
                      maxlength="256"
                      v-model="form.lastname"
                      :state="!form.errors.has('lastname')"
                      @input="form.errors.clear('lastname')"
                      data-name="lastname"
                      id="lastname"
                    />
                    <span class="error-msg" v-if="form.errors.get('lastname')"
                      >Required Field</span
                    >
                  </div>
                </div>
                <div class="_14"></div>
                <div class="div-block-48">
                  <div
                    :class="
                      form.errors.get('country')
                        ? 'haserror div-block-49'
                        : 'div-block-49'
                    "
                    :state="!form.errors.has('country')"
                    :invalid-feedback="form.errors.get('country')"
                  >
                    <label for="name-4" class="field-label-35">country *</label>
                    <v-select
                      :options="countrylist"
                      v-model="form.country"
                      :state="!form.errors.has('country')"
                      @input="
                        form.errors.clear('country');
                        getState();
                      "
                      :reduce="(countrylist) => countrylist.value"
                      label="text"
                      id="country"
                      :clearable="false"
                    ></v-select>
                    <span class="error-msg" v-if="form.errors.get('country')"
                      >Required Field</span
                    >
                  </div>
                  <div class="_35"></div>
                  <div
                    :class="
                      form.errors.get('companyname')
                        ? 'haserror div-block-50 typein'
                        : 'div-block-50 typein'
                    "
                    :state="!form.errors.has('companyname')"
                    :invalid-feedback="form.errors.get('companyname')"
                  >
                    <label for="name-4" class="field-label-36"
                      >company name*</label
                    >
                    <input
                      type="text"
                      class="w-input"
                      maxlength="256"
                      v-model="form.companyname"
                      :state="!form.errors.has('companyname')"
                      @input="form.errors.clear('companyname')"
                      data-name="Name 3"
                      id="companyname"
                    />
                    <span
                      class="error-msg"
                      v-if="form.errors.get('companyname')"
                      >Required Field</span
                    >
                  </div>
                </div>
                <div class="_14"></div>
                <div class="div-block-48">
                  <div
                    :state="!form.errors.has('streetaddress')"
                    :class="
                      form.errors.get('streetaddress')
                        ? 'haserror forstar div-block-49'
                        : 'forstar div-block-49'
                    "
                    :invalid-feedback="form.errors.get('streetaddress')"
                  >
                    <label for="name-4" class="field-label-37"
                      >address 1*</label
                    >
                    <input
                      type="text"
                      class="w-input"
                      maxlength="256"
                      :state="!form.errors.has('streetaddress')"
                      @input="form.errors.clear('streetaddress')"
                      v-model="form.streetaddress"
                      data-name="Name 3"
                      id="streetaddress"
                    />
                    <span
                      class="error-msg"
                      v-if="form.errors.get('streetaddress')"
                      >Required Field</span
                    >
                  </div>
                  <div class="_35"></div>
                  <div
                    class="div-block-50 typein"
                    :invalid-feedback="form.errors.get('streetaddress1')"
                  >
                    <label for="name-4" class="field-label-38">address 2</label>
                    <input
                      type="text"
                      class="w-input"
                      maxlength="256"
                      v-model="form.streetaddress1"
                      data-name="streetaddress1"
                      id="streetaddress1"
                    />
                  </div>
                </div>
                <div class="_14"></div>
                <div class="div-block-48">
                  <div
                    :state="!form.errors.has('city')"
                    :class="
                      form.errors.get('city')
                        ? 'haserror forstar div-block-50 typein'
                        : 'forstar div-block-50 typein'
                    "
                    :invalid-feedback="form.errors.get('city')"
                  >
                    <label for="name-4" class="field-label-39">city*</label>
                    <input
                      type="text"
                      class="w-input"
                      maxlength="256"
                      :state="!form.errors.has('city')"
                      @input="form.errors.clear('city')"
                      v-model="form.city"
                      data-name="City"
                      id="city"
                    />
                    <span class="error-msg" v-if="form.errors.get('city')"
                      >Required Field</span
                    >
                  </div>
                  <div class="_35"></div>
                  <div
                    :state="!form.errors.has('state')"
                    :class="
                      form.errors.get('state')
                        ? 'haserror forstar div-block-49'
                        : 'forstar div-block-49'
                    "
                    :invalid-feedback="form.errors.get('state')"
                  >
                    <label for="name-4" class="field-label-40">state*</label>
                    <v-select
                      :options="statelist"
                      v-model="form.state"
                      :state="!form.errors.has('state')"
                      @input="form.errors.clear('state')"
                      :reduce="(statelist) => statelist.value"
                      label="text"
                      id="state"
                      :clearable="false"
                    ></v-select>
                    <span class="error-msg" v-if="form.errors.get('state')"
                      >Required Field</span
                    >
                  </div>
                </div>
                <div class="_14"></div>
                <div class="div-block-48">
                  <div
                    :state="!form.errors.has('zipcode')"
                    :class="
                      form.errors.get('zipcode')
                        ? 'haserror forstar div-block-49'
                        : 'forstar div-block-49'
                    "
                    :invalid-feedback="form.errors.get('zipcode')"
                  >
                    <label for="name-4" class="field-label-41"
                      >Zip/postal code*</label
                    >
                    <input
                      type="text"
                      class="w-input"
                      maxlength="256"
                      :state="!form.errors.has('zipcode')"
                      @input="form.errors.clear('zipcode')"
                      v-model="form.zipcode"
                      data-name="zipcode"
                      id="zipcode"
                    />
                    <span class="error-msg" v-if="form.errors.get('zipcode')"
                      >Required Field</span
                    >
                  </div>
                  <div class="_35"></div>
                  <div
                    :state="!form.errors.has('phoneno')"
                    :class="
                      form.errors.get('phoneno')
                        ? 'haserror forstar div-block-50 typein'
                        : 'forstar div-block-50 typein'
                    "
                    :invalid-feedback="form.errors.get('phoneno')"
                  >
                    <label for="name-4" class="field-label-42">Phone*</label>
                    <input
                      type="text"
                      class="w-input"
                      maxlength="256"
                      :state="!form.errors.has('phoneno')"
                      @input="form.errors.clear('phoneno')"
                      v-model="form.phoneno"
                      data-name="phoneno"
                      id="phoneno"
                    />
                    <span class="error-msg" v-if="form.errors.get('phoneno')"
                      >Required Field</span
                    >
                  </div>
                </div>

                <div class="_14"></div>
                <div class="div-block-48">
                  <div
                    :state="!form.errors.has('email')"
                    :class="
                      form.errors.get('email')
                        ? 'haserror div-block-49'
                        : 'div-block-49'
                    "
                    :invalid-feedback="form.errors.get('email')"
                  >
                    <label for="name" class="field-label-30">Email*</label>
                    <input
                      type="text"
                      class="w-input"
                      maxlength="256"
                      v-model="form.email"
                      :state="!form.errors.has('email')"
                      @input="form.errors.clear('email')"
                      id="email"
                    />
                    <span class="error-msg" v-if="form.errors.get('email')">{{
                      form.errors.get("email") == "The email field is required."
                        ? "Required Field"
                        : form.errors.get("email")
                    }}</span>
                  </div>
                  <div class="_35"></div>
                  <div
                    :state="!form.errors.has('findus')"
                    :class="
                      form.errors.get('findus')
                        ? 'haserror forstar div-block-49'
                        : 'forstar div-block-49'
                    "
                    :invalid-feedback="form.errors.get('findus')"
                  >
                    <label for="name-4" class="field-label-45"
                      >Where did you find us?*</label
                    >
                    <v-select
                      :options="finduslist"
                      v-model="form.findus"
                      :state="!form.errors.has('findus')"
                      @input="form.errors.clear('findus')"
                      :reduce="(finduslist) => finduslist.value"
                      label="text"
                      id="findus"
                      :clearable="false"
                      placeholder="Please select"
                    ></v-select>
                    <span class="error-msg" v-if="form.errors.get('findus')"
                      >Required Field</span
                    >
                  </div>
                </div>
                <div class="_14"></div>
                <div class="div-block-48">
                  <div
                    :state="!form.errors.has('industry')"
                    :class="
                      form.errors.get('industry')
                        ? 'haserror forstar div-block-49'
                        : 'forstar div-block-49'
                    "
                    :invalid-feedback="form.errors.get('industry')"
                  >
                    <label for="name-4" class="field-label-46"
                      >Type of industry?*</label
                    >
                    <v-select
                      :options="industrylist"
                      v-model="form.industry"
                      :state="!form.errors.has('industry')"
                      @input="form.errors.clear('industry')"
                      :reduce="(industrylist) => industrylist.value"
                      label="text"
                      id="industry"
                      :clearable="false"
                      placeholder="Please select"
                    ></v-select>
                    <span class="error-msg" v-if="form.errors.get('industry')"
                      >Required Field</span
                    >
                  </div>
                  <div class="_35"></div>
                  <div
                    :state="!form.errors.has('companytype')"
                    :class="
                      form.errors.get('companytype')
                        ? 'haserror forstar div-block-49'
                        : 'forstar div-block-49'
                    "
                    :invalid-feedback="form.errors.get('companytype')"
                  >
                    <label for="companytype" class="field-label-47"
                      >company type?*</label
                    >
                    <v-select
                      :options="companytypelist"
                      v-model="form.companytype"
                      :state="!form.errors.has('companytype')"
                      @input="form.errors.clear('companytype')"
                      :reduce="(companytypelist) => companytypelist.value"
                      label="text"
                      id="companytype"
                      :clearable="false"
                      placeholder="Please select"
                    ></v-select>
                    <span
                      class="error-msg"
                      v-if="form.errors.get('companytype')"
                      >Required Field</span
                    >
                  </div>
                </div>
                <div class="_14"></div>
                <div class="div-block-48">
                  <div class="div-block-49">
                    <label for="notes" class="field-label-47">Note</label>
                    <textarea
                      type="text"
                      class="w-input"
                      maxlength="256"
                      v-model="form.notes"
                      id="notes"
                    ></textarea>
                  </div>
                </div>

                <div class="_14"></div>
                <b-button
                  type="submit"
                  class="button w-button"
                  block
                  variant="dark"
                  >Submit Quote Request</b-button
                >
              </b-form>
            </div>
            <div v-else>
              <p class="emptyquote" style="display: none">
                Your Quote List is empty.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../mixins/resources";

export default {
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  beforeMount() {
    this.initForm();
    this.getCountry();
  },
  mounted() {
    this.loadCartList();
  },
  data() {
    return {
      loadProducts: [],
      checkboxes: [],
      products: [],
      quantity: [],
      form: {
        firstname: null,
        lastname: null,
        email: null,
        country: null,
        companyname: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
        phoneno: null,
        notes: null,
        findus: null,
        industry: null,
        companytype: null,
        products: [],
        quantity: [],
        user_id: null,
      },
      show: true,
      country: [],
      states: [],
      companytypelist: [
        { value: "Gov", text: "Gov" },
        { value: "OEM", text: "OEM" },
        { value: "FBO", text: "FBO" },
        { value: "MRO", text: "MRO" },
        { value: "Distributor", text: "Distributor" },
      ],
      industrylist: [
        { value: "Military", text: "Military" },
        { value: "Defense", text: "Defense" },
        { value: "Medical", text: "Medical" },
        { value: "Industrial", text: "Industrial" },
        { value: "Aerospace", text: "Aerospace" },
        { value: "Technology", text: "Technology" },
      ],
      finduslist: [
        { value: "Google", text: "Google" },
        { value: "Bing", text: "Bing" },
        { value: "Yahoo", text: "Yahoo" },
        { value: "Thomasnet", text: "Thomasnet" },
        { value: "Other", text: "Other" },
      ],
      countrylist: [{ value: "United States", text: "United States" }],
      statelist: [{ value: "", text: "select country first" }],
    };
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters(["user", "cartlist"]),
    miniCartList() {
      return this.cartlist.success;
    },
  },

  methods: {
    initForm() {
      this.form = new Form({
        firstname: null,
        lastname: null,
        email: null,
        country: null,
        companyname: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
        phoneno: null,
        notes: null,
        findus: null,
        industry: null,
        companytype: null,
        products: [],
        quantity: [],
        user_id: this.user.id,
      });
      //this.getState(231);
    },

    submitQuote(evt) {
      evt.preventDefault();
      let list = [];
      let qty = [];
      $.each(this.miniCartList, function (key, value) {
        //console.log(key + "=" + value.product_id);
        list.push(value.product_id);
        qty.push(value.quantity);
      });
      this.form.products = list;
      this.form.quantity = qty;

      this.$store
        .dispatch("requestQuote", {
          form: this.form,
        })
        .then(() => {
          this.$notify({
            text: "quote submitted succesfully",
            type: "success",
          });
          this.$router.push("/store/quote/thankyou");
        })
        .catch((error) => {
          if (this.form.hasErrors()) {
            this.$notify({
              text: "There is some validation error. Please correct and try again!",
              type: "error",
            });
          } else {
            this.$notify({
              text: "Something went wrong!",
              type: "error",
            });
          }
        });
    },

    rowClass(item, type) {
      return "tbRows";
    },
    loadCartList() {
      this.$store.dispatch("loadCartList").then((loadProducts) => {
        if (loadProducts.data.success) {
          this.loadProducts = loadProducts.data.success;
        }
        if (this.user.id != 0) {
          this.$store
            .dispatch("getState", this.user.country)
            .then((response) => {
              this.statelist = response.data;

              if (this.user.id != 0) {
                this.form.firstname = this.user.firstname;
                this.form.lastname = this.user.lastname;
                this.form.email = this.user.email;
                this.form.country = parseInt(this.user.country);
                this.form.companyname = this.user.companyname;
                this.form.streetaddress = this.user.streetaddress;
                this.form.streetaddress1 = this.user.streetaddress1;
                this.form.city = this.user.city;

                this.form.state = parseInt(this.user.state);

                this.form.zipcode = this.user.zipcode;
                this.form.phoneno = this.user.phoneno;
                this.form.findus = this.user.findus;
                this.form.industry = this.user.industry;
                this.form.companytype = this.user.companytype;
              }
            });
        }
      });
    },
    getCountry() {
      this.$store.dispatch("loadCountries").then((countries) => {
        this.countrylist = countries.data;
      });
    },
    getState(value) {
      this.$store.dispatch("getState", this.form.country).then((response) => {
        this.statelist = response.data;
      });
      this.form.state = parseInt(this.statelist[0].text);
    },
    gotosinglePage(productid) {
      this.$router.push({ path: "/store/product/" + productid });
    },
  },
};
</script>
<style>
.page--requestquote .quotebtn {
  position: relative;
  float: right;
  background: none;
}
.page--requestquote .quotebtn .addquote {
  color: #000 !important;
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 13px;
}
.productlisttable .table .tbColumn img.tickimg {
  display: none;
}
.page--requestquote .quotebtn .addquote:hover {
  background-color: #fff !important;
  text-decoration: underline;
  font-size: 13px;
}
.qtyfield {
  width: 80px;
}
.page--requestquote .emptyquote {
  padding: 15px;
  color: #f2612c;
  display: block;
}
.loginform.w-form {
  background: #fff;
  padding: 30px;
}
.page--requestquote .haserror input.vs__search {
  border-color: #fff;
}
.page--requestquote .samediv span {
  float: left;
  font-size: 18px;
  border: 1px solid #d2d2d2;
  margin: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 8px;
  font-weight: 500;
  color: #303030;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
.page--requestquote .samediv span.lastspan {
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 39px;
}
.page--requestquote .samediv input {
  float: left;
  max-width: 45px;
  font-size: 14px;
  height: 35px;
  text-align: center;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  border-left: 0;
  border-right: 0;
  font-weight: 500;
  color: #303030;
  padding-top: 6px;
}
</style>
