<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Create New Account</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <h4 class="heading-4">
                <strong>Your Information</strong>
              </h4>
              <div class="loginform w-form">
                <b-form class="commonform w-clearfix" @submit="onSubmit">
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :class="
                        form.errors.get('firstname')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :state="!form.errors.has('firstname')"
                      :invalid-feedback="form.errors.get('firstname')"
                    >
                      <label for="name-4" class="field-label-33"
                        >First name*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.firstname"
                        :state="!form.errors.has('firstname')"
                        @input="form.errors.clear('firstname')"
                        data-name="firstname"
                        id="firstname"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('firstname')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      :class="
                        form.errors.get('lastname')
                          ? 'haserror div-block-50 typein'
                          : 'div-block-50 typein'
                      "
                      :state="!form.errors.has('lastname')"
                      :invalid-feedback="form.errors.get('lastname')"
                    >
                      <label for="name-4" class="field-label-34"
                        >Last name*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.lastname"
                        :state="!form.errors.has('lastname')"
                        @input="form.errors.clear('lastname')"
                        data-name="lastname"
                        id="lastname"
                      />
                      <span class="error-msg" v-if="form.errors.get('lastname')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :class="
                        form.errors.get('country')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :state="!form.errors.has('country')"
                      :invalid-feedback="form.errors.get('country')"
                    >
                      <label for="name-4" class="field-label-35"
                        >country *</label
                      >
                      <v-select
                        :options="countrylist"
                        v-model="form.country"
                        :state="!form.errors.has('country')"
                        @input="
                          form.errors.clear('country');
                          getState();
                        "
                        :reduce="(countrylist) => countrylist.value"
                        label="text"
                        id="country"
                        :clearable="false"
                      ></v-select>
                      <span class="error-msg" v-if="form.errors.get('country')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      :class="
                        form.errors.get('companyname')
                          ? 'haserror div-block-50 typein'
                          : 'div-block-50 typein'
                      "
                      :state="!form.errors.has('companyname')"
                      :invalid-feedback="form.errors.get('companyname')"
                    >
                      <label for="name-4" class="field-label-36"
                        >company name*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.companyname"
                        :state="!form.errors.has('companyname')"
                        @input="form.errors.clear('companyname')"
                        data-name="Name 3"
                        id="companyname"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('companyname')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!form.errors.has('streetaddress')"
                      :class="
                        form.errors.get('streetaddress')
                          ? 'haserror forstar div-block-49'
                          : 'forstar div-block-49'
                      "
                      :invalid-feedback="form.errors.get('streetaddress')"
                    >
                      <label for="name-4" class="field-label-37"
                        >address 1*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        :state="!form.errors.has('streetaddress')"
                        @input="form.errors.clear('streetaddress')"
                        v-model="form.streetaddress"
                        data-name="Name 3"
                        id="streetaddress"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('streetaddress')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50 typein">
                      <label for="name-4" class="field-label-38"
                        >address 2</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        :state="!form.errors.has('streetaddress1')"
                        @input="form.errors.clear('streetaddress1')"
                        v-model="form.streetaddress1"
                        data-name="streetaddress1"
                        id="streetaddress1"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!form.errors.has('city')"
                      :class="
                        form.errors.get('city')
                          ? 'haserror forstar div-block-50 typein'
                          : 'forstar div-block-50 typein'
                      "
                      :invalid-feedback="form.errors.get('city')"
                    >
                      <label for="name-4" class="field-label-39">city*</label>
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        :state="!form.errors.has('city')"
                        @input="form.errors.clear('city')"
                        v-model="form.city"
                        data-name="City"
                        id="city"
                      />
                      <span class="error-msg" v-if="form.errors.get('city')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      :state="!form.errors.has('state')"
                      :class="
                        form.errors.get('state')
                          ? 'haserror forstar div-block-49'
                          : 'forstar div-block-49'
                      "
                      :invalid-feedback="form.errors.get('state')"
                    >
                      <label for="name-4" class="field-label-40">state*</label>
                      <v-select
                        :options="statelist"
                        v-model="form.state"
                        :state="!form.errors.has('state')"
                        @input="form.errors.clear('state')"
                        :reduce="(statelist) => statelist.value"
                        label="text"
                        id="state"
                        :clearable="false"
                      ></v-select>
                      <span class="error-msg" v-if="form.errors.get('state')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!form.errors.has('zipcode')"
                      :class="
                        form.errors.get('zipcode')
                          ? 'haserror forstar div-block-49'
                          : 'forstar div-block-49'
                      "
                      :invalid-feedback="form.errors.get('zipcode')"
                    >
                      <label for="name-4" class="field-label-41"
                        >Zip/postal code*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        :state="!form.errors.has('zipcode')"
                        @input="form.errors.clear('zipcode')"
                        v-model="form.zipcode"
                        data-name="zipcode"
                        id="zipcode"
                      />
                      <span class="error-msg" v-if="form.errors.get('zipcode')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      :state="!form.errors.has('phoneno')"
                      :class="
                        form.errors.get('phoneno')
                          ? 'haserror forstar div-block-50 typein'
                          : 'forstar div-block-50 typein'
                      "
                      :invalid-feedback="form.errors.get('phoneno')"
                    >
                      <label for="name-4" class="field-label-42">Phone*</label>
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        :state="!form.errors.has('phoneno')"
                        @input="form.errors.clear('phoneno')"
                        v-model="form.phoneno"
                        data-name="phoneno"
                        id="phoneno"
                      />
                      <span class="error-msg" v-if="form.errors.get('phoneno')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="name-4" class="field-label-43"
                        >Cell phone(Optional)</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.cellphone"
                        data-name="Cell phone"
                        id="name-4"
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50 typein">
                      <label for="name-4" class="field-label-44"
                        >fax(Optional)</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.faxno"
                        data-name="Fax"
                        id="fax"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!form.errors.has('findus')"
                      :class="
                        form.errors.get('findus')
                          ? 'haserror forstar div-block-49'
                          : 'forstar div-block-49'
                      "
                      :invalid-feedback="form.errors.get('findus')"
                    >
                      <label for="name-4" class="field-label-45"
                        >Where did you find us?*</label
                      >
                      <v-select
                        :options="finduslist"
                        v-model="form.findus"
                        :state="!form.errors.has('findus')"
                        @input="form.errors.clear('findus')"
                        :reduce="(finduslist) => finduslist.value"
                        label="text"
                        id="findus"
                        :clearable="false"
                        placeholder="Please select"
                      ></v-select>
                      <span class="error-msg" v-if="form.errors.get('findus')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      :state="!form.errors.has('industry')"
                      :class="
                        form.errors.get('industry')
                          ? 'haserror forstar div-block-49'
                          : 'forstar div-block-49'
                      "
                      :invalid-feedback="form.errors.get('industry')"
                    >
                      <label for="name-4" class="field-label-46"
                        >Type of industry?*</label
                      >
                      <v-select
                        :options="industrylist"
                        v-model="form.industry"
                        :state="!form.errors.has('industry')"
                        @input="form.errors.clear('industry')"
                        :reduce="(industrylist) => industrylist.value"
                        label="text"
                        id="industry"
                        :clearable="false"
                        placeholder="Please select"
                      ></v-select>
                      <span class="error-msg" v-if="form.errors.get('industry')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!form.errors.has('companytype')"
                      :class="
                        form.errors.get('companytype')
                          ? 'haserror forstar div-block-49'
                          : 'forstar div-block-49'
                      "
                      :invalid-feedback="form.errors.get('companytype')"
                    >
                      <label for="companytype" class="field-label-47"
                        >company type?*</label
                      >
                      <v-select
                        :options="companytypelist"
                        v-model="form.companytype"
                        :state="!form.errors.has('companytype')"
                        @input="form.errors.clear('companytype')"
                        :reduce="(companytypelist) => companytypelist.value"
                        label="text"
                        id="companytype"
                        :clearable="false"
                        placeholder="Please select"
                      ></v-select>
                      <span
                        class="error-msg"
                        v-if="form.errors.get('companytype')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!form.errors.has('email')"
                      :class="
                        form.errors.get('email')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :invalid-feedback="form.errors.get('email')"
                    >
                      <label for="name" class="field-label-30">Email*</label>
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.email"
                        :state="!form.errors.has('email')"
                        @input="form.errors.clear('email')"
                        id="email"
                      />
                      <span class="error-msg" v-if="form.errors.get('email')">{{
                        form.errors.get("email") ==
                        "The email field is required."
                          ? "Required Field"
                          : form.errors.get("email")
                      }}</span>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :class="
                        form.errors.get('password')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :state="!form.errors.has('password')"
                      :invalid-feedback="form.errors.get('password')"
                    >
                      <label for="name" class="field-label-31"
                        >create Password *</label
                      >
                      <input
                        type="password"
                        class="w-input"
                        maxlength="256"
                        v-model="form.password"
                        :state="!form.errors.has('password')"
                        @input="form.errors.clear('password')"
                        data-name="password"
                        id="password"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('password')"
                        >{{
                          form.errors.get("password") ==
                          "The password field is required."
                            ? "Required Field"
                            : form.errors.get("password")
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :class="
                        form.errors.get('confirm_password')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :state="!form.errors.has('confirm_password')"
                      :invalid-feedback="form.errors.get('confirm_password')"
                    >
                      <label for="name-4" class="field-label-32"
                        >confirm Password *</label
                      >
                      <input
                        type="password"
                        class="w-input"
                        maxlength="256"
                        v-model="form.confirm_password"
                        :state="!form.errors.has('confirm_password')"
                        @input="form.errors.clear('confirm_password')"
                        data-name="Name 3"
                        id="confirm_password"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('confirm_password')"
                        >{{
                          form.errors.get("confirm_password") ==
                          "The confirm_password field is required."
                            ? "Required Field"
                            : form.errors.get("confirm_password")
                        }}</span
                      >
                    </div>
                  </div>

                  <div class="_14"></div>
                  <div class="div-block-48 notification">
                    <md-checkbox
                      value="1"
                      v-model="form.newsletter"
                    ></md-checkbox>
                    <label for="newsletter" class="field-label-45"
                      >Please send me emails with discount coupons, sales
                      material and special offers.</label
                    >
                  </div>
                  <div class="_14"></div>
                  <b-button
                    type="submit"
                    class="button w-button"
                    block
                    variant="dark"
                    >Submit</b-button
                  >
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
//import Multiselect from "vue-multiselect";
import AuthMixin from "../../mixins/auth";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../../mixins/resources";

export default {
  name: "ErrorsMessages",
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  data() {
    return {
      form: {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        confirm_password: null,
        country: null,
        countrycode: null,
        companyname: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
        phoneno: null,
        cellphone: null,
        faxno: null,
        findus: null,
        industry: null,
        companytype: null,
        newsletter: 0,
      },
      checkboxes: [],
      show: true,
      country: [],
      states: [],
      companytypelist: [
        { value: "Gov", text: "Gov" },
        { value: "OEM", text: "OEM" },
        { value: "FBO", text: "FBO" },
        { value: "MRO", text: "MRO" },
        { value: "Distributor", text: "Distributor" },
      ],
      industrylist: [
        { value: "Military", text: "Military" },
        { value: "Defense", text: "Defense" },
        { value: "Medical", text: "Medical" },
        { value: "Industrial", text: "Industrial" },
        { value: "Aerospace", text: "Aerospace" },
        { value: "Technology", text: "Technology" },
      ],
      finduslist: [
        { value: "Google", text: "Google" },
        { value: "Bing", text: "Bing" },
        { value: "Yahoo", text: "Yahoo" },
        { value: "Thomasnet", text: "Thomasnet" },
        { value: "Other", text: "Other" },
      ],
      countrylist: [{ value: "United States", text: "United States" }],
      statelist: [{ value: "", text: "select country first" }],
    };
  },

  mixins: [ResourceMixin],
  beforeMount() {
    this.initForm();
    //this.isLoading = false;
    this.getCountry();
  },
  computed: {
    //...mapGetters(["leadHotel"])
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.save();
      //alert(JSON.stringify(this.form));
    },
    initForm() {
      this.form = new Form({
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        confirm_password: null,
        country: null,
        countrycode: null,
        companyname: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
        phoneno: null,
        cellphone: null,
        faxno: null,
        findus: null,
        industry: null,
        companytype: null,
        newsletter: 0,
      });
      //this.getState(231);
    },
    save() {
      this.$store
        .dispatch("registerRequest", {
          token: this.$route.query.token,
          form: this.form,
        })
        .then((response) => {
          /*this.$notify({
                        text:
                            "Thank you, Please check your email to verify your account",
                        type: "success"
                    });*/
          if (response.data.success.is_redirect == "thankyou") {
            this.$router.push("/store/quote/thankyou");
          } else {
            this.$router.push("/store/login");
          }
        })
        .catch((error) => {
          /*if (this.form.hasErrors()) {
                        this.$notify({
                            text:
                                "There is some validation error. Please correct and try again!",
                            type: "error"
                        });
                    } else {
                        this.$notify({
                            text: "Something went wrong!",
                            type: "error"
                        });
                    }*/
        });
    },

    getCountry() {
      this.$store.dispatch("loadCountries").then((countries) => {
        this.countrylist = countries.data;
      });
    },
    getState(value) {
      console.log(this.form.country);
      this.$store.dispatch("getState", this.form.country).then((response) => {
        this.statelist = response.data;
      });
    },
  },
};
</script>
<style>
span.error-msg {
  color: red !important;
  margin-top: 5px;
  display: inline-block;
}
.commonform .haserror .w-input,
.commonform .haserror .md-textarea,
.commonform .haserror .vs__dropdown-toggle {
  border: 1px solid red;
}
.test {
  background: linear-gradient(135deg, transparent 10px, #c00 0) top left,
    linear-gradient(225deg, transparent 10px, #c00 0) top right,
    linear-gradient(315deg, transparent 10px, #c00 0) bottom right,
    linear-gradient(45deg, transparent 10px, #c00 0) bottom left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}
.pointer {
  pointer-events: none;
}
.vs__selected-options input {
  border-color: #fff;
}
</style>
