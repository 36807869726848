<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="home-content-wrap-copy-copy-copy-copy">
          <div class="left-side-copy">
            <img
              src="/images/john-torcasio-DXa_QaVmM2A-unsplash-4dffa8a5.jpg"
              srcset="
                /images/john-torcasio-DXa_QaVmM2A-unsplash-4dffa8a5-p-1080.jpeg 1080w,
                /images/john-torcasio-DXa_QaVmM2A-unsplash-4dffa8a5-p-1600.jpeg 1600w,
                /images/john-torcasio-DXa_QaVmM2A-unsplash-4dffa8a5-p-2000.jpeg 2000w,
                /images/john-torcasio-DXa_QaVmM2A-unsplash-4dffa8a5-p-2600.jpeg 2600w,
                /images/john-torcasio-DXa_QaVmM2A-unsplash-4dffa8a5.jpg         2778w
              "
              sizes="100vw"
              alt=""
              class="image-7-copy"
            />
            <div class="div-block-41">
              <div class="text-block-7 h6">Manufacturer</div>
              <div>
                <div class="div-block-42"></div>
              </div>
              <img src="/images/Rapid-Icon3x-242b8823-1.png" alt="" /><img
                src="/images/keyboard_arrow_down-24px.svg"
                alt=""
                class="image-20-copy-copy"
              /><img
                src="/images/keyboard_arrow_down-24px.svg"
                alt=""
                class="image-20-copy"
              /><img
                src="/images/keyboard_arrow_down-24px.svg"
                alt=""
                class="image-20"
              />
              <div>
                <div class="div-block-42"></div>
              </div>
              <div class="text-block-7 h6">Hardware<br />Distributors</div>
              <div>
                <div class="div-block-42"></div>
              </div>
              <div class="text-block-7 h6">OEM &amp; End Users</div>
            </div>
          </div>
          <div class="div-block-28">
            <div class="div-block-10">
              <div class="div-block-12">
                <h4 class="heading abouthead">About Us</h4>
                <img
                  src="/images/Asset-1.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                />
              </div>
              <div class="account-container">
                <div class="w-richtext">
                  <h5>
                    Semi-Tubular Rivets, Drive Rivets and Solid Rivets can be
                    viewed and specified by part number through our product
                    catalog.
                  </h5>
                  <p>
                    Rapid Rivet &amp; Fastener Corp. is a master stocking
                    distributor supplying precision fasteners, such as Solid
                    Rivets and Semi-tubular Rivets, to military, aerospace and
                    commercial industries.  Rapid Rivet was established in 1985
                    to serve a growing need for lot controlled, domestically
                    manufactured rivets to multi-line hardware distributors in
                    the United States. Since then, Rapid Rivet has become a full
                    service, factory authorized supplier to fastener
                    distributors and Original Equipment Manufacturers (OEM) in
                    the United States and abroad.
                  </p>
                  <p>
                    Our continued growth can be attributed to consistently
                    supplying a quality product with total attention to customer
                    service and satisfaction. We supply all types of rivets,
                    from solid to blind to Semi-tubular Rivets, in a number of
                    different materials, such as Brass Semi-tubular Rivets or
                    Aluminum Semi-tubular Rivets, or monel solid rivets and
                    stainless steel solid rivets. By knowing your application,
                    we can help provide the correct solution.
                  </p>
                  <p>
                    Explore our catalog to find out more about our Semi-tubular
                    Rivets and other rivet products, Our sales team has many
                    years of technical expertise and complete knowledge of
                    specification requirements for the items we sell.  With our
                    “Rapid” response to your critical requirement, you too will
                    recognize the value we add to the products we supply each
                    day.  The Department of Defense, The Boeing Company, and
                    General Electric Company have been among the well known and
                    satisfied customers of Rapid Rivet &amp; Fastener.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="div-block-45">
        <div class="test-copy">
          <div class="div-block-46">
            <h6 class="overline">Our Mission</h6>
            <p>
              Our success and continued growth can be directly attributed to our
              corporate mission: To consistently provide a quality product and
              service that strive to surpass industry standards. To maintain lot
              integrity and full traceability of our inventory. To conduct
              business fairly and professionally. To place customer needs and
              requirements ahead of our own.
            </p>
          </div>
          <img src="/images/Asset-17.svg" width="800" alt="" class="image-14" />
        </div>
      </div>
    </div>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="benefits-container">
          <div class="div-block-28">
            <div class="div-block-10">
              <div class="div-block-12">
                <h4 class="heading abouthead">Benefits</h4>
                <img
                  src="/images/Asset-1.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image-copy"
                />
              </div>
              <div class="account-container">
                <div class="w-richtext">
                  <p>
                    Receiving the following benefits, we are confident we will
                    earn your trust: Technically knowledgeable sales people
                    answering all calls... not a recording. Lot controlled and
                    fully traceable inventory of mil spec and commercial
                    products. Competitive pricing on every quotation.
                  </p>
                  <p>
                    Shipment of your order within 24 hours, or the same day if
                    requested. Service before and after the sale, to guarantee
                    your satisfaction. Excellent factory authorized support and
                    technical expertise to accommodate special requirements. One
                    toll free call, to obtain quotation, information and stock
                    availability.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="div-block-54-copy-copy">
            <img
              src="/images/aircraft-compressed.jpg"
              srcset="
                /images/aircraft-compressed-p-500.jpeg  500w,
                /images/aircraft-compressed-p-800.jpeg  800w,
                /images/aircraft-compressed.jpg        1080w
              "
              sizes="(max-width: 991px) 100vw, (max-width: 1279px) 771.985107421875px, 60vw"
              alt=""
              class="image-17"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container partcont">
        <div class="div-block-12">
          <h4 class="heading parthead">Supply Partners</h4>
          <img
            src="/images/Asset-1.svg"
            width="35"
            height="35"
            alt=""
            class="image"
          />
        </div>
        <div class="div-block-10-copy-copy">
          <div class="home-section-wrap-copy-copy">
            <div class="w-layout-grid grid-2">
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-22x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-32x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-42x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-52x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-62x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-72x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-82x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-92x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-102x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-112x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-122x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-132x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-142x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-152x.png" alt="" />
              </div>
              <div class="div-block-44">
                <img
                  src="/images/Top-Arrow.svg"
                  width="35"
                  height="35"
                  alt=""
                  class="image"
                /><img src="/images/Asset-162x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="testimonail-content-wrapper">
          <div class="div-block-81">
            <div class="div-block-18">
              <h4 class="heading">Testimonials</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt=""
                class="image-copy"
              />
            </div>
            <div class="div-block-19">
              <div class="columns-3 w-row">
                <div class="w-col w-col-3 w-col-medium-6">
                  <div class="div-block-20">
                    <div>
                      <img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Dark-traignle.svg"
                        width="35"
                        height="35"
                        alt=""
                        class="image"
                      />
                    </div>
                    <h5>Benny</h5>
                    <p>
                      Needed to know how many each were in a pound of rivets.
                      Mr. Al Felice, responded the next day, great customer
                      service.
                    </p>
                  </div>
                </div>
                <div class="w-col w-col-3 w-col-medium-6">
                  <div class="div-block-20">
                    <div>
                      <img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Dark-traignle.svg"
                        width="35"
                        height="35"
                        alt=""
                        class="image"
                      />
                    </div>
                    <h5>Katy</h5>
                    <p>
                      I appreciate the quick responses when I send over an
                      e-mail. Nothing is more frustrating than vendors who take
                      a week to get back to you. Any time I have contacted
                      anyone at Rapid Rivet, they have done a nice job
                      responding to my request and any questions I might have.
                      Thank you!
                    </p>
                  </div>
                </div>
                <div class="w-col w-col-3 w-col-medium-6">
                  <div class="div-block-20">
                    <div>
                      <img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Dark-traignle.svg"
                        width="35"
                        height="35"
                        alt=""
                        class="image"
                      />
                    </div>
                    <h5>Stephanie</h5>
                    <p>
                      Kevin is ALWAYS on point! He is courteous and really knows
                      his products! I’ve never had a problem with product or
                      shipping, the Rapid Rivet team is wonderful!
                    </p>
                  </div>
                </div>
                <div class="w-col w-col-3 w-col-medium-6">
                  <div class="div-block-20">
                    <div>
                      <img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Star.svg"
                        width="21"
                        height="21"
                        alt=""
                      /><img
                        src="/images/Dark-traignle.svg"
                        width="35"
                        height="35"
                        alt=""
                        class="image"
                      />
                    </div>
                    <h5>Diane</h5>
                    <p>
                      I have been dealing with Rapid Rivet for many years. They
                      have always been prompt, efficient and courteous.
                    </p>
                  </div>
                </div>
              </div>
              <div class="div-block-21">
                <a
                  href="mailto:mail@business.com?subject=You&#x27;ve%20got%20mail!"
                  class="button secondary w-inline-block"
                >
                  <div>See More Happy Reviews</div>
                </a>
              </div>
            </div>
          </div>
          <div class="testimonial-background">
            <img
              src="/images/aircraft-compressed.jpg"
              srcset="
                /images/aircraft-compressed-p-500.jpeg  500w,
                /images/aircraft-compressed-p-800.jpeg  800w,
                /images/aircraft-compressed.jpg        1080w
              "
              sizes="100vw"
              alt=""
              class="image-17"
            />
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
export default {
  components: {
    CommonHead,
    CommonFoot,
  },
};
</script>
<style>
</style>
