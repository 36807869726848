<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage" v-if="!isLoading">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Order No. #{{ this.myOrder.OrdNum }}</h4>

              <a
                v-if="Avail"
                href="javascript:;"
                @click="GetPDF()"
                class="button cc-contact-us w-inline-block invoicebtn"
              >
                <div>Download Invoice PDF</div>
              </a>

              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <div class="col-md-6 div-block-72">
                <p>Order Total: $ {{ this.myOrder.OrigNetAmtString }}</p>
                <p>Order Date: {{ this.myOrder.OrdDate }}</p>
                <p v-if="ShipTrackUrl">Status: Order Shipped</p>
                <p v-else>Status: Order Processing</p>

                <div v-if="this.ShipTracknum" class="trackship">
                  <p>Tracking Information:</p>
                  <!-- <a class="trackUrl" v-if="this.ShipTrackUrl" target="_blank" :href="this.ShipTrackUrl">{{ this.ShipTracknum}}</a>
                                    <a v-else href="javascript:;">{{ this.ShipTracknum}}</a> -->

                  <div class="home-section-wrap summaryTable">
                    <b-table
                      show-empty
                      responsive
                      class="productlisttable"
                      :items="this.ShipTrack"
                      :fields="trkfields"
                      :tbody-tr-class="rowClass"
                    >
                      <template v-slot:cell(TrackNum)="row">
                        <div class="cell-link">{{ row.item.TrackNum }}</div>
                      </template>

                      <template v-slot:cell(link)="row">
                        <div class="cell-text">
                          <a
                            v-if="row.item.TrackingURL"
                            class="button trackbtn"
                            target="_blank"
                            :href="row.item.TrackingURL.ShipperTrackingURL"
                            >Track Now</a
                          >
                        </div>
                      </template>
                    </b-table>
                  </div>
                  <!-- <div class="trackinfo" v-if="this.ShipTrack">
                                        <div class="tracklist"  v-for="(trackinfo, ShipTrack) in this.ShipTrack" :key="ShipTrack">
                                            <span class="num">{{ trackinfo.TrackNum}}</span>
                                            <a v-if="trackinfo.TrackingURL.ShipperTrackingURL" class="button" target="_blank" :href="trackinfo.TrackingURL.ShipperTrackingURL">
                                                Track Now
                                            </a>
                                            
                                        </div>
                                       
                                    </div> -->
                </div>

                <div class="shipbill">
                  <div class="billad">
                    <h5>Billing Address</h5>
                    <p>
                      {{ this.Billto.Addr1 }}
                      <br v-if="this.Billto.Addr2" />
                      {{ this.Billto.Addr2 }}
                      <br />
                      {{ this.Billto.City }}, {{ this.Billto.State }} -
                      {{ this.Billto.Zip }},
                      <br />
                      {{ this.Billto.Country }}
                    </p>
                  </div>
                  <div class="Shipad">
                    <h5>Shipping Address</h5>
                    <p>
                      {{ this.Shipto.Addr1 }}
                      <br v-if="this.Shipto.Addr2" />
                      {{ this.Shipto.Addr2 }}
                      <br />
                      {{ this.Shipto.City }}, {{ this.Shipto.State }} -
                      {{ this.Shipto.Zip }},
                      <br />
                      {{ this.Shipto.Country }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="home-content-wrap-copy">
          <div class="div-block-30">
            <div class="text-block-4 h6">Order Summary</div>
            <div v-if="orderItems">
              <div v-if="orderItems.length > 0">
                <!-- Main table element -->
                <b-table
                  show-empty
                  responsive
                  class="productlisttable"
                  :items="orderItems"
                  :fields="fields"
                  :tbody-tr-class="rowClass"
                >
                  <template v-slot:cell(name)="row">
                    <!-- <div class="cell-link" @click="gotosinglePage(row.item.id)"> -->
                    <div class="cell-link" @click="gotosinglePage(row.item)">
                      {{ row.item.ProdCode }}
                    </div>
                  </template>
                  <template v-slot:cell(QtyShp)="row">
                    <div class="cell-text">
                      {{ row.item.More.QtyShpString }}
                    </div>
                  </template>
                  <template v-slot:cell(PriceExt)="row">
                    <div class="cell-text">
                      $ {{ row.item.More.UnitPriceString }}
                    </div>
                  </template>
                  <template v-slot:cell(total)="row"
                    >$ {{ row.item.More.ShpTotalString }}</template
                  >
                  <template v-slot:cell(certy)="row">
                    <div class="cell-text center-btn">
                      <a
                        v-if="packinglistSavedPDFAvail"
                        :id="row.item.id"
                        href="javascript:;"
                        @click="GetPackingPDF()"
                        class="button cc-contact-us w-inline-block documentbtn"
                      >
                        <div
                          style="
                            font-size: 12px;
                            word-break: normal;
                            overflow: hidden;
                            white-space: normal;
                          "
                        >
                          Download Documentation
                        </div>
                      </a>
                    </div>
                  </template>
                  <template v-slot:cell(shipdate)="row">
                    <div class="cell-text center-btn">
                      <a
                        v-if="hasDates(row.item.More.ShpSched)"
                        :id="row.item.id + 'dates'"
                        href="javascript:;"
                        @click="
                          toggleModal(row.item.More.ShpSched, row.item.ProdCode)
                        "
                        class="button cc-contact-us w-inline-block"
                      >
                        <div style="font-size: 12px">Show Dates</div>
                      </a>
                    </div>
                  </template>
                </b-table>
                <div class="account-container">
                  <div class="right-align">
                    <div class="div-block-75">
                      <p class="margin-0">
                        TOTAL ({{ orderItems.length }} ITEMS )
                        <br />
                      </p>
                    </div>
                    <div class="div-block-75-copy">
                      <p class="margin-0">
                        $ {{ this.myOrder.OrigNetAmtString }}
                        <br />
                      </p>
                    </div>
                  </div>

                  <div class="_14"></div>
                  <div class="hr"></div>
                  <div class="_14"></div>
                  <div class="div-block-76">
                    <div class="div-block-77">
                      <a
                        href="javascript:;"
                        @click="$router.go(-1)"
                        class="button cc-contact-us w-inline-block"
                      >
                        <div>Back</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <p class="emptyquote">Your Order List is empty.</p>
              </div>
            </div>
            <!-- quick quote list -->
          </div>
        </div>
      </div>
    </div>
    <div class="dates-modal" v-if="showModal">
      <div class="container">
        <div class="modal-header-ship">
          <div class="div-block-30">
            <div class="text-block-4 h6">
              Schedule Dates for {{ modalTitle }}
            </div>
            <div class="text-block-4 h6 close-btn" @click="toggleModal([], '')">
              <b-img class="loginimg" src="/images/close.svg" alt="close" />
            </div>
          </div>
        </div>
        <b-table
          show-empty
          responsive
          class="productlisttable"
          :items="shipData"
          :fields="shipFields"
          :tbody-tr-class="rowClass"
        >
          <template v-slot:cell(date)="row">
            <div class="cell-text">
              {{ row.item.SchedDate }}
            </div>
          </template>
          <template v-slot:cell(qty)="row">
            <div class="cell-text">
              {{ row.item.SchedQty }}
            </div>
          </template>
          <template v-slot:cell(cmt)="row">
            <div class="cell-text">
              {{ row.item.SchedCmt }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../../mixins/resources";

export default {
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  beforeMount() {},
  mounted() {
    this.loadmyOrder();
  },
  data() {
    return {
      bodyOriginalType: "",
      modalTitle: "",
      showModal: false,
      shipData: [],
      isLoading: true,
      myOrder: [],
      orderItems: [],
      item_total: 0.0,

      show: true,
      country: [],
      states: [],
      checkboxes: [],
      Shipto: [],
      Billto: [],
      Address: [],
      ShipInfo: [],
      ShipTrack: [],
      ShipTracknum: "",
      ShipTrackUrl: "",
      invoiceSavedPDF: [],
      packinglistSavedPDF: null,
      FormName: "",
      DocRef: "",
      Avail: false,
      packinglistSavedPDFFormName: "",
      packinglistSavedPDFDocRef: "",
      packinglistSavedPDFAvail: false,
      trkfields: [
        {
          key: "TrackNum",
          label: "Tracking Number",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "link",
          label: "Link",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
      ],
      shipFields: [
        {
          key: "date",
          label: "Schedule Date",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "qty",
          label: "Schedule Qty",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "cmt",
          label: "Schedule Comment",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
      ],
      fields: [
        {
          key: "name",
          label: "Name",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "QtyShp",
          label: "Quantity",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "PriceExt",
          label: "Price",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "total",
          label: "Total",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "certy",
          label: "Certificate",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "shipdate",
          label: "Shipment Dates",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
      ],
    };
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    rowClass(item, type) {
      return "tbRows";
    },
    toggleModal(data, title) {
      this.shipData = data;
      this.showModal = !this.showModal;
      this.modalTitle = title;
      console.log(this.shipData);
      if (this.showModal) {
        this.bodyOriginalType = window.getComputedStyle(document.body).overflow;
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = this.bodyOriginalType;
      }
    },
    hasDates(data) {
      if (data) {
        if (data.length) {
          return true;
        }
      }

      return false;
    },
    loadmyOrder() {
      this.isLoading = true;
      this.$store
        .dispatch("viewTBEorder", {
          order_id: this.$route.params.order_id,
          user_id: this.user.id,
        })
        .then((response) => {
          this.isLoading = false;
          if (!response.data.status) {
            return;
          }
          console.log(response);
          this.myOrder = response.data.data;
          console.log(this.myOrder);
          this.Address = this.myOrder.Address;
          this.Shipto = this.Address.ShipTo;
          this.Billto = this.Address.BillTo;
          if (this.myOrder.invoiceSavedPDF) {
            this.invoiceSavedPDF = this.myOrder.invoiceSavedPDF;

            this.FormName = this.invoiceSavedPDF.FormName;
            this.DocRef = this.invoiceSavedPDF.DocID;
            this.Avail = this.invoiceSavedPDF.Avail;
            // this.Avail = (this.DocRef || "").length > 0;
          }
          if (this.myOrder.packinglistSavedPDF) {
            this.packinglistSavedPDF = this.myOrder.packinglistSavedPDF;

            this.packinglistSavedPDFFormName =
              this.packinglistSavedPDF.FormName;
            this.packinglistSavedPDFDocRef = this.packinglistSavedPDF.DocID;
            this.packinglistSavedPDFAvail = this.packinglistSavedPDF.Avail;
            // this.Avail = (this.DocRef || "").length > 0;
          }
          if (this.myOrder.ShipTrack) {
            if (this.myOrder.ShipTrack.length) {
              this.ShipTrack = this.myOrder.ShipTrack;
              this.ShipTracknum = this.myOrder.ShipTrack[0].TrackNum;
              this.ShipTrackUrl =
                this.myOrder.ShipTrack[0].TrackingURL.ShipperTrackingURL;
            }
            console.log(this.myOrder.ShipTrack);
          }

          // console.log(this.Shipto.Addr1);
          this.orderItems = this.myOrder.Detail;
          console.log(this.orderItems);
          // this.item_total = response.data.item_total;
        });
    },

    gotosinglePage(product) {
      if (product.id) {
        this.$router.push({ path: `/store/product/${product.id}` });
        return;
      }
      this.$router.push({ path: "/store/product/" + product.ProdID });
    },
    GetPDF() {
      /* TBE pdf */

      this.$store
        .dispatch("loadPackingPDF", {
          /* FormName: this.FormName,*/
          FormName: "invoice",
          DocRef: this.DocRef,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", "invoice.pdf");
          document.body.appendChild(link);
          link.click();
        });

      /* our pdf */
      /*this.$store
                .dispatch("loadInvoicePDF", {
                    order_id: this.$route.params.order_id
                })
                .then(response => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;

                    link.setAttribute("download", "invoice.pdf");
                    document.body.appendChild(link);
                    link.click();
                });*/
    },
    GetPackingPDF() {
      console.log(this.FormName);
      this.$store
        .dispatch("loadPackingPDF", {
          /* FormName: this.FormName,*/
          FormName: "packing-list",
          DocRef: this.packinglistSavedPDFDocRef,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", "packinglist.pdf");
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
<style>
.trackUrl {
  font-weight: bold;
}
.page--myquote .quotebtn {
  position: relative;
  float: right;
  background: none;
}
.page--myquote .quotebtn .addquote {
  color: #000 !important;
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 13px;
}
.productlisttable .table .tbColumn img.tickimg {
  display: none;
}
.page--myquote .quotebtn .addquote:hover {
  background-color: #fff !important;
  text-decoration: underline;
  font-size: 13px;
}
.qtyfield {
  width: 80px;
}
.emptyquote {
  padding: 15px;
  color: #f2612c;
}
.loginform.w-form {
  background: #fff;
  padding: 30px;
}
.page--myquote .haserror input.vs__search {
  border-color: #fff;
}
.page--myquote .samediv span {
  float: left;
  font-size: 18px;
  border: 1px solid #d2d2d2;
  margin: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 8px;
  font-weight: 500;
  color: #303030;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
.page--myquote .samediv span.lastspan {
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 39px;
}
.page--myquote .samediv input {
  float: left;
  max-width: 45px;
  font-size: 14px;
  height: 35px;
  text-align: center;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  border-left: 0;
  border-right: 0;
  font-weight: 500;
  color: #303030;
  padding-top: 6px;
}
.page--myquote a.specsheet:hover {
  background: #efefef;
  color: #000;
}
.page--myquote a.specsheet {
  background: #efefef;
  color: #000;
  text-decoration: underline;
}
.trackship .trackinfo .tracklist span.num {
  float: left;
  margin: 10px;
}
.trackship .trackinfo .tracklist {
  margin-bottom: 10px;
}
.center-btn {
  display: flex;
  justify-content: center;
}

.dates-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
}

.modal-header-ship > div {
  display: flex;
}
.modal-header-ship > div > div:first-child {
  flex: 1;
  margin: 0px;
}
.modal-header-ship > div > div {
  margin: 0px !important;
}
.close-btn {
  cursor: pointer;
  width: 50px;
}
</style>
