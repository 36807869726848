<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Forgot Password</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <b-form
                class="commonform w-clearfix"
                @submit.stop.prevent="forgotPassword"
              >
                <div class="_14"></div>
                <div class="div-block-48">
                  <div
                    :state="!form.errors.has('email')"
                    :class="
                      form.errors.get('email')
                        ? 'haserror div-block-49'
                        : 'div-block-49'
                    "
                    :invalid-feedback="form.errors.get('email')"
                  >
                    <label for="name" class="field-label-24">Email*</label>
                    <input
                      type="text"
                      class="w-input"
                      autofocus="true"
                      maxlength="256"
                      v-model="form.email"
                      :state="!form.errors.has('email')"
                      @input="form.errors.clear('email')"
                      data-name="Name 3"
                      id="email"
                    />
                    <span class="error-msg" v-if="form.errors.get('email')">{{
                      form.errors.get("email")
                    }}</span>
                  </div>
                </div>

                <b-button
                  type="submit"
                  class="button w-button"
                  block
                  variant="dark"
                  >Request New Password</b-button
                >

                <span class="success" v-if="this.successMessage">{{
                  this.successMessage
                }}</span>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import AuthMixin from "../../mixins/auth";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";

export default {
  components: {
    CommonHead,
    CommonFoot,
  },
  mixins: [AuthMixin],
  data() {
    return {
      form: new Form({
        email: "",
      }),
      isLoading: false,
      successMessage: "",
    };
  },
  methods: {
    forgotPassword() {
      // evt.preventDefault();
      this.isLoading = true;
      this.$store.dispatch("forgotPassword", this.form).then((response) => {
        if (response.data.success) {
          /* this.$notify({
                        text: response.data.message,
                        type: "success"
                    });*/
          this.$router.push(
            "/forgot-password-message?token=" + btoa(this.form.email)
          );
          this.successMessage = "Password Recovery Email Sent";

          this.form = new Form({
            email: null,
          });
        } else {
          /*this.$notify({
                        text: response.data.message,
                        type: "error"
                    });*/
        }
      });
      this.isLoading = false;
    },
  },
};
</script>
<style>
.commonform .haserror .w-input,
.commonform .haserror .md-textarea,
.commonform .haserror .vs__dropdown-toggle {
  border: 1px solid red;
}
span.error-msg {
  color: red !important;
  margin-top: 5px;
  display: inline-block;
}
</style