<template>
  <div>
    <div class="footer">
      <div class="div-block-24">
        <div class="columns-2 w-row">
          <div class="column footernav w-col w-col-3">
            <img
              src="/images/Rapid-Rivet-New-Logodf-f28a3fbf.svg"
              alt
              class="image-5 footlogo"
            />
            <div class="h6 navhead">Site Map</div>
            <div class="div-block-25 footmenu">
              <div class="margih-right w-clearfix">
                <a
                  href="index.html"
                  aria-current="page"
                  class="footer-link w--current"
                  >Home</a
                >
                <a href="about.html" class="footer-link">About Us</a>
                <a href="/store/quote-list" class="footer-link">Quick Quote</a>
                <a href="#" class="footer-link">Service</a>
                <a href="#" class="footer-link">Quality</a>
              </div>
              <div class="margin-right w-clearfix">
                <a href="#" class="footer-link">Terms</a>
                <a href="#" class="footer-link">My Account</a>
                <a href="#" class="footer-link">Contact</a>
                <a href="#" class="footer-link">Quote list</a>
              </div>
            </div>
          </div>
          <div class="column-2 w-col w-col-3">
            <div class="h6">Social</div>
            <div class="div-block-26">
              <a
                href="https://www.facebook.com/rapidrivet/"
                target="_blank"
                class="facebook w-inline-block"
              ></a>
              <a
                href="https://twitter.com/rapidrivet"
                target="_blank"
                class="twitter w-inline-block"
              ></a>
            </div>
          </div>
          <div class="column-3 w-col w-col-3">
            <div class="h6">Contact</div>
            <p>
              Rapid Rivet &amp; Fastener Corp.
              <br />121 Toledo Street <br />Farmingdale NY 11735
            </p>
          </div>
          <div class="column-4 w-col w-col-3">
            <div class="lastcont w-clearfix">
              <div class="h6">Phone</div>
              <a class="link-5">Toll Free: 800-RAPIDRT (727-4378)</a>
              <a class="link-5">Fax: 631-249-0401</a>
              <img
                src="/images/Cards-High-Res.jpg"
                width="210"
                class="image-30"
              />
            </div>
            <p class="paragraph-copy phonrcopy">
              Toll Free: 800-RAPIDRT (727-4378)
              <br />Fax: 631-249-0401
            </p>
          </div>
        </div>
        <img
          src="/images/Asset-1.svg"
          width="35"
          height="35"
          alt
          class="image"
        />
      </div>
      <div class="footend">
        <div class="div-block-94 w-clearfix">
          <div class="all-rights-reserved desktopcopy">
            © Rapid Rivet &amp; Fastener Corporation
            {{ new Date().getFullYear() }}. All rights reserved.
          </div>

          <a
            :href="user.web_flow_url + '/terms-conditions'"
            target="_blank"
            class="footer-link"
            >Vendor Terms of Services</a
          >
          <a
            :href="user.web_flow_url + '/customer-terms-conditions'"
            target="_blank"
            class="footer-link"
            >Customer Terms of Services</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ResourceMixin from "../mixins/resources";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
<style>
</style>