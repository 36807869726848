<template>
  <div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <router-view></router-view>
    <notifications position="bottom right" />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    axios.interceptors.request.use(
      (config) => {
        if (config.cancelToken) {
        } else {
          this.isLoading = true;
        }
        return config;
      },
      (error) => {
        this.isLoading = false;
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.isLoading = false;
        return response;
      },
      (error) => {
        this.isLoading = false;
        return Promise.reject(error);
      }
    );
  },
  components: {
    Loading,
  },
  watch: {
    $route: {
      handler(to, from) {
        const body = document.getElementsByTagName("body")[0];
        if (from.name) {
          body.classList.remove("page--" + from.name.toLowerCase());
        }
        if (to.name) {
          body.classList.add("page--" + to.name.toLowerCase());
        }
      },
    },
  },
};
</script>

<style>
</style>