<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="quick-list-rename">
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">{{ this.headerTitle }}</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <form
              id="email-form-2"
              name="email-form-2"
              data-name="Email Form 2"
              @submit="onSubmit"
            >
              <div class="home-section-wrap">
                <div class="w-form">
                  <!-- start contect info section -->
                  <div class="contact-info-section">
                    <div class="_14"></div>
                    <h5>Contact Information</h5>
                    <div class="div-block-48">
                      <div
                        :class="
                          form.errors.get('firstname')
                            ? 'haserror div-block-49'
                            : 'div-block-49'
                        "
                        :state="!form.errors.has('firstname')"
                        :invalid-feedback="form.errors.get('firstname')"
                      >
                        <label for="name-4" class="field-label-33"
                          >First name *</label
                        >
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          v-model="form.firstname"
                          :state="!form.errors.has('firstname')"
                          @input="form.errors.clear('firstname')"
                          data-name="firstname"
                          id="firstname"
                        />
                        <span
                          class="error-msg"
                          v-if="form.errors.get('firstname')"
                          >Required Field</span
                        >
                      </div>
                      <div class="_35"></div>
                      <div
                        :class="
                          form.errors.get('lastname')
                            ? 'haserror div-block-50 typein'
                            : 'div-block-50 typein'
                        "
                        :state="!form.errors.has('lastname')"
                        :invalid-feedback="form.errors.get('lastname')"
                      >
                        <label for="name-4" class="field-label-34"
                          >Last name*</label
                        >
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          v-model="form.lastname"
                          :state="!form.errors.has('lastname')"
                          @input="form.errors.clear('lastname')"
                          data-name="lastname"
                          id="lastname"
                        />
                        <span
                          class="error-msg"
                          v-if="form.errors.get('lastname')"
                          >Required Field</span
                        >
                      </div>
                      <div class="_35"></div>
                      <div
                        :class="
                          form.errors.get('email')
                            ? 'haserror div-block-50'
                            : 'div-block-50'
                        "
                        :state="!form.errors.has('email')"
                        :invalid-feedback="form.errors.get('email')"
                      >
                        <label for="name">EMail *</label>
                        <input
                          type="email"
                          class="w-input"
                          maxlength="256"
                          v-model="form.email"
                          :state="!form.errors.has('email')"
                          @input="form.errors.clear('email')"
                          data-name="email"
                          id="email"
                        />
                        <span class="error-msg" v-if="form.errors.get('email')"
                          >Required Field</span
                        >
                      </div>
                    </div>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :class="
                          form.errors.get('phone')
                            ? 'haserror div-block-49'
                            : 'div-block-49'
                        "
                        :state="!form.errors.has('phone')"
                        :invalid-feedback="form.errors.get('phone')"
                      >
                        <label for="name">Phone *</label>
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          v-model="form.phone"
                          :state="!form.errors.has('phone')"
                          @input="form.errors.clear('phone')"
                          data-name="phone"
                          id="phone"
                        />
                        <span class="error-msg" v-if="form.errors.get('phone')"
                          >Required Field</span
                        >
                      </div>
                      <div class="_35"></div>
                      <div
                        :class="
                          form.errors.get('company_name')
                            ? 'haserror div-block-49'
                            : 'div-block-49'
                        "
                        :state="!form.errors.has('company_name')"
                        :invalid-feedback="form.errors.get('company_name')"
                      >
                        <label for="name">Company Name *</label>
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          v-model="form.company_name"
                          :state="!form.errors.has('company_name')"
                          @input="form.errors.clear('company_name')"
                          data-name="company_name"
                          id="company_name"
                        />
                        <span
                          class="error-msg"
                          v-if="form.errors.get('company_name')"
                        >
                          Required Field
                        </span>
                      </div>
                      <div class="_35"></div>
                      <div
                        :class="
                          form.errors.get('preferred_invoice_email')
                            ? 'haserror div-block-49'
                            : 'div-block-49'
                        "
                        :state="!form.errors.has('preferred_invoice_email')"
                        :invalid-feedback="
                          form.errors.get('preferred_invoice_email')
                        "
                      >
                        <label for="name">Preferred Invoice Email *</label>
                        <input
                          type="email"
                          class="w-input"
                          maxlength="256"
                          v-model="form.preferred_invoice_email"
                          :state="!form.errors.has('preferred_invoice_email')"
                          @input="form.errors.clear('preferred_invoice_email')"
                          data-name="preferred_invoice_email"
                          id="preferred_invoice_email"
                        />
                        <span
                          class="error-msg"
                          v-if="form.errors.get('preferred_invoice_email')"
                        >
                          Required Field
                        </span>
                      </div>
                    </div>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :class="
                          form.errors.get('CustPONum')
                            ? 'haserror div-block-50'
                            : 'div-block-50'
                        "
                        :state="!form.errors.has('CustPONum')"
                        :invalid-feedback="form.errors.get('CustPONum')"
                      >
                        <label for="name">Customer PO Number *</label>
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          name="CustPONum"
                          v-model="form.CustPONum"
                          :state="!form.errors.has('CustPONum')"
                          @input="form.errors.clear('CustPONum')"
                          data-name="CustPONum"
                          id="CustPONum"
                        />
                        <span
                          class="error-msg"
                          v-if="form.errors.get('CustPONum')"
                          >Required Field</span
                        >
                      </div>
                      <div class="_35"></div>
                      <div class="div-block-49"></div>
                      <div class="_35"></div>
                      <div class="div-block-49"></div>
                    </div>
                  </div>

                  <!-- end contect info section -->
                  <!-- start billing address section -->
                  <div class="billing-address-section">
                    <div class="_14"></div>
                    <h5>Billing Address</h5>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :state="!form.errors.has('billing_streetaddress')"
                        :class="
                          form.errors.get('billing_streetaddress')
                            ? 'haserror forstar div-block-49'
                            : 'forstar div-block-49'
                        "
                        :invalid-feedback="
                          form.errors.get('billing_streetaddress')
                        "
                      >
                        <label for="name-4" class="field-label-37">
                          address 1*
                        </label>
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          :state="!form.errors.has('billing_streetaddress')"
                          @input="form.errors.clear('billing_streetaddress')"
                          v-model="form.billing_streetaddress"
                          data-name="Name 3"
                          id="billing_streetaddress"
                        />
                        <span
                          class="error-msg"
                          v-if="form.errors.get('billing_streetaddress')"
                        >
                          Required Field
                        </span>
                      </div>
                      <div class="_35"></div>
                      <div class="div-block-50 typein">
                        <label for="name-4" class="field-label-38">
                          address 2
                        </label>
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          v-model="form.billing_streetaddress1"
                          data-name="billing_streetaddress1"
                          id="billing_streetaddress1"
                        />
                      </div>
                      <div class="_35"></div>
                      <div
                        :state="!form.errors.has('billing_city')"
                        :class="
                          form.errors.get('billing_city')
                            ? 'haserror forstar div-block-50 typein'
                            : 'forstar div-block-50 typein'
                        "
                        :invalid-feedback="form.errors.get('billing_city')"
                      >
                        <label for="name-4" class="field-label-39">city*</label>
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          :state="!form.errors.has('billing_city')"
                          @input="form.errors.clear('billing_city')"
                          v-model="form.billing_city"
                          data-name="City"
                          id="billing_city"
                        />
                        <span
                          class="error-msg"
                          v-if="form.errors.get('billing_city')"
                        >
                          Required Field
                        </span>
                      </div>
                    </div>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :class="
                          form.errors.get('billing_country')
                            ? 'haserror div-block-49'
                            : 'div-block-49'
                        "
                        :state="!form.errors.has('billing_country')"
                        :invalid-feedback="form.errors.get('billing_country')"
                      >
                        <label for="name-4" class="field-label-35">
                          country *
                        </label>
                        <v-select
                          :options="countrylist"
                          v-model="form.billing_country"
                          :state="!form.errors.has('billing_country')"
                          @input="
                            form.errors.clear('billing_country');
                            getBillingState();
                            changeCountry();
                          "
                          :reduce="(countrylist) => countrylist.value"
                          label="text"
                          id="billing_country"
                          :clearable="false"
                        ></v-select>
                        <span
                          class="error-msg"
                          v-if="form.errors.get('billing_country')"
                        >
                          Required Field
                        </span>
                      </div>
                      <div class="_35"></div>

                      <div
                        :state="!form.errors.has('billing_state')"
                        :class="
                          form.errors.get('billing_state')
                            ? 'haserror forstar div-block-49'
                            : 'forstar div-block-49'
                        "
                        :invalid-feedback="form.errors.get('billing_state')"
                      >
                        <label for="name-4" class="field-label-40">
                          state *
                        </label>
                        <v-select
                          :options="billing_statelist"
                          v-model="form.billing_state"
                          :state="!form.errors.has('billing_state')"
                          @input="form.errors.clear('billing_state')"
                          :reduce="
                            (billing_statelist) => billing_statelist.value
                          "
                          label="text"
                          id="billing_state"
                          :clearable="false"
                        ></v-select>
                        <span
                          class="error-msg"
                          v-if="form.errors.get('billing_state')"
                        >
                          Required Field
                        </span>
                      </div>
                      <div class="_35"></div>
                      <div
                        :state="!form.errors.has('billing_zipcode')"
                        :class="
                          form.errors.get('billing_zipcode')
                            ? 'haserror forstar div-block-49'
                            : 'forstar div-block-49'
                        "
                        :invalid-feedback="form.errors.get('billing_zipcode')"
                      >
                        <label for="name-4" class="field-label-41">
                          Zip *
                        </label>
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          :state="!form.errors.has('billing_zipcode')"
                          @input="form.errors.clear('billing_zipcode')"
                          v-model="form.billing_zipcode"
                          data-name="billing_zipcode"
                          id="billing_zipcode"
                        />
                        <span
                          class="error-msg"
                          v-if="form.errors.get('billing_zipcode')"
                        >
                          Required Field
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end billing address section -->

                  <!-- start shipping address section -->
                  <div class="shipping-address-section">
                    <div class="_14"></div>
                    <h5>Shipping Address</h5>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <md-radio
                        value="same"
                        v-model="form.address_option"
                        :class="'is_primary'"
                        @change="changeAddress()"
                      >
                        Same as billing address.
                      </md-radio>
                      <div class="_35"></div>
                      <md-radio
                        value="different"
                        v-model="form.address_option"
                        :class="'is_primary'"
                        @change="changeAddress()"
                      >
                        Use different shipping address.
                      </md-radio>
                    </div>

                    <!-- billing address different -->
                    <div class="billing_differ">
                      <div class="_14"></div>
                      <div class="div-block-49">
                        <label for="name-4" class="field-label-40">
                          Saved Address
                        </label>
                        <v-select
                          :options="this.saved_address"
                          v-model="form.saved_address"
                          :reduce="(saved_address) => saved_address.value"
                          @input="GetAddress()"
                          label="text"
                          id="saved_address"
                          :clearable="false"
                          placeholder="Select Address"
                        ></v-select>
                      </div>
                      <div class="_14"></div>
                      <div class="div-block-48">
                        <div
                          :state="!form.errors.has('streetaddress')"
                          :class="
                            form.errors.get('streetaddress')
                              ? 'haserror forstar div-block-49'
                              : 'forstar div-block-49'
                          "
                          :invalid-feedback="form.errors.get('streetaddress')"
                        >
                          <label for="name-4" class="field-label-37">
                            address 1*
                          </label>
                          <input
                            type="text"
                            class="w-input"
                            maxlength="256"
                            :state="!form.errors.has('streetaddress')"
                            @input="form.errors.clear('streetaddress')"
                            v-model="form.streetaddress"
                            data-name="Name 3"
                            id="streetaddress"
                          />
                          <span
                            class="error-msg"
                            v-if="form.errors.get('streetaddress')"
                          >
                            Required Field
                          </span>
                        </div>
                        <div class="_35"></div>
                        <div class="div-block-50 typein">
                          <label for="name-4" class="field-label-38">
                            address 2
                          </label>
                          <input
                            type="text"
                            class="w-input"
                            maxlength="256"
                            v-model="form.streetaddress1"
                            data-name="streetaddress1"
                            id="streetaddress1"
                          />
                        </div>
                        <div class="_35"></div>
                        <div
                          :state="!form.errors.has('city')"
                          :class="
                            form.errors.get('city')
                              ? 'haserror forstar div-block-50 typein'
                              : 'forstar div-block-50 typein'
                          "
                          :invalid-feedback="form.errors.get('city')"
                        >
                          <label for="name-4" class="field-label-39">
                            city*
                          </label>
                          <input
                            type="text"
                            class="w-input"
                            maxlength="256"
                            :state="!form.errors.has('city')"
                            @input="form.errors.clear('city')"
                            v-model="form.city"
                            data-name="City"
                            id="city"
                          />
                          <span
                            class="error-msg"
                            v-if="form.errors.get('city')"
                          >
                            Required Field
                          </span>
                        </div>
                      </div>
                      <div class="_14"></div>
                      <div class="div-block-48">
                        <div
                          :class="
                            form.errors.get('country')
                              ? 'haserror div-block-49'
                              : 'div-block-49'
                          "
                          :state="!form.errors.has('country')"
                          :invalid-feedback="form.errors.get('country')"
                        >
                          <label for="name-4" class="field-label-35">
                            country *
                          </label>
                          <v-select
                            :options="countrylist"
                            v-model="form.country"
                            :state="!form.errors.has('country')"
                            @input="
                              form.errors.clear('country');
                              getState();
                              changeCountry();
                            "
                            :reduce="(countrylist) => countrylist.value"
                            label="text"
                            id="country"
                            :clearable="false"
                          ></v-select>
                          <span
                            class="error-msg"
                            v-if="form.errors.get('country')"
                          >
                            Required Field
                          </span>
                        </div>
                        <div class="_35"></div>
                        <div
                          :state="!form.errors.has('state')"
                          :class="
                            form.errors.get('state')
                              ? 'haserror forstar div-block-49'
                              : 'forstar div-block-49'
                          "
                          :invalid-feedback="form.errors.get('state')"
                        >
                          <label for="name-4" class="field-label-40">
                            state *
                          </label>
                          <v-select
                            :options="statelist"
                            v-model="form.state"
                            :state="!form.errors.has('state')"
                            @input="form.errors.clear('state')"
                            :reduce="(statelist) => statelist.value"
                            label="text"
                            id="state"
                            :clearable="false"
                          ></v-select>
                          <span
                            class="error-msg"
                            v-if="form.errors.get('state')"
                          >
                            Required Field
                          </span>
                        </div>
                        <div class="_35"></div>
                        <div
                          :state="!form.errors.has('zipcode')"
                          :class="
                            form.errors.get('zipcode')
                              ? 'haserror forstar div-block-49'
                              : 'forstar div-block-49'
                          "
                          :invalid-feedback="form.errors.get('zipcode')"
                        >
                          <label for="name-4" class="field-label-41">
                            Zip *
                          </label>
                          <input
                            type="text"
                            class="w-input"
                            maxlength="256"
                            :state="!form.errors.has('zipcode')"
                            @input="form.errors.clear('zipcode')"
                            v-model="form.zipcode"
                            data-name="zipcode"
                            id="zipcode"
                          />
                          <span
                            class="error-msg"
                            v-if="form.errors.get('zipcode')"
                          >
                            Required Field
                          </span>
                        </div>
                      </div>
                      <div class="_14"></div>
                      <div class="div-block-48">
                        <md-checkbox
                          :value="1"
                          v-model="form.is_primary"
                          :class="'is_primary'"
                        >
                          Make it my default shipping address.
                        </md-checkbox>
                        <div class="_35"></div>
                        <!-- <md-checkbox
                          :value="1"
                          v-model="form.Residential"
                          :class="'is_primary'"
                          @change="changeResidential()"
                        >
                          Is this Residential address?
                        </md-checkbox> -->
                      </div>
                    </div>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :class="
                          form.errors.get('is_resi_address')
                            ? 'haserror div-block-50'
                            : 'div-block-50'
                        "
                        :state="!form.errors.has('is_resi_address')"
                        :invalid-feedback="form.errors.get('is_resi_address')"
                      >
                        <label for="name">
                          Is this Residential address? *
                        </label>
                        <div class="is-resi-address">
                          <md-radio
                            value="YES"
                            v-model="form.is_resi_address"
                            :class="'is_primary'"
                          >
                            Yes
                          </md-radio>
                          <md-radio
                            value="NO"
                            v-model="form.is_resi_address"
                            :class="'is_primary'"
                          >
                            No
                          </md-radio>
                        </div>
                        <span
                          class="error-msg"
                          v-if="form.errors.get('is_resi_address')"
                        >
                          Required Field
                        </span>
                      </div>
                      <div style="width: 50%"></div>
                    </div>
                  </div>
                  <!-- end shipping address section -->

                  <!-- start shipping method section -->
                  <div class="shipping-method-section">
                    <div class="_14"></div>
                    <h5>Shipping Method</h5>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :state="!form.errors.has('shipping_standard')"
                        :class="
                          form.errors.get('shipping_standard')
                            ? 'haserror forstar div-block-49 shipping_standard_div'
                            : 'forstar div-block-49 shipping_standard_div'
                        "
                        style="flex: none; width: 100%"
                        :invalid-feedback="form.errors.get('shipping_standard')"
                      >
                        <label for="name-4" class="field-label-40">
                          Ship Date *
                        </label>
                        <v-select
                          :options="shippingStandardOption"
                          v-model="form.shipping_standard"
                          :state="!form.errors.has('shipping_standard')"
                          @input="
                            form.errors.clear('shipping_standard');
                            getShippingStandard();
                          "
                          :reduce="
                            (shippingStandardOption) =>
                              shippingStandardOption.value
                          "
                          label="text"
                          id="shipping_standard"
                          :clearable="false"
                          placeholder="Select Shipping Standard"
                          :selectable="(option) => !option.disabled"
                        ></v-select>
                        <span
                          class="error-msg"
                          v-if="form.errors.get('shipping_standard')"
                        >
                          Required Field
                        </span>
                      </div>
                      <div class="_35"></div>
                      <div
                        :state="!form.errors.has('shipping_standard_date')"
                        :class="
                          form.errors.get('shipping_standard_date')
                            ? 'haserror forstar div-block-49 shipping_standard_date hide_service'
                            : 'forstar div-block-49 shipping_standard_date hide_service'
                        "
                        :invalid-feedback="
                          form.errors.get('shipping_standard_date')
                        "
                      >
                        <label for="name-4" class="field-label-40">
                          <a href="javascript:;">
                            Select Date
                            <b-img
                              class="info-img"
                              src="/images/information.png"
                              alt="info"
                            ></b-img>
                            *
                            <md-tooltip md-direction="top">
                              Hold orders are allowed to be placed only on
                              Tuesdays and Thursdays
                            </md-tooltip>
                          </a>
                        </label>
                        <datepicker
                          v-model="form.shipping_standard_date"
                          class="datepick datepicker orderinput w-input shipping_standard_datepicker"
                          :format="customFormatter"
                          :disabledDates="disabledDatesForShippingStandardDate"
                        ></datepicker>
                        <span
                          class="error-msg"
                          v-if="form.errors.get('shipping_standard_date')"
                        >
                          Required Field
                        </span>
                      </div>
                    </div>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :state="!form.errors.has('shipping_payment_method')"
                        :class="
                          form.errors.get('shipping_payment_method')
                            ? 'haserror forstar div-block-49 shipping_payment_method_div'
                            : 'forstar div-block-49 shipping_payment_method_div'
                        "
                        style="flex: none; width: 100%"
                        :invalid-feedback="
                          form.errors.get('shipping_payment_method')
                        "
                      >
                        <label for="name-4" class="field-label-40">
                          Shipping Payment Method *
                        </label>
                        <v-select
                          :options="shippingPaymentMethods"
                          v-model="form.shipping_payment_method"
                          :state="!form.errors.has('shipping_payment_method')"
                          @input="
                            form.errors.clear('shipping_payment_method');
                            getPaymentMethod();
                          "
                          :reduce="
                            (shippingPaymentMethods) =>
                              shippingPaymentMethods.value
                          "
                          label="text"
                          id="shipping_payment_method"
                          :clearable="false"
                          placeholder="Select Payment Method"
                        ></v-select>
                        <span
                          class="error-msg"
                          v-if="form.errors.get('shipping_payment_method')"
                        >
                          Required Field
                        </span>
                      </div>
                    </div>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :state="!form.errors.has('shipping_method')"
                        :class="
                          form.errors.get('shipping_method')
                            ? 'haserror forstar div-block-49 shipping_method_div'
                            : 'forstar div-block-49 shipping_method_div'
                        "
                        style="flex: none; width: 100%"
                        :invalid-feedback="form.errors.get('shipping_method')"
                      >
                        <label for="name-4" class="field-label-40">
                          Shipping Method *
                        </label>
                        <v-select
                          :options="shippingmethods"
                          v-model="form.shipping_method"
                          :state="!form.errors.has('shipping_method')"
                          @input="
                            form.errors.clear('shipping_method');
                            getShippingService();
                          "
                          :reduce="(shippingmethods) => shippingmethods.value"
                          label="text"
                          id="shipping_method"
                          :clearable="false"
                          placeholder="Select shipping Method"
                          :selectable="(option) => !option.disabled"
                        ></v-select>
                        <span
                          class="error-msg"
                          v-if="form.errors.get('shipping_method')"
                        >
                          Required Field
                        </span>
                      </div>

                      <div
                        :state="!form.errors.has('fedex_service')"
                        :class="
                          form.errors.get('fedex_service')
                            ? 'haserror forstar div-block-49 hide_service showFedexService'
                            : 'forstar div-block-49 hide_service showFedexService'
                        "
                        :invalid-feedback="form.errors.get('fedex_service')"
                      >
                        <div class="_35"></div>
                        <div style="display: block; width: 100%">
                          <label for="fedex_service" class="field-label-40">
                            Select Fedex Service *
                          </label>
                          <v-select
                            :options="FedexServices"
                            v-model="form.fedex_service"
                            :state="!form.errors.has('fedex_service')"
                            @input="
                              form.errors.clear('fedex_service');
                              getFedexShippingRate();
                            "
                            :reduce="(FedexServices) => FedexServices.value"
                            label="text"
                            id="fedex_service"
                            :clearable="false"
                            placeholder="Select Fedex Service"
                          ></v-select>
                          <span
                            class="error-msg"
                            v-if="form.errors.get('fedex_service')"
                          >
                            Required Field
                          </span>
                        </div>
                      </div>
                      <div
                        :state="!form.errors.has('ups_service')"
                        :class="
                          form.errors.get('ups_service')
                            ? 'haserror forstar div-block-49 showUpsService hide_service'
                            : 'forstar div-block-49 showUpsService hide_service'
                        "
                        :invalid-feedback="form.errors.get('ups_service')"
                      >
                        <div class="_35"></div>
                        <div style="display: block; width: 100%">
                          <label for="ups_service" class="field-label-40">
                            Select UPS Service *
                          </label>
                          <v-select
                            :options="UpsServices"
                            v-model="form.ups_service"
                            :state="!form.errors.has('ups_service')"
                            @input="
                              form.errors.clear('ups_service');
                              getUpsShippingRate();
                            "
                            :reduce="(UpsServices) => UpsServices.value"
                            label="text"
                            id="ups_service"
                            :clearable="false"
                            placeholder="Select Ups Service"
                          ></v-select>
                          <span
                            class="error-msg"
                            v-if="form.errors.get('ups_service')"
                          >
                            Required Field
                          </span>
                        </div>
                      </div>

                      <div
                        :state="!form.errors.has('ups_express_service')"
                        :class="
                          form.errors.get('ups_express_service')
                            ? 'haserror forstar div-block-49 showUpsExpressService hide_service'
                            : 'forstar div-block-49 showUpsExpressService hide_service'
                        "
                        :invalid-feedback="
                          form.errors.get('ups_express_service')
                        "
                      >
                        <div class="_35"></div>
                        <div style="display: block; width: 100%">
                          <label
                            for="ups_express_service"
                            class="field-label-40"
                          >
                            Select ups Express Service *
                          </label>
                          <v-select
                            :options="upsExpressOptions"
                            v-model="form.ups_express_service"
                            :state="!form.errors.has('ups_express_service')"
                            @input="form.errors.clear('ups_express_service')"
                            :reduce="
                              (upsExpressOption) => upsExpressOption.value
                            "
                            label="text"
                            id="ups_express_service"
                            :clearable="false"
                            placeholder="Select Service"
                          ></v-select>
                          <span
                            class="error-msg"
                            v-if="form.errors.get('ups_express_service')"
                          >
                            Required Field
                          </span>
                        </div>
                      </div>
                      <div
                        :state="!form.errors.has('fedex_express_service')"
                        :class="
                          form.errors.get('fedex_express_service')
                            ? 'haserror forstar div-block-49 showFedexExpressService hide_service'
                            : 'forstar div-block-49 showFedexExpressService hide_service'
                        "
                        :invalid-feedback="
                          form.errors.get('fedex_express_service')
                        "
                      >
                        <div class="_35"></div>
                        <div style="display: block; width: 100%">
                          <label
                            for="fedex_express_service"
                            class="field-label-40"
                          >
                            Select Fedex Express Service *
                          </label>
                          <v-select
                            :options="fedexExpressOptions"
                            v-model="form.fedex_express_service"
                            :state="!form.errors.has('fedex_express_service')"
                            @input="form.errors.clear('fedex_express_service')"
                            :reduce="
                              (fedexExpressOption) => fedexExpressOption.value
                            "
                            label="text"
                            id="fedex_express_service"
                            :clearable="false"
                            placeholder="Select Service"
                          ></v-select>
                          <span
                            class="error-msg"
                            v-if="form.errors.get('fedex_express_service')"
                          >
                            Required Field
                          </span>
                        </div>
                      </div>
                      <div
                        :state="!form.errors.has('shipping_method_by_customer')"
                        :class="
                          form.errors.get('shipping_method_by_customer')
                            ? 'haserror forstar div-block-49 shipping_method_by_customer hide_service'
                            : 'forstar div-block-49 shipping_method_by_customer hide_service'
                        "
                        :invalid-feedback="
                          form.errors.get('shipping_method_by_customer')
                        "
                      >
                        <div class="_35"></div>
                        <div style="display: block; width: 100%">
                          <label
                            for="shipping_method_by_customer"
                            class="field-label-40"
                          >
                            Enter Custom shipping method *
                          </label>
                          <input
                            type="text"
                            class="w-input"
                            maxlength="256"
                            v-model="form.shipping_method_by_customer"
                            data-name="shipping_method_by_customer"
                            id="shipping_method_by_customer"
                          />
                          <span
                            v-if="
                              form.errors.get('shipping_method_by_customer')
                            "
                            class="error-msg"
                          >
                            Required Field
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :state="!form.errors.has('payment_ups_account_number')"
                        :class="
                          form.errors.get('payment_ups_account_number')
                            ? 'haserror forstar div-block-49 payment_ups_account_number hide_service'
                            : 'forstar div-block-49 payment_ups_account_number hide_service'
                        "
                        :invalid-feedback="
                          form.errors.get('payment_ups_account_number')
                        "
                      >
                        <label for="name-4" class="field-label-38">
                          Enter UPS/Fedex/DHL Acct# number *
                        </label>
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          v-model="form.payment_ups_account_number"
                          data-name="payment_ups_account_number"
                          id="payment_ups_account_number"
                          @input="
                            `${
                              form.payment_ups_account_number || ``
                            }`.toUpperCase()
                          "
                        />
                        <span
                          class="error-msg payment_ups_account_number_err"
                          v-if="
                            form.errors.get('payment_ups_account_number') ||
                            accErr.length > 0
                          "
                        >
                          {{ accErr || "Required Field" }}
                        </span>
                      </div>
                    </div>
                    <div class="_14"></div>

                    <!-- <div class="div-block-48">
                      <md-checkbox
                        :disabled="chk"
                        value="SHIP TODAY!!"
                        v-model="form.InternalRef"
                        :class="'is_primary shipToday'"
                      >
                        SHIP TODAY
                      </md-checkbox>
                      <md-checkbox
                        value="DO NOT INSURE"
                        v-model="form.FinalMsg"
                        :class="'is_primary'"
                      >
                        DO NOT INSURE
                      </md-checkbox>
                    </div> -->

                    <div class="div-block-49">
                      <label
                        :state="!form.errors.has('FinalMsg')"
                        :class="
                          form.errors.get('FinalMsg')
                            ? 'haserror forstar div-block-49 FinalMsg'
                            : 'forstar div-block-49 FinalMsg'
                        "
                        :invalid-feedback="form.errors.get('FinalMsg')"
                        for="FinalMsg"
                      >
                        Insurance on Shipment *
                      </label>
                      <div class="div-block-48">
                        <md-radio
                          value="N/A"
                          v-model="form.FinalMsg"
                          :class="'is_primary'"
                          style="margin-right: 35px"
                        >
                          No Preference
                        </md-radio>
                        <md-radio
                          value="No"
                          v-model="form.FinalMsg"
                          :class="'is_primary'"
                          style="margin-left: 35px"
                        >
                          No
                        </md-radio>
                        <md-radio
                          value="Yes"
                          v-model="form.FinalMsg"
                          :class="'is_primary'"
                          style="margin-left: 35px"
                        >
                          Yes
                        </md-radio>
                      </div>
                      <span
                        class="error-msg"
                        v-if="form.errors.get('FinalMsg')"
                      >
                        Required Field
                      </span>
                    </div>
                  </div>
                  <!-- end shipping method section -->
                  <!-- start payment method section -->
                  <div class="payment-method-section">
                    <div class="_14"></div>
                    <h5>Payment</h5>
                    <div class="Paymentmethods">
                      <!-- <md-radio
                        value="authorize"
                        v-model="form.payment_method"
                        :class="'is_primary'"
                        @change="changePaymentMethod()"
                        >Credit Card</md-radio
                      > -->
                      <div class="div-block-48">
                        <div
                          :state="!form.errors.has('payment_term')"
                          :class="
                            form.errors.get('payment_term')
                              ? 'haserror forstar div-block-49'
                              : 'forstar div-block-49'
                          "
                          :invalid-feedback="form.errors.get('payment_term')"
                        >
                          <label for="name-4" class="field-label-40">
                            Payment Terms *
                          </label>
                          <v-select
                            :options="paymentTerms"
                            v-model="form.payment_term"
                            :state="!form.errors.has('payment_term')"
                            @input="
                              form.errors.clear('payment_term');
                              getPaymentTerm();
                            "
                            :reduce="(paymentTerms) => paymentTerms.value"
                            label="text"
                            id="payment_term"
                            :clearable="false"
                            placeholder="Select Payment Method"
                          ></v-select>
                          <span
                            class="error-msg"
                            v-if="form.errors.get('payment_term')"
                          >
                            Required Field
                          </span>
                        </div>
                        <div class="_35"></div>
                        <div class="div-block-49">
                          <label for="name-4" class="field-label-41">
                            Instructions
                          </label>
                          <textarea
                            class="w-input instruction-textarea"
                            v-model="form.Instr"
                            data-name="Instr"
                            id="Instr"
                          ></textarea>
                        </div>
                      </div>
                      <div class="_14"></div>
                      <div class="div-block-49" v-if="this.ship_error">
                        <span style="color: red">{{ this.ship_error }}</span>
                      </div>
                      <div
                        class="AuthorizedPayment credit_card"
                        :style="`display:${(this.form.payment_term == 'credit_card') ? 'block' : 'none'}`"
                      >
                        <div class="_14"></div>
                        <div class="div-block-48">
                          <div
                            :class="
                              form.errors.get('name_on_card')
                                ? 'haserror div-block-49'
                                : 'div-block-49'
                            "
                            :state="!form.errors.has('name_on_card')"
                            :invalid-feedback="form.errors.get('name_on_card')"
                          >
                            <label for="name-4" class="field-label-33"
                              >Name On Card *</label
                            >
                            <input
                              type="text"
                              class="w-input"
                              maxlength="256"
                              v-model="form.name_on_card"
                              :state="!form.errors.has('name_on_card')"
                              @input="form.errors.clear('name_on_card')"
                              data-name="name_on_card"
                              id="name_on_card"
                            />
                            <span
                              class="error-msg"
                              v-if="form.errors.get('name_on_card')"
                              >Required Field</span
                            >
                          </div>
                          <div class="_35"></div>
                          <div
                            :class="
                              form.errors.get('card_number')
                                ? 'haserror div-block-50 typein'
                                : 'div-block-50 typein'
                            "
                            :state="!form.errors.has('card_number')"
                            :invalid-feedback="form.errors.get('card_number')"
                          >
                            <label for="name-4" class="field-label-34"
                              >Card Number*</label
                            >
                            <input
                              type="text"
                              class="w-input"
                              maxlength="256"
                              v-model="form.card_number"
                              :state="!form.errors.has('card_number')"
                              @input="form.errors.clear('card_number')"
                              data-name="card_number"
                              id="card_number"
                            />
                            <span
                              class="error-msg"
                              v-if="form.errors.get('card_number')"
                              >Required Field</span
                            >
                          </div>
                        </div>
                        <div class="_14"></div>

                        <div class="div-block-48">
                          <div
                            :class="
                              form.errors.get('card_expiry')
                                ? 'haserror div-block-50'
                                : 'div-block-50'
                            "
                            :state="!form.errors.has('card_expiry')"
                            :invalid-feedback="form.errors.get('card_expiry')"
                          >
                            <label for="name">Card Expiry*</label>
                            <!-- <v-select
                                                            class="style-chooser card_ex_month"
                                                            :options="exmonth"
                                                            v-model="form.month_of_expiary"
                                                            :state="!form.errors.has('month_of_expiary')"
                                                            @input="form.errors.clear('month_of_expiary')"
                                                            :clearable="false"
                                                            placeholder
                                                            id="month_of_expiary"
                                                        ></v-select>-->
                            <input
                              type="text"
                              class="w-input"
                              maxlength="256"
                              v-model="form.card_expiry"
                              data-name="card_expiry"
                              id="card_expiry"
                              placeholder="MM/YY"
                            />
                            <span
                              class="error-msg"
                              v-if="form.errors.get('card_expiry')"
                              >Required Field</span
                            >
                          </div>
                          <div class="_35"></div>
                          <div
                            :class="
                              form.errors.get('cvv')
                                ? 'haserror div-block-49'
                                : 'div-block-49'
                            "
                            :state="!form.errors.has('cvv')"
                            :invalid-feedback="form.errors.get('cvv')"
                          >
                            <label for="name-4" class="field-label-33"
                              >CVV *</label
                            >
                            <input
                              type="text"
                              class="w-input"
                              maxlength="256"
                              v-model="form.cvv"
                              :state="!form.errors.has('cvv')"
                              @input="form.errors.clear('cvv')"
                              data-name="cvv"
                              id="cvv"
                            />
                            <span
                              class="error-msg"
                              v-if="form.errors.get('cvv')"
                              >Required Field</span
                            >

                            <a href="javascript:;" class="what-this">
                              What is this?
                              <md-tooltip md-direction="top">
                                VISA, MasterCard, Discover The last 3-Digits
                                printed on the back of the card. American
                                Express The 4-Digits security code printed on
                                the front of the card above the 16 digits card
                                number
                              </md-tooltip>
                            </a>
                            <!-- <div
                                                        :class="form.errors.get('year_of_expiary')?'haserror div-block-49':'div-block-49'"
                                                        :state="!form.errors.has('year_of_expiary')"
                                                        :invalid-feedback="form.errors.get('year_of_expiary')"
                                                    >
                                                        <label for="name">Year of expiary *</label>
                                                        <v-select
                                                            class="style-chooser card_ex_year"
                                                            :options="exyear"
                                                            v-model="form.year_of_expiary"
                                                            :reduce="exyear => exyear.value"
                                                            :state="!form.errors.has('year_of_expiary')"
                                                            @input="form.errors.clear('year_of_expiary')"
                                                            label="text"
                                                            :clearable="false"
                                                            placeholder
                                                        ></v-select>

                                                        <span
                                                            class="error-msg"
                                                            v-if="form.errors.get('year_of_expiary')"
                                                        >Required Field</span>
                                                        </div>-->
                          </div>
                          <div class="div-block-48">
                            <!-- <md-tooltip md-direction="top" class="what-this">What is this?</md-tooltip> -->
                          </div>
                        </div>
                      </div>
                      <!-- <div class="_35"></div> -->
                      <md-radio
                        value="paypal"
                        v-model="form.payment_method"
                        :class="'is_primary'"
                        style="display: none"
                        @change="changePaymentMethod()"
                        >Paypal</md-radio
                      >
                      <div class="PaypalPayment">
                        <div class id="paypal-button-container"></div>
                      </div>
                    </div>
                  </div>
                  <div class="paymenterror" v-if="this.paymentError != null">
                    {{ this.paymentError }}
                  </div>
                  <!-- end payment method section -->
                </div>
                <div class="quote-summery">
                  <h5 class="summaryTitle">Summary</h5>
                  <div class="home-section-wrap summaryTable">
                    <div v-if="quoteItems.length > 0">
                      <!-- Main table element -->
                      <b-table
                        show-empty
                        responsive
                        class="productlisttable"
                        :items="quoteItems"
                        :fields="fields"
                        :tbody-tr-class="rowClass"
                      >
                        <template v-slot:cell(id)="row">
                          <div
                            class="cell-link"
                            @click="gotosinglePage(row.item.id)"
                          >
                            {{ row.item.name }}
                          </div>
                        </template>
                        <template v-slot:cell(firstname)="row">
                          <div
                            class="cell-link"
                            @click="gotosinglePage(row.item.id)"
                          >
                            {{ row.item.name }}
                          </div>
                        </template>
                        <template v-slot:cell()="row">
                          <div
                            class="cell-text"
                            @click="gotosinglePage(row.item.id)"
                          >
                            {{ row.value }}
                          </div>
                        </template>
                        <template v-slot:cell(price)="row">
                          {{
                            row.item.item_price
                              ? "$ " + row.item.item_price
                              : "-"
                          }}
                        </template>
                        <template v-slot:cell(uom)="row">
                          {{ row.item.uom }}
                        </template>
                        <template v-slot:cell(addtoquote)="row">
                          {{
                            row.item.item_price
                              ? "$ " +
                                parseFloat(
                                  Math.max(
                                    0,
                                    parseFloat(row.item.item_price) *
                                      parseFloat(row.item.item_quantity)
                                  )
                                ).toFixed(2)
                              : "-"
                          }}
                        </template>
                        <template v-slot:cell(notes)="row">
                          <textarea
                            class="w-input additional-note-textarea"
                            v-model="row.item.additional_notes"
                            data-name="additional_notes"
                          ></textarea>
                        </template>
                      </b-table>
                      <div class="account-container">
                        <div class="right-align">
                          <div class="div-block-75">
                            <p class="margin-0">
                              Subtotal
                              <br />
                            </p>
                          </div>
                          <div class="div-block-75-copy">
                            <p class="margin-0">
                              {{ item_total ? "$ " + item_total : "$0.00" }}
                              <br />
                            </p>
                          </div>
                        </div>
                        <div class="right-align" v-if="ccfee > 0">
                          <div class="div-block-75">
                            <p class="margin-0">
                              CC Fee
                              <br />
                            </p>
                          </div>
                          <div class="div-block-75-copy">
                            <p class="margin-0">
                              ${{ parseFloat(ccfee).toFixed(2) }}
                              <br />
                            </p>
                          </div>
                        </div>
                        <div class="right-align">
                          <div class="div-block-75">
                            <p class="margin-0">
                              Tax ( 0.00 % )
                              <br />
                            </p>
                          </div>
                          <div class="div-block-75-copy">
                            <p class="margin-0">
                              $0.00
                              <br />
                            </p>
                          </div>
                        </div>
                        <div class="right-align" v-if="surgeCharge !== 0">
                          <div class="div-block-75">
                            <p class="margin-0">
                              Minimum Order Surcharge
                              <br />
                            </p>
                          </div>
                          <div class="div-block-75-copy">
                            <p class="margin-0">
                              ${{ parseFloat(surgeCharge).toFixed(2) }}
                              <br />
                            </p>
                          </div>
                        </div>
                        <div
                          class="right-align"
                          v-if="this.form.ship_amount != 0"
                        >
                          <div class="div-block-75">
                            <p class="margin-0">
                              Shipping ({{ this.form.shipping_service_name }})
                              <br />
                            </p>
                          </div>
                          <div class="div-block-75-copy">
                            <p class="margin-0">
                              {{
                                this.form.ship_amount
                                  ? "$ " + this.form.ship_amount
                                  : "$0.00"
                              }}
                              <br />
                            </p>
                          </div>
                        </div>
                        <div class="right-align">
                          <div class="div-block-75">
                            <p class="margin-0">
                              TOTAL ( {{ quoteItems.length }} ITEMS )
                              <br />
                            </p>
                          </div>
                          <div class="div-block-75-copy">
                            <p class="margin-0">
                              {{
                                this.finalAmt !== 0 ? `$ ${this.finalAmt.toFixed(2)}` : this.form.total_amount ? "$ " + (parseFloat( `${this.form.total_amount}`.replace(/,/g,"")) + parseFloat(surgeCharge) + parseFloat(ccfee)) : "$0.00"
                              }}
                              <br />
                            </p>
                          </div>
                        </div>

                        <div class="_14"></div>
                        <div class="hr"></div>
                      </div>

                      <!-- <div class="div-block-48">
                                           
                                            <div class="_35"></div>

                                             <md-checkbox
                                                value="select"
                                                v-model="form.schedule_order"
                                                :class="'is_primary'"
                                                @change="changeOrderDates();"
                                            >Do you want to schedule your order?</md-checkbox>
 
                                        </div> -->

                      <div class="_14"></div>
                      <div class="div-block-48 forDateSelection">
                        <md-radio
                          value="onedate"
                          v-model="form.scheduledate"
                          :class="'is_primary'"
                          @change="changeDates()"
                        >
                          Ship entire order
                        </md-radio>
                        <div class="DateFiledsForAll">
                          <label for="name">Schedule Date</label>
                          <datepicker
                            v-model="form.schedule_date_all"
                            class="datepick datepicker orderinput w-input"
                            :format="customFormatter"
                            :disabledDates="disabledDates"
                          ></datepicker>
                        </div>
                        <md-radio
                          value="differentdate"
                          v-model="form.scheduledate"
                          :class="'is_primary'"
                          @change="changeDates()"
                        >
                          Ship partial quantities of the order
                        </md-radio>
                      </div>

                      <div class="DateFileds" id="data-2">
                        <div class="_14"></div>
                        <div
                          class="row"
                          v-for="(
                            schedule, dateIndex
                          ) in order_schedule_dates_fields"
                          :key="dateIndex"
                        >
                          <label for="name-41" class="field-label-35"
                            >Product Name</label
                          >
                          <b-col sm="2" class="arrival">
                            <b-form-group>
                              <v-select
                                multiple
                                :options="product_items"
                                v-model="schedule.product_item"
                                :reduce="(product_items) => product_items.value"
                                label="text"
                                id="product_item"
                                :clearable="false"
                                placeholder="Please select"
                              ></v-select>
                            </b-form-group>
                          </b-col>
                          <div class="_14"></div>
                          <b-col sm="2" class="departure">
                            <label for="name">Qty</label>
                            <input
                              type="number"
                              class="w-input"
                              maxlength="256"
                              v-model="schedule.Qty"
                              data-name="qty 1"
                              id="qty1"
                            />
                          </b-col>
                          <div class="_14"></div>
                          <b-col sm="2" class="pattern">
                            <label for="name">Schedule Date</label>
                            <datepicker
                              v-model="schedule.schedule_date"
                              class="datepick datepicker orderinput w-input"
                              :format="customFormatter"
                              :disabledDates="disabledDates"
                            ></datepicker>
                          </b-col>
                          <div class="_14"></div>
                          <b-col sm="2">
                            <div
                              class="removediv"
                              v-if="ScheduleDateShowRemoveButton(dateIndex)"
                            >
                              <a
                                class="link button secondary w-inline-block"
                                @click="removeScheduleDate(dateIndex)"
                                >Remove</a
                              >
                            </div>
                            <div v-if="ScheduleDateShowAddButton(dateIndex)">
                              <a
                                class="link button secondary w-inline-block"
                                @click="addScheduleDate"
                                >Add Another</a
                              >
                            </div>
                          </b-col>
                        </div>
                      </div>
                    </div>

                    <div v-else>
                      <p class="emptyquote">Your Cart List is empty.</p>
                    </div>
                  </div>

                  <div class="chk_buttons">
                    <div class="step-1-button">
                      <a
                        href="javascript:;"
                        class="button w-button"
                        @click="continueShipping"
                      >
                        Continue to shipping
                      </a>
                    </div>
                    <div class="step-2-button">
                      <a
                        href="javascript:;"
                        class="backinfo"
                        @click="continueContactInfo"
                      >
                        <b-img src="/images/arrow.svg" alt="arrow"></b-img>Back
                        to Information
                      </a>
                      <a
                        href="javascript:;"
                        class="button w-button"
                        @click="continuePayment"
                        >Continue to Payment</a
                      >
                    </div>
                    <div class="step-3-button">
                      <a
                        href="javascript:;"
                        class="backinfo"
                        @click="continueShipping"
                      >
                        <b-img src="/images/arrow.svg" alt="arrow"></b-img>Back
                        to shipping
                      </a>
                      <input
                        type="submit"
                        value="Submit Order"
                        data-wait="Please wait..."
                        class="button w-button purchaseNowBtn"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- <div class="div-block-54-copy">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div> -->
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../mixins/resources";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import momentTimeZone from "moment-timezone";
momentTimeZone.tz.setDefault("EST");

export default {
  components: {
    CommonHead,
    CommonFoot,
    Datepicker,
    moment,
    "v-select": vSelect,
  },
  beforeMount() {
    this.getCountry();
  },
  mounted() {
    this.loadmyQuote();
    this.loadCheckoutAddressInfo();
    this.FedexServices = this.FedexServicesUS;
    this.UpsServices = this.UpsServicesUS;
    // this.initForm();
  },
  data() {
    return {
      finalAmt: 0,
      loading: false,
      accErr: "",
      surgeCharge: 0,
      ccfee: 0,
      isInternationalShipping: false,
      myQuote: [],
      quoteItems: [],
      item_total: 0.0,
      final_total: 0.0,
      shipping_country_code: null,
      ship_error: null,

      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      disabledDatesForShippingStandardDate: {
        to: new Date(Date.now() - 8640000),
        days: [6, 0, 1, 3, 5],
      },

      show: true,
      chk: false,
      country: [],
      states: [],
      checkboxes: [],
      paymentError: null,
      headerTitle: "Step 1 - Information",

      form: {
        firstname: null,
        lastname: null,
        email: null,
        saved_address: "",
        country: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
        address_option: "same",
        billing_country: null,
        billing_streetaddress: null,
        billing_streetaddress1: null,
        billing_city: null,
        billing_state: null,
        billing_zipcode: null,
        phone: null,
        company_name: null,
        preferred_invoice_email: null,
        user_id: null,
        is_primary: 0,
        shipping_standard: null,
        shipping_standard_date: null,
        shipping_payment_method: null,
        payment_ups_account_number: null,
        shipping_method: null,
        fedex_service: null,
        ups_service: null,
        ups_express_service: null,
        fedex_express_service: null,
        shipping_method_by_customer: null,
        shipping_service_name: null,
        shipping_service_code: null,
        name_on_card: null,
        card_number: null,
        month_of_expiary: null,
        year_of_expiary: null,
        card_expiry: null,
        cvv: null,
        ship_amount: 0,
        total_amount: 0,
        sub_total: 0,
        payment_method: "authorize",
        payment_term: null,
        tax_amount: 0,
        tax_percent: 0,
        quote_id: null,
        CustPONum: null,
        Residential: 0,
        is_resi_address: "",
        test_report: 0,
        Instr: null,
        FinalMsg: "",
        InternalRef: 0,
        scheduledate: "",
        schedule_order: 0,
        schedule_date_all: "",
        schedule_date_1: "",
        schedule_date_2: "",
        schedule_date_3: "",
        schedule_date_4: "",
        Qty_1: "",
        Qty_2: "",
        Qty_3: "",
        Qty_4: "",
        order_schedule_fields: [],
        CustCode: "",
        items: [],
        surCharge: 0,
        ccfee: 0,
        payment_term_desc: "",
      },
      order_schedule_dates_fields: [
        { product_item: "", Qty: "", schedule_date: "" },
      ],
      countrylist: [{ value: "United States", text: "United States" }],
      statelist: [{ value: "", text: "select country first" }],
      billing_statelist: [{ value: "", text: "select country first" }],
      saved_address: [{ value: "", text: "New Address" }],
      primary_address: [],
      shippingStandardOption: [
        {
          value: "standard",
          text: "Standard (24-48 Hours)",
          disabled: false,
        },
        { value: "today", text: "Today", disabled: false },
        { value: "hold", text: "Hold", disabled: false },
      ],
      paymentTerms: [
        { value: "credit_card", text: "Credit Card" },
        // { value: "cod", text: "COD" },
        // { value: "proforma", text: "Proforma" },
        // { value: "net30", text: "Net 30 (If applicable)" },
      ],
      shippingPaymentMethods: [
        { value: "collect", text: "Collect" },
        { value: "pay&add", text: "Prepaid and Add" },
      ],
      shippingmethods: [
        { value: "UPS", text: "UPS", disabled: false },
        { value: "FEDEX", text: "Fedex", disabled: false },
        { value: "DHL", text: "DHL", disabled: false },
        { value: "OTHER", text: "Other", disabled: false },
      ],
      FedexServices: [],
      FedexServicesIn: [
        { value: "Economy", text: "Economy" },
        { value: "Priority", text: "Priority" },
        { value: "First", text: "First" },
        { value: "OTHER", text: "Other" },
      ],
      FedexServicesUS: [
        // { value: "FEDEX_2_DAY", text: "FedEx 2Day®" },
        // { value: "FEDEX_EXPRESS_SAVER", text: "FedEx Express Saver®" },
        // { value: "FEDEX_GROUND", text: "FedEx Ground®" },
        // { value: "FIRST_OVERNIGHT", text: "FedEx First Overnight®" },
        // { value: "GROUND_HOME_DELIVERY", text: "FedEx Home Delivery®" },
        // {
        //   value: "INTERNATIONAL_ECONOMY",
        //   text: "FedEx International Economy®",
        // },
        // {
        //   value: "INTERNATIONAL_FIRST",
        //   text: "FedEx International First®",
        // },
        // {
        //   value: "INTERNATIONAL_PRIORITY",
        //   text: "FedEx International Priority®",
        // },
        // {
        //   value: "PRIORITY_OVERNIGHT",
        //   text: "FedEx Priority Overnight®",
        // },
        // {
        //   value: "STANDARD_OVERNIGHT",
        //   text: "FedEx Standard Overnight®",
        // },
        { value: "GROUND", text: "Ground" },
        { value: "EXPRESS", text: "Express" },
        { value: "OTHER", text: "Other" },
      ],
      UpsServices: [],
      UpsServicesIn: [
        { value: "Standard", text: "Standard" },
        { value: "Expedited", text: "Expedited" },
        { value: "Saver", text: "Saver" },

        { value: "OTHER", text: "Other" },
      ],
      UpsServicesUS: [
        // { value: "01", text: "UPS Next Day Air" },
        // { value: "02", text: "UPS 2nd Day Air" },
        // { value: "03", text: "UPS Ground" },
        // { value: "07", text: "UPS Worldwide Express" },
        // { value: "08", text: "UPS Worldwide Expedited" },
        // { value: "11", text: "UPS Standard" },
        // { value: "12", text: "UPS 3 Day Select" },
        // { value: "13", text: "UPS Next Day Air Saver" },
        // { value: "14", text: "UPS Next Day Air Early A.M." },
        // { value: "54", text: "UPS Worldwide Express Plus" },
        // { value: "59", text: "UPS 2nd Day Air A.M." },
        // { value: "65", text: "UPS Express Saver" },
        { value: "GROUND", text: "Ground" },
        { value: "EXPRESS", text: "Express" },
        { value: "OTHER", text: "Other" },
      ],
      upsExpressOptions: [
        { value: "NEXT_DAY_AIR", text: "Next Day Air" },
        { value: "NEXT_DAY_AIR_SAVER", text: "Next Day Air Saver" },
        { value: "NEXT_DAY_AIR_EARLY_AM", text: "Next Day Air Early AM" },
        { value: "2_DAY_AIR", text: "2 Day Air" },
        { value: "3_DAY_SELECT", text: "3 Day Select" },
      ],
      fedexExpressOptions: [
        { value: "PRIORITY_OVERNIGHT", text: "Priority Overnight" },
        { value: "STANDARD_OVERNIGHT", text: "Standard Overnight" },
      ],
      getshipping: null,
      exmonth: [],
      exyear: [],
      product_items: [
        { value: "prod 1", text: "Prod 1" },
        { value: "prod 2", text: "Prod 2" },
      ],

      fields: [
        {
          key: "id",
          label: "Product Name",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "item_quantity",
          label: "Quantity",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "price",
          label: "Unit Price",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "uom",
          label: "UoM",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },

        {
          key: "addtoquote",
          label: "Sub Total",
          thClass: "tbheaderAction",
          tdClass: "tbColumn",
        },
        {
          key: "notes",
          label: "Notes",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
      ],
    };
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getPayOption() {
      console.log("priceDetails", this.user.priceDetails);
      if (!("PmtTerms" in this.user.priceDetails)) {
        return [{ value: "credit_card", text: "Credit Card" }];
      }

      switch (this.user.priceDetails.PmtTerms.TermsCode) {
        case "01":
          return [
            { value: "credit_card", text: "Credit Card" },
            {
              value: this.user.priceDetails.PmtTerms.TermsCode,
              text: this.user.priceDetails.PmtTerms.TermsDesc,
            },
          ];
        case "02":
          return [
            { value: "credit_card", text: "Credit Card" },
            {
              value: this.user.priceDetails.PmtTerms.TermsCode,
              text: this.user.priceDetails.PmtTerms.TermsDesc,
            },
          ];
        case "05":
          return [
            { value: "credit_card", text: "Credit Card" },
            {
              value: this.user.priceDetails.PmtTerms.TermsCode,
              text: this.user.priceDetails.PmtTerms.TermsDesc,
            },
          ];
        case "07":
          return [
            { value: "credit_card", text: "Credit Card" },
            {
              value: this.user.priceDetails.PmtTerms.TermsCode,
              text: this.user.priceDetails.PmtTerms.TermsDesc,
            },
          ];
        case "09":
          return [
            { value: "credit_card", text: "Credit Card" },
            {
              value: this.user.priceDetails.PmtTerms.TermsCode,
              text: this.user.priceDetails.PmtTerms.TermsDesc,
            },
          ];
        case "13":
          return [
            { value: "credit_card", text: "Credit Card" },
            {
              value: this.user.priceDetails.PmtTerms.TermsCode,
              text: this.user.priceDetails.PmtTerms.TermsDesc,
            },
          ];
        case "15":
          return [
            { value: "credit_card", text: "Credit Card" },
            {
              value: this.user.priceDetails.PmtTerms.TermsCode,
              text: this.user.priceDetails.PmtTerms.TermsDesc,
            },
          ];
        case "16":
          return [
            { value: "credit_card", text: "Credit Card" },
            {
              value: this.user.priceDetails.PmtTerms.TermsCode,
              text: this.user.priceDetails.PmtTerms.TermsDesc,
            },
          ];
        case "27":
          return [
            { value: "credit_card", text: "Credit Card" },
            {
              value: this.user.priceDetails.PmtTerms.TermsCode,
              text: this.user.priceDetails.PmtTerms.TermsDesc,
            },
          ];
        default:
          return [{ value: "credit_card", text: "Credit Card" }];
      }
    },
    addRow() {
      console.log(this.order_schedule_dates_fields);
    },
    changeCountry() {
      if (this.loading) {
        return;
      }
      const p = parseFloat(`${this.final_total}`.replace(/,/g, ""));
      console.log(
        "p",
        p,
        this.final_total,
        this.form.country,
        "this",
        this.form.address_option,
        this.form.billing_country,
        (this.form.country !== 231 && this.form.address_option !== "same") ||
          (this.form.address_option === "same" &&
            this.form.billing_country !== 231)
      );
      if (
        (`${this.form.country}` !== `231` &&
          this.form.address_option !== "same") ||
        (this.form.address_option === "same" &&
          `${this.form.billing_country}` !== `231`)
      ) {
        if (p < 100) {
          this.surgeCharge = Math.max(0, 100 - p);
        }
        this.shippingPaymentMethods = [{ value: "collect", text: "Collect" }];
        this.FedexServices = this.FedexServicesIn;
        this.UpsServices = this.UpsServicesIn;
      } else {
        this.surgeCharge = 0;
        if (p < 35) {
          this.surgeCharge = Math.max(0, 35 - p);
        } else {
        }
        this.shippingPaymentMethods = [
          { value: "collect", text: "Collect" },
          { value: "pay&add", text: "Prepaid and Add" },
        ];
        this.FedexServices = this.FedexServicesUS;
        this.UpsServices = this.UpsServicesUS;
      }
      if (this.form.fedex_service) {
        const isExist =
          this.FedexServices.filter((d) => d.value === this.form.fedex_service)
            .length > 0;
        if (!isExist) {
          this.form.fedex_service = null;
        }
      }
      if (this.form.ups_service) {
        const isExist =
          this.UpsServices.filter((d) => d.value === this.form.ups_service)
            .length > 0;
        if (!isExist) {
          this.form.ups_service = null;
        }
      }
    },
    initForm() {
      let form = Object.assign({
        firstname: null,
        lastname: null,
        email: null,
        saved_address: "",
        country: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
        address_option: "same",
        billing_country: null,
        billing_streetaddress: null,
        billing_streetaddress1: null,
        billing_city: null,
        billing_state: null,
        billing_zipcode: null,
        phone: null,
        company_name: null,
        preferred_invoice_email: null,
        user_id: null,
        is_primary: 0,
        shipping_standard: null,
        shipping_standard_date: null,
        shipping_payment_method: null,
        payment_ups_account_number: null,
        shipping_method: null,
        fedex_service: null,
        ups_service: null,
        ups_express_service: null,
        fedex_express_service: null,
        shipping_method_by_customer: null,
        shipping_service_name: null,
        shipping_service_code: null,
        name_on_card: null,
        card_number: null,
        month_of_expiary: null,
        year_of_expiary: null,
        card_expiry: null,
        cvv: null,
        ship_amount: 0,
        total_amount: 0,
        sub_total: 0,
        payment_method: "authorize",
        payment_term: "credit_card",
        tax_amount: 0,
        tax_percent: 0,
        quote_id: null,
        CustPONum: null,
        Residential: 0,
        is_resi_address: "",
        test_report: 0,
        Instr: null,
        FinalMsg: "",
        InternalRef: 0,
        scheduledate: "",
        schedule_order: 0,
        schedule_date_all: "",
        schedule_date_1: "",
        schedule_date_2: "",
        schedule_date_3: "",
        schedule_date_4: "",
        Qty_1: "",
        Qty_2: "",
        Qty_3: "",
        Qty_4: "",
        surCharge: 0,
        ccfee:0,
        payment_term_desc: "",
        order_schedule_dates_fields: [
          {
            product_item: null,
            Qty: null,
            schedule_date: "",
          },
        ],
      });

      this.form = new Form(form);
    },
    ScheduleDateShowAddButton(index) {
      return this.order_schedule_dates_fields.length - 1 === index;
    },

    ScheduleDateShowRemoveButton(index) {
      return this.order_schedule_dates_fields.length > 1 && index > 0;
    },

    addScheduleDate() {
      this.order_schedule_dates_fields.push({
        product_item: "",
        Qty: "",
        schedule_date: "",
      });
    },

    removeScheduleDate(index) {
      this.order_schedule_dates_fields.splice(index, 1);
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    changeOrderDates() {
      if (this.form.schedule_order == "select") {
        $(".page--checkoutinfo .forDateSelection").css("display", "block");
      } else {
        $(".page--checkoutinfo .forDateSelection").css("display", "none");
      }
      $(".page--checkoutinfo .DateFileds").css("display", "none");
      $(".page--checkoutinfo .DateFiledsForAll").css("display", "none");

      this.form.scheduledate = "";
      // this.changeDates();
    },
    changeResidential() {
      if (this.form.Residential == 1) {
        $(".is-resi-address").css({
          display: "flex",
          "justify-content": "space-between",
        });
      } else {
        $(".is-resi-address").css("display", "none");
      }
    },
    changeAddress() {
      if (this.form.address_option == "same") {
        $(".page--checkoutinfo .billing_differ").css("display", "none");

        this.form.country = this.form.billing_country;
        this.form.streetaddress = this.form.billing_streetaddress;
        this.form.streetaddress1 = this.form.billing_streetaddress1;
        this.form.city = this.form.billing_city;
        this.form.state = this.form.billing_state;
        this.form.zipcode = this.form.billing_zipcode;
      } else {
        $(".page--checkoutinfo .billing_differ").css("display", "block");

        this.form.country = null;
        this.form.streetaddress = null;
        this.form.streetaddress1 = null;
        this.form.city = null;
        this.form.state = null;
        this.form.zipcode = null;
      }
      this.changeCountry();
    },
    changeDates() {
      console.log(this.form.scheduledate);
      if (this.form.scheduledate == "onedate") {
        $(".page--checkoutinfo .DateFileds").css("display", "none");
        $(".page--checkoutinfo .DateFiledsForAll").css("display", "block");

        this.form.schedule_date_all = "";
        this.form.schedule_date_1 = "";
        this.form.schedule_date_2 = "";
        this.form.schedule_date_3 = "";
        this.form.schedule_date_4 = "";

        this.form.Qty_1 = "";
        this.form.Qty_2 = "";
        this.form.Qty_3 = "";
        this.form.Qty_4 = "";
      } else if (this.form.scheduledate == "differentdate") {
        $(".page--checkoutinfo .DateFiledsForAll").css("display", "none");
        $(".page--checkoutinfo .DateFileds").css("display", "block");

        this.form.schedule_date_all = "";
        this.form.schedule_date_1 = "";
        this.form.schedule_date_2 = "";
        this.form.schedule_date_3 = "";
        this.form.schedule_date_4 = "";

        this.form.Qty_1 = "";
        this.form.Qty_2 = "";
        this.form.Qty_3 = "";
        this.form.Qty_4 = "";
      } else {
        $(".page--checkoutinfo .DateFiledsForAll").css("display", "none");
        $(".page--checkoutinfo .DateFileds").css("display", "none");

        this.form.schedule_date_all = "";
        this.form.schedule_date_1 = "";
        this.form.schedule_date_2 = "";
        this.form.schedule_date_3 = "";
        this.form.schedule_date_4 = "";

        this.form.Qty_1 = "";
        this.form.Qty_2 = "";
        this.form.Qty_3 = "";
        this.form.Qty_4 = "";
      }
    },
    getShippingService() {
      this.ship_error = null;
      $(".showUpsExpressService").hide();
      $(".showFedexExpressService").hide();
      $(".shipping_method_div").css({ flex: "1", width: "auto" });
      if (this.form.shipping_method == "FEDEX") {
        $(".showFedexService").css("display", "flex");
        $(".showUpsService").hide();
        $(".shipping_method_by_customer").hide();
      } else if (this.form.shipping_method == "UPS") {
        $(".showFedexService").hide();
        $(".showUpsService").css("display", "flex");
        $(".shipping_method_by_customer").hide();
      } else if (this.form.shipping_method == "OTHER") {
        $(".showFedexService").hide();
        $(".showUpsService").hide();
        $(".shipping_method_by_customer").css("display", "flex");
      } else {
        $(".showFedexService").hide();
        $(".showUpsService").hide();
        $(".shipping_method_by_customer").hide();
      }
    },
    getShippingStandard() {
      if (this.form.shipping_standard == "hold") {
        $(".shipping_standard_div").css({ flex: "1", width: "auto" });
        $(".shipping_standard_date").show();
      } else {
        $(".shipping_standard_div").css({ flex: "none", width: "100%" });
        $(".shipping_standard_date").hide();
      }
    },
    getPaymentMethod() {
      if (this.form.shipping_payment_method == "collect") {
        $(".shipping_payment_method_div").css({ flex: "1", width: "auto" });
        $(".payment_ups_account_number").show();
        this.shippingmethods.map((v) => {
          if (v.value == "DHL" || v.value == "FEDEX") {
            v.disabled = false;
          }
          return v;
        });
      } else {
        $(".shipping_payment_method_div").css({ flex: "none", width: "100%" });
        $(".payment_ups_account_number").hide();
        this.shippingmethods.map((v) => {
          if (v.value == "DHL" || v.value == "FEDEX") {
            v.disabled = true;
          }
          return v;
        });
        if (
          this.form.shipping_method == "DHL" ||
          this.form.shipping_method == "FEDEX"
        ) {
          this.form.shipping_method = null;
        }
      }
    },
    getPaymentTerm() {
      // $(".credit_card").show();
      setTimeout(() => {
        if (this.form.payment_term == "credit_card") {
          document.querySelector(".credit_card").setAttribute("style", "");
          const ccfeecalculate = (this.item_total * 3.5) / 100;
          this.ccfee = ccfeecalculate.toFixed(2);
        } else {
          document.querySelector(".credit_card").setAttribute("style", "display:none;");
          this.ccfee = 0;
        }
      }, 100);
    },
    getFedexShippingRate() {
      this.ship_error = null;

      this.form.ship_amount = 0;
      this.form.ups_service = null;

      let url = [];
      if (this.form.zipcode != null) {
        url["zipcode"] = this.form.zipcode;
      }
      if (this.form.fedex_service != null) {
        url["fedex_service"] = this.form.fedex_service;
      }
      if (this.form.streetaddress != null) {
        url["streetaddress"] = this.form.streetaddress;
      }
      if (this.form.city != null) {
        url["city"] = this.form.city;
      }
      if (this.form.state != null) {
        url["state"] = this.form.state;
      }
      url["shipping_country_code"] = this.shipping_country_code;

      url["width"] = 3;

      url["height"] = 3;

      url["len"] = 3;
      url["weight"] = 3;

      var queryString = Object.keys(url)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(url[key]);
        })
        .join("&");

      // this.$store.dispatch("getFedexRate", queryString).then((response) => {
      //   if (response.data.error && response.data.errorMsg != "") {
      //     this.ship_error = response.data.errorMsg;
      //     this.form.ship_amount = 0;
      //     this.form.fedex_service = null;
      //   } else {
      //     this.form.ship_amount = response.data.shipamount;
      //     // console.log("Amount"+this.final_total.replace(/,/g,""));
      //     this.form.total_amount =
      //       parseFloat(this.final_total.replace(/,/g, "")) +
      //       parseFloat(response.data.shipamount);
      //     this.form.total_amount = this.form.total_amount.toFixed(2);

      //     let mythis = this;
      //     this.FedexServices.forEach(function(service) {
      //       if (service.value == mythis.form.fedex_service) {
      //         mythis.form.shipping_service_name = service.text;
      //         mythis.form.shipping_service_code = service.value;
      //       }
      //     });
      //   }
      // });
      if (this.form.fedex_service == "EXPRESS") {
        $(".showFedexExpressService").css("display", "flex");
      } else {
        $(".showFedexExpressService").hide();
      }
      if (this.form.fedex_service == "OTHER") {
        $(".shipping_method_by_customer").css("display", "flex");
      } else {
        $(".shipping_method_by_customer").hide();
      }
    },
    getUpsShippingRate() {
      $(".shipToday").prop("disabled", false);

      this.ship_error = null;

      let url = [];
      if (this.form.zipcode != null) {
        url["zipcode"] = this.form.zipcode;
      }
      if (this.form.ups_service != null) {
        url["ups_service"] = this.form.ups_service;
      }
      url["shipping_country_code"] = this.shipping_country_code;

      url["width"] = 3;

      url["height"] = 3;

      url["len"] = 3;
      url["weight"] = 3;

      this.form.fedex_service = null;
      this.form.ship_amount = 0;

      /*this.$store
                    .dispatch("getUPSRate", {
                        form: this.form
                    })
                    .then(response => {
                        this.getshipping = response.data;
                    });*/
      var queryString = Object.keys(url)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(url[key]);
        })
        .join("&");

      // this.$store.dispatch("getUPSRate", queryString).then((response) => {
      //   if (response.data != "error" && response.data != "") {
      //     this.form.ship_amount = response.data;
      //     this.form.total_amount =
      //       parseFloat(this.final_total.replace(/,/g, "")) +
      //       parseFloat(response.data);
      //     this.form.total_amount = this.form.total_amount.toFixed(2);

      //     let mythis = this;
      //     this.UpsServices.forEach(function(service) {
      //       if (service.value == mythis.form.ups_service) {
      //         mythis.form.shipping_service_name = service.text;
      //         mythis.form.shipping_service_code = service.value;
      //       }
      //     });
      //   } else {
      //     this.ship_error =
      //       "The rate for the chosen shipping method is not available at the moment. Kindly check your ZIP Code and try again.";
      //     this.form.ship_amount = 0;
      //     this.form.ups_service = null;
      //   }
      // });
      if (this.form.ups_service == "EXPRESS") {
        $(".showUpsExpressService").css("display", "flex");
      } else {
        $(".showUpsExpressService").hide();
      }
      if (this.form.ups_service == "OTHER") {
        $(".shipping_method_by_customer").css("display", "flex");
      } else {
        $(".shipping_method_by_customer").hide();
      }
    },
    changePaymentMethod() {
      if (this.form.payment_method == "paypal") {
        $(".page--checkoutinfo .AuthorizedPayment").css("display", "none");
        $(".page--checkoutinfo .purchaseNowBtn").css("display", "none");
        $(".page--checkoutinfo .PaypalPayment").css("display", "block");
      } else {
        $(".page--checkoutinfo .PaypalPayment").css("display", "none");
        $(".page--checkoutinfo .purchaseNowBtn").css("display", "block");
        $(".page--checkoutinfo .AuthorizedPayment").css("display", "block");
      }
    },
    continueShipping() {
      if (this.form.address_option == "same") {
        this.form.country = this.form.billing_country;
        this.form.streetaddress = this.form.billing_streetaddress;
        this.form.streetaddress1 = this.form.billing_streetaddress1;
        this.form.city = this.form.billing_city;
        this.form.state = this.form.billing_state;
        this.form.zipcode = this.form.billing_zipcode;
      }
      if (this.form.CustPONum == null) {
        this.form.CustPONum =
          this.form.firstname.toUpperCase() +
          moment(new Date()).format("DDMMYY");
      }
      console.log(this.form.CustPONum);

      this.form.order_schedule_fields = this.order_schedule_dates_fields;

      this.$store
        .dispatch("checkoutStep1", {
          form: this.form,
        })
        .then(() => {
          this.getCountryDetails();
          $(".page--checkoutinfo .contact-info-section").css("display", "none");
          $(".page--checkoutinfo .shipping-address-section").css(
            "display",
            "none"
          );
          $(".page--checkoutinfo .billing-address-section").css(
            "display",
            "none"
          );
          $(".page--checkoutinfo .payment-method-section").css(
            "display",
            "none"
          );
          $(
            ".page--checkoutinfo .step-1-button, .page--checkoutinfo .step-3-button"
          ).css("display", "none");
          $(".page--checkoutinfo .step-2-button").css("display", "block");
          $(".page--checkoutinfo .shipping-method-section").css(
            "display",
            "block"
          );
          this.headerTitle = "Step 2 - Shipping";
        })
        .catch((error) => {
          console.log(error);
          if (this.form.hasErrors()) {
            this.$notify({
              text: "A validation error has occurred. Please review your information and try again.",
              type: "error",
            });
          } else {
            this.$notify({
              text: "A validation error has occurred. Please review your information and try again.",
              type: "error",
            });
          }
        });
    },
    continuePayment() {
      this.form.order_schedule_fields = this.order_schedule_dates_fields;

      this.accErr = "";
      if (
        this.form.shipping_payment_method == "collect" &&
        this.form.shipping_method == "UPS"
      ) {
        this.form.payment_ups_account_number =
          `${this.form.payment_ups_account_number}`.toUpperCase();
      }
      if (
        this.form.shipping_payment_method == "collect" &&
        this.form.shipping_method == "UPS" &&
        !(
          /[A-Z0-9]{6}/.test(this.form.payment_ups_account_number) &&
          this.form.payment_ups_account_number.length === 6
        )
      ) {
        this.accErr = "Invalid UPS Account number";
        return;
      }

      if (
        this.form.shipping_payment_method == "collect" &&
        this.form.shipping_method == "FEDEX" &&
        !(
          /[0-9]{9}/.test(this.form.payment_ups_account_number) &&
          this.form.payment_ups_account_number.length === 9
        )
      ) {
        this.accErr = "Invalid FEDEX Account number";
        return;
      }

      this.$store
        .dispatch("checkoutStep2", {
          form: this.form,
        })
        .then(() => {
          $(".contact-info-section").css("display", "none");

          $(".shipping-address-section").css("display", "none");
          $(".billing-address-section").css("display", "none");
          $(".shipping-method-section").css("display", "none");
          $(
            ".page--checkoutinfo .step-1-button, .page--checkoutinfo .step-2-button"
          ).css("display", "none");
          $(".page--checkoutinfo .step-3-button").css("display", "block");
          $(".payment-method-section").css("display", "block");

          // render paypal button
          $(".PaypalPayment").css("display", "block");
          this.headerTitle = "Step 3 - Payment";
          //this.paypalRender();
          $(".PaypalPayment").css("display", "none");
        })
        .catch((error) => {
          console.log(error);
          if (this.form.hasErrors()) {
            this.$notify({
              text: "A validation error has occurred. Please review your information and try again.",
              type: "error",
            });
          } else {
            this.$notify({
              text: "A validation error has occurred. Please review your information and try again.",
              type: "error",
            });
          }
        });
    },
    continueContactInfo() {
      $(".payment-method-section").css("display", "none");
      $(".shipping-method-section").css("display", "none");
      $(".contact-info-section").css("display", "block");
      $(
        ".page--checkoutinfo .step-2-button, .page--checkoutinfo .step-3-button"
      ).css("display", "none");
      $(".page--checkoutinfo .step-1-button").css("display", "block");
      $(".shipping-address-section").css("display", "block");
      $(".billing-address-section").css("display", "block");
      this.headerTitle = "Step 1 - Information";
    },
    GetAddress() {
      if (this.form.saved_address != "") {
        this.$store
          .dispatch("getAddress", this.form.saved_address)
          .then((getaddress) => {
            this.$store
              .dispatch("getState", getaddress.data.success[0].country)
              .then((response) => {
                this.statelist = response.data;
              });

            this.form.country = parseInt(getaddress.data.success[0].country);
            this.form.streetaddress = getaddress.data.success[0].streetaddress;
            this.form.streetaddress1 =
              getaddress.data.success[0].streetaddress1;
            this.form.city = getaddress.data.success[0].city;
            this.form.state = parseInt(getaddress.data.success[0].state);
            this.form.zipcode = getaddress.data.success[0].zipcode;
            this.form.is_primary = getaddress.data.success[0].is_primary;
            this.shipping_country_code =
              getaddress.data.success[0].country_code;
          });
      } else {
        this.form.country = "";
        this.form.streetaddress = "";
        this.form.streetaddress1 = "";
        this.form.city = "";
        this.form.state = "";
        this.form.zipcode = "";
        this.form.is_primary = 0;
      }

      //this.getState(231);
    },
    deleteMyQuote(quote_id) {
      if (confirm("Are you sure to delete this quote!")) {
        this.$store
          .dispatch("myQuoteDelete", {
            product_id: quote_id,
          })
          .then((myQuote) => {
            if (myQuote.data.success) {
              this.$router.push("/myquotes");
            }
          })
          .catch((error) => {});
      }
    },
    rowClass(item, type) {
      return "tbRows";
    },
    loadmyQuote() {
      var d = new Date();

      var localTime = d.getTime();

      var localOffset = d.getTimezoneOffset() * 60000;

      var utc = localTime + localOffset;

      var offset = -6;

      var cst = utc + 3600000 * offset;

      var nd = new Date(cst);

      var newdate = nd.toLocaleString();
      console.log(nd.getHours() + " CST");

      // var today = new Date();
      //   var day = today.getHours();
      var day = nd.getHours();
      if (day >= "15") {
        this.chk = true;
      }

      if (this.$route.params.token == "info") {
        this.$store
          .dispatch("viewCheckoutCart", {
            token: this.$route.params.token,
          })
          .then((response) => {
            this.paymentTerms = this.getPayOption();

            this.myQuote = null;
            // this.myQuote[0].quote_type = "";
            this.quoteItems = response.data.success.items;
            console.log("quoteItems", this.quoteItems);
            this.item_total = parseFloat(
              `${response.data.success.item_total}`.replace(/,/g, "")
            );

            this.final_total = parseFloat(
              `${response.data.success.final_total}`.replace(/,/g, "")
            );

            this.exmonth = response.data.success.ex_month;
            this.exyear = response.data.success.ex_year;

            this.product_items = response.data.success.item_select_box;

            this.form = new Form({
              quote_id: "",
              user_id: "",
              firstname: "",
              lastname: "",
              email: "",
              country: "",
              streetaddress: "",
              streetaddress1: "",
              city: "",
              state: "",
              zipcode: "",
              phone: "",
              company_name: null,
              preferred_invoice_email: null,
              saved_address: "",
              CustPONum: null,
              Residential: 0,
              is_resi_address: "",
              test_report: 0,
              address_option: "same",
              billing_country: null,
              billing_streetaddress: null,
              billing_streetaddress1: null,
              billing_city: null,
              billing_state: null,
              billing_zipcode: null,
              is_primary: 0,
              shipping_standard: null,
              shipping_standard_date: null,
              shipping_payment_method: null,
              payment_ups_account_number: null,
              shipping_method: null,
              name_on_card: null,
              card_number: null,
              card_expiry: null,
              cvv: null,
              ship_amount: 0,
              total_amount: this.final_total,
              sub_total: parseFloat(
                `${response.data.success.item_total}`.replace(/,/g, "")
              ),
              payment_method: "authorize",
              payment_term: null,
              tax_amount: 0,
              fedex_service: null,
              ups_service: null,
              ups_express_service: null,
              fedex_express_service: null,
              shipping_method_by_customer: null,
              shipping_service_name: null,
              shipping_service_code: null,
              tax_percent: 0,
              Instr: null,
              FinalMsg: "",
              InternalRef: 0,
              scheduledate: "",
              schedule_date_all: "",
              schedule_date_1: "",
              schedule_date_2: "",
              schedule_date_3: "",
              schedule_date_4: "",
              Qty_1: "",
              Qty_2: "",
              Qty_3: "",
              Qty_4: "",
              order_schedule_fields: [],
              CustCode: this.user.CustCode,
              items: [],
              surCharge: 0,
              ccfee: 0,
              payment_term_desc: "",
            });
            if (
              parseFloat(`${this.form.total_amount}`.replace(/,/g, "")) > 100
            ) {
              this.shippingStandardOption.map((v) => {
                if (v.value == "hold") {
                  v.disabled = false;
                }
                return v;
              });
            } else {
              this.shippingStandardOption.map((v) => {
                if (v.value == "hold") {
                  v.disabled = true;
                }
                return v;
              });
            }
          });
      } else {
        this.$store
          .dispatch("viewCheckoutCart", {
            token: this.user.id,
          })
          .then((response) => {
            this.paymentTerms = this.getPayOption();

            // this.myQuote = response.data.success.quote;
            this.myQuote = null;
            this.quoteItems = response.data.success.items;
            console.log("quoteItems", this.quoteItems);

            this.item_total = parseFloat(
              `${response.data.success.item_total}`.replace(/,/g, "")
            );

            this.final_total = parseFloat(
              `${response.data.success.final_total}`.replace(/,/g, "")
            );
            // if (this.user.country !== "231") {
            //   if (this.final_total < 100) {
            //     this.surgeCharge = 100 - this.final_total;
            //   }
            // } else {
            //   if (this.final_total < 35) {
            //     this.surgeCharge = 35 - this.final_total;
            //   }
            // }

            this.exmonth = response.data.success.ex_month;
            this.exyear = response.data.success.ex_year;

            this.product_items = response.data.success.item_select_box;

            this.$store
              .dispatch(
                "getState",
                // this.myQuote[0].country
                //   ? this.myQuote[0].country
                //   : this.user.country
                this.user.country
              )
              .then((response) => {
                this.statelist = response.data;
              });

            this.form = new Form({
              // quote_id: this.myQuote[0].id,
              quote_id: "",
              // user_id: this.myQuote[0].user_id,
              // firstname: this.myQuote[0].firstname,
              // lastname: this.myQuote[0].lastname,
              // email: this.myQuote[0].email,
              user_id: this.user.id,
              firstname: this.user.firstname,
              lastname: this.user.lastname,
              email: this.user.email,
              country: this.user.country,
              // country: null,
              streetaddress: null,
              streetaddress1: null,
              city: null,
              state: null,
              zipcode: null,
              // phone: this.myQuote[0].phone,
              phone: this.user.phoneno,
              company_name: this.user.companyname,
              preferred_invoice_email: null,
              saved_address: "",
              CustPONum: null,
              Residential: 0,
              is_resi_address: "",
              test_report: 0,
              address_option: "same",
              billing_country: parseInt(`${this.user.country}`),
              billing_streetaddress: this.user.streetaddress,
              billing_streetaddress1: this.user.streetaddress1,
              billing_city: this.user.city,
              billing_state: parseInt(this.user.state),
              billing_zipcode: this.user.zipcode,
              is_primary: 0,
              shipping_standard: null,
              shipping_standard_date: null,
              shipping_payment_method: null,
              payment_ups_account_number: null,
              shipping_method: null,
              name_on_card: null,
              card_number: null,
              card_expiry: null,
              cvv: null,
              ship_amount: 0,
              total_amount: this.final_total,
              sub_total: parseFloat(
                `${response.data.success.item_total}`.replace(/,/g, "")
              ),
              payment_method: "authorize",
              payment_term: null,
              // tax_amount: this.myQuote[0].tax_amount,
              tax_amount: 0,
              fedex_service: null,
              ups_service: null,
              ups_express_service: null,
              fedex_express_service: null,
              shipping_method_by_customer: null,
              shipping_service_name: null,
              shipping_service_code: null,
              // tax_percent: this.myQuote[0].tax_percent,
              tax_percent: 0,
              Instr: null,
              FinalMsg: "",
              InternalRef: 0,
              scheduledate: "",
              schedule_date_all: "",
              schedule_date_1: "",
              schedule_date_2: "",
              schedule_date_3: "",
              schedule_date_4: "",
              Qty_1: "",
              Qty_2: "",
              Qty_3: "",
              Qty_4: "",
              order_schedule_fields: [],
              CustCode: this.user.CustCode,
              items: [],
              surCharge: 0,
              ccfee: 0,
              payment_term_desc: "",
            });
            if (
              parseFloat(this.form.total_amount.toString().replace(/,/g, "")) >
              100
            ) {
              this.shippingStandardOption.map((v) => {
                if (v.value == "hold") {
                  v.disabled = false;
                }
                return v;
              });
            } else {
              this.shippingStandardOption.map((v) => {
                if (v.value == "hold") {
                  v.disabled = true;
                }
                return v;
              });
            }
            this.getBillingState(false);
            this.changeCountry();
          });
      }

      let currentyHour = momentTimeZone(new Date()).format("HH");
      console.log(currentyHour);
      if (currentyHour >= 15) {
        this.shippingStandardOption.map((v) => {
          if (v.value == "today") {
            v.disabled = true;
          }
          return v;
        });
      } else {
        this.shippingStandardOption.map((v) => {
          if (v.value == "today") {
            v.disabled = false;
          }
          return v;
        });
      }
    },
    getCountry() {
      this.$store.dispatch("loadCountries").then((countries) => {
        this.countrylist = countries.data;
      });
    },
    getCountryDetails() {
      this.$store
        .dispatch("loadCountryDetails", this.form.country)
        .then((countries) => {
          console.log(countries[0].code);
          this.shipping_country_code = countries[0].code;
          this.changeCountry();
        });
    },
    getState(value) {
      this.$store.dispatch("getState", this.form.country).then((response) => {
        this.statelist = response.data;
      });
      this.form.state = parseInt(this.statelist[0].text);
    },
    loadCheckoutAddressInfo() {
      this.$store.dispatch("loadCheckoutAddressInfo").then((response) => {
        this.saved_address = response.data.listaddress;
        this.primary_address = response.data.primaryaddress;

        this.form.country = parseInt(response.data.primaryaddress.country);
        this.changeCountry();

        this.$store
          .dispatch("getState", parseInt(response.data.primaryaddress.country))
          .then((response) => {
            this.statelist = response.data;
          });

        this.form.streetaddress = response.data.primaryaddress.streetaddress;
        this.form.streetaddress1 = response.data.primaryaddress.streetaddress1;
        this.form.city = response.data.primaryaddress.city;
        this.form.state = parseInt(response.data.primaryaddress.state);
        this.form.zipcode = response.data.primaryaddress.zipcode;
        let mythis = this;
        setTimeout(() => {
          mythis.form.saved_address = parseInt(response.data.primaryaddress.id);
          mythis.form.is_primary = response.data.primaryaddress.is_primary;
          mythis.shipping_country_code =
            response.data.primaryaddress.country_code;
          mythis.form.state = parseInt(response.data.primaryaddress.state);

          console.log("State:" + mythis.form.state);
        }, 1500);
      });
    },
    getBillingState(reset = true) {
      this.$store
        .dispatch("getState", this.form.billing_country)
        .then((response) => {
          this.billing_statelist = response.data;
          if (reset) {
            this.form.billing_state = parseInt(this.billing_statelist[0].text);
          }
        });
    },
    gotosinglePage(productid) {
      this.$router.push({ path: "/store/product/" + productid });
    },
    onSubmit(evt) {
      this.loading = true;
      if (this.finalAmt == 0)
        this.finalAmt = parseFloat(`${this.form.total_amount}`.replace(/,/g, "")) + parseFloat(this.surgeCharge) + parseFloat(this.ccfee);

      evt.preventDefault();
      console.log(this.quoteItems);
      // return;
      if (this.form.payment_method != "paypal") {
        this.form.total_amount = `${parseFloat(`${this.form.total_amount}`.replace(/,/g, "")) + parseFloat(this.surgeCharge) + parseFloat(this.ccfee)}`;
        if (this.form.address_option == "same") {
          this.form.country = this.form.billing_country;
          this.form.streetaddress = this.form.billing_streetaddress;
          this.form.streetaddress1 = this.form.billing_streetaddress1;
          this.form.city = this.form.billing_city;
          this.form.state = this.form.billing_state;
          this.form.zipcode = this.form.billing_zipcode;
        }

        this.form.order_schedule_fields = this.order_schedule_dates_fields;
        this.form.surCharge = parseFloat(`${this.surgeCharge}`);
        this.form.ccfee = parseFloat(`${this.ccfee}`);

        // let quoteItems = JSON.parse(JSON.stringify(this.quoteItems));
        // if (this.myQuote[0].quote_type == "quick-quote") {
        //   quoteItems = Object.values(quoteItems).map((v) => {
        //     return {
        //       ...v,
        //       item_quantity: v.quantity,
        //       stock_uom: v.uom,
        //     };
        //   });
        // }

        this.form.items = this.quoteItems;
        this.form.payment_term_desc =
        this.form.payment_term == "credit_card" ? "Credit Card" : this.user.priceDetails.PmtTerms.TermsDesc;

        this.$store
          .dispatch("checkoutCart", {
            form: this.form,
          })
          .then((response) => {
            if (response.data.msg_type == "success_msg") {
              var gtagItems = [];
              for (var i = 0; i < this.quoteItems.length; i++) {
                let item = {
                  item_id: this.quoteItems[i].id,
                  item_name: this.quoteItems[i].name,
                  price: Number(this.quoteItems[i].item_price).toFixed(2),
                  quantity: this.quoteItems[i].Qty,
                };
                gtagItems.push(item);
              }
              gtag("event", "purchase", {
                affiliation: "Rapid Rivet & Fastener Corp.",
                transaction_id: new Date().getTime(),
                value: Number(this.item_total).toFixed(2),
                tax: 0.0,
                shipping: Number(this.form.ship_amount).toFixed(2),
                currency: "USD",
                items: gtagItems,
              });
              this.$router.push("/store/order/thankyou");
            } else {
              this.loading = false;

              this.paymentError = response.data.message_text;
            }
          })
          .catch((error) => {
            this.loading = false;

            console.log(error);
            this.$notify({
              text: "A validation error has occurred. Please review your information and try again.",
              type: "error",
            });
          });
      }
    },
    paypalRender() {},
  },
};
</script>

<style>
.forDateSelection,
.DateFiledsForAll,
.DateFileds {
  display: none;
}
.page--checkoutinfo .quotebtn {
  position: relative;
  float: right;
  background: none;
}
.page--checkoutinfo .quotebtn .addquote {
  color: #000 !important;
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 13px;
}
.productlisttable .table .tbColumn img.tickimg,
.hide_service {
  display: none;
}
.page--checkoutinfo .quotebtn .addquote:hover {
  background-color: #fff !important;
  text-decoration: underline;
  font-size: 13px;
}
.qtyfield {
  width: 80px;
}
.emptyquote {
  padding: 15px;
  color: #f2612c;
}
.loginform.w-form {
  background: #fff;
  padding: 30px;
}
.page--checkoutinfo .haserror input.vs__search {
  border-color: #fff;
}
.page--checkoutinfo .samediv span {
  float: left;
  font-size: 18px;
  border: 1px solid #d2d2d2;
  margin: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 8px;
  font-weight: 500;
  color: #303030;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
.page--checkoutinfo .samediv span.lastspan {
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 39px;
}
.page--checkoutinfo .samediv input {
  float: left;
  max-width: 100px;
  font-size: 14px;
  height: 35px;
  text-align: center;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  border-left: 0;
  border-right: 0;
  font-weight: 500;
  color: #303030;
  padding-top: 6px;
}
.page--checkoutinfo a.specsheet:hover {
  background: #efefef;
  color: #000;
}
.page--checkoutinfo a.specsheet {
  background: #efefef;
  color: #000;
  text-decoration: underline;
}
.page--checkoutinfo .quote-summery .row {
  position: relative;
  width: 100%;
  display: inline-block;
}
.page--checkoutinfo .quote-summery .row .button.secondary {
  margin-bottom: 20px;
}
.page--checkoutinfo .quote-summery .row .page--checkoutinfo .button.secondary {
  padding: 9px 19px;
}
.paymenterror {
  float: right;
  color: red;
}
.forDateSelection .DateFiledsForAll {
  margin: 20px 0 35px 0;
}
.datepicker.orderinput {
  border: 0;
  padding: 0;
}
.datepicker.orderinput div input {
  border: 1px solid #cccc;
  padding: 7px 10px;
}
.DateFileds {
  margin-bottom: 20px;
}
.datepicker.orderinput.shipping_standard_datepicker input {
  width: 100% !important;
  padding: 10px 10px !important;
  border: 1px solid #3c3c3c42 !important;
  color: #000;
}
.vdp-datepicker__calendar .cell.day:not(.disabled) {
  color: #000;
}
img.info-img {
  height: 14px !important;
}
.account-container {
  padding: 30px 20px 10px;
}
.chk_buttons {
  margin-top: 20px;
}
.is-resi-address {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.section.cc-store-home-wrap .quick-list-rename .div-block-10 {
  width: 100%;
}
.billing-address-section {
  margin-bottom: 0px !important;
}
.w-input.additional-note-textarea,
.w-input.instruction-textarea,
textarea.w-input {
  resize: none;
}
</style>
