import axios from "axios";

const state = {
  filters: [],
  cartlist: [],
  categorylist: [],
  itemcartlist: [],
  productlist: {
    types: [],
    proprietaries: [],
    headstyles: [],
    materials: [],
    finishes: [],
    diameters: [],
    len: [],
    material_specs: [],
    products: [],
  },
  chkboxes: [],
  showSearch: false,
  filterStatus: false,
};

const getters = {
  filters: (state) => state.filters,
  cartlist: (state) => state.cartlist,
  itemcartlist: (state) => state.itemcartlist,
  productlist: (state) => state.productlist,
  chkboxes: (state) => state.chkboxes,
  categorylist: (state) => state.categorylist,
  showSearch: (state) => state.showSearch,
  filterStatus: (state) => state.filterStatus,
};

const actions = {
  loadProducts({ commit }, requestquery) {
    return axios
      .get(`/api/products?${requestquery}`)
      .then((response) => {
        commit("PRODUCTS_LOADED", response.data);
        return response;
      });
  },

  loadCategory({ commit }) {
    return axios.get(`/api/categories`).then((response) => {
      commit("CATEGORY_LOADED", response.data);
      return response;
    });
  },

  loadProductDetail({ commit }, productid) {
    return axios
      .get(`/api/product/${productid}`)
      .then((response) => {
        return response;
      });
  },
  loadProductData({ commit }, { product_id, mfr }) {
    return axios
      .get(`/api/productData/${product_id}/${mfr}`)
      .then((response) => {
        return response;
      });
  },
  quoteSubmit(
    { commit },
    {
      user_id,
      product_id,
      QtyAvail,
      uom,
      quantity,
      input_mfr,
      input_cert,
    }
  ) {
    return axios
      .post(
        `/api/addquote/${user_id}/${input_mfr}/${QtyAvail}/${uom}/${input_cert}/${product_id}/${quantity}`
      )
      .then((response) => {
        commit("CART_LOADED", response.data);
        return response;
      });
  },
  quoteReSubmit({ commit }, items) {
    console.log(items, "yes");
    return new Promise((resolve, reject) => {
      Promise.all(
        items.map((item) => {
          const {
            user_id,
            product_id,
            QtyAvail,
            uom,
            quantity,
            input_mfr,
            input_cert,
          } = item;
          return axios.post(
            `/api/addquote/${user_id}/${input_mfr}/${QtyAvail}/${uom}/${input_cert}/${product_id}/${quantity}`
          );
        })
      )
        .then((res) => {
          if (res && res.length) {
            commit("CART_LOADED", res[res.length - 1].data);
          }
          resolve(res);
        })
        .catch(reject);
    });
  },

  addToCartSubmit(
    { commit },
    {
      user_id,
      product_id,
      quantity,
      QtyAvail,
      price,
      input_mfr,
      input_cert,
      uom,
    }
  ) {
    return axios
      .post(
        `/api/addtocart/${user_id}/${input_mfr}/${QtyAvail}/${input_cert}/${product_id}/${price}/${quantity}/${uom}`
      )
      .then((response) => {
        commit("ITEM_CART_LOADED", response.data);
        return response;
      });
  },

  loadItemCartList({ commit }) {
    return axios
      .get(`/api/itemcartlist`)
      .then((response) => {
        commit("ITEM_CART_LOADED", response.data);
        let arr = {
          qty: {},
          chk: [],
        };
        $.each(response.data.success, function(key, lp) {
          arr.chk.push(lp.product_id);
          arr.qty[lp.product_id] = lp.quantity;
        });
        commit("ACTIVE_CHECKBOXES", arr);

        return response;
      });
  },

  loadCartList({ commit }) {
    return axios.get(`/api/cartlist`).then((response) => {
      commit("CART_LOADED", response.data);
      let arr = {
        qty: {},
        chk: [],
      };
      $.each(response.data.success, function(key, lp) {
        arr.chk.push(lp.product_id);
        arr.qty[lp.product_id] = lp.quantity;
      });
      commit("ACTIVE_CHECKBOXES", arr);

      return response;
    });
  },
  requestQuote({ commit }, { form }) {
    return form
      .post(`/api/requestquote`)
      .then((response) => {
        return response;
      });
  },
  sendQuoteRequest({ commit }, { user_id }) {
    return axios
      .get(`/api/sendrequestquote/${user_id}`)
      .then((response) => {
        return response;
      });
  },
  quoteDelete({ commit }, { product_id }) {
    return axios
      .get(`/api/deletequote/${product_id}`)
      .then((response) => {
        return response;
      });
  },

  itemDelete({ commit }, { product_id }) {
    return axios
      .get(`/api/deleteitem/${product_id}`)
      .then((response) => {
        return response;
      });
  },
  quoteDeletebyProductId({ commit }, { product_id }) {
    return axios
      .get(`/api/deletequotebyproduct/${product_id}`)
      .then((response) => {
        commit("CART_LOADED", response.data);
      });
  },
  updateCartQty({ commit }, obj) {
    return axios
      .get(`/api/updatecart/${obj.id}/${obj.val}`)
      .then((response) => {
        return response;
      });
  },
  updateUOM({ commit }, obj) {
    return axios
      .get(`/api/updateuom/${obj.id}/${obj.val}`)
      .then((response) => {
        return response;
      });
  },
  updateCartNote({ commit }, { form }) {
    return form
      .post(`/api/updatecartnote`)
      .then((response) => {
        return response;
      });
  },
  updateItemCartQty({ commit }, obj) {
    return axios
      .get(
        `/api/updateitemcart/${obj.id}/${obj.val}/${obj.price}`
      )
      .then((response) => {
        return response;
      });
  },
};

const mutations = {
  FILTERS_LOADED: (state, data) => {
    state.filters = data;
  },
  CART_LOADED: (state, data) => {
    state.cartlist = data;
  },
  ITEM_CART_LOADED: (state, data) => {
    state.itemcartlist = data;
  },
  PRODUCTS_LOADED: (state, data) => {
    state.productlist = data;
  },
  ACTIVE_CHECKBOXES: (state, data) => {
    state.chkboxes = data;
  },
  CATEGORY_LOADED: (state, data) => {
    state.categorylist = data;
  },
  SHOW_SEARCH: (state, data) => {
    state.showSearch = data;
  },
  FILTER_STATUS: (state, data) => {
    state.filterStatus = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
