<template>
  <div>
    <CommonHead></CommonHead>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
export default {
  components: {
    CommonHead,
  },
  data() {
    // return {
    //   isLoading: false,
    //   TITLE: "RAPID",
    // };
  },
  mounted() {
    console.log("mounted WF");
    setTimeout(() => {
      Array.from(
        document
          .querySelectorAll("[data-vue-ready]")
          .forEach((r) => r.removeAttribute("data-vue-ready"))
      );
    }, 200);
  },
  methods: {},
};
</script>