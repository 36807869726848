<template>
  <div>
    <CommonHead></CommonHead>

    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";

export default {
  components: {
    CommonHead,
    CommonFoot,
  },
  data() {
    return {
      isLoading: false,
      TITLE: "RAPID",
    };
  },
  mounted() {
    this.$router.push({ path: "/store/login" });
  },
  methods: {},
};
</script>