class FormErrors {
    /**
     * Create a new FormErrors instance.
     */
    constructor() {
        this.errors = {};
    }

    /**
     * Determine if we have any errors.
     *
     * @return {boolean}
     */
    any() {
        return Object.keys(this.errors).length > 0;
    }

    /**
     * Determine if an error exists for the given field.
     *
     * @param {String|Array} field
     * @return {boolean}
     */
    has(field) {
        if (typeof field === 'string') {
            return this.errors.hasOwnProperty(field);
        } else {
            for (let i in field) {
                if (this.errors.hasOwnProperty(field[i])) {
                    return true;
                }
            }

            return false;
        }
    }

    /**
     * Returns the error for the given field.
     *
     * @param {string} field
     * @return {string}
     */
    get(field) {
        if (this.has(field)) {
            return this.errors[field][0];
        }
    }

    /**
     * Record the new errors.
     *
     * @param {Object} errors
     */
    record(errors) {
        this.errors = errors;
    }

    /**
     * Clear the given field, or all fields.
     *
     * @param {string=} field
     */
    clear(field) {
        if (field) {
            delete this.errors[field];
        } else {
            this.errors = {};
        }
    }
}

export default FormErrors;
