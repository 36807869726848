var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CommonHead'),_vm._v(" "),_c('div',{staticClass:"section cc-store-home-wrap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"home-content-wrap-copy"},[_c('div',{staticClass:"div-block-30"},[_c('h3',[_vm._v("Customer Info")]),_vm._v(" "),(this.miniCartList.length > 0)?_c('div',{staticClass:"loginform w-form"},[_c('b-form',{staticClass:"commonform w-clearfix",on:{"submit":_vm.submitQuote}},[_c('h4',{staticClass:"heading-4 yourinfo"},[_c('strong',[_vm._v("Please provide your contact information")])]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('div',{staticClass:"div-block-48"},[_c('div',{class:_vm.form.errors.get('firstname')
                      ? 'haserror div-block-49'
                      : 'div-block-49',attrs:{"state":!_vm.form.errors.has('firstname'),"invalid-feedback":_vm.form.errors.get('firstname')}},[_c('label',{staticClass:"field-label-33",attrs:{"for":"name-4"}},[_vm._v("First name*")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstname),expression:"form.firstname"}],staticClass:"w-input",attrs:{"type":"text","maxlength":"256","state":!_vm.form.errors.has('firstname'),"data-name":"firstname","id":"firstname"},domProps:{"value":(_vm.form.firstname)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "firstname", $event.target.value)},function($event){return _vm.form.errors.clear('firstname')}]}}),_vm._v(" "),(_vm.form.errors.get('firstname'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"_35"}),_vm._v(" "),_c('div',{class:_vm.form.errors.get('lastname')
                      ? 'haserror div-block-50 typein'
                      : 'div-block-50 typein',attrs:{"state":!_vm.form.errors.has('lastname'),"invalid-feedback":_vm.form.errors.get('lastname')}},[_c('label',{staticClass:"field-label-34",attrs:{"for":"name-4"}},[_vm._v("Last name*")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastname),expression:"form.lastname"}],staticClass:"w-input",attrs:{"type":"text","maxlength":"256","state":!_vm.form.errors.has('lastname'),"data-name":"lastname","id":"lastname"},domProps:{"value":(_vm.form.lastname)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "lastname", $event.target.value)},function($event){return _vm.form.errors.clear('lastname')}]}}),_vm._v(" "),(_vm.form.errors.get('lastname'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('div',{staticClass:"div-block-48"},[_c('div',{class:_vm.form.errors.get('country')
                      ? 'haserror div-block-49'
                      : 'div-block-49',attrs:{"state":!_vm.form.errors.has('country'),"invalid-feedback":_vm.form.errors.get('country')}},[_c('label',{staticClass:"field-label-35",attrs:{"for":"name-4"}},[_vm._v("country *")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.countrylist,"state":!_vm.form.errors.has('country'),"reduce":(countrylist) => countrylist.value,"label":"text","id":"country","clearable":false},on:{"input":function($event){_vm.form.errors.clear('country');
                      _vm.getState();}},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_vm._v(" "),(_vm.form.errors.get('country'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"_35"}),_vm._v(" "),_c('div',{class:_vm.form.errors.get('companyname')
                      ? 'haserror div-block-50 typein'
                      : 'div-block-50 typein',attrs:{"state":!_vm.form.errors.has('companyname'),"invalid-feedback":_vm.form.errors.get('companyname')}},[_c('label',{staticClass:"field-label-36",attrs:{"for":"name-4"}},[_vm._v("company name*")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.companyname),expression:"form.companyname"}],staticClass:"w-input",attrs:{"type":"text","maxlength":"256","state":!_vm.form.errors.has('companyname'),"data-name":"Name 3","id":"companyname"},domProps:{"value":(_vm.form.companyname)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "companyname", $event.target.value)},function($event){return _vm.form.errors.clear('companyname')}]}}),_vm._v(" "),(_vm.form.errors.get('companyname'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('div',{staticClass:"div-block-48"},[_c('div',{class:_vm.form.errors.get('streetaddress')
                      ? 'haserror forstar div-block-49'
                      : 'forstar div-block-49',attrs:{"state":!_vm.form.errors.has('streetaddress'),"invalid-feedback":_vm.form.errors.get('streetaddress')}},[_c('label',{staticClass:"field-label-37",attrs:{"for":"name-4"}},[_vm._v("address 1*")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.streetaddress),expression:"form.streetaddress"}],staticClass:"w-input",attrs:{"type":"text","maxlength":"256","state":!_vm.form.errors.has('streetaddress'),"data-name":"Name 3","id":"streetaddress"},domProps:{"value":(_vm.form.streetaddress)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "streetaddress", $event.target.value)},function($event){return _vm.form.errors.clear('streetaddress')}]}}),_vm._v(" "),(_vm.form.errors.get('streetaddress'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"_35"}),_vm._v(" "),_c('div',{staticClass:"div-block-50 typein",attrs:{"invalid-feedback":_vm.form.errors.get('streetaddress1')}},[_c('label',{staticClass:"field-label-38",attrs:{"for":"name-4"}},[_vm._v("address 2")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.streetaddress1),expression:"form.streetaddress1"}],staticClass:"w-input",attrs:{"type":"text","maxlength":"256","data-name":"streetaddress1","id":"streetaddress1"},domProps:{"value":(_vm.form.streetaddress1)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "streetaddress1", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('div',{staticClass:"div-block-48"},[_c('div',{class:_vm.form.errors.get('city')
                      ? 'haserror forstar div-block-50 typein'
                      : 'forstar div-block-50 typein',attrs:{"state":!_vm.form.errors.has('city'),"invalid-feedback":_vm.form.errors.get('city')}},[_c('label',{staticClass:"field-label-39",attrs:{"for":"name-4"}},[_vm._v("city*")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.city),expression:"form.city"}],staticClass:"w-input",attrs:{"type":"text","maxlength":"256","state":!_vm.form.errors.has('city'),"data-name":"City","id":"city"},domProps:{"value":(_vm.form.city)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "city", $event.target.value)},function($event){return _vm.form.errors.clear('city')}]}}),_vm._v(" "),(_vm.form.errors.get('city'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"_35"}),_vm._v(" "),_c('div',{class:_vm.form.errors.get('state')
                      ? 'haserror forstar div-block-49'
                      : 'forstar div-block-49',attrs:{"state":!_vm.form.errors.has('state'),"invalid-feedback":_vm.form.errors.get('state')}},[_c('label',{staticClass:"field-label-40",attrs:{"for":"name-4"}},[_vm._v("state*")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.statelist,"state":!_vm.form.errors.has('state'),"reduce":(statelist) => statelist.value,"label":"text","id":"state","clearable":false},on:{"input":function($event){return _vm.form.errors.clear('state')}},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}}),_vm._v(" "),(_vm.form.errors.get('state'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('div',{staticClass:"div-block-48"},[_c('div',{class:_vm.form.errors.get('zipcode')
                      ? 'haserror forstar div-block-49'
                      : 'forstar div-block-49',attrs:{"state":!_vm.form.errors.has('zipcode'),"invalid-feedback":_vm.form.errors.get('zipcode')}},[_c('label',{staticClass:"field-label-41",attrs:{"for":"name-4"}},[_vm._v("Zip/postal code*")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.zipcode),expression:"form.zipcode"}],staticClass:"w-input",attrs:{"type":"text","maxlength":"256","state":!_vm.form.errors.has('zipcode'),"data-name":"zipcode","id":"zipcode"},domProps:{"value":(_vm.form.zipcode)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "zipcode", $event.target.value)},function($event){return _vm.form.errors.clear('zipcode')}]}}),_vm._v(" "),(_vm.form.errors.get('zipcode'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"_35"}),_vm._v(" "),_c('div',{class:_vm.form.errors.get('phoneno')
                      ? 'haserror forstar div-block-50 typein'
                      : 'forstar div-block-50 typein',attrs:{"state":!_vm.form.errors.has('phoneno'),"invalid-feedback":_vm.form.errors.get('phoneno')}},[_c('label',{staticClass:"field-label-42",attrs:{"for":"name-4"}},[_vm._v("Phone*")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phoneno),expression:"form.phoneno"}],staticClass:"w-input",attrs:{"type":"text","maxlength":"256","state":!_vm.form.errors.has('phoneno'),"data-name":"phoneno","id":"phoneno"},domProps:{"value":(_vm.form.phoneno)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phoneno", $event.target.value)},function($event){return _vm.form.errors.clear('phoneno')}]}}),_vm._v(" "),(_vm.form.errors.get('phoneno'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('div',{staticClass:"div-block-48"},[_c('div',{class:_vm.form.errors.get('email')
                      ? 'haserror div-block-49'
                      : 'div-block-49',attrs:{"state":!_vm.form.errors.has('email'),"invalid-feedback":_vm.form.errors.get('email')}},[_c('label',{staticClass:"field-label-30",attrs:{"for":"name"}},[_vm._v("Email*")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"w-input",attrs:{"type":"text","maxlength":"256","state":!_vm.form.errors.has('email'),"id":"email"},domProps:{"value":(_vm.form.email)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)},function($event){return _vm.form.errors.clear('email')}]}}),_vm._v(" "),(_vm.form.errors.get('email'))?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.form.errors.get("email") == "The email field is required."
                      ? "Required Field"
                      : _vm.form.errors.get("email")))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"_35"}),_vm._v(" "),_c('div',{class:_vm.form.errors.get('findus')
                      ? 'haserror forstar div-block-49'
                      : 'forstar div-block-49',attrs:{"state":!_vm.form.errors.has('findus'),"invalid-feedback":_vm.form.errors.get('findus')}},[_c('label',{staticClass:"field-label-45",attrs:{"for":"name-4"}},[_vm._v("Where did you find us?*")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.finduslist,"state":!_vm.form.errors.has('findus'),"reduce":(finduslist) => finduslist.value,"label":"text","id":"findus","clearable":false,"placeholder":"Please select"},on:{"input":function($event){return _vm.form.errors.clear('findus')}},model:{value:(_vm.form.findus),callback:function ($$v) {_vm.$set(_vm.form, "findus", $$v)},expression:"form.findus"}}),_vm._v(" "),(_vm.form.errors.get('findus'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('div',{staticClass:"div-block-48"},[_c('div',{class:_vm.form.errors.get('industry')
                      ? 'haserror forstar div-block-49'
                      : 'forstar div-block-49',attrs:{"state":!_vm.form.errors.has('industry'),"invalid-feedback":_vm.form.errors.get('industry')}},[_c('label',{staticClass:"field-label-46",attrs:{"for":"name-4"}},[_vm._v("Type of industry?*")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.industrylist,"state":!_vm.form.errors.has('industry'),"reduce":(industrylist) => industrylist.value,"label":"text","id":"industry","clearable":false,"placeholder":"Please select"},on:{"input":function($event){return _vm.form.errors.clear('industry')}},model:{value:(_vm.form.industry),callback:function ($$v) {_vm.$set(_vm.form, "industry", $$v)},expression:"form.industry"}}),_vm._v(" "),(_vm.form.errors.get('industry'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"_35"}),_vm._v(" "),_c('div',{class:_vm.form.errors.get('companytype')
                      ? 'haserror forstar div-block-49'
                      : 'forstar div-block-49',attrs:{"state":!_vm.form.errors.has('companytype'),"invalid-feedback":_vm.form.errors.get('companytype')}},[_c('label',{staticClass:"field-label-47",attrs:{"for":"companytype"}},[_vm._v("company type?*")]),_vm._v(" "),_c('v-select',{attrs:{"options":_vm.companytypelist,"state":!_vm.form.errors.has('companytype'),"reduce":(companytypelist) => companytypelist.value,"label":"text","id":"companytype","clearable":false,"placeholder":"Please select"},on:{"input":function($event){return _vm.form.errors.clear('companytype')}},model:{value:(_vm.form.companytype),callback:function ($$v) {_vm.$set(_vm.form, "companytype", $$v)},expression:"form.companytype"}}),_vm._v(" "),(_vm.form.errors.get('companytype'))?_c('span',{staticClass:"error-msg"},[_vm._v("Required Field")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('div',{staticClass:"div-block-48"},[_c('div',{staticClass:"div-block-49"},[_c('label',{staticClass:"field-label-47",attrs:{"for":"notes"}},[_vm._v("Note")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.notes),expression:"form.notes"}],staticClass:"w-input",attrs:{"type":"text","maxlength":"256","id":"notes"},domProps:{"value":(_vm.form.notes)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "notes", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('b-button',{staticClass:"button w-button",attrs:{"type":"submit","block":"","variant":"dark"}},[_vm._v("Submit Quote Request")])],1)],1):_c('div',[_c('p',{staticClass:"emptyquote",staticStyle:{"display":"none"}},[_vm._v("\n              Your Quote List is empty.\n            ")])])])])])]),_vm._v(" "),_c('CommonFoot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }