<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="home-content-wrap-copy">
          <div class="div-block-30">
            <div class="text-block-4 h6">All Quote List</div>

            <div v-if="myQuotes.length > 0">
              <!-- Main table element -->
              <b-table
                show-empty
                responsive
                class="productlisttable"
                :items="myQuotes"
                :fields="fields"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(id)="row">
                  <div class="cell-link" @click="gotosinglePage(row.item.id)">
                    {{ row.item.id }}
                  </div>
                </template>
                <template v-slot:cell(total)="row">
                  <div class="cell-text">
                    {{ row.value != "0.00" ? "$ " + row.value : "-" }}
                  </div>
                </template>
                <template v-slot:cell()="row">
                  <div class="cell-text">{{ row.value }}</div>
                </template>

                <template v-slot:cell(addtoquote)="row">
                  <a href="javascript:;" @click="deleteMyQuote(row.item.id)">
                    <b-img
                      class="removesvg"
                      src="/images/delete.svg"
                      alt="delete"
                    ></b-img>
                  </a>
                  <a
                    v-if="
                      parseInt(row.item.total.replace(',', '')) > 0 &&
                      row.item.total != '' &&
                      !row.item.isExpired
                    "
                    :href="'/store/new-checkout/' + row.item.quote_token"
                    class="checkoutlink button"
                    style="max-width: 220px"
                  >
                    <div>Submit Purchase Order</div>
                  </a>
                  <p
                    v-if="row.item.isExpired"
                    @click="gotosinglePage(row.item.id)"
                    style="cursor: pointer"
                  >
                    Quote Expired
                  </p>
                </template>
              </b-table>
            </div>

            <!-- <div class="loginform w-form" v-if="myQuotes.length > 0"></div> -->
            <div v-else>
              <p class="emptyquote">Your Quote List is empty.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../../mixins/resources";
import moment from "moment";
export default {
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  beforeMount() {},
  mounted() {
    this.loadmyQuotes();
  },
  data() {
    return {
      myQuotes: [],

      show: true,
      country: [],
      states: [],

      fields: [
        {
          key: "id",
          label: "Quote Number",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "quote_date",
          label: "Quote Date",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "no_of_items",
          label: "No of items",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "total_quantity",
          label: "Total Quantity",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "total",
          label: "Total Quotation",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },

        {
          key: "addtoquote",
          label: "Action",
          thClass: "tbheaderAction",
          tdClass: "tbColumn",
        },
      ],
    };
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    deleteMyQuote(prod_id) {
      if (confirm("Are you sure to delete this quote!")) {
        this.$store
          .dispatch("myQuoteDelete", {
            product_id: prod_id,
          })
          .then((myQuotes) => {
            // this.$notify({
            //     text: "quote deleted successfully",
            //     type: "success"
            // });
            if (myQuotes.data.success) {
              this.loadmyQuotes();
            }
          })
          .catch((error) => {
            /*if (this.form.hasErrors()) {
                            this.$notify({
                                text:
                                    "There is some validation error. Please correct and try again!",
                                type: "error"
                            });
                        } else {
                            this.$notify({
                                text: "Something went wrong!",
                                type: "error"
                            });
                        }*/
          });
      }
    },
    rowClass(item, type) {
      return "tbRows";
    },
    loadmyQuotes() {
      this.$store.dispatch("loadMyQuotesAll").then((response) => {
        this.myQuotes = response.data.success.quote.map((d) => {
          return {
            ...d,
            isExpired:
              moment().diff(moment(d.quote_updated, "MM/DD/YYYY"), "days") > 30,
          };
        });
      });
    },

    gotosinglePage(productid) {
      this.$router.push({ path: "/store/myquote/" + productid });
    },
  },
};
</script>
<style>
.page--quotelist .quotebtn {
  position: relative;
  float: right;
  background: #f2612c;
}
.quotebtn .addquote {
  color: #ffff;
}
.productlisttable .table .tbColumn img.tickimg {
  display: none;
}
.quotebtn .addquote:hover {
  text-decoration: none;
  background-color: #f2612c;
}
.qtyfield {
  width: 80px;
}
.emptyquote {
  padding: 15px;
  color: #f2612c;
}
.loginform.w-form {
  background: #fff;
  padding: 30px;
}
.page--quotelist .haserror input.vs__search {
  border-color: #fff;
}
.tbheaderAction {
  width: 250px;
}
</style>
