import { initMain } from "./app-main";
import { initWF } from "./app-wf";

if (window.isWebflow) {
  if (window.skipVue) {
    console.log("skipping vue js");
  } else {
    console.log("Webflow+Vue");
    initWF();
  }
} else {
  initMain();
}
