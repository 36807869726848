export default {
    methods: {
        /**
         * Redirects front end after successful login..
         *
         * @return {void}
         */
        redirectAfterLogin(customRedirect) {
            let redirect = "";
            if (customRedirect) {
                redirect = customRedirect;
            } else {
                redirect = this.$route.query.redirect;
            }

            if (!redirect || redirect === "/") {
                if (this.hasOwnProperty("redirectTo")) {
                    redirect = this.redirectTo;
                } else {
                    redirect = "/store/account";
                }
            }

            if (_.startsWith(redirect, "/")) {
                this.$router.push(redirect);
            } else {
                this.form.busy = true;
                window.location = redirect;
            }
        },

        /**
         * Redirects front end after successful register..
         *
         * @return {void}
         */
        redirectAfterRegister() {
            let redirect = this.$route.query.redirect;

            if (
                !redirect ||
                redirect === "/" ||
                this.hasOwnProperty("redirectTo")
            ) {
                if (this.hasOwnProperty("redirectTo")) {
                    redirect = this.redirectTo;
                } else {
                    redirect = "/store/login";
                }
            }

            if (_.startsWith(redirect, "/")) {
                this.$router.push(redirect);
            } else {
                this.form.busy = true;
                window.location = redirect;
            }
        }
    }
};
