<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container thankyoupage">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock thankyoublock">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10 thankyoucont">
            <div class="div-block-12">
              <h4 class="heading">Thank You!</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image thankcut"
              />
            </div>
            <div class="home-section-wrap">
              <h5 class="heading-3">
                Or Call Toll Free : 800 - RapidRT ( 727 - 4378 )
              </h5>
              <div class="text-block-27">
                Thank you for ordering with us.
                <br />We will start processing your order.
              </div>

              <div class="nameadress">
                <p class="paragraph-copy contactfoot">
                  <strong class="bold-text-3">Need Help?</strong>
                </p>
                <div class="thankyoudiv">
                  <div class="setdiv">
                    <span>Email:</span>
                    <a href="mailto:sales@rapidrivet.com"
                      >sales@rapidrivet.com</a
                    >
                  </div>
                  <div class="setdiv">
                    <span>Phone:</span>
                    <a href="callto:(800) 727-4378">(800) 727-4378</a>
                  </div>
                </div>
              </div>
              <div class="thankyou_btns">
                <a
                  class="button secondary mobilehide w-inline-block"
                  href="/store/category/solid"
                  >Continue shopping</a
                >
                <a
                  v-if="this.user.id != 0"
                  class="button cc-contact-us mobhidde w-inline-block"
                  href="/store/myorders"
                  >View Order History</a
                >
                <a
                  v-else
                  class="button cc-contact-us mobhidde w-inline-block"
                  href="/store/login?redirect=/store/myorders"
                  >View Order History</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import { mapGetters } from "vuex";
export default {
  components: {
    CommonHead,
    CommonFoot,
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
<style>
.page--order-thankyou .thankyou_btns a {
  margin: 10px;
}
</style>
