<template>
  <div class="myaccount">
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div
            data-delay="4000"
            data-animation="slide"
            data-autoplay="1"
            data-duration="500"
            data-infinite="1"
            class="slider-2 contslider w-slider"
          >
            <div class="w-slider-mask">
              <div class="slide w-slide">
                <div class="div-block-20 contactblock">
                  <div>
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt
                      class="image"
                    />
                  </div>
                  <h5>Benny</h5>
                  <p>
                    Needed to know how many each were in a pound of rivets. Mr.
                    Al Felice, responded the next day, great customer service.
                  </p>
                </div>
              </div>
              <div class="slide-2 w-slide">
                <div class="div-block-20">
                  <div>
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt
                      class="image"
                    />
                  </div>
                  <h5>Katy</h5>
                  <p>
                    I appreciate the quick responses when I send over an e-mail.
                    Nothing is more frustrating than vendors who take a week to
                    get back to you. Any time I have contacted anyone at Rapid
                    Rivet, they have done a nice job responding to my request
                    and any questions I might have. Thank you!
                  </p>
                </div>
              </div>
              <div class="slide-3 w-slide">
                <div class="div-block-20">
                  <div>
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt
                      class="image"
                    />
                  </div>
                  <h5>Stephanie</h5>
                  <p>
                    Kevin is ALWAYS on point! He is courteous and really knows
                    his products! I’ve never had a problem with product or
                    shipping, the Rapid Rivet team is wonderful!
                  </p>
                </div>
              </div>
              <div class="slide-4 w-slide">
                <div class="div-block-20">
                  <div>
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt
                      class="image"
                    />
                  </div>
                  <h5>Diane</h5>
                  <p>
                    I have been dealing with Rapid Rivet for many years. They
                    have always been prompt, efficient and courteous.
                  </p>
                </div>
              </div>
            </div>
            <div class="left-arrow-3 w-slider-arrow-left">
              <div class="w-icon-slider-left"></div>
            </div>
            <div class="right-arrow-3 w-slider-arrow-right">
              <div class="w-icon-slider-right"></div>
            </div>
            <div class="w-slider-nav w-round"></div>
          </div>
          <div class="div-block-54-copy loginblock addressmob">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Address Book</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <a
                href="/store/addaddress"
                class="button cc-contact-us addnewadd w-inline-block bluebtn"
              >
                <div>Add New Address</div>
              </a>
              <div v-for="(primary, index) in this.primaryaddress" :key="index">
                <h4>Primary Address</h4>
                <div>
                  <strong
                    >{{ primary.firstname }} {{ primary.lastname }}</strong
                  >
                  <br />
                  {{ primary.companyname }}
                  <br />
                  {{ primary.streetaddress }} {{ primary.streetaddress1 }} ,
                  {{ primary.city }} , {{ primary.state_name }}
                  {{ primary.zipcode }}, {{ primary.country_code }}
                </div>
                <div class="addeditadd w-clearfix">
                  <a
                    :href="'/editaddress/' + primary.id"
                    class="button cc-contact-us editadd w-inline-block"
                  >
                    <div>Edit</div>
                  </a>
                </div>
              </div>

              <div
                class="addressdiv"
                v-for="(other, other_index) in this.otheraddress"
                :key="other_index"
              >
                <h4 v-if="other_index == 0">Other Address</h4>
                <div>
                  <strong>{{ other.firstname }} {{ other.lastname }}</strong>
                  <br />
                  {{ other.companyname }}
                  <br />
                  {{ other.streetaddress }} {{ other.streetaddress1 }} ,
                  {{ other.city }} , {{ other.state_name }} {{ other.zipcode }},
                  {{ other.country_code }}
                </div>
                <div class="addeditadd w-clearfix">
                  <a
                    :href="'/editaddress/' + other.id"
                    class="button cc-contact-us editadd w-inline-block"
                  >
                    <div>Edit</div>
                  </a>
                  <!-- <a
                                        href="/store/addaddress"
                                        class="button cc-contact-us addnewadd w-inline-block"
                                    >
                                        <div>Add New Address</div>
                                    </a> -->

                  <a
                    @click.stop.prevent="deleteAddress(other.id)"
                    href="#"
                    class="button cc-contact-us addnewadd w-inline-block"
                  >
                    <div>Delete</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import ResourceMixin from "../../mixins/resources";

export default {
  components: {
    CommonHead,
    CommonFoot,
  },
  mixins: [ResourceMixin],
  mounted() {
    this.$store.dispatch("loadAddressInfo").then((addressinfo) => {
      this.otheraddress = addressinfo.data.success.other_address;
      this.primaryaddress = addressinfo.data.success.primary_address;
    });
  },
  data() {
    return {
      isLoading: false,
      address_id: "",
      otheraddress: [],
      primaryaddress: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    deleteAddress(value) {
      if (confirm("Are you sure to delete this address?")) {
        this.isLoading = true;
        this.$store.dispatch("deleteAddress", value).then((response) => {
          if (response.data.success) {
            this.$notify({
              text: "Address Deleted Successfully",
              type: "success",
            });
            this.otheraddress = response.data.success.other_address;
            this.primaryaddress = response.data.success.primary_address;
          } else {
            this.$notify({
              text: "Please try again! Something went wrong!",
              type: "error",
            });
          }
        });
        this.isLoading = false;
      }
    },
  },
};
</script>
<style>
</style>