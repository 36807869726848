const state = {
    loading: [],
    resolvers: {},
    rejections: {}
};

const getters = {
    loadingResources: state => state.loading,
    resourceResolvers: state => state.resolvers,
    resourceRejections: state => state.rejections
};

const actions = {
    /**
     * Set given resourceKey as loading.
     *
     * @param commit
     * @param resourceKey
     * @return {void}
     */
    setResourceLoading({commit}, resourceKey) {
        commit('SET_RESOURCE_AS_LOADING', resourceKey);
    },

    /**
     * Set given resourceKey as not loading.
     *
     * @param commit
     * @param resourceKey
     * @return {void}
     */
    removeResourceLoading({commit}, resourceKey) {
        commit('REMOVE_RESOURCE_AS_LOADING', resourceKey);
    },

    /**
     * Registers given resolver callback as a resource resolver.
     *
     * @param commit
     * @param resourceKey
     * @param resolver
     * @return {void}
     */
    registerResolver({commit}, {resourceKey, resolver}) {
        commit('SET_RESOURCE_RESOLVER', {resourceKey, resolver});
    },

    /**
     * Registers given rejection callback as a resource rejection.
     *
     * @param commit
     * @param resourceKey
     * @param rejection
     * @return {void}
     */
    registerRejection({commit}, {resourceKey, rejection}) {
        commit('SET_RESOURCE_REJECTION', {resourceKey, rejection});
    },

    /**
     * Execute resolvers for given resource key.
     *
     * @param commit
     * @param {Array|String} resourceKeys
     * @return {void}
     */
    executeResolvers({commit}, resourceKeys) {
        if (Array.isArray(resourceKeys)) {
            resourceKeys.forEach(resourceKey => {
                commit('EXECUTE_RESOURCE_RESOLVERS', resourceKey);
            })
        } else {
            commit('EXECUTE_RESOURCE_RESOLVERS', resourceKeys);
        }
    },

    /**
     * Execute rejections for given resource key.
     *
     * @param commit
     * @param {Array|String} resourceKeys
     * @return {void}
     */
    executeRejections({commit}, resourceKeys) {
        if (Array.isArray(resourceKeys)) {
            resourceKeys.forEach(resourceKey => {
                commit('EXECUTE_RESOURCE_REJECTIONS', resourceKey);
            });
        } else {
            commit('EXECUTE_RESOURCE_REJECTIONS', resourceKeys);
        }
    },

    /**
     * Clear resolvers for given resource key.
     *
     * @param commit
     * @param {Array|String} resourceKeys
     * @return {void}
     */
    clearResolvers({commit}, resourceKeys) {
        if (Array.isArray(resourceKeys)) {
            resourceKeys.forEach(resourceKey => {
                commit('CLEAR_RESOURCE_RESOLVERS', resourceKey);
            });
        } else {
            commit('CLEAR_RESOURCE_RESOLVERS', resourceKeys);
        }
    },

    /**
     * Clear rejections for given resource key.
     *
     * @param commit
     * @param {Array|String} resourceKeys
     * @return {void}
     */
    clearRejections({commit}, resourceKeys) {
        if (Array.isArray(resourceKeys)) {
            resourceKeys.forEach(resourceKey => {
                commit('CLEAR_RESOURCE_REJECTIONS', resourceKey);
            });
        } else {
            commit('CLEAR_RESOURCE_REJECTIONS', resourceKeys);
        }
    }
};

const mutations = {
    /**
     * Adds given resourceKey to the loading state.
     *
     * @param state
     * @param resourceKey
     * @return {void}
     */
    SET_RESOURCE_AS_LOADING(state, resourceKey) {
        if (state.loading.indexOf(resourceKey) === -1) {
            state.loading.push(resourceKey);
        }
    },

    /**
     * Removes given resourceKey to the loading state.
     *
     * @param state
     * @param resourceKey
     * @return {void}
     */
    REMOVE_RESOURCE_AS_LOADING(state, resourceKey) {
        let i = state.loading.indexOf(resourceKey);

        if (i === -1) {
            state.loading = state.loading.splice(i, 1);
        }
    },

    /**
     * Adds given resolver callback to the store as a resolver.
     *
     * @param state
     * @param resourceKey
     * @param resolver
     * @return {void}
     */
    SET_RESOURCE_RESOLVER(state, {resourceKey, resolver}) {
        if (Array.isArray(state.resolvers)) {
            state.resolvers[resourceKey].push(resolver);
        } else {
            state.resolvers[resourceKey] = [resolver];
        }
    },

    /**
     * Adds given rejection callback to the store as a rejection.
     *
     * @param state
     * @param resourceKey
     * @param rejection
     * @return {void}
     */
    SET_RESOURCE_REJECTION(state, {resourceKey, rejection}) {
        if (Array.isArray(state.rejections)) {
            state.rejections[resourceKey].push(rejection);
        } else {
            state.rejections[resourceKey] = [rejection];
        }
    },

    /**
     * Executes resolvers on the state for the given resource key.
     *
     * @param state
     * @param resourceKey
     * @return {void}
     */
    EXECUTE_RESOURCE_RESOLVERS(state, resourceKey) {
        if (Array.isArray(state.rejections[resourceKey])) {
            state.resolvers[resourceKey].forEach(resolver => {
                resolver();
            });

            state.resolvers[resourceKey] = [];
        }
    },

    /**
     * Executes rejections on the state for the given resource key.
     *
     * @param state
     * @param resourceKey
     * @return {void}
     */
    EXECUTE_RESOURCE_REJECTIONS(state, resourceKey) {
        if (Array.isArray(state.rejections[resourceKey])) {
            state.rejections[resourceKey].forEach(rejection => {
                rejection();
            });

            state.rejections[resourceKey] = [];
        }
    },

    /**
     * Clears resolvers on the state for the given resource key.
     *
     * @param state
     * @param resourceKey
     * @return {void}
     */
    CLEAR_RESOURCE_RESOLVERS(state, resourceKey) {
        state.resolvers[resourceKey] = [];
    },

    /**
     * Clears rejections on the state for the given resource key.
     *
     * @param state
     * @param resourceKey
     * @return {void}
     */
    CLEAR_RESOURCE_REJECTIONS(state, resourceKey) {
        state.rejections[resourceKey] = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
