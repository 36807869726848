<template>
  <div class="myaccount">
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap">
      <div class="relative-container">
        <div class="accountdiv">
          <div class="div-block-54 accountset">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="account-wrapper">
            <div class="div-block-12 header-flex">
              <h4 class="heading">Your Account</h4>
              <div class="quick-links">
                <a href="/store/myorders">Order History</a>
                <a href="/store/myquotes">Quotes History</a>
                <a href="/store/tickets">Support Center</a>
              </div>
            </div>

            <div class="home-section-wrap">
              <h4 class="heading-4">
                <strong>Personal Information</strong>
              </h4>
              <div class="loginform w-form">
                <b-form class="commonform w-clearfix">
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :class="
                        resetform.errors.get('firstname')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :state="!resetform.errors.has('firstname')"
                      :invalid-feedback="resetform.errors.get('firstname')"
                    >
                      <label for="name-4" class="field-label-33"
                        >First name*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="resetform.firstname"
                        :state="!resetform.errors.has('firstname')"
                        @input="resetform.errors.clear('firstname')"
                        data-name="firstname"
                        id="firstname"
                      />
                      <span
                        class="error-msg"
                        v-if="resetform.errors.get('firstname')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      :class="
                        resetform.errors.get('lastname')
                          ? 'haserror div-block-50 typein'
                          : 'div-block-50 typein'
                      "
                      :state="!resetform.errors.has('lastname')"
                      :invalid-feedback="resetform.errors.get('lastname')"
                    >
                      <label for="name-4" class="field-label-34"
                        >Last name*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="resetform.lastname"
                        :state="!resetform.errors.has('lastname')"
                        @input="resetform.errors.clear('lastname')"
                        data-name="lastname"
                        id="lastname"
                      />
                      <span
                        class="error-msg"
                        v-if="resetform.errors.get('lastname')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!resetform.errors.has('phoneno')"
                      :class="
                        resetform.errors.get('phoneno')
                          ? 'haserror forstar div-block-50 typein'
                          : 'forstar div-block-50 typein'
                      "
                      :invalid-feedback="resetform.errors.get('phoneno')"
                    >
                      <label for="name-4" class="field-label-42">Phone*</label>
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        :state="!resetform.errors.has('phoneno')"
                        @input="resetform.errors.clear('phoneno')"
                        v-model="resetform.phoneno"
                        data-name="phoneno"
                        id="phoneno"
                      />
                      <span
                        class="error-msg"
                        v-if="resetform.errors.get('phoneno')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      :class="
                        resetform.errors.get('companyname')
                          ? 'haserror div-block-50 typein'
                          : 'div-block-50 typein'
                      "
                      :state="!resetform.errors.has('companyname')"
                      :invalid-feedback="resetform.errors.get('companyname')"
                    >
                      <label for="name-4" class="field-label-36"
                        >company name*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="resetform.companyname"
                        :state="!resetform.errors.has('companyname')"
                        @input="resetform.errors.clear('companyname')"
                        data-name="Name 3"
                        id="companyname"
                      />
                      <span
                        class="error-msg"
                        v-if="resetform.errors.get('companyname')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <md-radio
                      value="email"
                      v-model="resetform.emailpass_option"
                      :class="'is_primary'"
                      @change="changeEmailPass()"
                      >Change Email.</md-radio
                    >
                    <div class="_35"></div>
                    <md-radio
                      value="password"
                      v-model="resetform.emailpass_option"
                      :class="'is_primary'"
                      @change="changeEmailPass()"
                      >Change Password.</md-radio
                    >
                  </div>
                  <div class="emailOption">
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :state="!resetform.errors.has('email')"
                        :class="
                          resetform.errors.get('email')
                            ? 'haserror div-block-49'
                            : 'div-block-49'
                        "
                        :invalid-feedback="resetform.errors.get('email')"
                      >
                        <label for="name" class="field-label-30">Email*</label>
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          v-model="resetform.email"
                          :state="!resetform.errors.has('email')"
                          @input="resetform.errors.clear('email')"
                          id="email"
                        />
                        <span
                          class="error-msg"
                          v-if="resetform.errors.get('email')"
                          >{{
                            resetform.errors.get("email") ==
                            "The email field is required when emailpass option is email."
                              ? "Required Field"
                              : resetform.errors.get("email")
                          }}</span
                        >
                        <span class="error-msg" v-if="this.emailexist != ''">{{
                          this.emailexist
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="passwordOption">
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :class="
                          resetform.errors.get('password')
                            ? 'haserror div-block-49'
                            : 'div-block-49'
                        "
                        :state="!resetform.errors.has('password')"
                        :invalid-feedback="resetform.errors.get('password')"
                      >
                        <label for="name" class="field-label-31"
                          >New Password *</label
                        >
                        <input
                          type="password"
                          class="w-input"
                          maxlength="256"
                          v-model="resetform.password"
                          :state="!resetform.errors.has('password')"
                          @input="resetform.errors.clear('password')"
                          data-name="password"
                          id="password"
                        />
                        <span
                          class="error-msg"
                          v-if="resetform.errors.get('password')"
                          >{{
                            resetform.errors.get("password") ==
                            "The password field is required when emailpass option is password."
                              ? "Required Field"
                              : resetform.errors.get("password")
                          }}</span
                        >
                      </div>
                    </div>

                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div
                        :class="
                          resetform.errors.get('confirm_password')
                            ? 'haserror div-block-49'
                            : 'div-block-49'
                        "
                        :state="!resetform.errors.has('confirm_password')"
                        :invalid-feedback="
                          resetform.errors.get('confirm_password')
                        "
                      >
                        <label for="name-4" class="field-label-32"
                          >Confirm Password *</label
                        >
                        <input
                          type="password"
                          class="w-input"
                          maxlength="256"
                          v-model="resetform.confirm_password"
                          :state="!resetform.errors.has('confirm_password')"
                          @input="resetform.errors.clear('confirm_password')"
                          data-name="Name 3"
                          id="confirm_password"
                        />
                        <span
                          class="error-msg"
                          v-if="resetform.errors.get('confirm_password')"
                          >{{
                            resetform.errors.get("confirm_password") ==
                            "The confirm password field is required when emailpass option is password."
                              ? "Required Field"
                              : resetform.errors.get("confirm_password")
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48 savecancelbtn">
                    <b-button
                      class="button w-button"
                      @click="updatePassword()"
                      block
                      variant="dark"
                      >Save Changes</b-button
                    >
                    <b-button
                      class="button w-button"
                      block
                      variant="dark"
                      @click="Resetinfo"
                      >Cancel</b-button
                    >
                  </div>
                </b-form>
                <div class="_35"></div>
                <div class="shipping-address-container-1">
                  <div
                    data-duration-in="300"
                    data-duration-out="100"
                    class="tabs w-tabs"
                  >
                    <div class="w-tab-menu">
                      <a
                        data-w-tab="Tab 1"
                        class="tab tab1title w-inline-block w-tab-link w--current"
                        @click="AddressTab()"
                      >
                        <div>Address</div>
                        <img
                          src="/images/Asset-1.svg"
                          width="28"
                          height="28"
                          alt
                          class="image-10"
                        />
                      </a>
                      <a
                        data-w-tab="Tab 2"
                        class="tab tab2title w-inline-block w-tab-link"
                        @click="AddNewAddressTab()"
                      >
                        <div>Add New Address</div>
                        <img
                          src="/images/Asset-1.svg"
                          width="28"
                          height="28"
                          alt
                          class="image-10"
                        />
                      </a>
                    </div>
                    <div class="tabs-content w-tab-content">
                      <div
                        data-w-tab="Tab 1"
                        class="w-tab-pane w--tab-active"
                        id="tab1"
                      >
                        <div class="div-block-70">
                          <div class="_14"></div>
                          <div class="div-block-71 addresscont">
                            <div
                              v-for="(primary, index) in this.all_address"
                              :key="index"
                              class="div-block-72"
                            >
                              <div class="address-container">
                                <h5 class="margin-0">
                                  {{ primary.firstname }} {{ primary.lastname }}
                                </h5>
                                <p class="margin-0">
                                  {{
                                    primary.companyname
                                      ? primary.companyname + ", "
                                      : ""
                                  }}
                                  {{ primary.streetaddress }}
                                  {{ primary.streetaddress1 }}
                                </p>
                                <p>
                                  {{ primary.city }} , {{ primary.state_name }}
                                  {{ primary.zipcode }},
                                  {{ primary.country_code }}
                                </p>
                                <b-button
                                  class="button w-button"
                                  block
                                  variant="dark"
                                  @click="editAddress(primary.id)"
                                  >Edit</b-button
                                >
                                <b-button
                                  v-if="primary.is_primary == 0"
                                  class="button w-button"
                                  block
                                  variant="dark"
                                  @click.stop.prevent="
                                    deleteAddress(primary.id)
                                  "
                                  >Delete</b-button
                                >
                              </div>
                              <div class="_14"></div>
                              <div
                                class="div-block-73"
                                v-if="primary.is_primary == 0"
                              >
                                <md-checkbox
                                  value="1"
                                  :id="'is_primary' + primary.id"
                                  v-model="is_setprimary[primary.id]"
                                  @change="makeDefault(primary.id)"
                                ></md-checkbox>

                                <label
                                  :for="'is_primary' + primary.id"
                                  class="caption margin-0"
                                  >Make as default shipping address</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="gotoForm">
                          <div class="editaddressForm">
                            <h4 class="heading-4">
                              <strong>Edit Address</strong>
                            </h4>
                            <b-form
                              class="commonform w-clearfix"
                              @submit="onEditAddress"
                            >
                              <div class="_14"></div>
                              <div class="div-block-48">
                                <div
                                  :class="
                                    form.errors.get('firstname')
                                      ? 'haserror div-block-49'
                                      : 'div-block-49'
                                  "
                                  :state="!form.errors.has('firstname')"
                                  :invalid-feedback="
                                    form.errors.get('firstname')
                                  "
                                >
                                  <label for="name-4" class="field-label-33"
                                    >First name*</label
                                  >
                                  <input
                                    type="text"
                                    class="w-input"
                                    maxlength="256"
                                    v-model="form.firstname"
                                    :state="!form.errors.has('firstname')"
                                    @input="form.errors.clear('firstname')"
                                    data-name="firstname"
                                    id="editfirstname"
                                  />
                                  <span
                                    class="error-msg"
                                    v-if="form.errors.get('firstname')"
                                    >Required Field</span
                                  >
                                </div>
                                <div class="_35"></div>
                                <div
                                  :class="
                                    form.errors.get('lastname')
                                      ? 'haserror div-block-50 typein'
                                      : 'div-block-50 typein'
                                  "
                                  :state="!form.errors.has('lastname')"
                                  :invalid-feedback="
                                    form.errors.get('lastname')
                                  "
                                >
                                  <label for="name-4" class="field-label-34"
                                    >Last name*</label
                                  >
                                  <input
                                    type="text"
                                    class="w-input"
                                    maxlength="256"
                                    v-model="form.lastname"
                                    :state="!form.errors.has('lastname')"
                                    @input="form.errors.clear('lastname')"
                                    data-name="lastname"
                                    id="editlastname"
                                  />
                                  <span
                                    class="error-msg"
                                    v-if="form.errors.get('lastname')"
                                    >Required Field</span
                                  >
                                </div>
                              </div>
                              <div class="_14"></div>
                              <div class="div-block-48">
                                <div
                                  :state="!form.errors.has('phoneno')"
                                  :class="
                                    form.errors.get('phoneno')
                                      ? 'haserror forstar div-block-50 typein'
                                      : 'forstar div-block-50 typein'
                                  "
                                  :invalid-feedback="form.errors.get('phoneno')"
                                >
                                  <label for="name-4" class="field-label-42"
                                    >Phone*</label
                                  >
                                  <input
                                    type="text"
                                    class="w-input"
                                    maxlength="256"
                                    :state="!form.errors.has('phoneno')"
                                    @input="form.errors.clear('phoneno')"
                                    v-model="form.phoneno"
                                    data-name="phoneno"
                                    id="editphoneno"
                                  />
                                  <span
                                    class="error-msg"
                                    v-if="form.errors.get('phoneno')"
                                    >Required Field</span
                                  >
                                </div>
                                <div class="_35"></div>
                                <div class="div-block-50">
                                  <label for="name-4" class="field-label-36"
                                    >company name</label
                                  >
                                  <input
                                    type="text"
                                    class="w-input"
                                    maxlength="256"
                                    v-model="form.companyname"
                                    data-name="Name 3"
                                    id="editcompanyname"
                                  />
                                </div>
                              </div>
                              <div class="_14"></div>
                              <div class="div-block-48">
                                <div
                                  :state="!form.errors.has('streetaddress')"
                                  :class="
                                    form.errors.get('streetaddress')
                                      ? 'haserror forstar div-block-49'
                                      : 'forstar div-block-49'
                                  "
                                  :invalid-feedback="
                                    form.errors.get('streetaddress')
                                  "
                                >
                                  <label for="name-4" class="field-label-37"
                                    >address 1*</label
                                  >
                                  <input
                                    type="text"
                                    class="w-input"
                                    maxlength="256"
                                    :state="!form.errors.has('streetaddress')"
                                    @input="form.errors.clear('streetaddress')"
                                    v-model="form.streetaddress"
                                    data-name="Name 3"
                                    id="streetaddress"
                                  />
                                  <span
                                    class="error-msg"
                                    v-if="form.errors.get('streetaddress')"
                                    >Required Field</span
                                  >
                                </div>
                                <div class="_35"></div>
                                <div class="div-block-50 typein">
                                  <label for="name-4" class="field-label-38"
                                    >address 2</label
                                  >
                                  <input
                                    type="text"
                                    class="w-input"
                                    maxlength="256"
                                    :state="!form.errors.has('streetaddress1')"
                                    @input="form.errors.clear('streetaddress1')"
                                    v-model="form.streetaddress1"
                                    data-name="streetaddress1"
                                    id="streetaddress1"
                                  />
                                </div>
                              </div>

                              <div class="_14"></div>
                              <div class="div-block-48">
                                <div
                                  :class="
                                    form.errors.get('country')
                                      ? 'haserror div-block-49'
                                      : 'div-block-49'
                                  "
                                  :state="!form.errors.has('country')"
                                  :invalid-feedback="form.errors.get('country')"
                                >
                                  <label for="name-4" class="field-label-35"
                                    >country *</label
                                  >
                                  <v-select
                                    :options="countrylist"
                                    v-model="form.country"
                                    :state="!form.errors.has('country')"
                                    @input="
                                      form.errors.clear('country');
                                      getState();
                                    "
                                    :reduce="(countrylist) => countrylist.value"
                                    label="text"
                                    id="country"
                                    :clearable="false"
                                  ></v-select>
                                  <span
                                    class="error-msg"
                                    v-if="form.errors.get('country')"
                                    >Required Field</span
                                  >
                                </div>

                                <div class="_35"></div>
                                <div
                                  :state="!form.errors.has('state')"
                                  :class="
                                    form.errors.get('state')
                                      ? 'haserror forstar div-block-49'
                                      : 'forstar div-block-49'
                                  "
                                  :invalid-feedback="form.errors.get('state')"
                                >
                                  <label for="name-4" class="field-label-40"
                                    >state*</label
                                  >
                                  <v-select
                                    :options="statelist"
                                    v-model="form.state"
                                    :state="!form.errors.has('state')"
                                    @input="form.errors.clear('state')"
                                    :reduce="(statelist) => statelist.value"
                                    label="text"
                                    id="state"
                                    :clearable="false"
                                  ></v-select>
                                  <span
                                    class="error-msg"
                                    v-if="form.errors.get('state')"
                                    >Required Field</span
                                  >
                                </div>
                              </div>

                              <div class="_14"></div>
                              <div class="div-block-48">
                                <div
                                  :state="!form.errors.has('city')"
                                  :class="
                                    form.errors.get('city')
                                      ? 'haserror forstar div-block-50 typein'
                                      : 'forstar div-block-50 typein'
                                  "
                                  :invalid-feedback="form.errors.get('city')"
                                >
                                  <label for="name-4" class="field-label-39"
                                    >city*</label
                                  >
                                  <input
                                    type="text"
                                    class="w-input"
                                    maxlength="256"
                                    :state="!form.errors.has('city')"
                                    @input="form.errors.clear('city')"
                                    v-model="form.city"
                                    data-name="City"
                                    id="city"
                                  />
                                  <span
                                    class="error-msg"
                                    v-if="form.errors.get('city')"
                                    >Required Field</span
                                  >
                                </div>
                                <div class="_35"></div>
                                <div
                                  :state="!form.errors.has('zipcode')"
                                  :class="
                                    form.errors.get('zipcode')
                                      ? 'haserror forstar div-block-49'
                                      : 'forstar div-block-49'
                                  "
                                  :invalid-feedback="form.errors.get('zipcode')"
                                >
                                  <label for="name-4" class="field-label-41"
                                    >Zip/postal code*</label
                                  >
                                  <input
                                    type="text"
                                    class="w-input"
                                    maxlength="256"
                                    :state="!form.errors.has('zipcode')"
                                    @input="form.errors.clear('zipcode')"
                                    v-model="form.zipcode"
                                    data-name="zipcode"
                                    id="zipcode"
                                  />
                                  <span
                                    class="error-msg"
                                    v-if="form.errors.get('zipcode')"
                                    >Required Field</span
                                  >
                                </div>
                              </div>
                              <div class="_14"></div>
                              <b-button
                                type="submit"
                                class="button w-button"
                                block
                                variant="dark"
                                >Save Changes</b-button
                              >
                              <b-button
                                class="button w-button"
                                block
                                variant="dark"
                                @click="editAddress(form.address_id)"
                                >Cancel</b-button
                              >
                            </b-form>
                          </div>
                        </div>
                      </div>
                      <div data-w-tab="Tab 2" id="tab2" class="w-tab-pane">
                        <div class="div-block-70">
                          <div class="_14"></div>
                          <b-form
                            class="commonform w-clearfix"
                            @submit="AddNewAddress"
                          >
                            <div class="_14"></div>
                            <div class="div-block-48">
                              <div
                                :class="
                                  form1.errors.get('firstname')
                                    ? 'haserror div-block-49'
                                    : 'div-block-49'
                                "
                                :state="!form1.errors.has('firstname')"
                                :invalid-feedback="
                                  form1.errors.get('firstname')
                                "
                              >
                                <label for="name-4" class="field-label-33"
                                  >First name*</label
                                >
                                <input
                                  type="text"
                                  class="w-input"
                                  maxlength="256"
                                  v-model="form1.firstname"
                                  :state="!form1.errors.has('firstname')"
                                  @input="form1.errors.clear('firstname')"
                                  data-name="firstname"
                                  id="newfirstname"
                                />
                                <span
                                  class="error-msg"
                                  v-if="form1.errors.get('firstname')"
                                  >Required Field</span
                                >
                              </div>
                              <div class="_35"></div>
                              <div
                                :class="
                                  form1.errors.get('lastname')
                                    ? 'haserror div-block-50 typein'
                                    : 'div-block-50 typein'
                                "
                                :state="!form1.errors.has('lastname')"
                                :invalid-feedback="form1.errors.get('lastname')"
                              >
                                <label for="name-4" class="field-label-34"
                                  >Last name*</label
                                >
                                <input
                                  type="text"
                                  class="w-input"
                                  maxlength="256"
                                  v-model="form1.lastname"
                                  :state="!form1.errors.has('lastname')"
                                  @input="form1.errors.clear('lastname')"
                                  data-name="lastname"
                                  id="newlastname"
                                />
                                <span
                                  class="error-msg"
                                  v-if="form1.errors.get('lastname')"
                                  >Required Field</span
                                >
                              </div>
                            </div>
                            <div class="_14"></div>
                            <div class="div-block-48">
                              <div
                                :state="!form1.errors.has('phoneno')"
                                :class="
                                  form1.errors.get('phoneno')
                                    ? 'haserror forstar div-block-50 typein'
                                    : 'forstar div-block-50 typein'
                                "
                                :invalid-feedback="form1.errors.get('phoneno')"
                              >
                                <label for="name-4" class="field-label-42"
                                  >Phone*</label
                                >
                                <input
                                  type="text"
                                  class="w-input"
                                  maxlength="256"
                                  :state="!form1.errors.has('phoneno')"
                                  @input="form1.errors.clear('phoneno')"
                                  v-model="form1.phoneno"
                                  data-name="phoneno"
                                  id="newphoneno"
                                />
                                <span
                                  class="error-msg"
                                  v-if="form1.errors.get('phoneno')"
                                  >Required Field</span
                                >
                              </div>
                              <div class="_35"></div>
                              <div class="div-block-50">
                                <label for="name-4" class="field-label-36"
                                  >company name</label
                                >
                                <input
                                  type="text"
                                  class="w-input"
                                  maxlength="256"
                                  v-model="form1.companyname"
                                  data-name="Name 3"
                                  id="newcompanyname"
                                />
                              </div>
                            </div>
                            <div class="div-block-48">
                              <div
                                :state="!form1.errors.has('streetaddress')"
                                :class="
                                  form1.errors.get('streetaddress')
                                    ? 'haserror forstar div-block-49'
                                    : 'forstar div-block-49'
                                "
                                :invalid-feedback="
                                  form1.errors.get('streetaddress')
                                "
                              >
                                <label for="name-4" class="field-label-37"
                                  >address 1*</label
                                >
                                <input
                                  type="text"
                                  class="w-input"
                                  maxlength="256"
                                  :state="!form1.errors.has('streetaddress')"
                                  @input="form1.errors.clear('streetaddress')"
                                  v-model="form1.streetaddress"
                                  data-name="Name 3"
                                  id="newstreetaddress"
                                />
                                <span
                                  class="error-msg"
                                  v-if="form1.errors.get('streetaddress')"
                                  >Required Field</span
                                >
                              </div>
                              <div class="_35"></div>
                              <div class="div-block-50 typein">
                                <label for="name-4" class="field-label-38"
                                  >address 2</label
                                >
                                <input
                                  type="text"
                                  class="w-input"
                                  maxlength="256"
                                  :state="!form1.errors.has('streetaddress1')"
                                  @input="form1.errors.clear('streetaddress1')"
                                  v-model="form1.streetaddress1"
                                  data-name="streetaddress1"
                                  id="newstreetaddress1"
                                />
                              </div>
                            </div>

                            <div class="_14"></div>
                            <div class="div-block-48">
                              <div
                                :class="
                                  form1.errors.get('country')
                                    ? 'haserror div-block-49'
                                    : 'div-block-49'
                                "
                                :state="!form1.errors.has('country')"
                                :invalid-feedback="form1.errors.get('country')"
                              >
                                <label for="name-4" class="field-label-35"
                                  >country *</label
                                >
                                <v-select
                                  :options="newcountrylist"
                                  v-model="form1.country"
                                  :state="!form1.errors.has('country')"
                                  @input="
                                    form1.errors.clear('country');
                                    getNewState();
                                  "
                                  :reduce="
                                    (newcountrylist) => newcountrylist.value
                                  "
                                  label="text"
                                  id="newcountry"
                                  :clearable="false"
                                ></v-select>
                                <span
                                  class="error-msg"
                                  v-if="form1.errors.get('country')"
                                  >Required Field</span
                                >
                              </div>

                              <div class="_35"></div>
                              <div
                                :state="!form1.errors.has('state')"
                                :class="
                                  form1.errors.get('state')
                                    ? 'haserror forstar div-block-49'
                                    : 'forstar div-block-49'
                                "
                                :invalid-feedback="form1.errors.get('state')"
                              >
                                <label for="name-4" class="field-label-40"
                                  >state*</label
                                >
                                <v-select
                                  :options="newstatelist"
                                  v-model="form1.state"
                                  :state="!form1.errors.has('state')"
                                  @input="form1.errors.clear('state')"
                                  :reduce="(newstatelist) => newstatelist.value"
                                  label="text"
                                  id="newstate"
                                  :clearable="false"
                                ></v-select>
                                <span
                                  class="error-msg"
                                  v-if="form1.errors.get('state')"
                                  >Required Field</span
                                >
                              </div>
                            </div>

                            <div class="_14"></div>
                            <div class="div-block-48">
                              <div
                                :state="!form1.errors.has('city')"
                                :class="
                                  form1.errors.get('city')
                                    ? 'haserror forstar div-block-50 typein'
                                    : 'forstar div-block-50 typein'
                                "
                                :invalid-feedback="form1.errors.get('city')"
                              >
                                <label for="name-4" class="field-label-39"
                                  >city*</label
                                >
                                <input
                                  type="text"
                                  class="w-input"
                                  maxlength="256"
                                  :state="!form1.errors.has('city')"
                                  @input="form1.errors.clear('city')"
                                  v-model="form1.city"
                                  data-name="City"
                                  id="newcity"
                                />
                                <span
                                  class="error-msg"
                                  v-if="form1.errors.get('city')"
                                  >Required Field</span
                                >
                              </div>
                              <div class="_35"></div>
                              <div
                                :state="!form1.errors.has('zipcode')"
                                :class="
                                  form1.errors.get('zipcode')
                                    ? 'haserror forstar div-block-49'
                                    : 'forstar div-block-49'
                                "
                                :invalid-feedback="form1.errors.get('zipcode')"
                              >
                                <label for="name-4" class="field-label-41"
                                  >Zip/postal code*</label
                                >
                                <input
                                  type="text"
                                  class="w-input"
                                  maxlength="256"
                                  :state="!form1.errors.has('zipcode')"
                                  @input="form1.errors.clear('zipcode')"
                                  v-model="form1.zipcode"
                                  data-name="zipcode"
                                  id="newzipcode"
                                />
                                <span
                                  class="error-msg"
                                  v-if="form1.errors.get('zipcode')"
                                  >Required Field</span
                                >
                              </div>
                            </div>
                            <div class="_14"></div>
                            <b-button
                              class="button w-button"
                              block
                              type="submit"
                              variant="dark"
                              >Save Changes</b-button
                            >
                            <b-button
                              class="button w-button"
                              block
                              variant="dark"
                              @click="ResetNewAddress"
                              >Cancel</b-button
                            >
                          </b-form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="/images/Asset-1.svg"
              width="35"
              height="35"
              alt
              class="header-img-corner"
            />
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import ResourceMixin from "../../mixins/resources";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  mixins: [ResourceMixin],
  mounted() {
    // this.$store.dispatch("loadMyQuotes").then(response => {
    //     this.myQuotes = response.data.success;
    //     this.quotecount = response.data.success.quotecount;
    // });
    this.resetform.email = this.user.email;

    this.loadAddressInfo();
  },
  data() {
    return {
      isLoading: false,
      logoutForm: new Form({}),
      myQuotes: [],
      quotecount: 0,
      address_id: "",
      otheraddress: [],
      primaryaddress: [],
      all_address: [],
      is_setprimary: [],
      emailexist: "",

      form: {
        country: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
        address_id: "",
        companyname: null,
        phoneno: null,
        firstname: null,
        lastname: null,
      },
      form1: {
        firstname: null,
        lastname: null,
        companyname: null,
        phoneno: null,
        country: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
      },
      resetform: new Form({
        email: null,
        password: null,
        confirm_password: null,
        firstname: null,
        lastname: null,
        emailpass_option: "email",
        companyname: null,
        phoneno: null,
      }),
      checkboxes: [],
      show: true,
      country: [],
      states: [],
      companytypelist: [
        { value: "Gov", text: "Gov" },
        { value: "OEM", text: "OEM" },
        { value: "FBO", text: "FBO" },
        { value: "MRO", text: "MRO" },
        { value: "Distributor", text: "Distributor" },
      ],
      industrylist: [
        { value: "Military", text: "Military" },
        { value: "Defense", text: "Defense" },
        { value: "Medical", text: "Medical" },
        { value: "Industrial", text: "Industrial" },
        { value: "Aerospace", text: "Aerospace" },
        { value: "Technology", text: "Technology" },
      ],
      finduslist: [
        { value: "Google", text: "Google" },
        { value: "Bing", text: "Bing" },
        { value: "Yahoo", text: "Yahoo" },
        { value: "Thomasnet", text: "Thomasnet" },
        { value: "Other", text: "Other" },
      ],
      countrylist: [{ value: "United States", text: "United States" }],
      statelist: [{ value: "", text: "select country first" }],
      newcountrylist: [{ value: "United States", text: "United States" }],
      newstatelist: [{ value: "", text: "select country first" }],
    };
  },

  beforeMount() {
    this.initForm();
    //this.isLoading = false;
    this.getCountry();
    this.getNewCountry();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout", this.logoutForm).then(() => {
        this.$router.replace({ name: "login" });
      });
    },
    changeEmailPass() {
      if (this.resetform.emailpass_option == "email") {
        $(".page--account .passwordOption").css("display", "none");
        $(".page--account .emailOption").css("display", "block");
      } else {
        $(".page--account .emailOption").css("display", "none");
        $(".page--account .passwordOption").css("display", "block");
      }
    },
    AddressTab() {
      $(".page--account .tab2title").removeClass("w--current");
      $(".page--account .tab1title").addClass("w--current");
      $(".page--account #tab2").css("display", "none");
      $(".page--account #tab1").css("display", "block");
    },
    AddNewAddressTab() {
      $(".page--account .tab1title").removeClass("w--current");
      $(".page--account .tab2title").addClass("w--current");
      $(".page--account #tab1").css("display", "none");
      $(".page--account #tab2").css("display", "block");
    },
    makeDefault(address_id) {
      // var is_check = 0;
      // if (this.is_setprimary[address_id]) {

      var is_check = 1;

      //}
      this.$store
        .dispatch("makeDefaultAddress", {
          user_id: this.user.id,
          is_check: is_check,
          address_id: address_id,
        })
        .then(() => {
          this.loadAddressInfo();
        })
        .catch((error) => {
          if (this.form.hasErrors()) {
            this.$notify({
              text: "There is some validation error. Please correct and try again!",
              type: "error",
            });
          } else {
            this.$notify({
              text: "Something went wrong!",
              type: "error",
            });
          }
        });
    },
    onEditAddress(evt) {
      evt.preventDefault();
      this.SubmitAddress();
    },
    updatePassword() {
      this.emailexist = "";
      this.isLoading = true;
      this.$store.dispatch("resetPassword", this.resetform).then((response) => {
        if (response.data.success) {
          this.$notify({
            text: response.data.message,
            type: "success",
          });

          this.resetform.password = null;
          this.resetform.confirm_password = null;
        } else {
          if (response.data.message == "The email has already been taken.") {
            this.emailexist = response.data.message;
          }

          this.$notify({
            text: response.data.message,
            type: "error",
          });
        }
      });
      this.isLoading = false;
    },
    initForm() {
      this.$store.dispatch("getState", this.user.country).then((response) => {
        this.statelist = response.data;
      });

      this.form = new Form({
        country: parseInt(this.user.country),
        streetaddress: this.user.streetaddress,
        streetaddress1: this.user.streetaddress1,
        city: this.user.city,
        state: parseInt(this.user.state),
        zipcode: this.user.zipcode,
        firstname: null,
        lastname: null,
        companyname: null,
        phoneno: null,
      });

      this.form1 = new Form({
        country: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
        firstname: null,
        lastname: null,
        companyname: null,
        phoneno: null,
      });

      this.resetform.email = this.user.email;
      this.resetform.firstname = this.user.firstname;
      this.resetform.lastname = this.user.lastname;
      this.resetform.companyname = this.user.companyname;
      this.resetform.phoneno = this.user.phoneno;

      //this.getState(231);
    },
    Resetinfo() {
      this.resetform.email = this.user.email;
      this.resetform.firstname = this.user.firstname;
      this.resetform.lastname = this.user.lastname;
      this.resetform.companyname = this.user.companyname;
      this.resetform.phoneno = this.user.phoneno;
      this.resetform.password = null;
      this.resetform.confirm_password = null;

      this.resetform.emailpass_option = "email";
      $(".page--account .passwordOption").css("display", "none");
      $(".page--account .emailOption").css("display", "block");
    },
    save() {
      this.$store
        .dispatch("editAccountInfo", {
          user_id: this.user.id,
          form: this.form,
        })
        .then((response) => {})
        .catch((error) => {});
    },
    AddNewAddress(evt) {
      evt.preventDefault();
      this.$store
        .dispatch("addAddress", {
          user_id: this.user.id,
          form: this.form1,
        })
        .then(() => {
          this.loadAddressInfo();
          this.AddressTab();
          $(".page--account .editaddressForm").css("display", "none");
          this.form1 = new Form({
            country: null,
            streetaddress: null,
            streetaddress1: null,
            city: null,
            state: null,
            zipcode: null,
            firstname: null,
            lastname: null,
            companyname: null,
            phoneno: null,
          });
        })
        .catch((error) => {});
    },
    ResetNewAddress() {
      this.form1 = new Form({
        country: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
        firstname: null,
        lastname: null,
        companyname: null,
        phoneno: null,
      });
    },
    SubmitAddress() {
      this.$store
        .dispatch("editAddress", {
          form: this.form,
          address_id: this.form.address_id,
        })
        .then(() => {
          this.loadAddressInfo();
        })
        .catch((error) => {});
    },

    getCountry() {
      this.$store.dispatch("loadCountries").then((countries) => {
        this.countrylist = countries.data;
      });
    },
    getNewCountry() {
      this.$store.dispatch("loadCountries").then((countries) => {
        this.newcountrylist = countries.data;
      });
    },
    getState(value) {
      this.$store.dispatch("getState", this.form.country).then((response) => {
        this.statelist = response.data;
      });
      this.form.state = parseInt(this.statelist[0].text);
    },
    getNewState(value) {
      this.$store.dispatch("getState", this.form1.country).then((response) => {
        this.newstatelist = response.data;
      });
      this.form1.state = parseInt(this.newstatelist[0].text);
    },
    deleteAddress(value) {
      if (confirm("Are you sure to delete this address?")) {
        this.isLoading = true;
        this.$store.dispatch("deleteAddress", value).then((response) => {
          if (response.data.success) {
            this.otheraddress = response.data.success.other_address;
            this.primaryaddress = response.data.success.primary_address;
            this.all_address = response.data.success.all_address;
          } else {
          }
        });
        this.isLoading = false;
      }
    },
    editAddress(value) {
      this.$store.dispatch("getAddress", value).then((getaddress) => {
        this.$store
          .dispatch("getState", getaddress.data.success[0].country)
          .then((response) => {
            this.statelist = response.data;
          });
        $(".page--account .editaddressForm").css("display", "block");
        this.form = new Form({
          country: parseInt(getaddress.data.success[0].country),
          address_id: getaddress.data.success[0].id,
          streetaddress: getaddress.data.success[0].streetaddress,
          streetaddress1: getaddress.data.success[0].streetaddress1,
          city: getaddress.data.success[0].city,
          state: parseInt(getaddress.data.success[0].state),
          zipcode: getaddress.data.success[0].zipcode,
          firstname: getaddress.data.success[0].firstname,
          lastname: getaddress.data.success[0].lastname,
          companyname: getaddress.data.success[0].companyname,
          phoneno: getaddress.data.success[0].phoneno,
        });
      });
      $("html, body").animate(
        {
          scrollTop: $("div.gotoForm").offset().top,
        },
        800
      );
    },
    loadAddressInfo() {
      this.$store.dispatch("loadAddressInfo").then((addressinfo) => {
        this.otheraddress = addressinfo.data.success.other_address;
        this.primaryaddress = addressinfo.data.success.primary_address;
        this.all_address = addressinfo.data.success.all_address;

        let mythis = this;
        setTimeout(() => {
          if (this.all_address.length > 0) {
            this.all_address.forEach(function (lp) {
              mythis.is_setprimary[lp.id] = 0;
            });
          }
        }, 1000);
      });
    },
  },
};
</script>
<style>
.page--account .passwordOption,
.page--account .editaddressForm {
  display: none;
}
.page--account .tabs.w-tabs {
  width: 100%;
  max-width: 100%;
  padding: 0;
}
.page--account .loginform.w-form {
  padding: 0;
}
.header-flex {
  display: flex;
  justify-content: space-between;
}

.header-flex .quick-links {
  display: flex;
  align-items: center;
}
.header-flex .quick-links a {
  color: #fff;
  margin-left: 10px;
  background: #f2612c;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}
.header-img-corner {
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: visible;
  background-color: transparent;
  color: #ffffff;
}
@media (max-width: 685px) {
  .header-flex {
    height: 180px;
    flex-direction: column;
  }
  .header-flex .quick-links {
    justify-content: center;
  }
  .header-flex .quick-links a {
    font-size: 12px;
  }
}
@media (max-width: 590px) {
  .header-flex .quick-links {
    flex-direction: column;
    display: none;
  }
  .header-flex {
    height: 120px;
  }
  /* .header-flex .quick-links a {
    width:100%;
    text-align: center;
     margin-left: 0px;
     margin-bottom: 10px;
  } */
}
</style>