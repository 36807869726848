<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock addressmob">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Orders</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <div class="searchorder">
                <h3 class="heading-19">Search</h3>
                <h5 class="heading-20">Order Date:</h5>
                <div class="radiodiv">
                  <div class="w-form">
                    <b-form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      class="form-2 w-clearfix"
                    >
                      <div class="radioopt">
                        <md-radio
                          value="today"
                          @change="SearchOption()"
                          v-model="form.search_option"
                          >Today</md-radio
                        >
                        <md-radio
                          value="week-to-date"
                          @change="SearchOption()"
                          v-model="form.search_option"
                          >Week to date</md-radio
                        >
                        <md-radio
                          value="month-to-date"
                          @change="SearchOption()"
                          v-model="form.search_option"
                          >Month to date</md-radio
                        >

                        <md-radio
                          value="last-120-days"
                          @change="SearchOption()"
                          v-model="form.search_option"
                          >Last 120 days</md-radio
                        >

                        <md-radio
                          value="year-to-date"
                          @change="SearchOption()"
                          v-model="form.search_option"
                          >Year to date</md-radio
                        >
                        <md-radio
                          value="custom-date"
                          @change="SearchOption()"
                          v-model="form.search_option"
                          >By custom date</md-radio
                        >
                      </div>
                      <div class="datesearch">
                        <div class="hideshowdate">
                          <div class="startend">
                            <h5 class="heading-20">Start Date:</h5>
                            <datepicker
                              v-model="form.start_date"
                              class="datepick datepicker orderinput w-input"
                              :format="customFormatter"
                              :disabledDates="disabledDates"
                            ></datepicker>
                          </div>
                          <div class="startend">
                            <h5 class="heading-20">End Date:</h5>
                            <datepicker
                              v-model="form.end_date"
                              class="datepick datepicker orderinput w-input"
                              :format="customFormatter"
                              :disabledDates="disabledDates"
                            ></datepicker>
                          </div>
                        </div>
                        <div class="startend" style="display: none">
                          <h5 class="heading-20">Status:</h5>
                          <v-select
                            :options="this.order_status"
                            v-model="form.order_status"
                            :reduce="(countrylist) => countrylist.value"
                            label="text"
                          ></v-select>
                        </div>
                      </div>
                      <div class="startend">
                        <h5 class="heading-20">Customer Po:</h5>
                        <input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          v-model="form.CustPONum"
                          data-name="CustPONum"
                          id="CustPONum"
                        />
                      </div>
                      <div class="orderbtns">
                        <a
                          href="javascript:;"
                          @click="searchOrder()"
                          class="button secondary w-inline-block"
                        >
                          <div>Search</div>
                        </a>

                        <a
                          href="/store/myorders"
                          class="button secondary w-inline-block clear"
                        >
                          <div>Clear</div>
                        </a>
                      </div>
                    </b-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section cc-store-home-wrap conttesti">
      <div class="container">
        <div class="testimonail-content-wrapper">
          <div class="testimonial-background">
            <img
              src="/images/aircraft-compressed.jpg"
              srcset="
                /images/aircraft-compressed-p-500.jpeg  500w,
                /images/aircraft-compressed-p-800.jpeg  800w,
                /images/aircraft-compressed.jpg        1080w
              "
              sizes="100vw"
              alt
              class="image-17"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="home-content-wrap-copy">
          <div class="div-block-30">
            <div class="text-block-4 h6">All Orders List</div>

            <div v-if="myOrders.length > 0">
              <!-- Main table element -->
              <b-table
                show-empty
                responsive
                class="productlisttable"
                :items="myOrders"
                :fields="fields"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(OrdNum)="row">
                  <router-link :to="`/store/myorder/${row.item.OrdNum}`">
                    <div class="cell-link hover-link">
                      {{ row.item.OrdNum }}
                    </div>
                  </router-link>
                </template>
                <template v-slot:cell(InvDate)="row">
                  <router-link :to="`/store/myorder/${row.item.OrdNum}`">
                    <div class="cell-text hover-link">
                      {{ customFormatter(row.item.date) }}
                    </div>
                  </router-link>
                </template>
                <template v-slot:cell(CustPONum)="row">
                  <router-link :to="`/store/myorder/${row.item.OrdNum}`">
                    <div class="cell-text hover-link">
                      {{ row.item.CustPONum }}
                    </div>
                  </router-link>
                </template>
                <template v-slot:cell(OrigAmt)="row">
                  <router-link :to="`/store/myorder/${row.item.OrdNum}`">
                    <div class="cell-text hover-link">
                      {{ row.item.total != "0.00" ? row.item.total : "--" }}
                    </div>
                  </router-link>
                </template>
                <template v-slot:cell(status)="row">
                  <router-link :to="`/store/myorder/${row.item.OrdNum}`">
                    <div
                      class="cell-text hover-link"
                      v-if="row.item.status == 1"
                    >
                      Order Shipped
                    </div>
                    <div class="cell-text hover-link" v-else>
                      Order Processing
                    </div>
                  </router-link>
                </template>
              </b-table>
              <div class="itemspage" v-if="myOrders.length > 0">
                <label>Items Per Page</label>
                <v-select
                  :options="itemspage"
                  v-model="form.items"
                  @input="loadFilter"
                  :clearable="false"
                  placeholder="10"
                ></v-select>
              </div>
              <div class="propagination" v-if="myOrders.length > 0">
                <div class="custompage">
                  <b-pagination
                    v-model="currentPage"
                    @input="paginationChange"
                    :total-rows="rows"
                    :per-page="perPage"
                    align="center"
                    first-number
                    last-number
                  ></b-pagination>
                </div>
              </div>
            </div>

            <div v-if="myOrders.length <= 0">
              <p class="emptyquote">
                {{
                  this.isLoading ? "Loading..." : "Your Order History is empty."
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../../mixins/resources";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    CommonHead,
    CommonFoot,
    Datepicker,
    moment,
    "v-select": vSelect,
  },
  beforeMount() {},
  mounted() {
    this.loadmyOrders();
  },
  data() {
    return {
      myOrders: [],
      tbeInvoices: [],
      show: true,
      country: [],
      states: [],
      isLoading: true,
      form: {
        search_option: null,
        order_status: null,
        items: "20",
        start_date: null,
        end_date: null,
        CustPONum: null,
        page: 1,
      },
      rows: 20,
      perPage: 20,
      currentPage: 1,
      itemspage: ["10", "20", "25", "50", "100"],

      disabledDates: {
        from: new Date(Date.now()),
      },

      order_status: [
        { value: "pending", text: "Pending" },
        { value: "processing", text: "Processing" },
        { value: "shipped", text: "Shipped" },
        { value: "all", text: "All Orders" },
      ],

      fields: [
        {
          key: "OrdNum",
          label: "Order No",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "CustPONum",
          label: "Customer PO",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "InvDate",
          label: "Order Date",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "OrigAmt",
          label: "Order Total",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "status",
          label: "Order Status",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
      ],
    };
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    rowClass(item, type) {
      return "tbRows";
    },
    customFormatter(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    SearchOption() {
      if (this.form.search_option == "custom-date") {
        $(".hideshowdate").show();
      } else {
        this.form.start_date = null;
        this.form.end_date = null;
        $(".hideshowdate").hide();
      }
    },
    paginationChange(page) {
      this.form.page = page;
      this.loadFilter();
    },
    searchOrder() {
      let url = [];

      if (
        this.form.search_option != null &&
        this.form.search_option != "custom-date"
      ) {
        url["search_option"] = this.form.search_option;
      }
      if (this.form.order_status != null) {
        url["order_status"] = this.form.order_status;
      }
      if (this.form.CustPONum != null) {
        url["CustPONum"] = this.form.CustPONum;
      }

      if (this.form.start_date != null) {
        url["start_date"] = this.customFormatter(this.form.start_date);
      }
      if (this.form.end_date != null) {
        url["end_date"] = this.customFormatter(this.form.end_date);
      }

      if (this.form.items != null) {
        url["items"] = this.form.items;
      }

      url["page"] = 1;

      this.$router.push({ query: url });

      var queryString = Object.keys(url)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(url[key]);
        })
        .join("&");
      this.$store.dispatch("loadTBEOrders", queryString).then((response) => {
        this.myOrders = response.data.data;
        this.rows = response.data.total;
        this.currentPage = this.form.page;
        this.perPage = this.form.items;
      });
      this.AciveFilter();
    },
    loadmyOrders() {
      let url = [];
      this.isLoading = true;
      if (this.$route.query.search_option) {
        url["search_option"] = this.$route.query.search_option;
      }
      if (this.$route.query.order_status) {
        url["order_status"] = this.$route.query.order_status;
      }
      if (this.$route.query.CustPONum) {
        url["CustPONum"] = this.$route.query.CustPONum;
      }
      if (this.$route.query.start_date) {
        url["start_date"] = this.$route.query.start_date;
      }
      if (this.$route.query.end_date) {
        url["end_date"] = this.$route.query.end_date;
      }
      if (this.$route.query.start_date && this.$route.query.end_date) {
        this.form.search_option = "custom-date";
        $(".hideshowdate").show();
      }
      if (this.$route.query.items) {
        url["items"] = this.$route.query.items;
      } else {
        url["items"] = this.form.items;
      }
      if (this.$route.query.page) {
        url["page"] = this.$route.query.page;
      } else {
        url["page"] = this.form.page;
      }
      this.$router.push({ query: url });

      var queryString = Object.keys(url)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(url[key]);
        })
        .join("&");
      this.$store.dispatch("loadTBEOrders", queryString).then((response) => {
        this.myOrders = response.data.data;
        this.rows = response.data.total;
        this.currentPage = this.form.page;
        this.perPage = this.form.items;
        this.isLoading = false;
        //console.log( this.myOrders);
      });
      this.AciveFilter();
    },
    loadFilter() {
      let url = [];
      if (this.form.search_option != null) {
        url["search_option"] = this.form.search_option;
      }
      if (this.form.order_status != null) {
        url["order_status"] = this.form.order_status;
      }
      if (this.form.CustPONum != null) {
        url["CustPONum"] = this.form.CustPONum;
      }

      if (this.form.start_date != null) {
        url["start_date"] = this.customFormatter(this.form.start_date);
      }
      if (this.form.end_date != null) {
        url["end_date"] = this.customFormatter(this.form.end_date);
      }

      if (this.form.items != null) {
        url["items"] = this.form.items;
      }

      url["page"] = this.form.page;

      this.$router.push({ query: url });

      var queryString = Object.keys(url)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(url[key]);
        })
        .join("&");
      this.$store.dispatch("loadTBEOrders", queryString).then((response) => {
        this.myOrders = response.data.data;
        this.rows = response.data.total;
        this.currentPage = this.form.page;
        this.perPage = this.form.items;
      });
      this.AciveFilter();
    },
    AciveFilter() {
      if (this.$route.query.search_option) {
        this.form.search_option = this.$route.query.search_option;
      }
      if (this.$route.query.order_status) {
        this.form.order_status = this.$route.query.order_status;
      }
      if (this.$route.query.CustPONum) {
        this.form.CustPONum = this.$route.query.CustPONum;
      }
      if (this.$route.query.start_date) {
        this.form.start_date = this.$route.query.start_date;
      }
      if (this.$route.query.end_date) {
        this.form.end_date = this.$route.query.end_date;
      }

      if (this.$route.query.page) {
        this.form.page = this.$route.query.page;
      }

      if (this.$route.query.items) {
        this.form.items = this.$route.query.items;
      }
    },

    gotosinglePage(order_id) {
      this.$router.push({ path: "/store/myorder/" + order_id });
    },
  },
};
</script>
<style>
.hover-link {
  cursor: pointer;
}
.page--quotelist .quotebtn {
  position: relative;
  float: right;
  background: #f2612c;
}
.quotebtn .addquote {
  color: #ffff;
}
.productlisttable .table .tbColumn img.tickimg {
  display: none;
}
.quotebtn .addquote:hover {
  text-decoration: none;
  background-color: #f2612c;
}
.qtyfield {
  width: 80px;
}
.emptyquote {
  padding: 15px;
  color: #f2612c;
}
.loginform.w-form {
  background: #fff;
  padding: 30px;
}
.page--quotelist .haserror input.vs__search {
  border-color: #fff;
}
.hideshowdate {
  display: none;
}
.datepick {
  max-width: 395px;
  min-width: 395px;
  margin-bottom: 5px;
  height: calc(2.19rem + -1px);
  border-radius: 0;
  color: #b5b6b7;
}
</style>
