<template>
  <div class="positionset in-header">
    <div class="top-bar">
      <div class="text-block-8">
        Established in 1985 - Large Inventory - Ready to Ship
      </div>
      <a
        href="/store/documents/Defense-Industrial-Base-Essential-Critical-Infrastructure-Workforce.pdf"
        target="_blank"
        class="link-3"
      >
        <strong class="bold-text">Memorandum: Defense Industrial Base</strong>
      </a>
      <a href="tel:+18007274378" class="secondary-button w-inline-block">
        <img
          src="/images/phone-24px.svg"
          width="21"
          height="21"
          alt
          class="button-icon"
        />
        <div>Expert Support: 800-727-4378</div>
      </a>
    </div>
    <div class="logo-wf-holder">
      <a href="/" aria-current="page" class="logo-img-wf-link">
        <img src="/images/Logo-Blue.svg" width="185" alt class="logo-img-wf" />
      </a>
    </div>
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      role="banner"
      class="navigation w-nav"
    >
      <div class="navigation-wrap">
        <div class="mobilemenu">
          <div class="menu-button w-nav-button">
            <a href="javascript:;" class="menuclick" @click="openmenu()">
              <img
                src="/images/menu-icon.svg"
                width="24"
                height="24"
                alt
                class="menuimg"
              />
            </a>
          </div>
        </div>
        <div class="div-block-17">
          <a
            href="/"
            aria-current="page"
            class="logo-link w-nav-brand w--current"
          >
            <img
              src="/images/Logo-Blue.svg"
              width="185"
              alt
              class="logo-image"
            />
          </a>

          <form
            class="form search-wf-bar"
            data-name="Search Form header"
            id="search-form-header"
            name="search-form-header"
            action="/store/search"
            method="get"
            target="_blank"
          >
            <input
              class="search-input-vue w-input"
              style="font-size: 16px"
              data-name="Search Inventory"
              maxlength="256"
              name="search"
              required
              id="headsearch"
              v-model="this.successtitle"
              placeholder="Search Inventory"
              type="text"
              autocomplete="off"
            />
            <input
              type="submit"
              style="max-width: 110px"
              class="search-button h6 styling w-button"
              data-wait="Please wait..."
              value="Search"
            />
            <div
              class="result-bar"
              v-if="this.resultlist && this.resultlist.length > 0"
            >
              <div
                class="result-bar-item"
                v-for="(tt, ttk) in this.resultlist"
                :key="ttk"
              >
                <a :href="tt.link">{{ tt.name }}</a>
              </div>
            </div>
          </form>

          <!-- <div class="navbar-search desktop-hide">
            <div class="w-inline-block">
              <div class="html-embed w-embed">
                <form
                  class="form"
                  data-name="Search Form header"
                  id="search-form-header"
                  name="search-form-header"
                  action="/store/search"
                  method="get"
                  target="_blank"
                >
                  <input
                    class="search-input-vue w-input"
                    style="font-size: 16px"
                    data-name="Search Inventory"
                    maxlength="256"
                    name="search"
                    required
                    v-model="this.successtitle"
                    placeholder="Search Inventory"
                    type="text"
                  />
                  <input
                    type="submit"
                    style="max-width: 110px"
                    class="search-button h6 styling w-button"
                    data-wait="Please wait..."
                    value="Search"
                  />
                </form>
              </div>
            </div>
          </div> -->
        </div>
        <div class="div-block-4 w-clearfix">
          <nav role="navigation" class="navigation-items w-nav-menu">
            <div data-delay="0" data-hover="1" class="w-dropdown">
              <div
                class="dropdown-toggle w-dropdown-toggle"
                @click="togglemenu"
              >
                <div class="icon w-icon-dropdown-toggle"></div>
                <div class="body2">About</div>
              </div>
              <nav class="dropdown-list-4 w-dropdown-list">
                <a
                  v-for="(item, index) in this.menuItems.about"
                  :key="index"
                  :href="item.link"
                  class="dropdown-link w-dropdown-link"
                  :style="'color:' + item.color + ';'"
                  >{{ item.name }}</a
                >
              </nav>
            </div>
            <div data-delay="0" data-hover="1" class="w-dropdown">
              <div
                class="dropdown-toggle w-dropdown-toggle"
                @click="togglemenu"
              >
                <div class="body2">Products</div>
                <div class="icon w-icon-dropdown-toggle"></div>
              </div>
              <nav class="dropdown-list-4 w-dropdown-list">
                <div class="dropdown-link mobfea w-dropdown">
                  <div
                    class="dropdown-link newfea w-dropdown-toggle"
                    @click="togglemenu"
                  >
                    <div class="icon-2 w-icon-dropdown-toggle"></div>
                    <div class="body2">Material Categories</div>
                  </div>
                  <nav class="subdrop w-dropdown-list">
                    <a
                      v-for="(item, index) in this.menuItems.category"
                      :key="index"
                      :href="item.link"
                      class="dropdown-link w-dropdown-link"
                      :style="'color:' + item.color + ';'"
                      >{{ item.name }}</a
                    >
                  </nav>
                </div>
                <div class="dropdown-link mobfea w-dropdown">
                  <div
                    class="dropdown-link newfea w-dropdown-toggle"
                    @click="togglemenu"
                  >
                    <div class="icon-2 w-icon-dropdown-toggle"></div>
                    <div class="body2">Manufacturers</div>
                  </div>
                  <nav class="subdrop w-dropdown-list">
                    <a
                      v-for="(item, index) in this.menuItems.manufacture"
                      :key="index"
                      :href="item.link"
                      class="dropdown-link w-dropdown-link"
                      :style="'color:' + item.color + ';'"
                      >{{ item.name }}</a
                    >
                  </nav>
                </div>
                <div class="dropdown-link mobfea w-dropdown">
                  <div
                    class="dropdown-link newfea w-dropdown-toggle"
                    @click="togglemenu"
                  >
                    <div class="icon-2 w-icon-dropdown-toggle"></div>
                    <div class="body2">Applications</div>
                  </div>
                  <nav class="subdrop w-dropdown-list">
                    <a
                      v-for="(item, index) in this.menuItems.application"
                      :key="index"
                      :href="item.link"
                      class="dropdown-link w-dropdown-link"
                      :style="'color:' + item.color + ';'"
                      >{{ item.name }}</a
                    >
                  </nav>
                </div>
                <a
                  v-for="(item, index) in this.menuItems.product"
                  :key="index"
                  :href="item.link"
                  class="dropdown-link w-dropdown-link"
                  :style="'color:' + item.color + ';'"
                  >{{ item.name }}</a
                >
              </nav>
            </div>

            <div data-delay="0" data-hover="1" class="servdrop w-dropdown">
              <div class="dropdown-toggle w-dropdown-toggle">
                <div class="icon w-icon-dropdown-toggle"></div>
                <div class="body2">Services</div>
              </div>
              <nav class="dropdown-list-4 w-dropdown-list">
                <a
                  href="/store/quick-quote"
                  class="dropdown-link w-dropdown-link"
                  >Quick Quote</a
                >
                <a href="#" class="w-dropdown-link">View Line Card</a>
              </nav>
            </div>

            <a
              v-for="(item, index) in this.menuItems.nav"
              :key="index"
              :href="item.link"
              class="link-8"
              :style="'color:' + item.color + ';'"
              >{{ item.name }}</a
            >

            <div class="div-block-5 mobbtns w-clearfix">
              <!-- <a href="/login" class="button secondary headbtn w-inline-block">
                                <div>Login</div>
                            </a> -->
              <a
                href="/store/quick-quote"
                class="button cc-contact-us headbtn w-inline-block"
              >
                <span>Quick Quote</span>
              </a>
            </div>
          </nav>
          <a
            href="/store/quick-quote"
            class="button cc-contact-us mobhidde w-inline-block"
          >
            <span>Quick Quote</span>
          </a>
          <div
            v-if="user.id"
            href="#"
            class="button secondary mobilehide w-inline-block w-dropdown"
            data-delay="0"
            data-hover="1"
          >
            <div class="dropdown-toggle w-dropdown-toggle">
              <b-img class="loginimg" src="/images/login.svg" alt="login" />
            </div>
            <nav class="dropdown-list-4 w-dropdown-list accountdrop">
              <a href="javascript:;" class="dropdown-link w-dropdown-link"
                >Welcome {{ this.user.firstname }}</a
              >
              <a href="/store/account" class="dropdown-link w-dropdown-link">
                <div>Your Account</div>
              </a>
              <a href="/store/myorders" class="dropdown-link w-dropdown-link">
                <div>Your Orders</div>
              </a>
              <a href="/store/myquotes" class="dropdown-link w-dropdown-link">
                <div>Your Quote Lists</div>
              </a>
              <a href="/store/tickets" class="dropdown-link w-dropdown-link">
                <div>Support Center</div>
              </a>

              <a
                href="javascript:;"
                @click.prevent="logout"
                class="dropdown-link w-dropdown-link"
                >Sign Out</a
              >
            </nav>
          </div>
          <a
            v-else
            href="/store/login"
            class="button secondary mobilehide w-inline-block"
          >
            <div>
              <b-img class="loginimg" src="/images/login.svg" alt="login" />
            </div>
          </a>

          <div>
            <b-button v-b-toggle.sidebar-right class="open_sidebar">
              <b-img
                class="quotesvg new-cart"
                src="/images/quote.svg"
                alt="quote"
              ></b-img>
              <span
                class="cart_count"
                v-if="this.miniCartList && this.miniCartList.length > 0"
                >{{ this.miniCartList.length }}</span
              >
              <span class="cart_count" v-else>0</span>
            </b-button>
            <b-button
              v-if="displyCart"
              v-b-toggle.sidebar-item-right
              class="open_sidebar"
            >
              <b-img class="quotesvg" src="/images/cart.svg" alt="cart"></b-img>
              <span
                class="cart_count"
                v-if="this.miniItemCartList && this.miniItemCartList.length > 0"
                >{{ this.miniItemCartList.length }}</span
              >
              <span class="cart_count itmcnt" v-else>0</span>
            </b-button>

            <b-sidebar
              id="sidebar-right"
              text-variant="dark"
              title="Your Quote List"
              v-model="visible"
              right
              shadow
            >
              <template v-slot:footer>
                <div
                  class="d-flex bg-dark text-light align-items-center px-3 py-2"
                >
                  <strong class="mr-auto">
                    <a href="/store/quote-list" class="viewcart button btn"
                      >View Quote List</a
                    >
                  </strong>
                  <!-- <b-button size="sm" @click="hide">Close</b-button> -->
                </div>
              </template>
              <perfect-scrollbar>
                <div class="px-3 py-2">
                  <!-- <form
                    class="Cart Drawer__Content"
                    action
                    method="POST"
                    novalidate
                  > -->
                  <div class="Drawer__Main" data-scrollable>
                    <div class="Drawer__Container">
                      <div class="Cart__ItemList">
                        <div
                          class="CartItemWrapper"
                          v-if="
                            this.miniCartList && this.miniCartList.length > 0
                          "
                        >
                          <div
                            class="CartItem"
                            v-for="(qt, qtk) in this.miniCartList"
                            :key="qtk"
                          >
                            <div class="cartdelete">
                              <div class="CartItem__Info">
                                <h2 class="CartItem__Title Heading">
                                  <a
                                    href="javascript:;"
                                    class="itemtitle"
                                    @click="gotosinglePage(qt.product_id)"
                                    >{{ qt.name }}</a
                                  >
                                </h2>
                              </div>
                              <button
                                type="button"
                                @click="deleteQuote(qt.id)"
                                class="cartbtn"
                                data-quantity="1"
                              >
                                <b-img
                                  class="removesvg"
                                  src="/images/delete.svg"
                                  alt="Delete"
                                ></b-img>
                              </button>
                            </div>

                            <div class="CartItem__Variant">
                              Material: {{ qt.material }}
                            </div>
                            <div class="cartsku" v-if="qt.type">
                              Type: {{ qt.type }}
                            </div>
                            <div class="cartsku" v-if="qt.finish">
                              Finish: {{ qt.finish }}
                            </div>
                            <div class="cartsku" v-if="qt.dia">
                              Dia: {{ qt.dia }}
                            </div>
                            <div class="cartsku" v-if="qt.length">
                              Length: {{ qt.length }}
                            </div>
                            <div class="cartsku" v-if="qt.uom">
                              Unit of Measure: {{ qt.uom }}
                            </div>
                            <div class="qunupdate">
                              <label class="qtn">Quantity:</label>
                              <div class="samediv quantity">
                                <input
                                  type="number"
                                  :id="'cart_qtybox_' + qt.id"
                                  v-model="qt.quantity"
                                />

                                <input
                                  :id="'quote_avl_qtybox_' + qt.id"
                                  v-model="qt.available_qty"
                                  type="hidden"
                                />
                              </div>
                              <button
                                type="button"
                                @click="updateQtyBybutton(qt.id)"
                                class="cartbtn"
                                data-quantity="1"
                                style="margin-top: 0px"
                              >
                                <b-img
                                  class="editsvg"
                                  src="/images/update.svg"
                                  alt="update"
                                ></b-img
                                >Update
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <p>Your Quote List is empty.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- </form> -->
                </div>
              </perfect-scrollbar>
            </b-sidebar>

            <b-sidebar
              v-if="displyCart"
              id="sidebar-item-right"
              text-variant="dark"
              :title="`Cart (${(this.miniItemCartList || []).length})`"
              v-model="itemvisible"
              right
              shadow
            >
              <template v-slot:footer>
                <div
                  class="d-flex bg-dark text-light align-items-center px-3 py-2"
                  style="flex-direction: column"
                >
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <label style="color: #1a1b1f">Subtotal</label>
                    <label style="color: #1a1b1f"
                      >${{ miniItemCartListTotal }}</label
                    >
                  </div>
                  <strong class="mr-auto" style="width: 100%">
                    <a
                      href="/store/checkoutinfo"
                      class="viewcart button btn"
                      style="width: 100%"
                    >
                      Checkout</a
                    >
                  </strong>
                  <!-- <b-button size="sm" @click="hide">Close</b-button> -->
                </div>
              </template>
              <perfect-scrollbar>
                <div class="px-3 py-2">
                  <!-- <form
                    class="Cart Drawer__Content"
                    action
                    method="POST"
                    novalidate
                  > -->
                  <div class="Drawer__Main" data-scrollable>
                    <div class="Drawer__Container">
                      <div class="Cart__ItemList">
                        <div
                          class="CartItemWrapper"
                          v-if="
                            this.miniItemCartList &&
                            this.miniItemCartList.length > 0
                          "
                        >
                          <div
                            class="CartItem"
                            v-for="(qt, qtk) in this.miniItemCartList"
                            :key="qtk"
                          >
                            <div class="cartdelete">
                              <div class="CartItem__Info">
                                <h2 class="CartItem__Title Heading">
                                  <a
                                    href="javascript:;"
                                    class="itemtitle"
                                    @click="gotosinglePage(qt.product_id)"
                                    >{{ qt.name }}</a
                                  >
                                </h2>
                              </div>
                              <button
                                type="button"
                                @click="deleteItem(qt.id)"
                                class="cartbtn"
                                data-quantity="1"
                              >
                                <b-img
                                  class="removesvg"
                                  src="/images/delete.svg"
                                  alt="Delete"
                                ></b-img>
                              </button>
                            </div>
                            <div class="cartsku" v-if="qt.type">
                              Type: {{ qt.type }}
                            </div>
                            <div class="CartItem__Variant" v-if="qt.material">
                              Material: {{ qt.material }}
                            </div>

                            <div class="cartsku" v-if="qt.finish">
                              Finish: {{ qt.finish }}
                            </div>
                            <div class="cartsku" v-if="qt.dia">
                              Dia: {{ qt.dia }}
                            </div>
                            <div class="cartsku" v-if="qt.length">
                              Length: {{ qt.length }}
                            </div>
                            <div class="cartsku" v-if="qt.uom">
                              Unit of Measure: {{ qt.uom }}
                            </div>
                            <div class="cartsku" v-if="qt.price">
                              Cost per qty: : ${{ qt.price }}
                            </div>
                            <div
                              class="qunupdate"
                              style="
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 10px;
                                align-items: center;
                              "
                            >
                              <div
                                style="
                                  display: flex;
                                  gap: 4px;
                                  align-items: center;
                                "
                              >
                                <div class="cartsku">Quantity:</div>
                                <div class="samediv quantity">
                                  <input
                                    :id="'cart_qtybox_' + qt.id"
                                    v-model="qt.quantity"
                                    type="number"
                                  />

                                  <input
                                    :id="'cart_avl_qtybox_' + qt.id"
                                    v-model="qt.available_qty"
                                    type="hidden"
                                  />
                                </div>
                              </div>
                              <button
                                type="button"
                                @click="updateItemQtyBybutton(qt.id)"
                                class="cartbtn"
                                data-quantity="1"
                              >
                                <b-img
                                  class="editsvg"
                                  src="/images/update.svg"
                                  alt="update"
                                ></b-img
                                >Update
                              </button>
                            </div>
                            <div class="cartsku">
                              Total Cost: ${{
                                getTotalPrice(
                                  qt.id,
                                  parseFloat(qt.quantity)
                                ).toFixed(2)
                              }}
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <p>Your Cart List is empty.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- </form> -->
                </div>
              </perfect-scrollbar>
            </b-sidebar>
          </div>
        </div>

        <!-- <div class="navbar-search desktop-hide mobilesearch">
          <div class="w-inline-block">
            <div class="html-embed w-embed">
              <form
                class="form mobile-search"
                data-name="Search Form header"
                id="search-form-header-mobile"
                name="search-form-header"
                action="/search"
                method="get"
                target="_blank"
              >
                <input
                  class="search-input-vue w-input"
                  style="font-size: 16px"
                  data-name="Search Inventory"
                  maxlength="256"
                  name="search"
                  required
                  v-model="this.successtitle"
                  placeholder="Search Inventory"
                  type="text"
                />
                <input
                  type="submit"
                  style="max-width: 110px"
                  class="search-button h6 styling w-button"
                  data-wait="Please wait..."
                  value="Search"
                />
              </form>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import ResourceMixin from "../mixins/resources";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "CommonHead",
  mixins: [ResourceMixin],
  data() {
    return {
      lastActionTime: -1,
      lastScrollPosition: 0,
      scrollDirection: "up",
      displyCart: true,
      scrollPosition: null,
      logoutForm: new Form({}),
      variant: "dark",
      isseen: true,
      searchtext: null,
      successtitle: "",
      quotelist: [],
      resultlist: [],
      categorylist: [],
      form: new Form({
        user_id: "",
        cart: "",
        error_name: "",
      }),
      visible: false,
      itemvisible: false,
      user_id: "",
      shownewproject: false,
      shownewclient: false,
      isCartempty: false,
      clientscart: [
        { value: "1", text: "James" },
        { value: "2", text: "Mathew" },
        { value: "3", text: "John" },
        { value: "4", text: "Nick" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "cartlist",
      "itemcartlist",
      "showSearch",
      "filterStatus",
      "priceLvl",
    ]),
    miniCartList() {
      return (this.cartlist.success || []).map((d) => ({
        ...d,
        quantity: Math.max(1, Math.round(parseFloat(d.quantity))),
      }));
    },
    menuItems() {
      const about = window.__menuItem
        .filter((d) => d.parent === "ABOUT")
        .sort((a, b) => {
          if (a.order > b.order) return 1;
          return -1;
        });
      const application = window.__menuItem
        .filter((d) => d.parent === "APPLICATION")
        .sort((a, b) => {
          if (a.order > b.order) return 1;
          return -1;
        });
      const manufacture = window.__menuItem
        .filter((d) => d.parent === "MANUFACTURER")
        .sort((a, b) => {
          if (a.order > b.order) return 1;
          return -1;
        });
      const category = window.__menuItem
        .filter((d) => d.parent === "CATEGORY")
        .sort((a, b) => {
          if (a.order > b.order) return 1;
          return -1;
        });
      const product = window.__menuItem
        .filter((d) => d.parent === "PRODUCT")
        .sort((a, b) => {
          if (a.order > b.order) return 1;
          return -1;
        });
      const nav = window.__menuItem
        .filter((d) => d.parent === "MAIN_NAV")
        .sort((a, b) => {
          if (a.order > b.order) return 1;
          return -1;
        });

      return { about, manufacture, category, product, nav, application };
    },
    miniShoppingCartList() {
      return [];
    },
    miniItemCartList() {
      const d = (this.itemcartlist.success || []).map((d) => ({
        ...d,
        quantity: Math.max(1, Math.round(parseFloat(d.quantity))),
      }));
      console.log("items", d);
      return d;
    },
    miniItemCartListTotal() {
      console.log(this.itemcartlist, "null");
      if (this.itemcartlist && this.itemcartlist.success)
        return (
          this.itemcartlist.success
            .map((d) => d.quantity * parseFloat(d.price))
            .reduce((a, b) => a + b, 0) || 0
        ).toFixed(2);

      return 0;
    },
    miniItemShoppingCartList() {
      return [];
    },
  },
  watch: {
    filterStatus: function (val) {
      if (val && this.showSearch) this.$store.commit("SHOW_SEARCH", false);
    },
  },
  methods: {
    validateAndAdjustOrderQuantity(orderQuantity, incrementValue) {
      if (incrementValue === 0) {
        return orderQuantity;
      }
      if (orderQuantity % incrementValue === 0) {
        return orderQuantity;
      } else {
        let adjustedQuantity =
          Math.ceil(orderQuantity / incrementValue) * incrementValue;
        this.$notify({
          text: `This product can only be ordered in increments of ${
            `${incrementValue}`.includes(".")
              ? parseFloat(incrementValue).toFixed(2)
              : incrementValue
          }. Quantity has been adjusted.`,
          type: "warn",
        });

        return `${adjustedQuantity}`.includes(".")
          ? parseFloat(parseFloat(adjustedQuantity).toFixed(4))
          : adjustedQuantity;
      }
    },
    getTotalPrice(id, qty) {
      let getPro = this.miniItemCartList.filter((d) => d.id === id);
      if (getPro.length) {
        let loadProducts = { data: getPro[0].pro };
        console.log("getPro", getPro, loadProducts);
        let qtyBreak =
          loadProducts.data.pro.Product[0].More.PriceTable.QtyBreak || [];
        let qtyBreakEA =
          loadProducts.data.pro.Product[0].More.PriceTable.QtyBreak || [];
        let qtyBreakLB = (
          loadProducts.data.pro.Product[0].More.PriceTable.QtyBreak || []
        ).map((d) => ({
          ...d,
          Qty: d.Qty / (1 / parseFloat(loadProducts.data.TheoWght)),
          BaseUnitPrice:
            d.BaseUnitPrice * (1 / parseFloat(loadProducts.data.TheoWght)),
          UnitPrice: d.UnitPrice * (1 / parseFloat(loadProducts.data.TheoWght)),
        }));
        let min_qtyLB =
          (loadProducts.data.min_qty || 100) /
          (1 / parseFloat(loadProducts.data.TheoWght));
        let min_qty = loadProducts.data.min_qty || 100;

        console.log("CART: EA Table");
        console.table(
          qtyBreakEA.map((d) => ({
            PriceLvlCode: d.PriceLvlCode,
            Qty: d.Qty,
            UnitPrice: d.UnitPrice,
            BaseUnitPrice: d.BaseUnitPrice,
          }))
        );
        console.log("CART: LB Table");
        console.table(
          qtyBreakLB.map((d) => ({
            PriceLvlCode: d.PriceLvlCode,
            Qty: d.Qty,
            UnitPrice: d.UnitPrice,
            BaseUnitPrice: d.BaseUnitPrice,
          }))
        );
        let uom = getPro[0].uom;
        console.log("CART: UOM", id, uom);

        if (uom === "LB") {
          if (parseFloat(qty || 0) < min_qtyLB) {
            this.$notify({
              text: `Minimum allowed quantity is ${parseFloat(
                this.min_qtyLB
              ).toFixed(2)}.`,
              type: "error",
            });
          }
        } else {
          if (parseFloat(qty || 0) < min_qty) {
            this.$notify({
              text: `Minimum allowed quantity is ${min_qty}.`,
              type: "error",
            });
          }
        }

        const getFinalPrice = () => {
          if (uom === "LB") {
            qtyBreak = qtyBreakLB;
          } else {
            qtyBreak = qtyBreakEA;
          }
          console.log(uom);
          console.table(qtyBreak);
          if (!qtyBreak) {
            return 0;
          }
          if (this.priceLvl === "none") {
            return qtyBreak[0].BaseUnitPrice;
          }
          console.log("CART: priceLvl", this.priceLvl, qty);
          const _qtyBreak = qtyBreak.filter(
            (d) => d.PriceLvlCode === this.priceLvl
          );
          console.table(
            _qtyBreak.map((d) => ({
              PriceLvlCode: d.PriceLvlCode,
              Qty: d.Qty,
              UnitPrice: d.UnitPrice,
              BaseUnitPrice: d.BaseUnitPrice,
            }))
          );
          const findClosestNumberIndex = (arr, x) => {
            let _i = 0;

            for (let i = 0; i < arr.length; i++) {
              if (x >= arr[i]) {
                _i = i;
              }
            }
            return _i;
          };
          const applicablePriceRow =
            _qtyBreak[
              findClosestNumberIndex(
                _qtyBreak.map((d) => d.Qty),
                parseFloat(qty || 0) || 0
              )
            ];

          if (applicablePriceRow) {
            console.table(
              [applicablePriceRow].map((d) => ({
                PriceLvlCode: d.PriceLvlCode,
                Qty: d.Qty,
                UnitPrice: d.UnitPrice,
                BaseUnitPrice: d.BaseUnitPrice,
              }))
            );
            return applicablePriceRow.UnitPrice;
          }
          return 0;
        };
        console.log("finalPrice", getFinalPrice());
        return getFinalPrice() * qty;
      } else {
        console.log("CART", id, "not supported");
        return 0;
      }
    },
    PageloadFilter() {
      let url = [];
      if (this.$route.query.type) {
        url["type"] = this.$route.query.type;
      }
      if (this.$route.query.proprietary) {
        url["proprietary"] = this.$route.query.proprietary;
      }
      if (this.$route.query.head_style) {
        url["head_style"] = this.$route.query.head_style;
      }
      if (this.$route.query.material) {
        url["material"] = this.$route.query.material;
      }
      if (this.$route.query.finish) {
        url["finish"] = this.$route.query.finish;
      }
      if (this.$route.query.dia) {
        url["dia"] = this.$route.query.dia;
      }
      if (this.$route.query.len) {
        url["len"] = this.$route.query.len;
      }
      if (this.$route.query.material_spec) {
        url["material_spec"] = this.$route.query.material_spec;
      }
      if (this.$route.query.items) {
        url["items"] = this.$route.query.items;
      }

      if (this.$route.query.page) {
        url["page"] = this.$route.query.page;
      }
      this.$router.push({ query: url });

      // Not included in url
      if (this.$route.params.category) {
        url["category"] = this.$route.params.category;
      }

      var queryString = Object.keys(url)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(url[key]);
        })
        .join("&");

      this.$store.dispatch("loadProducts", queryString).finally(() => {
        this.isLoading = false;
      });
    },
    logout() {
      this.$store.dispatch("logout", this.logoutForm).then(() => {
        //this.$router.replace({ name: "login" });
        //this.$router.push({ path: "/login" });
        window.location.href = "/store/login";
      });
    },
    loadCartList() {
      this.isLoading = true;
      this.$store.dispatch("loadCartList").then((quotelist) => {
        this.isLoading = false;
      });
    },
    loadItemCartList() {
      this.isLoading = true;
      this.$store.dispatch("loadItemCartList").then((quotelist) => {
        this.isLoading = false;
      });
    },
    loadCategory() {
      this.isLoading = true;
      this.$store.dispatch("loadCategory").then((categorylist) => {
        this.isLoading = false;
        this.categorylist = categorylist.data;
        console.log(this.categorylist);
      });
    },
    increment(id) {
      var val = parseInt(document.getElementById("cart_qtybox_" + id).value);
      val = val + 1;
      document.getElementById("cart_qtybox_" + id).value = val;
      this.updateQty(val, id);
    },
    decrement(id) {
      var val = parseInt(document.getElementById("cart_qtybox_" + id).value);
      if (val === 1) {
        console.log("Negative quantity not allowed");
      } else {
        val = val - 1;
        document.getElementById("cart_qtybox_" + id).value = val;
        this.updateQty(val, id);
      }
    },
    updateQty(val, id) {
      this.$store
        .dispatch("updateCartQty", {
          val: val,
          id: id,
        })
        .finally(() => {
          this.isLoading = true;
          this.loadCartList();
        });
    },
    updateQtyBybutton(id) {
      var val = $("#cart_qtybox_" + id).val();
      var avl_val = $("#quote_avl_qtybox_" + id).val();

      if (parseFloat(val || 0) > 0 && val != "") {
        //console.log(parseFloat(avl_val));

        this.$store
          .dispatch("updateCartQty", {
            val: Math.round(parseFloat(val)),
            id: id,
          })
          .finally(() => {
            this.isLoading = true;

            this.loadCartList();
          });
        if (Math.round(parseFloat(val)) <= parseFloat(avl_val)) {
          //do nothing
        } else {
          this.$notify({
            text: "This quantity is above our listed stock amount and may be subject to a delayed quote response, a lead time, and a minimum quantity.",
            type: "error",
          });
        }
      } else {
        this.$notify({
          text: "Quantity should be greater than 0 (Zero)!",
          type: "error",
        });
      }
    },
    deleteQuote(prod_id) {
      if (confirm("Are you sure to delete this quote!")) {
        this.$store
          .dispatch("quoteDelete", {
            product_id: prod_id,
          })
          .then((quotelist) => {
            this.loadCartList();
          })
          .catch((error) => {
            if (this.form.hasErrors()) {
              this.$notify({
                text: "There is some validation error. Please correct and try again!",
                type: "error",
              });
            } else {
              this.$notify({
                text: "Something went wrong!",
                type: "error",
              });
            }
          });
      }
    },

    updateItemQtyBybutton(id) {
      let val = $("#cart_qtybox_" + id).val();
      var avl_val = $("#cart_avl_qtybox_" + id).val();
      let getPro = this.miniItemCartList.filter((d) => d.id === id);
      if (getPro.length) {
        let loadProducts = { data: getPro[0].pro };
        console.log("getPro", getPro, loadProducts);
        let qtyBreak =
          loadProducts.data.pro.Product[0].More.PriceTable.QtyBreak || [];
        let qtyBreakEA =
          loadProducts.data.pro.Product[0].More.PriceTable.QtyBreak || [];
        let qtyBreakLB = (
          loadProducts.data.pro.Product[0].More.PriceTable.QtyBreak || []
        ).map((d) => ({
          ...d,
          Qty: d.Qty / (1 / parseFloat(loadProducts.data.TheoWght)),
          BaseUnitPrice:
            d.BaseUnitPrice * (1 / parseFloat(loadProducts.data.TheoWght)),
          UnitPrice: d.UnitPrice * (1 / parseFloat(loadProducts.data.TheoWght)),
        }));
        let min_qtyLB =
          (loadProducts.data.min_qty || 100) /
          (1 / parseFloat(loadProducts.data.TheoWght));
        let min_qty = loadProducts.data.min_qty || 100;

        console.log("CART: EA Table");
        console.table(
          qtyBreakEA.map((d) => ({
            PriceLvlCode: d.PriceLvlCode,
            Qty: d.Qty,
            UnitPrice: d.UnitPrice,
            BaseUnitPrice: d.BaseUnitPrice,
          }))
        );
        console.log("CART: LB Table");
        console.table(
          qtyBreakLB.map((d) => ({
            PriceLvlCode: d.PriceLvlCode,
            Qty: d.Qty,
            UnitPrice: d.UnitPrice,
            BaseUnitPrice: d.BaseUnitPrice,
          }))
        );
        let uom = getPro[0].uom;
        console.log("CART: UOM", id, uom);
        let in_value = loadProducts.data.in_value || 0;
        let in_valueLB =
          (loadProducts.data.in_value || 0) /
          (1 / parseFloat(loadProducts.data.TheoWght));
        val = this.validateAndAdjustOrderQuantity(
          parseFloat(`${val || 0}`),
          uom === "LB" ? in_valueLB : in_value
        );
        $("#cart_qtybox_" + id).val(val);

        if (uom === "LB") {
          if (parseFloat(val || 0) < min_qtyLB) {
            this.$notify({
              text: `Minimum allowed quantity is ${parseFloat(
                this.min_qtyLB
              ).toFixed(2)}.`,
              type: "error",
            });
            return;
          }
        } else {
          if (parseFloat(val || 0) < min_qty) {
            this.$notify({
              text: `Minimum allowed quantity is ${min_qty}.`,
              type: "error",
            });
            return;
          }
        }

        const getFinalPrice = () => {
          if (uom === "LB") {
            qtyBreak = qtyBreakLB;
          } else {
            qtyBreak = qtyBreakEA;
          }
          console.log(uom);
          console.table(qtyBreak);
          if (!qtyBreak) {
            return 0;
          }
          if (this.priceLvl === "none") {
            return qtyBreak[0].BaseUnitPrice;
          }
          const _qtyBreak = qtyBreak.filter(
            (d) => d.PriceLvlCode === this.priceLvl
          );
          console.table(
            _qtyBreak.map((d) => ({
              PriceLvlCode: d.PriceLvlCode,
              Qty: d.Qty,
              UnitPrice: d.UnitPrice,
              BaseUnitPrice: d.BaseUnitPrice,
            }))
          );
          const findClosestNumberIndex = (arr, x) => {
            let _i = 0;

            for (let i = 0; i < arr.length; i++) {
              if (x >= arr[i]) {
                _i = i;
              }
            }
            return _i;
          };
          const applicablePriceRow =
            _qtyBreak[
              findClosestNumberIndex(
                _qtyBreak.map((d) => d.Qty),
                parseFloat(val || 0) || 0
              )
            ];

          if (applicablePriceRow) {
            console.table(
              [applicablePriceRow].map((d) => ({
                PriceLvlCode: d.PriceLvlCode,
                Qty: d.Qty,
                UnitPrice: d.UnitPrice,
                BaseUnitPrice: d.BaseUnitPrice,
              }))
            );
            return applicablePriceRow.UnitPrice;
          }
          return 0;
        };
        console.log("finalPrice", getFinalPrice());
        if (parseFloat(val || 0) > 0 && val != "") {
          if (Math.round(parseFloat(val)) <= parseFloat(avl_val)) {
            this.$store
              .dispatch("updateItemCartQty", {
                val: Math.round(parseFloat(val)),
                id: id,
                price: getFinalPrice(),
              })
              .finally(() => {
                this.isLoading = true;

                this.loadItemCartList();
              });
          } else {
            this.$notify({
              text: "Quantity should be less than Available Quantity!",
              type: "error",
            });
          }
        } else {
          this.$notify({
            text: "Quantity should be greater than 0 (Zero)!",
            type: "error",
          });
        }
      } else {
        console.log("Not applicable, cart item");
      }
    },
    deleteItem(prod_id) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store
          .dispatch("itemDelete", {
            product_id: prod_id,
          })
          .then((quotelist) => {
            this.loadItemCartList();
            //this.PageloadFilter();
          })
          .catch((error) => {
            if (this.form.hasErrors()) {
              this.$notify({
                text: "There is some validation error. Please correct and try again!",
                type: "error",
              });
            } else {
              this.$notify({
                text: "Something went wrong!",
                type: "error",
              });
            }
          });
      }
    },
    openmenu() {
      $("nav.navigation-items").toggle();
    },
    togglemenu(evt) {
      $(evt.target).parent().children("nav.w-dropdown-list").toggle();
    },
    toggleSearch() {
      $(".searchbox").slideToggle(() =>
        this.$store.commit("SHOW_SEARCH", !this.showSearch)
      );
    },
    scrollHide() {
      document.addEventListener("scroll", (e) => {
        let top =
          window.pageYOffset ||
          (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;
        let lastPosition = this.lastScrollPosition;
        let direction = top > lastPosition ? "down" : "up";
        this.scrollDirection = direction;
        // console.log(top, direction, lastPosition);
        if (top > 95) {
          let c = Date.now() / 1000;
          if (c - this.lastActionTime >= 0.5) {
            if (direction === "down") {
              this.$store.commit("SHOW_SEARCH", false);
            } else {
              this.$store.commit("SHOW_SEARCH", true);
            }
            this.lastActionTime = c;
          } else {
            // console.log(c, this.lastActionTime, c - this.lastActionTime);
          }
          this.lastScrollPosition = top;
        }
      });
    },
  },
  mounted() {
    window.__showSidebarCart = () => {
      this.itemvisible = true;
    };
    if (this.$route.query.search) {
      this.$store.commit("SHOW_SEARCH", true);
      // var title = this.$route.query.search;
      // var title = null;
      // if (t.includes("/")) {
      //   var t1 = t.split("/");
      //   title = t1[0];
      // } else {
      //   title = this.$route.query.search;
      // }
      this.successtitle = this.$route.query.search;
    } else {
      this.successtitle = "";
      if (this.$route.path.includes("category/")) {
        this.$store.commit("SHOW_SEARCH", true);
        console.log(this.$route.path.includes("category/"), this.$route.path);
      } else {
        console.log(this.$route.path.includes("category/"), this.$route.path);
        this.$store.commit("SHOW_SEARCH", false);
      }
    }

    this.loadCartList();
    this.loadItemCartList();
    this.loadCategory();
    this.scrollHide();
    console.log("user", this.user);
    const i = document.querySelector("#headsearch");
    const makeUrlFriendly = (str) => {
      str = str.toLowerCase().split("-");

      str = str.map((str) => str.replace(/[^\w\s]/gi, "")).join("-");

      str = str.replace(/\s+/g, "-");

      return str;
    };
    i.addEventListener("input", () => {
      const val = i.value;
      this.successtitle = val;
      console.log(val);
      if (val && val.length >= 3) {
        const q = `items=10&search=${val}&page=1`;
        this.resultlist = [
          {
            name: "Please wait...",
            link: "#",
          },
        ];
        this.$store
          .dispatch("getSearchresult", q)
          .then((loadProducts) => {
            console.log(loadProducts);

            if (loadProducts && loadProducts.data.products.data) {
              this.resultlist = loadProducts.data.products.data.map((i) => {
                return {
                  name: i.name,
                  link: `/store/product/${makeUrlFriendly(i.name)}/${i.id}`,
                };
              });
            }
          })
          .catch(console.log);
      } else {
        this.resultlist = [];
        if (window.__request) {
          window.__request.cancel();
        }
      }
    });
  },
};
</script>
<style>
.positionset {
  display: contents;
}
.test {
  background: linear-gradient(135deg, transparent 10px, #c00 0) top left,
    linear-gradient(225deg, transparent 10px, #c00 0) top right,
    linear-gradient(315deg, transparent 10px, #c00 0) bottom right,
    linear-gradient(45deg, transparent 10px, #c00 0) bottom left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}
.pointer {
  pointer-events: none;
}
#sidebar-backdrop .samediv span {
  float: left;
  font-size: 18px;
  border: 1px solid #d2d2d2;
  margin: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 8px;
  font-weight: 500;
  color: #303030;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
#sidebar-backdrop .samediv span.lastspan {
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 39px;
}
#sidebar-backdrop .samediv input {
  float: left;
  max-width: 100px;
  font-size: 14px;
  height: 35px;
  text-align: center;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  border-left: 0;
  border-right: 0;
  font-weight: 500;
  color: #303030;
  padding-top: 6px;
}
.my-color {
  color: var(--color);
}

.open_sidebar .quotesvg {
  height: 20px;
  width: 20px;
  margin-left: 20px !important;
}

.in-header .search-input-vue {
  width: 60%;
  max-width: unset;
}

.in-header #search-form-header {
  justify-content: center;
}

.in-header .searchbox {
  position: sticky;
  padding: 20px;
  top: 130px;
  z-index: 900;
  background-color: #efefef;
}
.in-header .search-wf-bar {
  display: flex !important;
  flex: 1 !important;
  margin: 0px 72.5px !important;
  position: relative !important;
}
.in-header .search-wf-bar .search-input-vue {
  flex: 1 !important;
  height: 48px !important;
  border: 1px solid #bababa !important;
  border-right: none !important;
  margin-bottom: 0px !important;
}
.in-header .search-wf-bar .search-button {
  width: 48px !important;
  height: 48px !important;
  color: transparent !important;
  background-image: url(/images/search-icon.svg) !important;
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
}
.in-header .logo-image {
  width: 10vw;
}
@media only screen and (max-width: 1420px) {
  .in-header .search-wf-bar {
    margin: 0px 24px !important;
  }
}
.logo-wf-holder {
  display: none;
  padding-top: 12px;
  background: #fff;
}
.logo-wf-holder a {
  text-decoration: none;
}
.logo-wf-holder img {
  width: 108px;
}
.result-bar {
  position: absolute;
  top: 100%;
  background: #fff;
  width: 100%;
  padding: 16px 0px;
  max-height: 200px;
  overflow: auto;
  display: flex;
  gap: 8px;
  flex-direction: column;
  z-index: 99999;
}
.result-bar-item {
  padding: 0px 16px;
}
.result-bar-item:hover {
  background-color: #eaeaea;
}
@media only screen and (max-width: 1100px) {
  .logo-wf-holder {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-link {
    display: none !important;
  }
  .in-header .search-wf-bar {
    margin: 0px 24px 0px 0px !important;
  }
  #app .navigation {
    padding: 16px 20px 12px 20px !important;
  }
}
</style>