import axios from 'axios';

export default {
    methods: {
        /**
         * @param resourceKey
         * @param {Boolean} forceReload
         * @return {Promise}
         */
        load(resourceKey, forceReload) {
            if (forceReload === undefined) {
                forceReload = false;
            }

            if (Array.isArray(resourceKey)) {
                return this.loadMultiple(resourceKey, forceReload);
            }

            return new Promise((resolver, rejection) => {
                if (this.storeIsLoadingResource(resourceKey)) {
                    this.$store.dispatch('registerResolver', {resourceKey, resolver});
                    this.$store.dispatch('registerRejection', {resourceKey, rejection});

                    return;
                } else if (!forceReload && this.$store.getters[resourceKey].length !== 0) {
                    return (this.$store.getters[resourceKey]);
                }

                this.$store.dispatch('setResourceLoading', resourceKey);

                this.$store.dispatch('registerResolver', {resourceKey, resolver});
                this.$store.dispatch('registerRejection', {resourceKey, rejection});

                this.$store.dispatch(`${resourceKey}Load`).then(response => {
                    this.$store.dispatch('executeResolvers', resourceKey);

                    this.$store.dispatch('removeResourceLoading', resourceKey);

                    resolve(response.data);
                }).catch(error => {
                    this.$store.dispatch('executeRejections', resourceKey);

                    reject(error);
                });
            });
        },

        /**
         * Load Multiple resources at one using Axios parallel calls.
         *
         * @param resourceKeys
         * @param forceReload
         * @returns {Promise}
         */
        loadMultiple(resourceKeys, forceReload) {
            if (forceReload === undefined) {
                forceReload = false;
            }

            return new Promise((resolve, reject) => {
                axios.all(resourceKeys.map(resourceKey => {
                    this.$store.dispatch('registerResolver', {
                        resourceKey,
                        resolver: resolve
                    });

                    this.$store.dispatch('registerRejection', {
                        resourceKey,
                        rejection: reject
                    });

                    if (this.storeIsLoadingResource(resourceKey)) {
                        return false;
                    } else if (!forceReload && this.$store.getters[resourceKey].length !== 0) {
                        return false;
                    }

                    this.$store.dispatch('setResourceLoading', resourceKey);

                    return this.$store.dispatch(`${resourceKey}Load`).then(() => {
                        this.$store.dispatch('executeResolvers', resourceKey);
                        this.$store.dispatch('clearRejections', resourceKey);

                        this.$store.dispatch('removeResourceLoading', resourceKey);

                        return true;
                    }).catch(() => {
                        this.$store.dispatch('executeRejections', resourceKey);
                        this.$store.dispatch('clearResolvers', resourceKey);

                        this.$store.dispatch('removeResourceLoading', resourceKey);

                        return false;
                    });
                }).filter(o => !!o)).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            });
        },

        /**
         * @param resourceKey
         * @returns {boolean}
         */
        storeIsLoadingResource(resourceKey) {
            return this.$store.getters['loadingResources'].indexOf(resourceKey) !== -1;
        }
    }
}
