<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Submit a Customer Support Ticket</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <h5 class="heading-21">Describe Your Problem or Question</h5>
              <div class="newticketform">
                <form
                  id="login-form"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="post"
                  @submit="onSubmit"
                >
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :class="
                        form.errors.get('subject')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :state="!form.errors.has('subject')"
                      :invalid-feedback="form.errors.get('subject')"
                    >
                      <label for="formsubject" class="field-label-26"
                        >subject*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.subject"
                        :state="!form.errors.has('subject')"
                        @input="form.errors.clear('subject')"
                        id="formsubject"
                      />
                      <span class="error-msg" v-if="form.errors.get('subject')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="formsku" class="field-label-26">sku</label>
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.sku"
                        :state="!form.errors.has('sku')"
                        @input="form.errors.clear('sku')"
                        id="formsku"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="formserialnumber" class="field-label-26"
                        >serial number</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.serialnumber"
                        :state="!form.errors.has('serialnumber')"
                        @input="form.errors.clear('serialnumber')"
                        id="formserialnumber"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="formcustomerrefernce" class="field-label-26"
                        >customer reference</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.customerrefernce"
                        :state="!form.errors.has('customerrefernce')"
                        @input="form.errors.clear('customerrefernce')"
                        id="formcustomerrefernce"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :class="
                        form.errors.get('description')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :state="!form.errors.has('description')"
                      :invalid-feedback="form.errors.get('description')"
                    >
                      <label for="formdescription" class="field-label-26"
                        >Description/Question*</label
                      >
                      <textarea
                        maxlength="5000"
                        v-model="form.description"
                        :state="!form.errors.has('description')"
                        @input="form.errors.clear('description')"
                        id="formdescription"
                        class="textarea-3 w-input"
                      ></textarea>
                      <span
                        class="error-msg"
                        v-if="form.errors.get('description')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="createticketsave">
                    <router-link to="/store/tickets" class="backinfo"
                      ><b-img src="/images/arrow.svg" alt="arrow"></b-img
                      >Back</router-link
                    >
                    <input
                      type="submit"
                      value="Create New Ticket"
                      data-wait="Please wait..."
                      id="loginform"
                      class="button savechanges createticket w-button"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import { mapGetters } from "vuex";
import ResourceMixin from "../../mixins/resources";

export default {
  components: {
    CommonHead,
    CommonFoot,
  },
  beforeMount() {
    this.initForm();
  },
  data() {
    return {
      form: {
        subject: "",
        sku: "",
        email: "",
        serialnumber: "",
        customerrefernce: "",
        description: "",
        customer_id: "",
        status: "open",
      },
    };
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    initForm() {
      this.form = new Form({
        subject: "",
        sku: "",
        email: "",
        serialnumber: "",
        customerrefernce: "",
        description: "",
        customer_id: "",
        status: "open",
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.form.customer_id = this.user.id;
      this.form.email = this.user.email;
      this.$store
        .dispatch("requestTicket", {
          form: this.form,
        })
        .then(() => {
          // this.$notify({
          //     text: "Ticket created Successfully",
          //     type: "success"
          // });
          this.$router.push("/store/tickets");
        })
        .catch((error) => {
          // if (this.form.hasErrors()) {
          //     this.$notify({
          //         text:
          //             "There is some validation error. Please correct and try again!",
          //         type: "error"
          //     });
          // } else {
          //     this.$notify({
          //         text: "Something went wrong!",
          //         type: "error"
          //     });
          // }
        });
    },
  },
};
</script>
<style>
.page--quotelist .quotebtn {
  position: relative;
  float: right;
  background: #f2612c;
}
.quotebtn .addquote {
  color: #ffff;
}
.productlisttable .table .tbColumn img.tickimg {
  display: none;
}
.quotebtn .addquote:hover {
  text-decoration: none;
  background-color: #f2612c;
}
.qtyfield {
  width: 80px;
}
.emptyquote {
  padding: 15px;
  color: #f2612c;
}
.loginform.w-form {
  background: #fff;
  padding: 30px;
}
.page--quotelist .haserror input.vs__search {
  border-color: #fff;
}
</style>
