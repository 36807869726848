import axios from "axios";

const state = {
    tickets:[]
};

const getters = {
    tickets: state => state.tickets
};

const actions = {
    requestTicket({ commit }, { form }) {
        return form.post(`/api/saveticket`).then(response => {
            return response;
        });
    },
    updateTicket({ commit }, { form }) {
        return form.post(`/api/updateTicket`).then(response => {
            return response;
        });
    },
    loadTicket({ commit }, {ticket_id,user_id}) {
        return axios.get(`/api/ticket/${ticket_id}/${user_id}`).then(response => {
            return response.data;
        });
    },

    loadTicketsSearch({ commit }, {userid,queryString}) {
        return axios.get(`/api/searchtickets/${userid}?${queryString}`).then(response => {
            return response;
        });
    }
};

const mutations = {
    TICKETS_LOADED: (state, data) => {
        state.tickets = data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
