<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock addressmob">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">
                Ticket Id #{{ result.ticket_id }}
                <br />
              </h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <h5>Subject: {{ result.subject }}</h5>
              <p class="ticketinfo">SKU: {{ result.sku }}</p>
              <p class="ticketinfo">Serial Number: {{ result.serialnumber }}</p>
              <p class="ticketinfo">Status: {{ result.status }}</p>
              <div class="div-block-ticketinfo">
                <h6 class="overline">{{ this.user.firstname }}</h6>
                <p class="descriptionpre">{{ result.description }}</p>
              </div>
              <div class="commentsrapid" v-if="result.comments.length > 0">
                <div
                  class="div-block-ticketinfo"
                  v-for="(rs, rsk) in result.comments"
                  :key="rsk"
                >
                  <h6 class="overline">{{ rs.comment_by }}</h6>
                  <p class="descriptionpre">{{ rs.comment }}</p>
                </div>
              </div>
              <div class="updateTicketsection">
                <form method="post" @submit="updateTicket">
                  <h5>Update Ticket</h5>
                  <div
                    :class="
                      form.errors.get('comment')
                        ? 'haserror div-block-49'
                        : 'div-block-49'
                    "
                    :state="!form.errors.has('comment')"
                    :invalid-feedback="form.errors.get('comment')"
                  >
                    <label for="comment">Comment *</label>
                    <textarea
                      v-model="form.comment"
                      :state="!form.errors.has('description')"
                      @input="form.errors.clear('description')"
                      maxlength="5000"
                      id="comment"
                      data-name="Field"
                    >
                    </textarea>
                    <span class="error-msg" v-if="form.errors.get('comment')"
                      >Required Field</span
                    >
                  </div>
                  <div class="createticketsave">
                    <router-link to="/store/tickets" class="backinfo"
                      ><b-img src="/images/arrow.svg" alt="arrow"></b-img
                      >Back</router-link
                    >
                    <input
                      type="submit"
                      value="Update Ticket"
                      data-wait="Please wait..."
                      class="button savechanges createticket w-button"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
export default {
  components: {
    CommonHead,
    CommonFoot,
  },
  mounted() {
    this.loadTicketDetail(this.$route.params.ticketid);
  },
  data() {
    return {
      result: {
        ticket_id: null,
        sku: null,
        serialnumber: null,
        status: null,
        subject: null,
        description: null,
        comments: [],
      },
      form: new Form({
        comment: null,
        commentby: null,
        ticket_id: null,
      }),
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    loadTicketDetail(ticket_id) {
      this.$store
        .dispatch("loadTicket", {
          ticket_id: ticket_id,
          user_id: this.user.id,
        })
        .then((response) => {
          this.result.ticket_id = response.id;
          this.result.sku = response.sku;
          this.result.serialnumber = response.serialnumber;
          this.result.status = response.status;
          this.result.subject = response.subject;
          this.result.description = response.description;
          this.result.comments = response.comments;
        });
    },
    updateTicket(e) {
      e.preventDefault();
      this.form.ticket_id = this.$route.params.ticketid;
      this.form.commentby = this.user.firstname;
      this.$store
        .dispatch("updateTicket", {
          form: this.form,
        })
        .then(() => {
          this.$notify({
            text: "Comment added Successfully",
            type: "success",
          });
          this.loadTicketDetail(this.$route.params.ticketid);
          this.form.comment = null;
        })
        .catch((error) => {
          if (this.form.hasErrors()) {
            this.$notify({
              text: "There is some validation error. Please correct and try again!",
              type: "error",
            });
          } else {
            this.$notify({
              text: "Something went wrong!",
              type: "error",
            });
          }
        });
    },
  },
};
</script>
<style>
</style>
