<template>
  <div>
    <loading
      :active.sync="qtyReady"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <CommonHead></CommonHead>
    <div class="rrnp-header" v-if="name">
      <div class="container">
        <h1>
          {{ name }}
        </h1>
      </div>
    </div>
    <div class="rrnp-content" v-if="longdescription && product_image">
      <div class="container">
        <div class="rrnp-content-split">
          <div class="rrnp-content-left">
            <p v-if="longdescription">
              {{ longdescription }}
            </p>
            <button
              class="button cc-contact-us w-inline-block"
              @click="intoView"
            >
              View Specifications
            </button>
          </div>
          <div class="rrnp-content-right" v-if="product_image">
            <img :src="product_image" />
          </div>
        </div>
      </div>
    </div>
    <div class="section cc-store-home-wrap" id="mainsection">
      <div class="container-non">
        <div class="product-hero">
          <div class="div-block-10">
            <div class="rnnp-div-block-12">
              <div class="div-block-35">
                <h1 class="product_heading heading">Specifications</h1>
              </div>
              <img
                src="https://assets.website-files.com/5e6af16d40b2ff71462cb57b/5e6b0716127853498b2a88a6_Asset%201.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="newprod-design">
              <div class="div-block-38-copy">
                <div class="product_spec">
                  <!-- <div class="product_name">
                                            Name: <span>{{name}}</span> 
                                    </div> -->

                  <div class="spec_det">
                    <div class="tabcontent">
                      <div class="rrnp-props">
                        <div v-if="stockuom">
                          <p>Stock UOM</p>
                          <p>{{ stockuom }}</p>
                        </div>
                        <div v-if="category == 'Solid Rivets'">
                          <p>Pieces Per Pound</p>

                          <p>
                            {{
                              this.TheoWght == 0 || this.TheoWght == ""
                                ? 0
                                : Math.round(1 / parseFloat(this.TheoWght))
                            }}
                          </p>
                        </div>
                        <div>
                          <p>On Hand</p>
                          <p>{{ onhand }}</p>
                        </div>
                      </div>
                      <div class="details_specification">
                        <div class="even_row clearfix" v-if="type">
                          <div class="spec_title">Type:</div>
                          <div class="spec_info">{{ type }}</div>
                        </div>

                        <div class="odd_row clearfix" v-if="head_style">
                          <div class="spec_title">Head Style:</div>
                          <div class="spec_info">{{ head_style }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="material">
                          <div class="spec_title">Material:</div>
                          <div class="spec_info">{{ material }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="material_body">
                          <div class="spec_title">Material Body:</div>
                          <div class="spec_info">{{ material_body }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="material_grade">
                          <div class="spec_title">Material Grade:</div>
                          <div class="spec_info">{{ material_grade }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="line_card">
                          <div class="spec_title">Line Card:</div>
                          <div class="spec_info">{{ line_card }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="head_size">
                          <div class="spec_title">Head Size:</div>
                          <div class="spec_info">{{ head_size }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="size_number">
                          <div class="spec_title">Size Number:</div>
                          <div class="spec_info">{{ size_number }}</div>
                        </div>

                        <div class="odd_row clearfix" v-if="finish">
                          <div class="spec_title">Finish:</div>
                          <div class="spec_info">{{ finish }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="dia">
                          <div class="spec_title">Dia:</div>
                          <div class="spec_info">{{ dia }}</div>
                        </div>

                        <div class="odd_row clearfix" v-if="lengthof">
                          <div class="spec_title">Length:</div>
                          <div class="spec_info">{{ lengthof }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="sellingdesc">
                          <div class="spec_title">Selling Des:</div>
                          <div class="spec_info">{{ sellingdesc }}</div>
                        </div>

                        <div class="odd_row clearfix" v-if="shortdescription">
                          <div class="spec_title">Category Description:</div>
                          <div class="spec_info">{{ shortdescription }}</div>
                        </div>

                        <div class="odd_row clearfix" v-if="materialCollar">
                          <div class="spec_title">Material Collar:</div>
                          <div class="spec_info">{{ materialCollar }}</div>
                        </div>

                        <div class="odd_row clearfix" v-if="classification">
                          <div class="spec_title">Classification:</div>
                          <div class="spec_info">{{ classification }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="revision">
                          <div class="spec_title">
                            <a
                              href="javascript:;"
                              style="cursor: default"
                              title="All parts are In Accordance With this latest revision"
                              >Revision:</a
                            >
                          </div>
                          <div class="spec_info">{{ revision }}</div>
                        </div>

                        <div class="odd_row clearfix" v-if="head_dia">
                          <div class="spec_title">Head Dia:</div>
                          <div class="spec_info">{{ head_dia }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="finish_spec">
                          <div class="spec_title">Finish Spec:</div>
                          <div class="spec_info">{{ finish_spec }}</div>
                        </div>

                        <div class="odd_row clearfix" v-if="notes">
                          <div class="spec_title">Notes:</div>
                          <div class="spec_info">{{ notes }}</div>
                        </div>

                        <div class="even_row clearfix" v-if="category">
                          <div class="spec_title">Category:</div>
                          <div class="spec_info">{{ category }}</div>
                        </div>

                        <div
                          class="tabcontent"
                          v-if="name.includes('MS20426AD5-5') == -1"
                        >
                          <div class="details_specification">
                            <div class="even_row clearfix" v-if="PriceTblCode">
                              <div class="spec_title">Price Table Code:</div>
                              <div class="spec_info">{{ PriceTblCode }}</div>
                            </div>

                            <div class="odd_row clearfix" v-if="PriceTblDesc">
                              <div class="spec_title">
                                Price Table Description:
                              </div>
                              <div class="spec_info">{{ PriceTblDesc }}</div>
                            </div>

                            <div class="even_row clearfix" v-if="PriceTblID">
                              <div class="spec_title">Price Table ID:</div>
                              <div class="spec_info">{{ PriceTblID }}</div>
                            </div>

                            <div class="even_row clearfix" v-if="PriceTblInfo">
                              <div class="spec_title">Price Table ID:</div>
                              <div class="spec_info">{{ PriceTblInfo }}</div>
                            </div>
                          </div>
                        </div>

                        <div class="tabcontent">
                          <div class="details_specification">
                            <div class="even_row clearfix" v-if="Alternate">
                              <div class="spec_title">
                                Alternate Product Code:
                              </div>
                              <div class="spec_info">
                                <div
                                  v-for="(result, index) in Alternate"
                                  :key="index"
                                >
                                  <p>{{ result.AltCode }}</p>
                                </div>
                              </div>
                            </div>
                            <!-- <div v-if="EntityTracking">
                                                                <div class="tabs">
                                                                            <span>LOTs </span>
                                                                        </div>
                                                            <div class="lotnumbers" v-for="(result, index1) in EntityTracking" :key="index1">
                                                                <div  class="even_row clearfix" v-if="result.Lot">
                                                                    <div class="spec_title" >Lot number:</div>
                                                                    <div class="spec_info"> {{result.Lot}}</div>
                                                                </div>
                                                                <div  class="even_row clearfix" v-if="result.Mfr && result.Mfr.trim()!=''">
                                                                    <div class="spec_title" >Manufacturer:</div>
                                                                    <div class="spec_info"> {{result.Mfr}}</div>
                                                                </div>
                                                                <div  class="even_row clearfix" v-if="result.CofO">
                                                                    <div class="spec_title" >Country of Origin:</div>
                                                                    <div class="spec_info"> {{result.CofO}}</div>
                                                                </div>
                                                                <div class="even_row clearfix" v-if="result.RoHSOpt">
                                                                        <div class="spec_title">RoHS Compliant:</div>
                                                                        <div class="spec_info">{{result.RoHSOpt}}</div>
                                                                </div>
                                                                    
                                                                <div class="even_row clearfix" v-if="result.DFARSOpt">
                                                                        <div class="spec_title">DFARS Compliant:</div>
                                                                        <div class="spec_info">{{result.DFARSOpt}}</div>
                                                                    </div>
                                                                    <div class="certiinfoTable" v-if="result.Certificates">
                                                                        <div class="tabs">
                                                                            <span>Certificates</span>
                                                                        </div>
                                                                    <div class="certificates" v-for="(certi, certificates) in result.Certificates" :key="certificates">
                                                                        <div class="certificates count" v-if="certi.CertID && certi.CertID!=47">
                                                                            <div class="even_row clearfix" v-if="certi.CertInfo">
                                                                                    <div class="spec_title">CertInfo:</div>
                                                                                    <div class="spec_info">{{certi.CertInfo}}</div>
                                                                            </div>
                                                                        </div>
                                                                         </div>
                                                                    </div>

                                                                </div>

                                                            
                                                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="QtyAvail">
                    Total Quantity Available: {{ this.onhand }}
                  </div>
                </div>
              </div>
              <div class="home-section-wrap">
                <div class="details_specification">
                  <div class="mfr_div" v-if="Mfrlist.length > 0">
                    <label>Manufacturer:</label>
                    <a class="clearall" href="javascript:;" @click="ClearMfr()"
                      >Clear</a
                    >
                    <div class="specinput samediv quantity">
                      <v-select
                        :options="Mfrlist"
                        v-model="input_mfr"
                        @input="changeQty()"
                        :searchable="false"
                        :reduce="(Mfrlist) => Mfrlist.value"
                        label="text"
                        id="input_mfr"
                        :clearable="false"
                        placeholder="Please select"
                      ></v-select>
                    </div>
                  </div>
                  <div class="mfr_div">
                    <label>Unit of Measure:</label>
                    <div class="specinput samediv quantity">
                      <v-select
                        v-if="this.category.includes('solid') || this.category.includes('Solid')"
                        :options="product_uom"
                        v-model="uom"
                        @input="updateQty()"
                        :searchable="false"
                        :reduce="(product_uom) => product_uom.value"
                        label="text"
                        id="uom"
                        :clearable="false"
                        :disabled="Number(TheoWght) == 0"
                        placeholder="Please select"
                      ></v-select>
                      <v-select
                        v-else
                        :options="product_uom"
                        v-model="uom"
                        :searchable="false"
                        :reduce="(product_uom) => product_uom.value"
                        label="text"
                        id="uom"
                        :clearable="false"
                        :disabled="true"
                        placeholder="Please select"
                      ></v-select>
                    </div>
                  </div>
                  <div class="cert_div" v-if="Certlist.length > 0">
                    <label>Certificates:</label>
                    <a class="clearall" href="javascript:;" @click="ClearCert()"
                      >Clear</a
                    >
                    <div class="specinput samediv quantity">
                      <v-select
                        :options="Certlist"
                        v-model="input_cert"
                        :searchable="false"
                        :reduce="(Certlist) => Certlist.value"
                        label="text"
                        id="input_cert"
                        :clearable="false"
                        placeholder="Please select"
                      ></v-select>
                    </div>
                  </div>
                  <div class="cert_div" v-if="this.QtyAvail && !this.qtyReady">
                    <label>Available Quantity:</label>
                    <div class="specinput samediv quantity">
                      {{ this.QtyAvail }}
                    </div>
                  </div>

                  <!-- <div class="cert_div" v-if="ListPrice > 0">
                    <label>List Price:</label>
                    <div class="specinput samediv price">
                      {{ ListPriceString }}
                    </div>
                  </div> -->

                  <!-- <div class="cert_div" v-if="ListPriceMethod">
                                        <label>Unit of Measure:</label>
                                        <div class="specinput samediv price">
                                        {{ListPriceMethod}}
                                        </div>
                                    </div> -->

                  <div
                    class="specinp-ut samediv quantity"
                    v-if="
                      enable_pricing === true
                        ? user.id === 0
                          ? false
                          : true
                        : true
                    "
                  >
                    <label>Quantity:</label>
                    <!-- <span @click="decrement(product_id)">-</span> -->
                    <input
                      type="number"
                      v-model="quantity"
                      :id="'qtybox_' + product_id"
                      min="1"
                      step="any"
                      :max="QtyAvail"
                      v-on:blur="onQtyChange"
                    />
                    <!-- v-on:keyup="onQtyChangeOverRide" -->
                    <span v-if="this.qtyError">{{ this.qtyError }}</span>
                    <!-- <span @click="increment(product_id)" class="lastspan">+</span> -->
                  </div>

                  <div
                    class="specinp-ut samediv quantity"
                    v-if="enable_pricing === true && user.id !== 0"
                  >
                    <label>Cost per unit:</label>
                    <div class="specinput samediv quantity">
                      ${{ parseFloat(this.finalCalculatedPrice).toFixed(2) }}
                    </div>
                  </div>
                  <div
                    class="specinp-ut samediv quantity"
                    v-if="enable_pricing === true && user.id !== 0"
                  >
                    <label>Total Cost:</label>
                    <div class="specinput samediv quantity">
                      ${{
                        (
                          Math.max(0, parseFloat(this.quantity || 0)) *
                          this.finalCalculatedPrice
                        ).toFixed(2)
                      }}
                    </div>
                  </div>

                  <div class="text-block-4 h6 quotebtn add">
                    <a
                      href="javascript:;"
                      @click="submitQuote(product_id)"
                      class="addquote"
                      v-if="enable_pricing === false"
                      >Add to Quote</a
                    >
                    <a
                      href="javascript:;"
                      v-if="enable_pricing && user.id !== 0"
                      @click="submitCart(product_id)"
                      class="addquote"
                      >Add to Shopping cart</a
                    >
                    <p
                      v-if="enable_pricing && user.id === 0"
                      style="
                        color: #d10f0f;
                        text-transform: none;
                        margin-bottom: 0px;
                      "
                    >
                      Please login, before adding to shopping cart
                    </p>
                    <a
                      href="javascript:;"
                      v-if="enable_pricing && user.id === 0"
                      @click="submitCartForLogin(product_id)"
                      class="addquote"
                      >Login</a
                    >
                  </div>
                  <!-- <div class="text-block-4 h6 quotebtn" v-if="ListPrice>0 && name.includes('MS20426AD5-5')==-1">
                                        <a 
                                            href="javascript:;"
                                            @click="submitCart(product_id);"
                                            class="addquote"
                                        >Add to Cart</a>
                                    </div> -->
                  <div class="text-block-4 h6 quotebtn add hideview">
                    <a href="/store/quote-list" class="addquote viewquote"
                      >View Quote</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rrnp-related-container" v-if="relatedPros.length">
      <div class="container">
        <h3>Related Products</h3>
        <div class="rrnp-related-list">
          <a
            v-for="(dd, ddd) in relatedPros"
            :key="ddd"
            target="_blank"
            :href="`/store/product/${makeUrlFriendly(dd.name)}/${dd.id}`"
          >
            <div class="related-header">
              <h5>{{ dd.name }}</h5>
              <img
                src="https://cdn.prod.website-files.com/649d77c0369d0ac782a24f43/649d77c0369d0ac782a24f53_Asset%201.svg"
                width="35"
                height="35"
                alt="White corner"
                class="image"
              />
            </div>
            <div class="related-img">
              <img
                :src="
                  dd.product_image && dd.product_image.includes('https://')
                    ? dd.product_image
                    : `https://uploads-ssl.webflow.com/649d77c0369d0ac782a24f43/66b348679e75bd30e238e237_img-placeholder.jpg`
                "
                alt="product image"
              />
            </div>
            <div class="related-text" v-if="dd.longdescription">
              {{ textClip(dd.longdescription) }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../mixins/resources";
import Loading from "vue-loading-overlay";

import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    CommonHead,
    CommonFoot,
    Loading,
    "v-select": vSelect,
  },
  mounted() {
    this.loadProductDetail();
    console.log("priceLvl", this.priceLvl, this.user);
  },
  metaInfo() {
    return {
      title: `${this.name}${this.categoryname} `,
    };
  },
  data() {
    return {
      name: "",
      in_value: 0,
      in_valueLB: 0,
      qtyReady: true,
      min_qty: 100,
      min_qtyLB: 0,
      type: null,
      head_style: null,
      material: null,
      material_body: null,
      material_grade: null,
      line_card: null,
      size_number: null,
      head_size: null,
      finish: null,
      dia: null,
      lengthof: null,
      sellingdesc: null,
      stockuom: null,
      onhand: null,
      onhand_def: null,
      classification: null,
      revision: null,
      head_dia: null,
      finish_spec: null,
      notes: null,
      category: "",
      product_id: null,
      quantity: "1",
      TheoWght: null,
      PriceTblCode: null,
      PriceTblID: null,
      PriceTblDesc: null,
      PriceTblInfo: null,
      AltCode: null,
      ProdID: null,
      LicPlate: null,
      Qty: null,
      Lot: null,
      Mfr: null,
      CofO: null,
      RoHSOpt: null,
      DFARSOpt: null,
      QtyAvail: null,
      ListPrice: null,
      ListPriceString: null,
      ListPriceMethod: null,
      CertCode: null,
      CertDesc: null,
      CertID: null,
      CertInfo: null,
      Alternate: [],
      EntityTracking: [],
      longdescription: "",
      shortdescription: "",
      materialCollar: "",
      input_mfr: "none",
      input_cert: "none",
      Mfrlist: [{}],
      Certlist: [{}],
      relatedPros: [],
      qtyError: "",
      uom: "LB",
      uom_method: "",
      product_uom: [
        { value: "EA", text: "EA" },
        { value: "LB", text: "LB" },
      ],
      categoryname: "",
      enable_pricing: false,
      qtyBreak: null,
      qtyBreakLB: null,
      qtyBreakEA: null,
      finalCalculatedPrice: 0,
    };
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters(["user", "priceLvl"]),
  },
  methods: {
    validateAndAdjustOrderQuantity(orderQuantity, incrementValue) {
      if (!this.enable_pricing) {
        return orderQuantity;
      }
      if (incrementValue === 0) {
        return orderQuantity;
      }
      if (orderQuantity % incrementValue === 0) {
        return orderQuantity;
      } else {
        let adjustedQuantity = Math.ceil(orderQuantity / incrementValue) * incrementValue;
        this.$notify({
          text: `This product can only be ordered in increments of ${`${incrementValue}`.includes(".") ? parseFloat(incrementValue).toFixed(2) : incrementValue }. Quantity has been adjusted.`,
          type: "warn",
        });

        return `${adjustedQuantity}`.includes(".") ? parseFloat(parseFloat(adjustedQuantity).toFixed(4)) : adjustedQuantity;
      }
    },
    textClip(text) {
      if (text.length > 110) {
        return `${text.substring(0, 110 - 3)}...`;
      }
      return text;
    },
    intoView() {
      document.querySelector("#mainsection").scrollIntoView();
      setTimeout(
        () =>
          window.scrollBy({
            top: -150,
          }),
        10
      );
    },
    onQtyChangeOverRide() {
      if (Math.round(Math.max(0, parseFloat(this.quantity || 0))) !== 0) {
        this.quantity = this.validateAndAdjustOrderQuantity(Math.round(Math.max(1, parseFloat(this.quantity || 0))),this.uom === "LB" ? this.in_valueLB : this.in_value);
        this.finalCalculatedPrice = this.getFinalPrice();
      }
    },
    onQtyChange(skip) {
      console.log("qty", this.quantity);
      if (parseFloat(this.quantity || 0) <= 0) {
        this.$notify({
          text: "Please enter valid quantity value",
          type: "error",
        });
      }
      if (Math.round(parseFloat(this.quantity || 0)) > this.QtyAvail && this.enable_pricing) {
        // this.quantity = this.QtyAvail;
        this.$notify({
          text: "Only Available quantity can be added to the cart",
          type: "error",
        });
      }
      this.onQtyChangeOverRide();
    },
    getFinalPrice() {
      if (this.uom === "LB") {
        this.qtyBreak = this.qtyBreakLB;
      } else {
        this.qtyBreak = this.qtyBreakEA;
      }
      console.log(this.uom);
      console.table(this.qtyBreak);
      if (!this.qtyBreak) {
        return 0;
      }
      if (this.priceLvl === "none") {
        return his.qtyBreak[0].BaseUnitPrice;
      }
      console.log("PRODUCT: priceLvl", this.priceLvl);
      const _qtyBreak = this.qtyBreak.filter(
        (d) => d.PriceLvlCode === this.priceLvl
      );
      console.table(
        _qtyBreak.map((d) => ({
          PriceLvlCode: d.PriceLvlCode,
          Qty: d.Qty,
          UnitPrice: d.UnitPrice,
          BaseUnitPrice: d.BaseUnitPrice,
        }))
      );
      const findClosestNumberIndex = (arr, x) => {
        let _i = 0;

        for (let i = 0; i < arr.length; i++) {
          if (x >= arr[i]) {
            _i = i;
          }
        }
        return _i;
      };
      const applicablePriceRow =
        _qtyBreak[
          findClosestNumberIndex(
            _qtyBreak.map((d) => d.Qty),
            parseFloat(this.quantity || 0) || 0
          )
        ];

      if (applicablePriceRow) {
        console.table(
          [applicablePriceRow].map((d) => ({
            PriceLvlCode: d.PriceLvlCode,
            Qty: d.Qty,
            UnitPrice: d.UnitPrice,
            BaseUnitPrice: d.BaseUnitPrice,
          }))
        );
        return applicablePriceRow.UnitPrice;
      }
      return 0;
    },
    ClearMfr() {
      this.input_mfr = "none";
      if (this.uom == "EA") {
        let lb = 1 / parseFloat(this.TheoWght);
        var qtyRounded = parseFloat(lb) * parseFloat(this.onhand_def);
        this.QtyAvail = Math.round(qtyRounded);
      } else {
        this.QtyAvail = this.onhand_def;
      }
    },
    ClearCert() {
      this.input_cert = "none";
    },
    changeQty() {
      this.$store
        .dispatch("loadProductData", {
          product_id: this.$route.params.product_id,
          mfr: this.input_mfr,
        })
        .then((loadData) => {
          const QtyAvail = loadData.data;
          if (this.uom == "EA") {
            let lb = 1 / parseFloat(this.TheoWght);
            var qtyRounded = parseFloat(lb) * parseFloat(QtyAvail);
            this.QtyAvail = Math.round(qtyRounded);
          } else {
            this.QtyAvail = QtyAvail;
          }
        });
    },
    updateQtyonload() {
      this.onhand = this.onhand_def;
      this.QtyAvail = this.QtyAvail_def;
      this.qtyReady = false;
    },
    updateQty() {
      console.log(this.uom, "D", this.input_mfr);
      if(this.input_mfr == "none"){
        if (this.uom == "EA") {
          if (
            this.stockuom.includes("LB") &&
            this.TheoWght != "" &&
            this.TheoWght != 0
          ) {
            let lb = 1 / parseFloat(this.TheoWght);
            var qtyRounded = parseFloat(lb) * parseFloat(this.onhand);
            this.onhand = Math.round(qtyRounded);
            this.QtyAvail = Math.round(qtyRounded);
          } else if (this.stockuom.includes("EA")) {
            this.onhand = this.onhand_def;
            this.QtyAvail = this.QtyAvail_def;
          } else {
            this.onhand = 0;
            this.QtyAvail = 0;
          }
        } else {
          if (
            this.stockuom.includes("EA") &&
            this.TheoWght != "" &&
            this.TheoWght != 0
          ) {
            var qtyRounded = parseFloat(this.TheoWght) * parseInt(this.onhand);
            this.onhand = Math.round(qtyRounded);
            this.QtyAvail = Math.round(qtyRounded);
          } else if (this.stockuom.includes("LB")) {
            this.onhand = this.onhand_def;
            this.QtyAvail = this.QtyAvail_def;
          } else {
            this.onhand = 0;
            this.QtyAvail = 0;
          }
        }
      }else{
        if (this.uom == "EA") {
          let lb = 1 / parseFloat(this.TheoWght);
          var qtyRounded = parseFloat(lb) * parseFloat(this.QtyAvail);
          this.QtyAvail = Math.round(qtyRounded);
        } else {
          var qtyRounded = parseFloat(this.TheoWght) * parseInt(this.QtyAvail);
          this.QtyAvail = qtyRounded.toFixed(2);
        }
      }
      this.finalCalculatedPrice = this.getFinalPrice();
    },
    increment(id) {
      var val = parseInt(document.getElementById("qtybox_" + id).value);
      val = val + 1;
      document.getElementById("qtybox_" + id).value = val;
      this.quantity = val;
    },
    decrement(id) {
      var val = parseInt(document.getElementById("qtybox_" + id).value);
      if (val === 1) {
        console.log("Negative quantity not allowed");
      } else {
        val = val - 1;
        document.getElementById("qtybox_" + id).value = val;
        this.quantity = val;
      }
    },
    submitQuote(prod_id) {
      if (prod_id != "") {
        console.log(parseFloat(this.quantity) + "<=" + parseFloat(this.onhand));
        /*if(this.QtyAvail==null || this.QtyAvail=='' || this.QtyAvail==0 ||  parseFloat(this.quantity)<=parseFloat(this.QtyAvail)){*/
        if (parseFloat(this.quantity) > 0) {
          if (parseFloat(this.quantity) <= parseFloat(this.onhand)) {
            //do nothing
          } else {
            this.$notify({
              text: "This quantity is above our listed stock amount and may be subject to a delayed quote response, a lead time, and a minimum quantity.",
              type: "warn",
            });
          }
          console.log(this.QtyAvail + "==" + this.onhand);
          this.qtyError = "";
          this.$store
            .dispatch("quoteSubmit", {
              user_id: this.user.id,
              product_id: prod_id,
              quantity: this.quantity,
              uom: this.uom,
              input_mfr: this.input_mfr,
              input_cert: this.input_cert,
              QtyAvail: this.QtyAvail != null ? this.QtyAvail : this.onhand,
            })
            .then(() => {
              this.quantity = "1";

              $(".quotebtn.add").css("display", "none");
              $(".quotebtn.hideview").css("display", "unset");
            })
            .catch((error) => {
              if (this.form.hasErrors()) {
                this.$notify({
                  text: "There is some validation error. Please correct and try again!",
                  type: "error",
                });
              } else {
                this.$notify({
                  text: "Something went wrong!",
                  type: "error",
                });
              }
            });
        } else {
          //this.qtyError= "Quantity should not greater than Available Quantity";
          this.$notify({
            text: "Quantity should be greater than 0 (Zero)!",
            type: "error",
          });
        }
      } else {
        this.$notify({
          text: "select at least one checkbox!",
          type: "error",
        });
      }
    },
    submitCartForLogin(prodId) {
      this.$router.push(`/store/login?via_product=${prodId}`);
    },
    submitCart(prod_id) {
      if (prod_id != "") {
        if (parseFloat(this.quantity || 0) <= 0) {
          this.$notify({
            text: "Please submit with valid quantity value.",
            type: "error",
          });
          return;
        }
        if (parseFloat(this.QtyAvail || 0) === 0) {
          this.$notify({
            text: "Product is not Available at the moment",
            type: "error",
          });
          return;
        }
        if (
          this.uom === "EA" &&
          parseFloat(this.quantity || 0) < this.min_qty
        ) {
          this.$notify({
            text: `Minimum allowed quantity is ${this.min_qty}.`,
            type: "error",
          });
          return;
        }
        if (
          this.uom === "LB" &&
          parseFloat(this.quantity || 0) < this.min_qtyLB
        ) {
          this.$notify({
            text: `Minimum allowed quantity is ${parseFloat(
              this.min_qtyLB
            ).toFixed(2)}.`,
            type: "error",
          });
          return;
        }
        if (parseFloat(this.quantity || 0) > parseFloat(this.QtyAvail)) {
          this.$notify({
            text: "Only Available quantity can be added to the cart",
            type: "error",
          });
          return;
        }
        console.log(this.quantity + "<=" + this.QtyAvail);
        if (
          this.QtyAvail == null ||
          this.QtyAvail == "" ||
          this.QtyAvail == 0 ||
          parseFloat(this.quantity) <= parseFloat(this.QtyAvail)
        ) {
          console.log("Yes");

          this.qtyError = "";
          this.$store
            .dispatch("addToCartSubmit", {
              user_id: this.user.id,
              product_id: prod_id,
              quantity: this.quantity,
              price: this.finalCalculatedPrice,
              input_mfr: this.input_mfr,
              input_cert: this.input_cert,
              QtyAvail: this.QtyAvail,
              uom: this.uom,
            })
            .then(() => {
              this.quantity = "1";
              if ("__showSidebarCart" in window) {
                window.__showSidebarCart();
              }
            })
            .catch((error) => {
              if (this.form.hasErrors()) {
                this.$notify({
                  text: "There is some validation error. Please correct and try again!",
                  type: "error",
                });
              } else {
                this.$notify({
                  text: "Something went wrong!",
                  type: "error",
                });
              }
            });
        } else {
          this.qtyError = "Quantity should not greater than Available Quantity";
        }
      } else {
        this.$notify({
          text: "select at least one checkbox!",
          type: "error",
        });
      }
    },
    makeUrlFriendly(str) {
      str = str.toLowerCase().split("-");

      str = str.map((str) => str.replace(/[^\w\s]/gi, "")).join("-");

      str = str.replace(/\s+/g, "-");

      return str;
    },
    loadProductDetail() {
      this.$store
        .dispatch("loadProductDetail", this.$route.params.product_id)
        .then((loadProducts) => {
          if (
            this.$route.params.product_name &&
            this.$route.params.product_name !==
              this.makeUrlFriendly(loadProducts.data.name)
          ) {
            this.$router.push({ path: "/store/404" });
          }
          if (!this.$route.params.product_name && loadProducts.data.name) {
            this.$router.push({
              path: `/store/product/${this.makeUrlFriendly(
                loadProducts.data.name
              )}/${this.$route.params.product_id}`,
            });
          }
          this.name = loadProducts.data.name || "";
          this.type = loadProducts.data.type;
          this.head_style = loadProducts.data.head_style;
          this.material = loadProducts.data.material;
          this.finish = loadProducts.data.finish;
          this.dia = loadProducts.data.dia;
          this.lengthof = loadProducts.data.length;
          this.sellingdesc = loadProducts.data.selling_des;
          this.longdescription = loadProducts.data.longdescription;
          this.shortdescription = loadProducts.data.shortdescription;
          this.materialCollar = loadProducts.data.material_collar;
          this.stockuom = loadProducts.data.stock_uom;
          this.onhand = loadProducts.data.onhand;
          this.onhand_def = loadProducts.data.onhand;
          this.classification = loadProducts.data.classification;
          this.revision = loadProducts.data.revision;
          this.head_dia = loadProducts.data.head_dia;
          this.finish_spec = loadProducts.data.finish_spec;
          this.notes = loadProducts.data.notes;
          this.category = loadProducts.data.category || "";
          this.product_image = loadProducts.data.product_image;
          this.relatedPros = loadProducts.data.relatedPro;
          this.product_id = this.$route.params.product_id;
          this.material_body = loadProducts.data.material_body;
          this.material_grade = loadProducts.data.material_grade;
          this.line_card = loadProducts.data.line_card;
          this.size_number = loadProducts.data.size_number;
          this.head_size = loadProducts.data.head_size;
          this.TheoWght = loadProducts.data.TheoWght;
          this.PriceTblCode = loadProducts.data.PriceTblCode;
          this.PriceTblID = loadProducts.data.PriceTblID;
          this.PriceTblDesc = loadProducts.data.PriceTblDesc;
          this.PriceTblInfo = loadProducts.data.PriceTblInfo;
          this.AltCode = loadProducts.data.AltCode;
          this.ProdID = loadProducts.data.ProdID;
          this.LicPlate = loadProducts.data.LicPlate;
          this.Qty = loadProducts.data.Qty;
          this.Lot = loadProducts.data.Lot;
          this.Mfr = loadProducts.data.Mfr;
          this.CofO = loadProducts.data.CofO;
          this.RoHSOpt = loadProducts.data.RoHSOpt;
          this.DFARSOpt = loadProducts.data.DFARSOpt;
          // this.QtyAvail = loadProducts.data.QtyAvail;
          this.QtyAvail_def = loadProducts.data.onhand;
          this.ListPrice = loadProducts.data.ListPrice;
          this.ListPriceString = loadProducts.data.ListPriceString;
          this.ListPriceMethod = loadProducts.data.ListPriceMethod;
          this.CertCode = loadProducts.data.CertCode;
          this.CertDesc = loadProducts.data.CertDesc;
          this.CertID = loadProducts.data.CertID;
          this.CertInfo = loadProducts.data.CertInfo;
          this.Alternate = JSON.parse(loadProducts.data.Alternate);
          this.EntityTracking = JSON.parse(loadProducts.data.EntityTracking);
          this.Mfrlist = loadProducts.data.MfrData;
          this.Certlist = loadProducts.data.CertData;
          this.categoryname = loadProducts.data.categoriesName;
          this.min_qty = loadProducts.data.min_qty || 100;
          this.in_value = loadProducts.data.in_value || 0;
          this.in_valueLB =
            (loadProducts.data.in_value || 0) /
            (1 / parseFloat(loadProducts.data.TheoWght));

          this.qtyBreak =
            loadProducts.data.pro.Product[0].More.PriceTable.QtyBreak || [];
          this.qtyBreakEA =
            loadProducts.data.pro.Product[0].More.PriceTable.QtyBreak || [];
          this.qtyBreakLB = (
            loadProducts.data.pro.Product[0].More.PriceTable.QtyBreak || []
          ).map((d) => ({
            ...d,
            Qty: d.Qty / (1 / parseFloat(loadProducts.data.TheoWght)),
            BaseUnitPrice:
              d.BaseUnitPrice * (1 / parseFloat(loadProducts.data.TheoWght)),
            UnitPrice:
              d.UnitPrice * (1 / parseFloat(loadProducts.data.TheoWght)),
          }));
          this.min_qtyLB =
            (loadProducts.data.min_qty || 100) /
            (1 / parseFloat(loadProducts.data.TheoWght));

          this.enable_pricing =
            parseFloat(loadProducts.data.onhand) >=
            ((this.stockuom || "").includes("EA")
              ? this.min_qty
              : this.min_qtyLB)
              ? loadProducts.data.enable_pricing === 1
              : false;

          console.log("EA Table");
          console.table(
            this.qtyBreakEA.map((d) => ({
              PriceLvlCode: d.PriceLvlCode,
              Qty: d.Qty,
              UnitPrice: d.UnitPrice,
              BaseUnitPrice: d.BaseUnitPrice,
            }))
          );
          console.log("LB Table");
          console.table(
            this.qtyBreakLB.map((d) => ({
              PriceLvlCode: d.PriceLvlCode,
              Qty: d.Qty,
              UnitPrice: d.UnitPrice,
              BaseUnitPrice: d.BaseUnitPrice,
            }))
          );

          if ((this.stockuom || "").includes("EA")) {
            this.uom = "EA";
          } else {
            this.uom = "LB";
            this.qtyBreak = this.qtyBreakLB;
          }
          this.finalCalculatedPrice = this.getFinalPrice();

          /*this.uom_method=loadProducts.data.stock_uom.split(",")[0];
                    this.uom_method= this.uom_method.replace("Qty ","");
                    this.uom=this.uom_method.trim();*/
        });

      let mythis = this;
      setTimeout(function () {
        if ($(".certificates .count").length == 0) {
          $(".certiinfoTable .tabs").css("display", "none");
        }
        mythis.updateQtyonload();
      }, 3000);
    },
  },
};
</script>
<style>
.lotnumbers,
.certificates {
  margin-bottom: 15px;
}
.page--product .quotebtn {
  position: relative;
  float: right;
  background: #f2612c;
}
.page--product .quotebtn.hideview {
  display: none;
}
.page--product .samediv span {
  float: left;
  font-size: 18px;
  border: 1px solid #d2d2d2;
  margin: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 8px;
  font-weight: 500;
  color: #303030;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
.page--product .samediv span.lastspan {
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 39px;
}
.page--product .samediv input {
  float: left;
  max-width: 100px;
  font-size: 14px;
  height: 35px;
  text-align: center;
  border: 1px solid #d2d2d2;
  font-weight: 500;
  color: #303030;
  padding-top: 3px;
}

.rrnp-header {
  overflow: hidden;
  min-height: 300px;
  background-image: url(https://rapidrivet.s3.amazonaws.com/public_assets/header-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  align-items: center;
}
.rrnp-header h1 {
  font-size: 56px;
  font-weight: bold;
}
.rrnp-content {
  overflow: hidden;
  padding: 100px 0px;
  background-color: white;
}

.rrnp-content-split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
}

.rrnp-content-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.rrnp-content-left p {
  color: #1a1a20;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}

.rrnp-content-right img {
  width: 100%;
  aspect-ratio: 830 / 566;
  max-height: 566px;
  max-width: 830px;
  object-fit: contain;
}

.container {
  padding: 0 20px;
}

.rnnp-div-block-12 {
  background: #efefef;
}
.rnnp-div-block-12 h1 {
  margin: 0px;
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: bold;
}

.newprod-design {
  padding: 28px 24px;
}
.rrnp-related-container {
  margin-top: 100px;
  padding: 100px 0px;
  background-color: #ffffff;
}

.rrnp-related-container h3 {
  font-size: 24px;
  line-height: 32px;
  margin: 0px;
  margin-bottom: 40px;
  font-weight: bold;
}

.page--product .footer {
  margin-top: 0px;
}
.rrnp-related-list {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
}

.rrnp-related-list a {
  width: 100%;
}

.rrnp-related-list .related-header {
  background-color: #253c8a;
  position: relative;
  color: #fff;
  padding: 24px 40px;
}
.rrnp-related-list .related-header h4 {
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
}

.related-header .image {
  top: 0px;
  filter: brightness(1.5);
}

.related-img {
  min-height: 206px;
  position: relative;
  overflow: hidden;
}

.related-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.related-text {
  padding: 24px;
  background-color: #efefef;
  font-size: 16px;
  line-height: 24px;
}

.rrnp-props {
  display: flex;
  gap: 1px;
  flex-wrap: wrap;
  background: #ffffff;
  margin-bottom: 16px;
}
.rrnp-props > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #ffffff;
  background-color: #253c8a;
  padding: 16px;
  width: calc(33% - 0.5px);
}
.rrnp-props > div p {
  margin: 0px;
  font-size: 16px;
  font-weight: 24px;
}
@media only screen and (max-width: 850px) {
  .rrnp-content-right {
    order: -1;
  }
  .rrnp-content-split {
    grid-template-columns: 1fr;
  }

  .rrnp-related-list {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 650px) {
  .rrnp-props > div {
    width: 100%;
  }
}
</style>
