<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Edit Address</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <div class="loginform w-form">
                <b-form class="commonform w-clearfix" @submit="onSubmit">
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :class="
                        form.errors.get('firstname')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :state="!form.errors.has('firstname')"
                      :invalid-feedback="form.errors.get('firstname')"
                    >
                      <label for="name-4" class="field-label-33"
                        >First name *</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.firstname"
                        :state="!form.errors.has('firstname')"
                        @input="form.errors.clear('firstname')"
                        data-name="firstname"
                        id="firstname"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('firstname')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      :class="
                        form.errors.get('lastname')
                          ? 'haserror div-block-50 typein'
                          : 'div-block-50 typein'
                      "
                      :state="!form.errors.has('lastname')"
                      :invalid-feedback="form.errors.get('lastname')"
                    >
                      <label for="name-4" class="field-label-34"
                        >Last name*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.lastname"
                        :state="!form.errors.has('lastname')"
                        @input="form.errors.clear('lastname')"
                        data-name="lastname"
                        id="lastname"
                      />
                      <span class="error-msg" v-if="form.errors.get('lastname')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :class="
                        form.errors.get('country')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :state="!form.errors.has('country')"
                      :invalid-feedback="form.errors.get('country')"
                    >
                      <label for="name-4" class="field-label-35"
                        >country *</label
                      >
                      <v-select
                        :options="countrylist"
                        v-model="form.country"
                        :state="!form.errors.has('country')"
                        @input="
                          form.errors.clear('country');
                          getState();
                        "
                        :reduce="(countrylist) => countrylist.value"
                        label="text"
                        id="country"
                        :clearable="false"
                      ></v-select>
                      <span class="error-msg" v-if="form.errors.get('country')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50 typein">
                      <label for="name-4" class="field-label-36"
                        >company name</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.companyname"
                        :state="!form.errors.has('companyname')"
                        @input="form.errors.clear('companyname')"
                        data-name="Name 3"
                        id="companyname"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!form.errors.has('streetaddress')"
                      :class="
                        form.errors.get('streetaddress')
                          ? 'haserror forstar div-block-49'
                          : 'forstar div-block-49'
                      "
                      :invalid-feedback="form.errors.get('streetaddress')"
                    >
                      <label for="name-4" class="field-label-37"
                        >address 1*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        :state="!form.errors.has('streetaddress')"
                        @input="form.errors.clear('streetaddress')"
                        v-model="form.streetaddress"
                        data-name="Name 3"
                        id="streetaddress"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('streetaddress')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50 typein">
                      <label for="name-4" class="field-label-38"
                        >address 2</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.streetaddress1"
                        data-name="streetaddress1"
                        id="streetaddress1"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!form.errors.has('city')"
                      :class="
                        form.errors.get('city')
                          ? 'haserror forstar div-block-50 typein'
                          : 'forstar div-block-50 typein'
                      "
                      :invalid-feedback="form.errors.get('city')"
                    >
                      <label for="name-4" class="field-label-39">city*</label>
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        :state="!form.errors.has('city')"
                        @input="form.errors.clear('city')"
                        v-model="form.city"
                        data-name="City"
                        id="city"
                      />
                      <span class="error-msg" v-if="form.errors.get('city')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      :state="!form.errors.has('state')"
                      :class="
                        form.errors.get('state')
                          ? 'haserror forstar div-block-49'
                          : 'forstar div-block-49'
                      "
                      :invalid-feedback="form.errors.get('state')"
                    >
                      <label for="name-4" class="field-label-40">state *</label>
                      <v-select
                        :options="statelist"
                        v-model="form.state"
                        :state="!form.errors.has('state')"
                        @input="form.errors.clear('state')"
                        :reduce="(statelist) => statelist.value"
                        label="text"
                        id="state"
                        :clearable="false"
                      ></v-select>
                      <span class="error-msg" v-if="form.errors.get('state')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!form.errors.has('zipcode')"
                      :class="
                        form.errors.get('zipcode')
                          ? 'haserror forstar div-block-49'
                          : 'forstar div-block-49'
                      "
                      :invalid-feedback="form.errors.get('zipcode')"
                    >
                      <label for="name-4" class="field-label-41"
                        >Zip/postal code*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        :state="!form.errors.has('zipcode')"
                        @input="form.errors.clear('zipcode')"
                        v-model="form.zipcode"
                        data-name="zipcode"
                        id="zipcode"
                      />
                      <span class="error-msg" v-if="form.errors.get('zipcode')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      :state="!form.errors.has('phoneno')"
                      :class="
                        form.errors.get('phoneno')
                          ? 'haserror forstar div-block-50 typein'
                          : 'forstar div-block-50 typein'
                      "
                      :invalid-feedback="form.errors.get('phoneno')"
                    >
                      <label for="name-4" class="field-label-42">Phone*</label>
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        :state="!form.errors.has('phoneno')"
                        @input="form.errors.clear('phoneno')"
                        v-model="form.phoneno"
                        data-name="phoneno"
                        id="phoneno"
                      />
                      <span class="error-msg" v-if="form.errors.get('phoneno')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="name-4" class="field-label-43"
                        >Cell phone</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.cellphone"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50 typein">
                      <label for="name-4" class="field-label-44">fax</label>
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.faxno"
                        data-name="Fax"
                        id="fax"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="billing" class="field-label-45"
                        >Billing</label
                      >
                      <md-checkbox
                        value="2"
                        v-model="form.billing"
                      ></md-checkbox>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="shipping" class="field-label-45"
                        >Shipping</label
                      >
                      <md-checkbox
                        value="2"
                        v-model="form.Shipping"
                      ></md-checkbox>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="is_primary" class="field-label-45"
                        >Set as primary address</label
                      >
                      <md-checkbox
                        value="3"
                        v-model="form.is_primary"
                      ></md-checkbox>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <b-button
                    type="submit"
                    class="button w-button"
                    block
                    variant="dark"
                    >Submit</b-button
                  >
                  <a href="/store/address" class="button-2 w-button">Go Back</a>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
//import Multiselect from "vue-multiselect";
import AuthMixin from "../../mixins/auth";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../../mixins/resources";

export default {
  name: "ErrorsMessages",
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  mounted() {
    this.getAddress();
  },
  data() {
    return {
      form: new Form({
        user_id: null,
        firstname: null,
        lastname: null,
        country: null,
        companyname: null,
        streetaddress: null,
        streetaddress1: null,
        city: null,
        state: null,
        zipcode: null,
        phoneno: null,
        cellphone: null,
        faxno: null,
        billing: 0,
        shipping: 0,
        is_primary: 0,
      }),
      checkboxes: [],
      show: true,
      country: [],
      states: [],
      address: {},

      countrylist: [{ value: "United States", text: "United States" }],
      statelist: [
        { value: "Alabama", text: "Alabama" },
        { value: "Alaska", text: "Alaska" },
      ],
    };
  },

  mixins: [ResourceMixin],
  beforeMount() {
    //this.initForm();
    //this.isLoading = false;
    this.getCountry();
  },
  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.save();
      //alert(JSON.stringify(this.form));
    },
    getAddress() {
      this.$store
        .dispatch("getAddress", this.$route.params.address_id)
        .then((getaddress) => {
          this.$store
            .dispatch("getState", getaddress.data.success[0].country)
            .then((response) => {
              this.statelist = response.data;
            });

          this.form = new Form({
            user_id: getaddress.data.success[0].user_id,
            firstname: getaddress.data.success[0].firstname,
            lastname: getaddress.data.success[0].lastname,
            country: parseInt(getaddress.data.success[0].country),
            companyname: getaddress.data.success[0].companyname,
            streetaddress: getaddress.data.success[0].streetaddress,
            streetaddress1: getaddress.data.success[0].streetaddress1,
            city: getaddress.data.success[0].city,
            state: parseInt(getaddress.data.success[0].state),
            zipcode: getaddress.data.success[0].zipcode,
            phoneno: getaddress.data.success[0].phoneno,
            cellphone: getaddress.data.success[0].cellphone,
            faxno: getaddress.data.success[0].faxno,
            billing: getaddress.data.success[0].billing,
            shipping: getaddress.data.success[0].shipping,
            is_primary: getaddress.data.success[0].is_primary,
          });
        });

      //this.getState(231);
    },
    save() {
      this.$store
        .dispatch("editAddress", {
          form: this.form,
          address_id: this.$route.params.address_id,
        })
        .then(() => {
          this.$notify({
            text: "Address Added Successfully",
            type: "success",
          });
          this.$router.push("/store/address");
        })
        .catch((error) => {
          if (this.form.hasErrors()) {
            this.$notify({
              text: "There is some validation error. Please correct and try again!",
              type: "error",
            });
          } else {
            this.$notify({
              text: "Something went wrong!",
              type: "error",
            });
          }
        });
    },

    getCountry() {
      this.$store.dispatch("loadCountries").then((countries) => {
        this.countrylist = countries.data;
      });
    },
    getState(value) {
      this.$store.dispatch("getState", this.form.country).then((response) => {
        this.statelist = response.data;
      });
      this.form.state = parseInt(this.statelist[0].text);
    },
  },
};
</script>
<style>
span.error-msg {
  color: red !important;
  margin-top: 5px;
  display: inline-block;
}
.commonform .haserror .w-input,
.commonform .haserror .md-textarea,
.commonform .haserror .vs__dropdown-toggle {
  border: 1px solid red;
}
.test {
  background: linear-gradient(135deg, transparent 10px, #c00 0) top left,
    linear-gradient(225deg, transparent 10px, #c00 0) top right,
    linear-gradient(315deg, transparent 10px, #c00 0) bottom right,
    linear-gradient(45deg, transparent 10px, #c00 0) bottom left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}
.pointer {
  pointer-events: none;
}
</style>
