<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock addressmob">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">
                Ticket List
                <br />
              </h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <div class="createticketcont">
                <router-link
                  to="/store/createticket"
                  class="btn button w-button btn-dark btn-block"
                  >Create New Ticket</router-link
                >
              </div>
              <div class="searchorder">
                <h3 class="heading-19">Search</h3>
                <div class="radiodiv">
                  <div class="w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      class="form-2 w-clearfix"
                      @submit="searchtickets"
                    >
                      <div class="datesearch">
                        <div class="startend">
                          <h5 class="heading-20">Status:</h5>
                          <v-select
                            :options="status"
                            v-model="form.status"
                            :reduce="(status) => status.value"
                            label="text"
                            id="status"
                          ></v-select>
                          <!-- <h5 class="heading-20">Status:</h5>
                          <select
                            id="field"
                            name="field"
                            v-model="form.status"
                            class="select-field-3 statusticket w-select"
                          >
                            <option value></option>
                            <option value="open">Open</option>
                            <option value="close">Close</option>
                            <option value="all">All</option>
                          </select> -->
                        </div>
                        <div class="startend">
                          <h5 class="heading-20">Ticket ID:</h5>
                          <input
                            type="text"
                            class="datepicker ticketid w-input"
                            maxlength="256"
                            name="field"
                            v-model="form.ticketid"
                            data-name="Field"
                            id="datepicker1"
                          />
                        </div>
                        <div class="startend">
                          <h5 class="heading-20">Keywords:</h5>
                          <input
                            type="text"
                            class="datepicker w-input"
                            maxlength="256"
                            name="field"
                            v-model="form.keyword"
                            data-name="Field"
                            id="datepicker2"
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        class="button secondary w-inline-block"
                      >
                        <div>Search</div>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="home-content-wrap-copy">
          <div class="div-block-30">
            <div class="text-block-4 h6">All Tickets List</div>

            <div v-if="ticketslist.length > 0">
              <!-- Main table element -->
              <b-table
                show-empty
                responsive
                class="productlisttable"
                :items="ticketslist"
                :fields="ticketHeads"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(id)="row">
                  <div class="cell-link" @click="gotosinglePage(row.item.id)">
                    {{ row.item.id }}
                  </div>
                </template>

                <template v-slot:cell(created_at)="row">
                  <div class="cell-text">{{ row.item.created_at | date }}</div>
                </template>
                <template v-slot:cell(subject)="row">
                  <div class="cell-text">{{ row.value }}</div>
                </template>
                <template v-slot:cell(status)="row">
                  <div class="cell-text">{{ row.value }}</div>
                </template>
              </b-table>
              <div class="itemspage" v-if="ticketslist.length > 0">
                <label>Items Per Page</label>
                <v-select
                  :options="itemspage"
                  v-model="form.items"
                  @input="loadItemForTickets"
                  :clearable="false"
                  placeholder="10"
                ></v-select>
              </div>
              <div class="propagination" v-if="ticketslist.length > 0">
                <div class="custompage">
                  <b-pagination
                    v-model="currentPage"
                    @input="paginationChange"
                    :total-rows="rows"
                    :per-page="perPage"
                    align="center"
                    first-number
                    last-number
                  ></b-pagination>
                </div>
              </div>
            </div>

            <div v-if="ticketslist.length <= 0">
              <p class="emptyquote">Your Order List is empty.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  mounted() {
    this.loadTickets();
  },
  data() {
    return {
      form: {
        status: null,
        ticketid: null,
        keyword: null,
        page: 1,
        items: "20",
      },
      status: [
        { value: "open", text: "Open" },
        { value: "close", text: "Close" },
        { value: "all", text: "All" },
      ],
      ticketslist: [],
      ticketHeads: [
        {
          key: "id",
          label: "Ticket ID",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "created_at",
          label: "Ticket Date",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "status",
          label: "Status",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "subject",
          label: "Subject",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
      ],
      rows: 20,
      perPage: 20,
      currentPage: 1,
      itemspage: ["10", "20", "25", "50", "100"],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    rowClass(item, type) {
      return "tbRows";
    },
    paginationChange(page) {
      this.form.page = page;
      this.loadTickets();
    },
    loadItemForTickets() {
      this.form.page = 1;
      this.loadTickets();
    },
    loadTickets() {
      let url = [];
      if (this.form.status != null) {
        url["status"] = this.form.status;
      }
      if (this.form.ticketid != null) {
        url["ticketid"] = this.form.ticketid;
      }
      if (this.form.keyword != null) {
        url["keyword"] = this.form.keyword;
      }
      url["page"] = this.form.page;
      url["items"] = this.form.items;
      var queryString = Object.keys(url)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(url[key]);
        })
        .join("&");
      this.$store
        .dispatch("loadTicketsSearch", {
          userid: this.user.id,
          queryString: queryString,
        })
        .then((response) => {
          this.ticketslist = response.data.data;
          this.rows = response.data.total;
          this.currentPage = this.form.page;
          this.perPage = this.form.items;
        });
    },
    searchtickets(e) {
      e.preventDefault();
      let url = [];
      if (this.form.status != null) {
        url["status"] = this.form.status;
      }
      if (this.form.ticketid != null) {
        url["ticketid"] = this.form.ticketid;
      }
      if (this.form.keyword != null) {
        url["keyword"] = this.form.keyword;
      }
      url["page"] = this.form.page;
      url["items"] = this.form.items;
      var queryString = Object.keys(url)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(url[key]);
        })
        .join("&");
      this.$store
        .dispatch("loadTicketsSearch", {
          userid: this.user.id,
          queryString: queryString,
        })
        .then((response) => {
          this.ticketslist = response.data.data;
          this.rows = response.data.total;
          this.currentPage = this.form.page;
          this.perPage = this.form.items;
        });
    },
    gotosinglePage(ticket_id) {
      this.$router.push({ path: "/store/ticket/" + ticket_id });
    },
  },
};
</script>
<style>
</style>
