import Vue from "vue";
import Routes from "./wfRoutes";
import VueRouter from "vue-router";
import UserGuard from "./guards/userGuard";
import AuthGuard from "./guards/authGuard";
import HomeGuard from "./guards/homeGuard";
import VerifyGuard from "./guards/verifyGuard";

Vue.use(VueRouter);

const Router = new VueRouter({
  routes: Routes,
  mode: "history",
  linkActiveClass: "is-active",
  fallback: true,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
// These guards will run checks prior to initializing the application to ensure
// the request route is viewable.
Router.beforeEach(UserGuard);
Router.beforeEach(AuthGuard);
Router.beforeEach(HomeGuard);

// Invite Token Verify Checker
Router.beforeEach(VerifyGuard);

export default Router;
