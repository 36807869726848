import FormErrors from './FormErrors';
import Vue from 'vue';

class Form {
    /**
     * Create a new Form instance.
     *
     * @param {Object} data
     * @param {Boolean=} persistChanges
     */
    constructor(data, persistChanges) {
        this.busy = false;
        this.successful = false;
        this.original = JSON.parse(JSON.stringify(Vue.util.extend(data)));

        if (typeof persistChanges !== 'undefined') {
            this.persistChanges = persistChanges;
        } else {
            this.persistChanges = true;
        }

        for (let field in data) {
            this[field] = data[field];
        }

        this.errors = new FormErrors;
    }

    /**
     * Returns the relevant data for the form.
     *
     * @return {Object}
     */
    data() {
        let data = {};

        for (let field in this.original) {
            data[field] = this[field];
        }

        return data;
    }

    /**
     * Reset the form fields.
     */
    reset() {
        for (let field in this.original) {
            this[field] = JSON.parse(JSON.stringify(Vue.util.extend(this.original[field])));
        }

        this.busy = false;
        this.successful = false;
        this.errors.clear();
    }

    /**
     * Send a POST request to the given URL.
     *
     * @param {String} url
     * @return {Promise}
     */
    post(url) {
        return this.submit('post', url);
    }

    /**
     * Send a PUT request to the given URL.
     *
     * @param {String} url
     * @return {Promise}
     */
    put(url) {
        return this.submit('put', url);
    }

    /**
     * Send a PATCH request to the given URL.
     *
     * @param {String} url
     * @return {Promise}
     */
    patch(url) {
        return this.submit('patch', url);
    }

    /**
     * Send a DELETE request to the given URL.
     *
     * @param {String} url
     * @return {Promise}
     */
    delete(url) {
        return this.submit('delete', url);
    }

    /**
     * Submit the form.
     *
     * @param {String} verb
     * @param {String} url
     * @return {Promise}
     */
    submit(verb, url) {
        $(':focus').blur();

        return new Promise((resolve, reject) => {
            this.busy = true;
            this.successful = false;

            let data = {};
            if (verb === 'delete' || verb === 'get') {
                data = this.data();

                Object.keys(data).map(k => {
                    if (data[k] === true) {
                        data[k] = '1';
                    } else if (data[k] === false) {
                        data[k] = '0';
                    }
                })

                data = { params: data };
            } else {
                data = this.data();
            }

            axios[verb](url, data).then(response => {
                this.onSuccess(response.data, verb);

                resolve(response);
            }).catch(error => {
                if (error.response && error.response.data) {
                    if (error.response.data.hasOwnProperty('errors')) {
                        this.onFail(error.response.data.errors);
                    } else {
                        this.onFail(error.response.data);
                    }
                }

                reject(error);
            });
        });
    }

    /**
     * Handle a successful form submission.
     *
     * @param {Object} data
     * @param {String} verb
     * @return {void}
     */
    onSuccess(data, verb) {
        this.busy = false;
        this.successful = true;

        if (! this.persistChanges) {
            return;
        }

        if (verb === 'patch') {
            this.value = data[this.attribute];
            this.original.value = data[this.attribute];
        } else {
            for (let field in data) {
                if (field.indexOf('password') !== -1) {
                    continue;
                }

                this[field] = data[field];
                this.original[field] = data[field];
            }
        }
    }

    /**
     * Handles a failed form submission.
     *
     * @param {Object} errors
     */
    onFail(errors) {
        this.busy = false;
        this.successful = false;

        this.errors.record(errors);
    }

    /**
     * Returns true if the form has errors to display.
     *
     * @return {Boolean}
     */
    hasErrors() {
        return this.errors.any();
    }

    /**
     * Returns true if the form has been modified from its original state
     *
     * @return {boolean}
     */
    isDirty() {
        return JSON.stringify(this.data()) !== JSON.stringify(this.original);
    }
}

export default Form;
