<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Quotation - {{ myQuote[0].id }}</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <div class="col-md-6 div-block-72">
                <h5>Quote Information</h5>
                <p>Quote Number: {{ myQuote[0].id }}</p>
                <p>Quote Date: {{ myQuote[0].created_at | date }}</p>
                <p>
                  No of items:
                  {{
                    myQuote[0].quote_type != "quick-quote"
                      ? quoteItems.length
                      : "1"
                  }}
                </p>
                <p>
                  Total Quotation: $ {{ final_total ? final_total : "0.00" }}
                </p>
                <p v-if="myQuote[0].notes">Notes: {{ myQuote[0].notes }}</p>
                <p>Type of Industry: {{ myQuote[0].industry }}</p>
                <p
                  v-if="
                    myQuote[0].specsheet != '' && myQuote[0].specsheet != null
                  "
                >
                  Specsheet:
                  <a
                    target="_blank"
                    class="specsheet"
                    :href="'/uploads/quotes/' + myQuote[0].specsheet"
                    >{{ myQuote[0].specsheet }}</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="home-content-wrap-copy">
          <div class="div-block-30">
            <div class="text-block-4 h6">Quote List</div>
            <div v-if="myQuote[0].quote_type != 'quick-quote'">
              <div v-if="quoteItems.length > 0">
                <!-- Main table element -->
                <b-table
                  show-empty
                  responsive
                  class="productlisttable bigtable overlap-table"
                  :items="quoteItems"
                  :fields="fields"
                  :tbody-tr-class="rowClass"
                >
                  <template v-slot:cell(id)="row">
                    <div
                      class="cell-link no-word-overflow"
                      @click="gotosinglePage(row.item.id)"
                    >
                      {{ row.item.id }}
                    </div>
                  </template>
                  <template v-slot:cell(firstname)="row">
                    <div
                      class="cell-link no-word-overflow"
                      @click="gotosinglePage(row.item.id)"
                    >
                      {{ row.item.name }}
                    </div>
                  </template>
                  <template v-slot:cell()="row">
                    <div
                      class="cell-text no-word-overflow"
                      @click="gotosinglePage(row.item.id)"
                    >
                      {{ row.value }}
                    </div>
                  </template>
                  <template v-slot:cell(price)="row">{{
                    row.item.item_price ? "$ " + row.item.item_price : "-"
                  }}</template>
                  <template v-slot:cell(addtoquote)="row">{{
                    row.item.item_subtotal ? "$ " + row.item.item_subtotal : "-"
                  }}</template>
                  <!-- <template v-slot:cell(action)="row">
                                        <md-checkbox
                                            :value="row.item.id"
                                            v-model="checkboxes"
                                            :class="'checkbox'+row.item.id"
                                        ></md-checkbox>
                                    </template>-->
                </b-table>
                <div class="account-container">
                  <div class="right-align">
                    <div class="div-block-75">
                      <p class="margin-0">
                        Subtotal
                        <br />
                      </p>
                    </div>
                    <div class="div-block-75-copy">
                      <p class="margin-0">
                        {{ item_total ? "$ " + item_total : "0.00" }}
                        <br />
                      </p>
                    </div>
                  </div>
                  <div class="right-align">
                    <div class="div-block-75">
                      <p class="margin-0">
                        Tax ( {{ myQuote[0].tax_percent }}% )
                        <br />
                      </p>
                    </div>
                    <div class="div-block-75-copy">
                      <p class="margin-0">
                        {{
                          myQuote[0].tax_amount
                            ? "$ " + myQuote[0].tax_amount
                            : "0.00"
                        }}
                        <br />
                      </p>
                    </div>
                  </div>
                  <div class="right-align">
                    <div class="div-block-75">
                      <p class="margin-0">
                        TOTAL ( {{ quoteItems.length }} ITEMS )
                        <br />
                      </p>
                    </div>
                    <div class="div-block-75-copy">
                      <p class="margin-0">
                        {{ final_total ? "$ " + final_total : "0.00" }}
                        <br />
                      </p>
                    </div>
                  </div>

                  <div class="_14"></div>
                  <div class="hr"></div>
                  <div class="_14"></div>
                  <!-- <div
                    v-if="parseInt(final_total.replace(',', '')) <= 0"
                    class="alert alert-danger"
                  >
                    Rapid Rivet has a minimum Purchase Order value of $0. If
                    you would like to submit a purchase for this quote, please
                    contact us at sales@rapidrivet.com or call us: 800-727-4378
                  </div> -->
                  <div v-if="isExpired" class="alert alert-danger">
                    Rapid Rivet has a Quote Expiration of 30 Days. If you would
                    like updated Quote information, please submit a Re-Quote on
                    the above item(s).
                  </div>
                  <div class="div-block-76">
                    <div class="div-block-77">
                      <a
                        href="javascript:;"
                        @click="gotoListpage"
                        class="backinfo"
                      >
                        <b-img src="/images/arrow.svg" alt="arrow"></b-img>Back
                      </a>
                    </div>
                    <div class="div-block-78">
                      <div class="_14"></div>
                      <div
                        v-if="myQuote[0].status != 'checkout process completed'"
                      >
                        <a
                          v-if="
                            item_total != 0.0 && item_total != '' && !isExpired
                          "
                          :href="
                            '/store/new-checkout/' + myQuote[0].quote_token
                          "
                          class="button cc-contact-us w-inline-block"
                        >
                          <div>Submit Purchase Order</div>
                        </a>
                        <a
                          v-if="isExpired"
                          style="cursor: pointer"
                          class="button cc-contact-us w-inline-block"
                          @click="submitReQuote()"
                        >
                          <div>Re-Quote</div>
                        </a>
                      </div>
                      <div v-else class="checkout-process-completed">
                        Your order has been placed.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <p class="emptyquote">Your Quote List is empty.</p>
              </div>
            </div>
            <!-- quick quote list -->
            <div v-else>
              <b-table
                show-empty
                responsive
                class="productlisttable"
                :items="quoteItems"
                :fields="quickfields"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(id)="row">
                  <div class="cell-link no-word-overflow">
                    {{ row.item.id }}
                  </div>
                </template>

                <template v-slot:cell()="row">
                  <div class="cell-text no-word-overflow">{{ row.value }}</div>
                </template>
                <template v-slot:cell(price)="row">{{
                  row.item.price ? "$ " + row.item.price : "-"
                }}</template>
                <template v-slot:cell(addtoquote)="row">{{
                  row.item.subtotal ? "$ " + row.item.subtotal : "-"
                }}</template>
                <!-- <template v-slot:cell(action)="row">
                                    <md-checkbox
                                        :value="row.item.id"
                                        v-model="checkboxes"
                                        :class="'checkbox'+row.item.id"
                                    ></md-checkbox>
                                </template>-->
              </b-table>
              <!-- <div class="text-block-4 h6 quotebtn" v-if="myQuote.length > 0">
                                <a
                                    href="javascript:;"
                                    @click="deleteQuote();"
                                    class="addquote"
                                >Delete selected products</a>
                            </div>-->
              <div class="account-container">
                <div class="right-align">
                  <div class="div-block-75">
                    <p class="margin-0">
                      Subtotal
                      <br />
                    </p>
                  </div>
                  <div class="div-block-75-copy">
                    <p class="margin-0">
                      {{ item_total ? "$ " + item_total : "0.00" }}
                      <br />
                    </p>
                  </div>
                </div>

                <div class="right-align">
                  <div class="div-block-75">
                    <p class="margin-0">
                      Tax ( {{ myQuote[0].tax_percent }}% )
                      <br />
                    </p>
                  </div>
                  <div class="div-block-75-copy">
                    <p class="margin-0">
                      {{
                        myQuote[0].tax_amount
                          ? "$ " + myQuote[0].tax_amount
                          : "0.00"
                      }}
                      <br />
                    </p>
                  </div>
                </div>
                <div class="right-align">
                  <div class="div-block-75">
                    <p class="margin-0">
                      TOTAL ( {{ myQuote.length }} ITEMS )
                      <br />
                    </p>
                  </div>
                  <div class="div-block-75-copy">
                    <p class="margin-0">
                      {{ final_total ? "$ " + final_total : "0.00" }}
                      <br />
                    </p>
                  </div>
                </div>

                <div class="_14"></div>
                <div class="hr"></div>
                <div class="_14"></div>
                <div class="div-block-76">
                  <div class="div-block-77">
                    <a
                      href="javascript:;"
                      @click="gotoListpage"
                      class="backinfo"
                    >
                      <b-img src="/images/arrow.svg" alt="arrow"></b-img>Back
                    </a>
                  </div>

                  <div class="div-block-78">
                    <div class="_14"></div>
                    <a
                      v-if="item_total != 0.0 && item_total != '' && !isExpired"
                      :href="'/store/new-checkout/' + myQuote[0].quote_token"
                      class="button cc-contact-us w-inline-block"
                    >
                      <div>Submit Purchase Order</div>
                    </a>
                    <a
                      v-if="isExpired"
                      @click="submitReQuote()"
                      style="cursor: pointer"
                      class="button cc-contact-us w-inline-block"
                    >
                      <div>Re-Quote</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../../mixins/resources";
import moment from "moment";

export default {
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  beforeMount() {},
  mounted() {
    this.loadmyQuote();
  },
  data() {
    return {
      myQuote: [],
      quoteItems: [],
      item_total: 0.0,
      final_total: 0.0,
      isExpired: false,
      show: true,
      country: [],
      states: [],
      checkboxes: [],

      fields: [
        // {
        //   key: "id",
        //   label: "Product Id",
        //   thClass: "tbheader",
        //   tdClass: "tbColumn",
        // },
        {
          key: "firstname",
          label: "Name",
          thClass: "tbheader new10",
          tdClass: "tbColumn bluebg new10",
        },
        // {
        //     key: "type",
        //     label: "Type",
        //     thClass: "tbheader",
        //     tdClass: "tbColumn"
        // },

        // {
        //     key: "head_style",
        //     label: "Head Style",
        //     thClass: "tbheader",
        //     tdClass: "tbColumn"
        // },
        // {
        //     key: "material",
        //     label: "Material",
        //     thClass: "tbheader",
        //     tdClass: "tbColumn"
        // },
        // {
        //     key: "finish",
        //     label: "Finish",
        //     thClass: "tbheader",
        //     tdClass: "tbColumn"
        // },
        // {
        //     key: "dia",
        //     label: "Diameter",
        //     thClass: "tbheader",
        //     tdClass: "tbColumn"
        // },
        // {
        //     key: "length",
        //     label: "Length",
        //     thClass: "tbheader",
        //     tdClass: "tbColumn"
        // },
        {
          key: "additional_notes",
          label: "Notes",
          thClass: "tbheader new20",
          tdClass: "tbColumn new20",
        },
        {
          key: "admin_notes",
          label: "Admin Notes",
          thClass: "tbheader new20",
          tdClass: "tbColumn new20",
        },
        {
          key: "uom",
          label: "UOM",
          thClass: "tbheader uom",
          tdClass: "tbColumn uom",
        },
        {
          key: "item_quantity",
          label: "Quantity",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "price",
          label: "Unit Price",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },

        {
          key: "addtoquote",
          label: "Sub Total",
          thClass: "tbheaderAction",
          tdClass: "tbColumn",
        },
        // {
        //     key: "action",
        //     label: "Action",
        //     thClass: "tbheaderAction",
        //     tdClass: "tbColumn"
        // }
      ],
      quickfields: [
        {
          key: "partnumber",
          label: "Part Number",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "manufacturer",
          label: "Manufacturer",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "material",
          label: "Material",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "targetprice",
          label: "TARGET PRICE EA",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "needby",
          label: "Need By",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        // {
        //   key: "additional_notes",
        //   label: "Notes",
        //   thClass: "tbheader",
        //   tdClass: "tbColumn",
        // },
        {
          key: "admin_notes",
          label: "Admin Notes",
          thClass: "tbheader fixWidth",
          tdClass: "tbColumn fixWidth",
        },
        {
          key: "quantity",
          label: "Quantity",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "price",
          label: "Unit Price",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },

        {
          key: "addtoquote",
          label: "Sub Total",
          thClass: "tbheaderAction",
          tdClass: "tbColumn",
        },
        // {
        //     key: "action",
        //     label: "Action",
        //     thClass: "tbheaderAction",
        //     tdClass: "tbColumn"
        // }
      ],
    };
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    submitReQuote() {
      console.log("yes");
      this.$store
        .dispatch(
          "quoteReSubmit",
          this.quoteItems.map((d) => {
            return {
              user_id: this.myQuote[0].user_id,
              product_id: d.id,
              quantity: d.item_quantity,
              uom: d.uom,
              input_mfr: d.manufacturer || "none",
              input_cert: d.certificate || "none",
              QtyAvail: d.QtyAvail != null ? d.QtyAvail : d.onhand,
            };
          })
        )
        .then(() => {
          this.$notify({
            text: "Quote item added successfully",
            type: "success",
          });
          this.$router.push("/store/quote-list");
        })
        .catch((error) => {
          this.$notify({
            text: "Something went wrong!",
            type: "error",
          });
        });
    },
    deleteMyQuote(prod_id) {
      if (confirm("Are you sure to delete this quote!")) {
        this.$store
          .dispatch("myQuoteDelete", {
            product_id: this.$route.params.quote_id,
          })
          .then((myQuote) => {
            this.$notify({
              text: "quote deleted successfully",
              type: "success",
            });
            if (myQuote.data.success) {
              this.$router.push("/store/myquotes");
            }
          })
          .catch((error) => {
            if (this.form.hasErrors()) {
              this.$notify({
                text: "There is some validation error. Please correct and try again!",
                type: "error",
              });
            } else {
              this.$notify({
                text: "Something went wrong!",
                type: "error",
              });
            }
          });
      }
    },
    rowClass(item, type) {
      return "tbRows";
    },
    loadmyQuote() {
      this.$store
        .dispatch("viewQuote", {
          quote_id: this.$route.params.quote_id,
        })
        .then((response) => {
          this.myQuote = response.data.success.quote;
          if (response.data.success.quote.length)
            this.isExpired =
              moment().diff(
                moment(
                  response.data.success.quote[0].updated_at,
                  "YYYY-MM-DD hh:mm:ss"
                ),
                "days"
              ) > 30;
          this.quoteItems = response.data.success.items;
          this.item_total = response.data.success.item_total;
          this.final_total = response.data.success.total;
        });
    },

    gotosinglePage(productid) {
      this.$router.push({ path: "/store/product/" + productid });
    },
    gotoListpage() {
      this.$router.push("/store/myquotes");
    },
  },
};
</script>
<style>
.page--myquote .quotebtn {
  position: relative;
  float: right;
  background: none;
}
.page--myquote .quotebtn .addquote {
  color: #000 !important;
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 13px;
}
.productlisttable .table .tbColumn img.tickimg {
  display: none;
}
.page--myquote .quotebtn .addquote:hover {
  background-color: #fff !important;
  text-decoration: underline;
  font-size: 13px;
}
.qtyfield {
  width: 80px;
}
.emptyquote {
  padding: 15px;
  color: #f2612c;
}
.loginform.w-form {
  background: #fff;
  padding: 30px;
}
.page--myquote .haserror input.vs__search {
  border-color: #fff;
}
.page--myquote .samediv span {
  float: left;
  font-size: 18px;
  border: 1px solid #d2d2d2;
  margin: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 8px;
  font-weight: 500;
  color: #303030;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
.page--myquote .samediv span.lastspan {
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 39px;
}
.page--myquote .samediv input {
  float: left;
  max-width: 45px;
  font-size: 14px;
  height: 35px;
  text-align: center;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  border-left: 0;
  border-right: 0;
  font-weight: 500;
  color: #303030;
  padding-top: 6px;
}
.page--myquote a.specsheet:hover {
  background: #efefef;
  color: #000;
}
.page--myquote a.specsheet {
  background: #efefef;
  color: #000;
  text-decoration: underline;
}
.no-word-overflow {
  overflow: visible !important;
  white-space: normal !important;
  text-overflow: unset !important;
}
.uom {
  width: 75px !important;
}
.new20 {
  width: 20% !important;
}
.new10 {
  width: 10% !important;
}
.bluebg {
  background-color: #253c8a !important;
}
.fixWidth {
  width: 240px;
}
.checkout-process-completed {
  font-weight: bold;
  color: #f2612c;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
</style>
