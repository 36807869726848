export default function(to, from, next) {
    if (to.matched.some(record => record.meta.verify)) {
        const token = to.params.token;
        axios
            .post("/api/users/verify", { token })
            .then(response => {
                if (response.data.registered === true) {
                    next({
                        path: "/store/login",
                        params: { nextUrl: to.fullPath }
                    });
                } else {
                    next({
                        path: "/store/register",
                        params: { nextUrl: to.fullPath }
                    });
                }
            })
            .catch(error => {
                next();
                if (!error.response || error.response.status !== 418) {
                    throw new Error(error);
                }
            });
        return next({
            path: "/store/login",
            params: { nextUrl: to.fullPath }
        });
    }
    next();
}
