<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap">
      <div class="container-noot-relative">
        <div class="account-wrapper-not-relative">
          <div class="div-block-12">
            <h4 class="heading">Shopping Cart</h4>
            <img
              src="/images/Asset-1.svg"
              width="35"
              height="35"
              alt=""
              class="image"
            />
          </div>
          <div class="account-container">
            <h4>Requirements &amp; Preferences</h4>
            <div class="_35"></div>
            <div class="requirements-container">
              <div class="account-column">
                <div>
                  <h6 class="margin-0">Off-Contract Line Minimum<br /></h6>
                  <h4>$25.00</h4>
                  <div class="_14"></div>
                </div>
              </div>
              <div class="_35"></div>
              <div class="account-column">
                <div>
                  <h6 class="margin-0">Order Minimum<br /></h6>
                  <h4>$50.0 0</h4>
                </div>
              </div>
            </div>
            <div class="account-column">
              <a href="#" class="link-2">Show Internal References</a>
              <div class="_14"></div>
              <a href="#" class="link-2">Switch to Advanced Shipping</a>
            </div>
            <div class="_14"></div>
            <div class="table">
              <div class="w-layout-grid grid-copy">
                <div class="subhead-cell">Part Number</div>
                <div class="subhead-cell">Description</div>
                <div class="subhead-cell">Manufacturer</div>
                <div class="subhead-cell">Revision</div>
                <div class="subhead-cell">Shelf Life</div>
                <div class="subhead-cell">Certs</div>
                <div id="w-node-c4ecacc65fab-f3bfcc12" class="subhead-cell">
                  Actions
                </div>
                <div class="subhead-cell">Qty</div>
                <div class="subhead-cell">Unit of Measure</div>
                <div id="w-node-c4ecacc65fb1-f3bfcc12" class="subhead-cell">
                  Unit Price
                </div>
                <div class="subhead-cell">Subtotal</div>
                <div class="cell-text-copy">-</div>
                <div id="w-node-c4ecacc65fb7-f3bfcc12" class="cell-name-table">
                  5305-01-280-5773
                </div>
                <div class="cell-text-copy">BOLT</div>
                <div class="cell-text-copy">BOLT</div>
                <div class="cell-text-copy">BOLT</div>
                <div class="cell-text-copy">-</div>
                <div class="cell-text-copy">-</div>
                <div class="cell-text-copy">207</div>
                <div class="cell-text-copy">EA</div>
                <div class="cell-text-copy">$3.3000</div>
                <div class="cell-text-copy">$3.3000</div>
                <div class="cell-text-copy">-</div>
                <div class="cell-name-table">5305-01-280-5773</div>
                <div class="cell-text-copy">BOLT</div>
                <div class="cell-text-copy">BOLT</div>
                <div class="cell-text-copy">BOLT</div>
                <div class="cell-text-copy">-</div>
                <div class="cell-text-copy">-</div>
                <div class="cell-text-copy">207</div>
                <div class="cell-text-copy">EA</div>
                <div class="cell-text-copy">$3.3000</div>
                <div class="cell-text-copy">$3.3000</div>
                <div class="cell-text-copy">-</div>
                <div class="cell-name-table">5305-01-280-5773</div>
                <div class="cell-text-copy">BOLT</div>
                <div class="cell-text-copy">BOLT</div>
                <div class="cell-text-copy">BOLT</div>
                <div class="cell-text-copy">-</div>
                <div class="cell-text-copy">-</div>
                <div class="cell-text-copy">207</div>
                <div class="cell-text-copy">EA</div>
                <div class="cell-text-copy">$3.3000</div>
                <div class="cell-text-copy">$3.3000</div>
              </div>
            </div>
            <div class="_14"></div>
            <p class="caption">
              Your order may be subject to applicable sales tax, VAT, and
              freight charges.<br />
            </p>
          </div>
          <div class="hr"></div>
          <div>
            <div class="account-container">
              <h4>Shipping Address</h4>
              <div class="_35"></div>
              <div class="shipping-address-container-1">
                <div
                  data-duration-in="300"
                  data-duration-out="100"
                  class="tabs w-tabs"
                >
                  <div class="w-tab-menu" role="tablist">
                    <a
                      data-w-tab="Tab 1"
                      class="tab w-inline-block w-tab-link w--current"
                      id="w-tabs-0-data-w-tab-0"
                      href="#w-tabs-0-data-w-pane-0"
                      role="tab"
                      aria-controls="w-tabs-0-data-w-pane-0"
                      aria-selected="true"
                    >
                      <div>Ship to Address</div>
                      <img
                        src="/images/Asset-1.svg"
                        width="28"
                        height="28"
                        alt=""
                        class="image-10"
                    /></a>
                    <a
                      data-w-tab="Tab 2"
                      class="tab w-inline-block w-tab-link"
                      tabindex="-1"
                      id="w-tabs-0-data-w-tab-1"
                      href="#w-tabs-0-data-w-pane-1"
                      role="tab"
                      aria-controls="w-tabs-0-data-w-pane-1"
                      aria-selected="false"
                    >
                      <div>Ultimate Destination Addresses</div>
                      <img
                        src="/images/Asset-1.svg"
                        width="28"
                        height="28"
                        alt=""
                        class="image-10"
                    /></a>
                  </div>
                  <div class="tabs-content w-tab-content">
                    <div
                      data-w-tab="Tab 1"
                      class="w-tab-pane w--tab-active"
                      id="w-tabs-0-data-w-pane-0"
                      role="tabpanel"
                      aria-labelledby="w-tabs-0-data-w-tab-0"
                    >
                      <div class="div-block-70">
                        <div class="search-address">
                          <a href="browse.html" class="w-inline-block">
                            <div class="html-embed w-embed">
                              <title></title>

                              <form
                                class="form"
                                data-name="Email Form 2"
                                id="email-form-2"
                                name="email-form-2"
                              >
                                <input
                                  class="search-input-vue w-input"
                                  style="font-size: 16px"
                                  data-name="Search Addresses"
                                  maxlength="256"
                                  name="Enter-keyword-item-model-or-part-number"
                                  placeholder="Search Addresses"
                                  type="text"
                                /><input
                                  class="search-button h6 styling w-button"
                                  data-wait="Please wait..."
                                  type="submit"
                                  value="Search"
                                />
                              </form>
                            </div>
                          </a>
                        </div>
                        <div class="_14"></div>
                        <div class="hr"></div>
                        <div class="_14"></div>
                        <div class="div-block-71">
                          <div class="div-block-72">
                            <div class="address-container">
                              <img
                                src="/images/person_pin_circle-24px.svg"
                                width="35"
                                height="35"
                                alt=""
                              />
                              <h5 class="margin-0">121 Toledo St</h5>
                              <p class="margin-0">
                                Rapid Rivet &amp; Fastener Corp, 121 Toledo St
                              </p>
                              <p>Farmingdale, NY, 11735, USA</p>
                            </div>
                            <div class="_14"></div>
                            <div class="div-block-73">
                              <div class="address-checkbox"></div>
                              <p class="caption margin-0">
                                Set as Ship To Default
                              </p>
                            </div>
                          </div>
                          <div class="_14"></div>
                          <div class="div-block-72">
                            <div class="address-container">
                              <img
                                src="/images/person_pin_circle-24px.svg"
                                width="35"
                                height="35"
                                alt=""
                              />
                              <h5 class="margin-0">121 Toledo St</h5>
                              <p class="margin-0">
                                Rapid Rivet &amp; Fastener Corp, 121 Toledo St
                              </p>
                              <p>Farmingdale, NY, 11735, USA</p>
                            </div>
                            <div class="_14"></div>
                            <div class="div-block-73">
                              <div class="address-checkbox"></div>
                              <p class="caption margin-0">
                                Set as Ship To Default
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-w-tab="Tab 2"
                      class="w-tab-pane"
                      id="w-tabs-0-data-w-pane-1"
                      role="tabpanel"
                      aria-labelledby="w-tabs-0-data-w-tab-1"
                    >
                      <div class="div-block-70">
                        <div class="search-address">
                          <a href="browse.html" class="w-inline-block">
                            <div class="html-embed w-embed">
                              <title></title>

                              <form
                                class="form"
                                data-name="Email Form 2"
                                id="email-form-2"
                                name="email-form-2"
                              >
                                <input
                                  class="search-input-vue w-input"
                                  style="font-size: 16px"
                                  data-name="Search Addresses"
                                  maxlength="256"
                                  name="Enter-keyword-item-model-or-part-number"
                                  placeholder="Search Addresses"
                                  type="text"
                                /><input
                                  class="search-button h6 styling w-button"
                                  data-wait="Please wait..."
                                  type="submit"
                                  value="Search"
                                />
                              </form>
                            </div>
                          </a>
                        </div>
                        <div class="_14"></div>
                        <div class="hr"></div>
                        <div class="_14"></div>
                        <div class="div-block-71">
                          <div class="div-block-72">
                            <div class="address-container">
                              <img
                                src="/images/person_pin_circle-24px.svg"
                                width="35"
                                height="35"
                                alt=""
                              />
                              <h5 class="margin-0">121 Toledo St</h5>
                              <p class="margin-0">
                                Rapid Rivet &amp; Fastener Corp, 121 Toledo St
                              </p>
                              <p>Farmingdale, NY, 11735, USA</p>
                            </div>
                            <div class="_14"></div>
                            <div class="div-block-73">
                              <div class="address-checkbox"></div>
                              <p class="caption margin-0">
                                Set as Ship To Default
                              </p>
                            </div>
                          </div>
                          <div class="_14"></div>
                          <div class="div-block-72">
                            <div class="address-container">
                              <img
                                src="/images/person_pin_circle-24px.svg"
                                width="35"
                                height="35"
                                alt=""
                              />
                              <h5 class="margin-0">121 Toledo St</h5>
                              <p class="margin-0">
                                Rapid Rivet &amp; Fastener Corp, 121 Toledo St
                              </p>
                              <p>Farmingdale, NY, 11735, USA</p>
                            </div>
                            <div class="_14"></div>
                            <div class="div-block-73">
                              <div class="address-checkbox"></div>
                              <p class="caption margin-0">
                                Set as Ship To Default
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hr"></div>
          <div class="account-container">
            <div class="right-align">
              <div class="div-block-75">
                <p class="margin-0">Subtotal<br /></p>
              </div>
              <div class="div-block-75-copy">
                <p class="margin-0">$12,824.00<br /></p>
              </div>
            </div>
            <div class="right-align">
              <div class="div-block-75">
                <p class="margin-0">TOTAL (1 ITEM )<br /></p>
              </div>
              <div class="div-block-75-copy">
                <p class="margin-0">$12,824.00<br /></p>
              </div>
            </div>
            <div class="_14"></div>
            <div class="right-align">
              <div class="div-block-73">
                <div class="address-checkbox"></div>
                <p class="margin-0">
                  I accept Boeing Distribution’s terms &amp; conditions.
                </p>
              </div>
            </div>
            <div class="_14"></div>
            <div class="right-align">
              <div class="div-block-73">
                <div class="address-checkbox"></div>
                <p class="margin-0">
                  My Ultimate Destination is correct.<br />
                </p>
              </div>
            </div>
            <div class="_14"></div>
            <div class="hr"></div>
            <div class="_14"></div>
            <div class="div-block-76">
              <div class="div-block-77">
                <a href="account.html" class="button secondary w-inline-block">
                  <div>Empty Cart</div>
                </a>
              </div>
              <div class="div-block-78">
                <a href="account.html" class="button secondary w-inline-block">
                  <div>Save as Quote</div>
                </a>
                <div class="_14"></div>
                <a
                  href="cart.html"
                  aria-current="page"
                  class="button cc-contact-us w-inline-block w--current"
                >
                  <div>Purchase</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="div-block-54">
          <img
            src="/images/john-torcasio-DXa_QaVmM2A-unsplash-4dffa8a5-p-1080_1.jpeg"
            srcset="
              /images/john-torcasio-DXa_QaVmM2A-unsplash-4dffa8a5-p-1080_1-p-800.jpeg  800w,
              /images/john-torcasio-DXa_QaVmM2A-unsplash-4dffa8a5-p-1080_1.jpeg       1080w
            "
            sizes="(max-width: 991px) 100vw, (max-width: 1279px) 954.7791748046875px, 75vw"
            alt=""
            class="image-17"
          />
        </div>
      </div>
    </div>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="quick-list-rename">
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Quick List</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt=""
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <p>Please provide your contact information</p>
              <div class="w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                >
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="name">First name *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50">
                      <label for="name">Last name *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="name">Company name *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50">
                      <label for="name">Type of industry *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="name">Phone *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50">
                      <label for="name">EMail *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="name">How did you find us?</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50">
                      <label for="name">Country *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                  </div>
                  <div class="_35"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="name">City</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50">
                      <label for="name">Address</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="name">State / Province</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50">
                      <label for="name">ZIP / Postal Code</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                  </div>
                  <div class="_35"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="name">Note *</label
                      ><textarea
                        name="field"
                        maxlength="5000"
                        id="field"
                        class="w-input"
                      ></textarea>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-50">
                      <div
                        data-sitekey="6LdoywEVAAAAAI3YaQRr8jQvIUcjS854I-Vin_Iu"
                        class="w-form-formrecaptcha g-recaptcha g-recaptcha-error g-recaptcha-disabled"
                      >
                        <div style="width: 304px; height: 78px">
                          <div>
                            <iframe
                              src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LdoywEVAAAAAI3YaQRr8jQvIUcjS854I-Vin_Iu&amp;co=ZmlsZTo.&amp;hl=en&amp;v=oqtdXEs9TE9ZUAIhXNz5JBt_&amp;size=normal&amp;cb=ss90h6mytnks"
                              width="304"
                              height="78"
                              role="presentation"
                              name="a-12o0yubaucd6"
                              frameborder="0"
                              scrolling="no"
                              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
                            ></iframe>
                          </div>
                          <textarea
                            id="g-recaptcha-response"
                            name="g-recaptcha-response"
                            class="g-recaptcha-response"
                            style="
                              width: 250px;
                              height: 40px;
                              border: 1px solid rgb(193, 193, 193);
                              margin: 10px 25px;
                              padding: 0px;
                              resize: none;
                              display: none;
                            "
                          ></textarea>
                        </div>
                        <iframe style="display: none"></iframe>
                      </div>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <input
                    type="submit"
                    value="Request a Quote"
                    data-wait="Please wait..."
                    class="button w-button"
                  />
                </form>
                <div class="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="div-block-54-copy">
            <img
              src="/images/nils-rasmusson-gf2tGkp_GbI-unsplash-402ce28e-1-p-1080.jpeg"
              srcset="
                /images/nils-rasmusson-gf2tGkp_GbI-unsplash-402ce28e-1-p-1080-p-500.jpeg  500w,
                /images/nils-rasmusson-gf2tGkp_GbI-unsplash-402ce28e-1-p-1080.jpeg       1080w
              "
              sizes="(max-width: 991px) 100vw, (max-width: 1279px) 954.7791748046875px, 75vw"
              alt=""
              class="image-17"
            />
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
export default {
  components: {
    CommonHead,
    CommonFoot,
  },
};
</script>
<style>
</style>
