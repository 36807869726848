<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div
            data-delay="4000"
            data-animation="slide"
            data-autoplay="1"
            data-duration="500"
            data-infinite="1"
            class="slider-2 contslider w-slider"
          >
            <div class="w-slider-mask">
              <div class="slide w-slide">
                <div class="div-block-20 contactblock">
                  <div>
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt
                      class="image"
                    />
                  </div>
                  <h5>Benny</h5>
                  <p>
                    Needed to know how many each were in a pound of rivets. Mr.
                    Al Felice, responded the next day, great customer service.
                  </p>
                </div>
              </div>
              <div class="slide-2 w-slide">
                <div class="div-block-20">
                  <div>
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt
                      class="image"
                    />
                  </div>
                  <h5>Katy</h5>
                  <p>
                    I appreciate the quick responses when I send over an e-mail.
                    Nothing is more frustrating than vendors who take a week to
                    get back to you. Any time I have contacted anyone at Rapid
                    Rivet, they have done a nice job responding to my request
                    and any questions I might have. Thank you!
                  </p>
                </div>
              </div>
              <div class="slide-3 w-slide">
                <div class="div-block-20">
                  <div>
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt
                      class="image"
                    />
                  </div>
                  <h5>Stephanie</h5>
                  <p>
                    Kevin is ALWAYS on point! He is courteous and really knows
                    his products! I’ve never had a problem with product or
                    shipping, the Rapid Rivet team is wonderful!
                  </p>
                </div>
              </div>
              <div class="slide-4 w-slide">
                <div class="div-block-20">
                  <div>
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img src="/images/Star.svg" width="21" height="21" alt />
                    <img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt
                      class="image"
                    />
                  </div>
                  <h5>Diane</h5>
                  <p>
                    I have been dealing with Rapid Rivet for many years. They
                    have always been prompt, efficient and courteous.
                  </p>
                </div>
              </div>
            </div>
            <div class="left-arrow-3 w-slider-arrow-left">
              <div class="w-icon-slider-left"></div>
            </div>
            <div class="right-arrow-3 w-slider-arrow-right">
              <div class="w-icon-slider-right"></div>
            </div>
            <div class="w-slider-nav w-round"></div>
          </div>
          <div class="div-block-54-copy loginblock">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h1 class="product_heading heading">Login</h1>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <div class="loginform w-form">
                <b-form class="commonform w-clearfix" @submit="onSubmit">
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :state="!form.errors.has('email')"
                      :class="
                        form.errors.get('email')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :invalid-feedback="form.errors.get('email')"
                    >
                      <label for="name" class="field-label-24">Email*</label>
                      <input
                        type="text"
                        class="w-input"
                        autofocus="true"
                        maxlength="256"
                        v-model="form.email"
                        :state="!form.errors.has('email')"
                        @input="form.errors.clear('email')"
                        data-name="Name 3"
                        id="email"
                      />
                      <span class="error-msg" v-if="form.errors.get('email')">{{
                        form.errors.get("email")
                      }}</span>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      :class="
                        form.errors.get('password')
                          ? 'haserror div-block-49'
                          : 'div-block-49'
                      "
                      :state="!form.errors.has('password')"
                      :invalid-feedback="form.errors.get('password')"
                    >
                      <label for="name" class="field-label-25"
                        >Password *</label
                      >
                      <input
                        type="password"
                        class="w-input"
                        autofocus="true"
                        maxlength="256"
                        data-name="Name 3"
                        id="password"
                        v-model="form.password"
                        :state="!form.errors.has('password')"
                        @input="form.errors.clear('password')"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('password')"
                        >{{ form.errors.get("password") }}</span
                      >
                    </div>
                  </div>
                  <div class="div-block-48 rememberme">
                    <b-button
                      type="submit"
                      class="button w-button"
                      block
                      variant="dark"
                      >Sign In</b-button
                    >
                    <div class="div-block-49">
                      <a href="/store/forgotpassword" class="link-9"
                        >Forgot Password?</a
                      >
                    </div>
                  </div>
                </b-form>
              </div>
              <div class="newregister">
                <a href="/store/generate-password" class="register"
                  >Placed an order with us?</a
                >
              </div>
              <div class="newregister">
                <div>Don't have an account?</div>
                <a href="/store/register" class="register">Create one</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import AuthMixin from "../../mixins/auth";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";

export default {
  components: {
    CommonHead,
    CommonFoot,
  },
  mixins: [AuthMixin],
  data() {
    return {
      form: new Form({
        email: "",
        password: "",
        name: "",
        nameState: null,
        remember: false,
      }),
      checkboxes: [],
      form1: new Form({
        email: "",
      }),
      isLoading: false,
      successMessage: "",
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.login();
      //alert(JSON.stringify(this.form));
    },
    login() {
      this.isLoading = true;
      this.$store
        .dispatch("login", this.form)
        .then((response) => {
          if (this.$route.query.via_product) {
            window.location = `/store/product/${this.$route.query.via_product}`;
            this.isLoading = false;
            return;
          }
          if (response.data.data.is_redirect == "thankyou") {
            this.$router.push("/store/quote/thankyou");
          } else {
            //console.log(document.referrer);
            //this.$router.go(-1);
            this.redirectAfterLogin("");
          }

          this.isLoading = false;
        })
        .catch((error) => {
          /* if (this.form.hasErrors()) {
                        this.$notify({
                            text: "These credentials do not match our records.",
                            type: "error"
                        });
                    } else {
                        this.$notify({
                            text: "Something went wrong!",
                            type: "error"
                        });
                    }*/
          this.isLoading = false;
        });
    },
    forgotPassword() {
      // evt.preventDefault();
      this.isLoading = true;
      this.$store.dispatch("forgotPassword", this.form1).then((response) => {
        if (response.data.success) {
          /*this.$notify({
                            text: response.data.message,
                            type: "success"
                        });*/
          $(".reset_button").text("Resend");
          $(".reset_title").text("Didn’t get the message?");

          this.successMessage =
            "we have sent a recovery email to " +
            this.form1.email +
            ". Check your inbox and follow the link to verify your address.";
          // this.form1 = new Form({
          //   email: null
          // });
        } else {
          //   this.$notify({
          //     text: response.data.message,
          //     type: "error",
          //   });
        }
      });
      this.isLoading = false;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;

      this.form1.email = "";
      this.successMessage = "";
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.forgotPassword();
    },
  },
};
</script>
<style>
.commonform .haserror .w-input,
.commonform .haserror .md-textarea,
.commonform .haserror .vs__dropdown-toggle {
  border: 1px solid red;
}
span.error-msg {
  color: red !important;
  margin-top: 5px;
  display: inline-block;
}
</style>