<template>
    <div class="myaccount">
        <CommonHead></CommonHead>
        <div class="section cc-store-home-wrap loginpage">
            <div class="container">
                <div class="contact-container logincontainer">
                    <div
                        data-delay="4000"
                        data-animation="slide"
                        data-autoplay="1"
                        data-duration="500"
                        data-infinite="1"
                        class="slider-2 contslider w-slider"
                    >
                        <div class="w-slider-mask">
                            <div class="slide w-slide">
                                <div class="div-block-20 contactblock">
                                    <div>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img
                                            src="/images/Dark-traignle.svg"
                                            width="35"
                                            height="35"
                                            alt
                                            class="image"
                                        >
                                    </div>
                                    <h5>Benny</h5>
                                    <p>Needed to know how many each were in a pound of rivets. Mr. Al Felice, responded the next day, great customer service.</p>
                                </div>
                            </div>
                            <div class="slide-2 w-slide">
                                <div class="div-block-20">
                                    <div>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img
                                            src="/images/Dark-traignle.svg"
                                            width="35"
                                            height="35"
                                            alt
                                            class="image"
                                        >
                                    </div>
                                    <h5>Katy</h5>
                                    <p>I appreciate the quick responses when I send over an e-mail. Nothing is more frustrating than vendors who take a week to get back to you. Any time I have contacted anyone at Rapid Rivet, they have done a nice job responding to my request and any questions I might have. Thank you!</p>
                                </div>
                            </div>
                            <div class="slide-3 w-slide">
                                <div class="div-block-20">
                                    <div>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img
                                            src="/images/Dark-traignle.svg"
                                            width="35"
                                            height="35"
                                            alt
                                            class="image"
                                        >
                                    </div>
                                    <h5>Stephanie</h5>
                                    <p>Kevin is ALWAYS on point! He is courteous and really knows his products! I’ve never had a problem with product or shipping, the Rapid Rivet team is wonderful!</p>
                                </div>
                            </div>
                            <div class="slide-4 w-slide">
                                <div class="div-block-20">
                                    <div>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img src="/images/Star.svg" width="21" height="21" alt>
                                        <img
                                            src="/images/Dark-traignle.svg"
                                            width="35"
                                            height="35"
                                            alt
                                            class="image"
                                        >
                                    </div>
                                    <h5>Diane</h5>
                                    <p>I have been dealing with Rapid Rivet for many years. They have always been prompt, efficient and courteous.</p>
                                </div>
                            </div>
                        </div>
                        <div class="left-arrow-3 w-slider-arrow-left">
                            <div class="w-icon-slider-left"></div>
                        </div>
                        <div class="right-arrow-3 w-slider-arrow-right">
                            <div class="w-icon-slider-right"></div>
                        </div>
                        <div class="w-slider-nav w-round"></div>
                    </div>
                    <div class="div-block-54-copy loginblock addressmob">
                        <img
                            src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
                            alt
                            class="image-17"
                        >
                    </div>
                    <div class="div-block-10">
                        <div class="div-block-12">
                            <h4 class="heading">Change Email Address &amp; Password</h4>
                            <img src="/images/Asset-1.svg" width="35" height="35" alt class="image">
                        </div>
                        <div class="home-section-wrap">
                            <b-form class="commonform" @submit="onSubmit">
                                <div class="_14"></div>
                                <div class="div-block-48">
                                    <div
                                        :state="!resetform.errors.has('email')"
                                        :class="resetform.errors.get('email')?'haserror div-block-49':'div-block-49'"
                                        :invalid-feedback="resetform.errors.get('email')"
                                    >
                                        <label for="name" class="field-label-24">Email*</label>
                                        <input
                                            type="text"
                                            class="w-input"
                                            autofocus="true"
                                            maxlength="256"
                                            v-model="resetform.email"
                                            :state="!resetform.errors.has('email')"
                                            @input="resetform.errors.clear('email')"
                                            data-name="Name 3"
                                            id="email"
                                            required
                                            readonly
                                        >
                                        <span
                                            class="error-msg"
                                            v-if="resetform.errors.get('email')"
                                        >{{resetform.errors.get('email')}}</span>
                                    </div>
                                </div>
                                <div class="_14"></div>
                                <div class="div-block-48">
                                    <div
                                        :class="resetform.errors.get('old_password')?'haserror div-block-49':'div-block-49'"
                                        :state="!resetform.errors.has('old_password')"
                                        :invalid-feedback="resetform.errors.get('old_password')"
                                    >
                                        <label for="name" class="field-label-25">Password *</label>
                                        <input
                                            type="password"
                                            class="w-input"
                                            autofocus="true"
                                            maxlength="256"
                                            data-name="Name 3"
                                            id="old_password"
                                            v-model="resetform.old_password"
                                            :state="!resetform.errors.has('old_password')"
                                            @input="resetform.errors.clear('old_password')"
                                            required
                                        >
                                        <span
                                            class="error-msg"
                                            v-if="resetform.errors.get('old_password')"
                                        >{{resetform.errors.get('old_password')}}</span>
                                    </div>
                                </div>
                                <div class="_14"></div>
                                <div class="div-block-48">
                                    <div
                                        :class="resetform.errors.get('password')?'haserror div-block-49':'div-block-49'"
                                        :state="!resetform.errors.has('password')"
                                        :invalid-feedback="resetform.errors.get('password')"
                                    >
                                        <label for="name" class="field-label-25">New Password *</label>
                                        <input
                                            type="password"
                                            class="w-input"
                                            autofocus="true"
                                            maxlength="256"
                                            data-name="Name 3"
                                            id="password"
                                            v-model="resetform.password"
                                            :state="!resetform.errors.has('password')"
                                            @input="resetform.errors.clear('password')"
                                            required
                                        >
                                        <span
                                            class="error-msg"
                                            v-if="resetform.errors.get('password')"
                                        >{{resetform.errors.get('password')}}</span>
                                    </div>
                                </div>
                                <div class="_14"></div>
                                <div class="div-block-48">
                                    <div
                                        :class="resetform.errors.get('confirm_password')?'haserror div-block-49':'div-block-49'"
                                        :state="!resetform.errors.has('confirm_password')"
                                        :invalid-feedback="resetform.errors.get('confirm_password')"
                                    >
                                        <label for="name" class="field-label-25">Confirm Password *</label>
                                        <input
                                            type="password"
                                            class="w-input"
                                            autofocus="true"
                                            maxlength="256"
                                            data-name="Name 3"
                                            id="confirm_password"
                                            v-model="resetform.confirm_password"
                                            :state="!resetform.errors.has('confirm_password')"
                                            @input="resetform.errors.clear('confirm_password')"
                                            required
                                        >
                                        <span
                                            class="error-msg"
                                            v-if="resetform.errors.get('confirm_password')"
                                        >{{resetform.errors.get('confirm_password')}}</span>
                                    </div>
                                </div>

                                <div class="_14"></div>
                                <div class="div-block-93 savegoback w-clearfix">
                                    <input
                                        type="submit"
                                        value="Save Changes"
                                        data-wait="Please wait..."
                                        id="loginform"
                                        class="button savechanges w-button"
                                    >
                                    <a href="/store/account" class="button-2 w-button">Go Back</a>
                                </div>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CommonFoot></CommonFoot>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import ResourceMixin from "../../mixins/resources";

export default {
    components: {
        CommonHead,
        CommonFoot
    },
    mixins: [ResourceMixin],
    mounted() {
        this.resetform.email = this.user.email;
    },
    data() {
        return {
            isLoading: false,
            resetform: new Form({
                old_password: null,
                email: null,
                password: null,
                confirm_password: null
            })
        };
    },
    computed: {
        ...mapGetters(["user"])
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault();
            this.updatePassword();
            //alert(JSON.stringify(this.form));
        },
        updatePassword() {
            this.isLoading = true;
            this.$store
                .dispatch("resetPassword", this.resetform)
                .then(response => {
                    if (response.data.success) {
                        this.$notify({
                            text: response.data.message,
                            type: "success"
                        });
                        this.resetform = new Form({
                            old_password: null,
                            password: null,
                            email: this.user.email,
                            confirm_password: null
                        });
                    } else {
                        this.$notify({
                            text: response.data.message,
                            type: "error"
                        });
                    }
                });
            this.isLoading = false;
        }
    }
};
</script>
<style>
</style>