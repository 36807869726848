<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap loginpage">
      <div class="container">
        <div class="contact-container logincontainer">
          <div class="div-block-54-copy loginblock">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt
              class="image-17"
            />
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Welcome to Rapidrivet.</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <div class="loginform w-form" v-if="this.successMessage">
                <span style="color: red">{{ this.successMessage }}</span>
              </div>
              <div class="loginform w-form" v-else>
                <span>Please wait...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import AuthMixin from "../../mixins/auth";
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";

export default {
  components: {
    CommonHead,
    CommonFoot,
  },
  mixins: [AuthMixin],

  data() {
    return {
      isLoading: true,
      successMessage: "",
    };
  },
  mounted() {
    this.verifyAccount();
  },
  methods: {
    verifyAccount() {
      // evt.preventDefault();
      this.isLoading = true;
      this.$store
        .dispatch("verifyAccount", this.$route.params.token)
        .then((response) => {
          if (response.data.success) {
            this.isLoading = true;
            // this.$notify({
            //     text: "your account verified successfully.",
            //     type: "success"
            // });

            this.$router.push("/store/login");

            this.isLoading = false;
          } else {
            this.successMessage = response.data.message;
            // this.$notify({
            //     text: response.data.message,
            //     type: "error"
            // });
          }
        });
    },
  },
};
</script>
<style>
.commonform .haserror .w-input,
.commonform .haserror .md-textarea,
.commonform .haserror .vs__dropdown-toggle {
  border: 1px solid red;
}
span.error-msg {
  color: red !important;
  margin-top: 5px;
  display: inline-block;
}
</style