var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CommonHead'),_vm._v(" "),_c('div',{staticClass:"section cc-store-home-wrap loginpage"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"contact-container logincontainer"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"div-block-10"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"home-section-wrap"},[_c('b-form',{staticClass:"commonform w-clearfix",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.forgotPassword.apply(null, arguments)}}},[_c('div',{staticClass:"_14"}),_vm._v(" "),_c('div',{staticClass:"div-block-48"},[_c('div',{class:_vm.form.errors.get('password')
                      ? 'haserror div-block-49'
                      : 'div-block-49',attrs:{"state":!_vm.form.errors.has('password'),"invalid-feedback":_vm.form.errors.get('password')}},[_c('label',{staticClass:"field-label-25",attrs:{"for":"name"}},[_vm._v("New Password *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"w-input",attrs:{"type":"password","autofocus":"true","maxlength":"256","data-name":"Name 3","id":"password","state":!_vm.form.errors.has('password')},domProps:{"value":(_vm.form.password)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)},function($event){return _vm.form.errors.clear('password')}]}}),_vm._v(" "),(_vm.form.errors.get('password'))?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.form.errors.get("password")))]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('div',{staticClass:"div-block-48"},[_c('div',{class:_vm.form.errors.get('confirm_password')
                      ? 'haserror div-block-49'
                      : 'div-block-49',attrs:{"state":!_vm.form.errors.has('confirm_password'),"invalid-feedback":_vm.form.errors.get('confirm_password')}},[_c('label',{staticClass:"field-label-25",attrs:{"for":"name"}},[_vm._v("Confirm Password *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirm_password),expression:"form.confirm_password"}],staticClass:"w-input",attrs:{"type":"password","autofocus":"true","maxlength":"256","data-name":"Name 3","id":"confirm_password","state":!_vm.form.errors.has('confirm_password')},domProps:{"value":(_vm.form.confirm_password)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "confirm_password", $event.target.value)},function($event){return _vm.form.errors.clear('confirm_password')}]}}),_vm._v(" "),(_vm.form.errors.get('confirm_password'))?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.form.errors.get("confirm_password")))]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"_14"}),_vm._v(" "),_c('b-button',{staticClass:"button w-button",attrs:{"type":"submit","block":"","variant":"dark"}},[_vm._v("submit")])],1)],1)])])])]),_vm._v(" "),_c('CommonFoot')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-54-copy loginblock 123"},[_c('img',{staticClass:"image-17",attrs:{"src":"/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-12"},[_c('h4',{staticClass:"heading"},[_vm._v("Set New Password")]),_vm._v(" "),_c('img',{staticClass:"image",attrs:{"src":"/images/Asset-1.svg","width":"35","height":"35","alt":""}})])
}]

export { render, staticRenderFns }