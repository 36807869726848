import Vue from "vue";
import moment from "moment";

moment.locale("en");

/**
 * Format the given date.
 */
Vue.filter("date", (value, format) => {
    if (value != "-") {
        if (format === undefined) {
            format = "M/D/YYYY";
        }

        return moment
            .utc(value)
            .local()
            .format(format);
    } else {
        return value;
    }
});

Vue.filter("date2", (value, format) => {
    if (format === undefined) {
        format = "MMM Do, YYYY";
    }

    return moment
        .utc(value)
        .local()
        .format(format);
});

Vue.filter("limit", (value, length) => {
    if (!value) {
        return value;
    } else if (value.length > length) {
        return value.substring(0, length) + "...";
    } else {
        return value;
    }
});

/**
 * Format the given date as a timestamp.
 */
Vue.filter("datetime", (value, format) => {
    if (value != "-") {
        if (format === undefined) {
            format = "MMMM Do, YYYY h:mm A";
        }
    } else {
        return value;
    }

    return moment
        .utc(value)
        .local()
        .format(format);
});

Vue.filter("logindatetime", (value, format) => {
    if (value != "-") {
        if (format === undefined) {
            format = "h:mma M/D/YYYY ";
        }
    } else {
        return value;
    }

    return moment
        .utc(value)
        .local()
        .format(format);
});

/**
 * Converts the given value into a formatted currency output.
 *
 * @param {int} value
 * @param {int} floatLength
 * @param {boolean} showSymbol
 * @return {*}
 */
Vue.filter("currency", (value, floatLength) => {
    value = value / 100;

    if (floatLength === undefined) {
        floatLength = 2;
    }

    value = parseFloat(value);

    if (!isFinite(value) || (!value && value !== 0)) {
        return "";
    }

    let string = Math.abs(value).toFixed(floatLength);
    let _int = floatLength ? string.slice(0, -1 - floatLength) : string;
    let i = _int.length % 3;
    let head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? "," : "") : "";
    let _float = string.slice(-1 - floatLength);
    let sign = value < 0 ? "-" : "";

    if (!parseFloat(_float)) {
        _float = ".00";
    } else {
        _float = parseFloat(_float)
            .toString()
            .substring(1);

        if (_float.length < floatLength) {
            _float += new Array(floatLength - _float.length + 1).join("0");
        } else if (_float.length > 2) {
            while (_float.length > 2) {
                if (_float.slice(-1) === "0") {
                    _float = _float.slice(0, -1);
                } else {
                    break;
                }
            }
        }
    }

    return (
        sign +
        "$" +
        head +
        _int.slice(i).replace(/(\d{3})(?=\d)/g, "$1,") +
        (floatLength > 0 ? _float : "")
    );
});

/**
 * Format the given value to a number. (number_format for PHP).
 *
 * @param {String} value
 * @return {String}
 */
Vue.filter("number", value => {
    if (!value) {
        value = 0;
    }

    return parseInt(value.toString().replace(/\D/g, ""), 10).toLocaleString();
});

/**
 * Format the given date into a relative time.
 */
Vue.filter("relative", (value, locale) => {
    if (locale === undefined) {
        locale = "en-short";
    }

    return moment
        .utc(value)
        .local()
        .locale(locale)
        .fromNow();
});

/**
 * Convert the first character to upper case.
 */
Vue.filter("capitalize", value => {
    if (!value && value !== 0) {
        return "";
    }

    return (
        value
            .toString()
            .charAt(0)
            .toUpperCase() + value.slice(1)
    );
});

/**
 * Pads the left side of the string with the given pad value.
 *
 * @param {String} value
 * @param {Number} width
 * @param {String} pad
 * @return {String}
 */
Vue.filter("lpad", (value, width, pad) => {
    pad = pad || "0";
    value = value + "";

    if (value.length >= width) {
        return value;
    } else {
        return new Array(width - value.length + 1).join(pad) + value;
    }
});

/**
 * Pads the right side of the string with the given pad value.
 *
 * @param {String} value
 * @param {Number} width
 * @param {String} pad
 * @return {String}
 */
Vue.filter("rpad", (value, width, pad) => {
    pad = pad || "0";
    value = value + "";

    if (value.length >= width) {
        return value;
    } else {
        return value + new Array(width - value.length + 1).join(pad);
    }
});

/**
 * Prepends a zero onto the month value if it less than 10.
 */
Vue.filter("expmonth", value => {
    value = parseInt(value);

    if (value < 10) {
        return `0${value}`;
    } else {
        return value;
    }
});

// usage: {{ file.size | prettyBytes }}
Vue.filter("prettyBytes", function(num) {
    // jacked from: https://github.com/sindresorhus/pretty-bytes
    if (typeof num !== "number" || isNaN(num)) {
        throw new TypeError("Expected a number");
    }

    var exponent;
    var unit;
    var neg = num < 0;
    var units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    if (neg) {
        num = -num;
    }

    if (num < 1) {
        return (neg ? "-" : "") + num + " B";
    }

    exponent = Math.min(
        Math.floor(Math.log(num) / Math.log(1000)),
        units.length - 1
    );
    num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
    unit = units[exponent];

    return (neg ? "-" : "") + num + " " + unit;
});

/**
 *  Pluralize a string
 */
Vue.filter("pluralize", (word, amount = 2) => (amount > 1 ? `${word}s` : word));
