export default [
  {
    path: "/",
    name: "WF",
    component: require("../views/WF").default,
  },
  {
    path: "/store/",
    name: "home",
    component: require("../views/Home").default,
  },
  {
    path: "/store/login",
    name: "login",
    component: require("../views/Auth/Login").default,
  },
  {
    path: "/store/forgotpassword",
    name: "forgotpassword",
    component: require("../views/Auth/ForgotPassword")
      .default,
  },
  {
    path: "/store/generate-password",
    name: "generatepassword",
    component: require("../views/Auth/GeneratePassword.vue")
      .default,
  },
  {
    path: "/store/newpassword/token/:token",
    name: "newpassword",
    component: require("../views/Auth/ForgotPasswordform")
      .default,
  },
  {
    path: "/store/gen-new-password/token/:token",
    name: "gen-new-password",
    component: require("../views/Auth/GenPasswordForm")
      .default,
  },
  {
    path: "/store/verifyaccount/token/:token",
    name: "verifyaccount",
    component: require("../views/Auth/VerifyAccount")
      .default,
  },
  {
    path: "/store/register",
    name: "register",
    component: require("../views/Auth/Register").default,
  },
  {
    path: "/store/about",
    name: "about",
    component: require("../views/About").default,
  },
  {
    path: "/store/account",
    name: "account",
    component: require("../views/Dashboard/Account")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/changepassword",
    name: "changepassword",
    component: require("../views/Dashboard/ChangeEmailPassword")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/address",
    name: "address",
    component: require("../views/Dashboard/Address")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/myquotes",
    name: "myquotes",
    component: require("../views/Dashboard/myQuotes")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/myquote/:quote_id",
    name: "myquote",
    component: require("../views/Dashboard/myQuoteView")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/myorders",
    name: "myorders",
    component: require("../views/Dashboard/myOrders")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/myorder/:order_id",
    name: "myorder",
    component: require("../views/Dashboard/myOrderView")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/addaddress",
    name: "addaddress",
    component: require("../views/Dashboard/AddAddress")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/editaddress/:address_id",
    name: "editaddress",
    component: require("../views/Dashboard/EditAddress")
      .default,
    meta: { auth: true },
  },

  {
    path: "/store/quick-quote",
    name: "quickquote",
    component: require("../views/QuickQuote").default,
    meta: { auth: true },
  },
  {
    path: "/store/forgot-password-message",
    name: "forgot-password-message",
    component: require("../views/ForgotPasswordMessage")
      .default,
  },
  {
    path: "/store/new-password-message",
    name: "new-password-message",
    component: require("../views/NewPasswordMessage.vue")
      .default,
  },
  {
    path: "/store/quote-list",
    name: "quotelist",
    component: require("../views/QuoteList").default,
  },
  {
    path: "/store/request-quote",
    name: "requestquote",
    component: require("../views/RequestQuoteForm").default,
  },
  {
    path: "/store/search",
    name: "search",
    component: require("../views/Search").default,
  },
  {
    path: "/store/contact",
    name: "contact",
    component: require("../views/Contact").default,
  },
  {
    path: "/store/category/:category",
    name: "productlist",
    component: require("../views/ProductList").default,
  },
  {
    path: "/store/product/:product_id",
    name: "product",
    component: require("../views/Product").default,
  },
  {
    path: "/store/product/:product_name/:product_id",
    name: "product",
    component: require("../views/Product").default,
  },
  {
    path: "/store/cart",
    name: "cart",
    component: require("../views/Cart").default,
  },
  {
    path: "/store/checkout/:token",
    name: "checkout",
    component: require("../views/Checkout").default,
    meta: { auth: true },
  },
  {
    path: "/store/new-checkout/:token",
    name: "checkout",
    component: require("../views/newCheckout").default,
    meta: { auth: true },
  },
  {
    path: "/store/checkoutinfo",
    name: "checkoutinfo",
    component: require("../views/checkoutinfo_new").default,
    meta: { auth: true },
  },
  {
    path: "/store/tickets",
    name: "tickets",
    component: require("../views/Dashboard/TicketList")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/createticket",
    name: "createticket",
    component: require("../views/Dashboard/createTicket")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/ticket/:ticketid",
    name: "ticketdetail",
    component: require("../views/Dashboard/TicketDetail")
      .default,
    meta: { auth: true },
  },
  {
    path: "/store/quote/thankyou",
    name: "quote-thankyou",
    component: require("../views/Thankyou").default,
  },
  {
    path: "/store/order/thankyou",
    name: "order-thankyou",
    component: require("../views/orderThankyou").default,
  },

  // 404
  {
    path: "/store*",
    redirect: "/store/404",
  },
  {
    path: "/store/404",
    name: "404",
    component: require("../views/Errors/NotFound.vue")
      .default,
  },
];
