import Auth from "../../services/auth";
import Store from "../../store";

export default function(to, from, next) {
  if (
    to.matched.some((record) => record.meta.auth) &&
    !Auth.isLoggedIn()
  ) {
    return next({
      path: "/store/login",
      params: { nextUrl: to.fullPath },
    });
  } else {
    if (to.name == "login" && Auth.isLoggedIn()) {
      next("/store/account");
    } else {
      next();
    }
  }
}
